import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Navbar from './navbar.component'
import Footer from './footer.component'
import axios from 'axios';
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import '../styles/video.css'
import ChannelList from './channel-list.component'

class ChannelsScreen extends Component {

  constructor(props) {
    super(props);

  }

  componentDidMount() {
    
  }

  render() {

    if(this.props.demoMode && this.props.electronMode){
      return <Redirect to='demo' />
    }

    return (

    <React.Fragment>
      <Helmet>
          <meta name="Description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
          <meta property="og:url" content="https://vidfitness.com/explore-channels"/>
          <meta property="og:title" content="VidFitness - Channels of Different Fitness Content"/>
          <meta property="og:image" content="https://vidfitness.com/vidfitness-screen.jpg"/>
          <meta property="og:description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
          <meta property="og:type" content="website" />
          <meta property="fb:app_id" content="432091207556688"/>
      </Helmet>

      <div>

        <Navbar />
        <br />

         <div className="container-fluid">

          <div className="row"> 
            <div className="col-12 pl-4 pl-sm-5 mt-1 mb-3 pb-3 pb-sm-2">
              <h3 className="page-title">Channels</h3>
            </div>
          </div>


          <div className="container-fluid" style={{minHeight: '50vh'}}>
            <ChannelList />
          </div>

        </div>

        { !this.props.electronMode &&
          <Footer />
        }
      </div>

    </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user:         state.user,
    electronMode: state.general.electronMode,
    demoMode:     state.general.demoMode
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsScreen);


// import React, { Component } from 'react';
// import { Redirect } from 'react-router-dom'
// import { connect } from 'react-redux'
// import Navbar from './navbar.component'
// import Footer from './footer.component'
// import axios from 'axios';
// import { saveTracks, deleteTrackAsync } from '../actions/trackActions'
// import { updateUser } from '../actions/userActions'
// import Track from './track.component'
// import { Link } from "react-router-dom";
// import { isUserStravaAcessTokenFresh, refreshStravaAccessToken } from '../utils/utils'
// import {Helmet} from "react-helmet";
// import '../styles/homepage.css'
// import '../styles/video.css'
// import placeholder from '../images/placeholder.png'
// var moment = require('moment')


// class ChannelsScreen extends Component {


//   constructor(props) {
//     super(props);
//     this.state = {channels: []};

//     this.imgError = this.imgError.bind(this)
//   }

//   componentWillMount() {

//   }


//   componentDidMount() {
//     this.loadUsersFromServer()
//   }


//   // loadUsersFromServer() {
//   //   // Gets videos from current user
//   //   axios.get((process.env.REACT_APP_SERVER)+'/api/v1/users')
//   //     .then(response => {
//   //       const data = response.data

//   //       data.data[0].races.map((race) => {
//   //         console.log(moment(race.timestamp).format("MMMM Do YYYY"))
//   //       })

//   //       this.setState({
//   //         channels: data.data,
//   //       });
//   //     })
//   //     .catch(function (error){
//   //       console.log(error);
//   //     })
//   //  }

//   // getFirstImageURL(url){
//   //   return (process.env.REACT_APP_VIDEOS_OPTIMIZED_BUCKET)+url+'/thumbs-'+url+'-00001.jpg';
//   // };

//   // getFirstImageURL(url){
//   //   return process.env.REACT_APP_ELECTRON_VIDEOS_BUCKET + '/' + url + '/thumbs-'+url+'-00001.jpg';
//   // };

//   // imgError(ev) {
//   //   ev.target.src = placeholder
//   //   return true
//   // }

//   render() {

//     if(this.props.demoMode && this.props.electronMode){
//       return <Redirect to='demo' />
//     }

//     return (

//     <React.Fragment>
//       <Helmet>
//           <meta name="Description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
//           <meta property="og:url" content="https://vidfitness.com/explore-channels"/>
//           <meta property="og:title" content="VidFitness - Channels of Different Fitness Content"/>
//           <meta property="og:image" content="https://vidfitness.com/vidfitness-screen.jpg"/>
//           <meta property="og:description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
//           <meta property="og:type" content="website" />
//           <meta property="fb:app_id" content="432091207556688"/>
//       </Helmet>

//       <div>

//         <Navbar />
//         <br />

//          <div className="container-fluid">


//           <div className="row ml-2">
//             <div className="col-12 mb-2">
//               <div>&nbsp;</div>
//             </div>
//           </div>


//           <div className="container-fluid">
//             <div className="row">

//               {
//                 this.state.channels.map(function(user, i)

//                 {
//                   return (
//                     <React.Fragment>
//                         <div onClick={ () => { }} className="gallery-ride col-lg-3 col-md-4 col-sm-6 col-xs-12">
//                           <div className=" shadow-lg rounded">
//                             <Link to={ '/channel/' + user.id } className="text-dark">
//                               <div className="videobox aspect16x9 bg-white rounded">
//                                   <img className="w-100 rounded-top" style={{top: '0'}} src={ this.getFirstImageURL(user.races[0].url) } alt="" onError={this.imgError}/>
//                                   <div className="line-clamp-5 p-3">
//                                     <p className="line-clamp-5 small text-left text-dark">{user.channel_description}</p>
//                                   </div>
//                               </div>
//                             </Link>
//                               <div className="gallery-video-title text-center text-truncate bg-white pt-1 pl-2 pr-2 border-left border-right">{user.channel_title}
//                               </div>
//                               <div className="text-center bg-white small mb-1 pb-1 border-left border-right border-bottom rounded-bottom">&nbsp;&nbsp;
//                               </div>
//                           </div>
//                         </div>
//                     </React.Fragment>
//                     )
//                   }.bind(this))
//                 }
//             </div>
//           </div>

//         </div>

//         { !this.props.electronMode &&
//           <Footer />
//         }
//       </div>

//     </React.Fragment>
//     )
//   }
// }

// const mapStateToProps = (state) => {
//   return {
//     user:         state.user,
//     electronMode: state.general.electronMode,
//     demoMode:     state.general.demoMode
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(ChannelsScreen);

