import React, { useState, useEffect, Component } from 'react'
import { connect } from 'react-redux'
import { withRouter }from "react-router-dom";
import { Field, reduxForm } from 'redux-form'
import { toggleProfileFormEditability } from '../actions/uiActions'
import { updateUser } from '../actions/userActions'

import axios from 'axios'
import '../styles/misc.css'
var moment = require('moment');
var _ = require('lodash');

// Render field method
const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning }
}) => (
    <div>
      <input {...input} className="form-control" placeholder={label} type={type} />
      {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
)

//sentence case
String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

// Valitation Function
const validate = values => {
  const errors = {}

  // Display Name

  if (!values.display_name) {
    errors.display_name = 'Required'
  }
  else if (values.display_name.length < 3) {
    errors.display_name = 'Must be 3 characters or more'
  }

  return errors
}

// Warn Function
const warn = values => {
  const warnings = {}

  // Usage Example:
  // if (values.age < 19) {
  //   warnings.age = 'Hmm, you seem a bit young...'
  // }

  if (values.cancel == true) {
    warnings.cancel = 'Are you sure you want to cancel your Subscription?'
  }


  return warnings
}

class ProfileForm extends Component {

  constructor(props) {
    super(props)
    this.secondsToMinutes = this.secondsToMinutes.bind(this)
  }

  secondsToMinutes(seconds) {
    switch(seconds) {
      case '':
      case null:
        return 'Turned Off'
        break;
      case 10:
      case '10':
        return '10 seconds'
        break;
      case 30:
      case '30':
        return '30 seconds'
        break;
      case 60:
      case '60':
        return '1 Minute'
        break;
      case 300:
      case '300':
        return '5 Minutes'
        break;
      case 900:
      case '900':
        return '15 Minutes'
        break;
      case 1800:
      case '1800':
        return '30 Minutes'
        break;
      case 3600:
      case '3600':
        return '60 Minutes'
        break;
    }
  }
 
  render() {
    return (
    <div>
    
    <form id="profile-form" onSubmit={this.props.onSubmit}>

      <div className="container">
        <div className="row justify-content-sm-center" style={{paddingTop: '10px'}}>
          <div className="col col-sm-10">

            {/* Display Name */}
            <div style={{paddingTop:0}}></div>

            { !this.props.isFormEditable &&
              <div className="form-group">
                <label for="displayNameInput">Display Name</label>
                <input readOnly id="displayNameInput" name="display_name" type="text" value={this.props.initialValues.display_name} className="form-control"/>
              </div>
            }

            { this.props.isFormEditable &&
              <div className="form-group">
                <label for="displayNameInput">Display Name</label>
                <Field component={renderField} id="displayNameInput" name="display_name" type="text" required="required" className="form-control"/>
              </div>
            }


            {/* First Name */}

            { !this.props.isFormEditable &&
              <div className="form-group">
                <label for="firstNameInput">First Name</label>
                <input id="firstNameInput" readOnly name="firstNameInput" type="text" value={this.props.initialValues.first_name} className="form-control"/>
              </div>
            }

            { this.props.isFormEditable &&
              <div className="form-group">
                <label for="firstNameInput">First Name</label>
                <Field id="firstNameInput" name="first_name" type="text" component="input" className="form-control"/>
              </div>
            }

            {/* Last Name */}

            { !this.props.isFormEditable &&
              <div className="form-group">
                <label for="lastNameInput">Last Name</label>
                <input id="lastNameInput" readOnly name="lastNameInput" type="text" value={this.props.initialValues.last_name} className="form-control"/>
              </div>
            }

            { this.props.isFormEditable &&
              <div className="form-group">
                <label for="lastNameInput">Last Name</label>
                <Field id="lastNameInput" name="last_name" type="text" component="input" className="form-control"/>
              </div>
            }


            {/*Hide height and weight right now*/}
            
            { false &&

              <div>
              {/* Weight */}
              
              { !this.props.isFormEditable &&
                <div className="form-group">
                  <label for="weightInput">Weight</label>
                  <input id="weightInput" readOnly name="weightInput" type="text" aria-describedby="weightInputHelpBlock" value={this.props.initialValues.weight ? this.props.initialValues.weight + ' lb' : ''} className="form-control"/>
                  <span id="weightInputHelpBlock" className="form-text text-muted">in pounds (lbs)</span>
                </div>
              }

              { this.props.isFormEditable &&
                <div className="form-group">
                  <label for="weightInput">Weight</label>
                  <Field id="weightInput" name="weight" type="text" component="input" aria-describedby="weightInputHelpBlock" className="form-control"/>
                  <span id="weightInputHelpBlock" className="form-text text-muted">in pounds (lbs)</span>
                </div>
              }

              {/* Height */}

              { !this.props.isFormEditable &&
                <div className="form-group">
                  <label for="heightInput">Height</label>
                  <input id="heightInput" readOnly name="heightInput" type="text" aria-describedby="heightInputHelpBlock" value={this.props.initialValues.height || ''} className="form-control"/>
                  <span id="heightInputHelpBlock" className="form-text text-muted">in inches (in)</span>
                </div>
              }

              { this.props.isFormEditable &&
                <div className="form-group">
                  <label for="heightInput">Height</label>
                  <Field id="heightInput" name="height" type="text" component="input" aria-describedby="heightInputHelpBlock" className="form-control"/>
                  <span id="heightInputHelpBlock" className="form-text text-muted">in inches (in)</span>
                </div>
              }
            </div>

          }

            {/* Enter Demonstration Mode After  */}

            { !this.props.isFormEditable &&
              <div className="form-group">
                <label for="demoInput">Demo Mode</label>
                <input id="demoInput" readOnly name="demoInput" type="text" aria-describedby="demoInputHelpBlock" value={ (this.secondsToMinutes(this.props.initialValues.enter_demo_mode_at) || '') } className="form-control"/>
              </div>
            }

            { this.props.isFormEditable &&
              <div className="form-group">
                <label for="enter_demo_mode_at">Demo Mode</label>
                <div>
                  <Field id="enter_demo_mode_at" name="enter_demo_mode_at" component="select" className="custom-select">
                    <option value="">Turned Off</option>
                    <option value="30">30 seconds</option>
                    <option value="60">1 minute</option>
                    <option value="300">5 minutes</option>
                    <option value="900">15 minutes</option>
                    <option value="1800">30 minutes</option>
                    <option value="3600">60 minutes</option>
                  </Field>
                </div>
              </div>
            }

            <div style={{marginTop:-5}}>
              { !this.props.isFormEditable &&
                <div className="form-group">
                  <label></label>
                  <div>
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input 
                        name="strava_auto_save" 
                        id="strava_auto_save_0" 
                        type="checkbox" 
                        disabled
                        checked={ this.props.initialValues.strava_auto_save }
                        className="custom-control-input" />
                      <label for="strava_auto_save_0" className="custom-control-label">Automatically save my workout activity to Strava (if longer than 1 minute).</label>
                    </div>
                  </div>
                </div>
              }

              { this.props.isFormEditable &&
                <div className="form-group">
                  <label></label>
                  <div>
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <Field 
                        name="strava_auto_save" 
                        id="strava_auto_save_0" 
                        type="checkbox" 
                        component="input"
                        className="custom-control-input" />
                      <label for="strava_auto_save_0" className="custom-control-label">Automatically save my workout activity to Strava (if longer than 1 minute).</label>
                    </div>
                  </div>
                </div>
            }
            </div>

            <div style={{marginTop:-10}}>
            { !this.props.isFormEditable &&
              <div className="form-group">
                <label></label>
                <div>
                  <div className="custom-control custom-checkbox custom-control-inline">
                    <input 
                      name="send_weekly_fitness_summary" 
                      id="send_weekly_fitness_summary" 
                      type="checkbox" 
                      disabled
                      className="custom-control-input" 
                      checked={ this.props.initialValues.send_weekly_fitness_summary }/>
                    <label 
                      for="send_weekly_fitness_summary" 
                      className="custom-control-label">Email me a summary of my fitness activities (weekly).</label>
                  </div>
                </div>
              </div>
            }

            { this.props.isFormEditable &&
              <div className="form-group">
                <label></label>
                <div>
                  <div className="custom-control custom-checkbox custom-control-inline">
                    <Field 
                      name="send_weekly_fitness_summary" 
                      id="send_weekly_fitness_summary" 
                      type="checkbox" 
                      component="input" 
                      className="custom-control-input"/>
                    <label 
                      for="send_weekly_fitness_summary" 
                      className="custom-control-label">Email me a summary of my fitness activities (weekly).</label>
                  </div>
                </div>
              </div>
            }
            </div>

            <div style={{marginTop:-10}}>
            { !this.props.isFormEditable &&
              <div className="form-group">
                <label></label>
                <div>
                  <div className="custom-control custom-checkbox custom-control-inline">
                    <input 
                      name="send_most_ridden_tracks" 
                      id="send_most_ridden_tracks" 
                      type="checkbox" 
                      disabled
                      className="custom-control-input" 
                      checked={ this.props.initialValues.send_most_ridden_tracks }/>
                    <label 
                      for="send_most_ridden_tracks" 
                      className="custom-control-label">Email me this week’s most popular workouts (weekly).</label>
                  </div>
                </div>
              </div>
            }

            { this.props.isFormEditable &&
              <div className="form-group">
                <label></label>
                <div>
                  <div className="custom-control custom-checkbox custom-control-inline">
                    <Field 
                      name="send_most_ridden_tracks" 
                      id="send_most_ridden_tracks" 
                      type="checkbox" 
                      component="input" 
                      className="custom-control-input"/>
                    <label 
                      for="send_most_ridden_tracks" 
                      className="custom-control-label">Email me this week’s most popular workouts (weekly).</label>
                  </div>
                </div>
              </div>
            }
            </div>

            <div style={{marginTop:-10}}>
            { !this.props.isFormEditable &&
              <div className="form-group">
                <label></label>
                <div>
                  <div className="custom-control custom-checkbox custom-control-inline">
                    <input 
                      name="send_who_rode_your_rides" 
                      id="send_who_rode_your_rides" 
                      type="checkbox" 
                      disabled
                      className="custom-control-input" 
                      checked={ this.props.initialValues.send_who_rode_your_rides }/>
                    <label 
                      for="send_who_rode_your_rides" 
                      className="custom-control-label">Email me when my uploaded videos are viewed (weekly).</label>
                  </div>
                </div>
              </div>
            }

            { this.props.isFormEditable &&
              <div className="form-group">
                <label></label>
                <div>
                  <div className="custom-control custom-checkbox custom-control-inline">
                    <Field 
                      name="send_who_rode_your_rides" 
                      id="send_who_rode_your_rides" 
                      type="checkbox" 
                      component="input" 
                      className="custom-control-input"/>
                    <label
                      for="send_who_rode_your_rides" 
                      className="custom-control-label">Email me when my uploaded videos are viewed (weekly).</label>
                  </div>
                </div>
              </div>
            }
            </div>

            <div className="col button-holder-submit">
            </div>
          </div>
        </div>
      </div>
    </form>
    
    </div>
  )
  }

}

// let ProfileForm = props => {
//   const [couponError, setCouponError] = useState('')
//   const [couponMode, setCouponMode] = useState(false)
//   const [couponInput, setCouponInput] = useState('')
//   const [couponLoading, setCouponLoading] = useState(false)
//   const [dp, sdp] = useState(props.initialValues.display_name

//   const [acc_level, setAccLevel] = useState(props.initialValues.account_level)
//   const [comp_access, set_comp_access] = useState(props.initialValues.complimentary_access)

//   if(props.electronMode) {
//     const { ipcRenderer } = window.require('electron')
//     ipcRenderer.on('stripe-client-portal-closed', (event, arg) => {
//       props.history.push('/portal-redirect')
//     })
//     ipcRenderer.on('strava-portal-closed', (event, arg) => {
//       console.log(arg)
//       props.history.push(`/portal-redirect${arg}`)
//     })
//   }

//   const { handleSubmit } = props

//   const secondsToMinutes = (seconds) => {
//     switch(seconds) {
//       case '':
//       case null:
//         return 'Turned Off'
//         break;
//       case 10:
//       case '10':
//         return '10 seconds'
//         break;
//       case 30:
//       case '30':
//         return '30 seconds'
//         break;
//       case 60:
//       case '60':
//         return '1 Minute'
//         break;
//       case 300:
//       case '300':
//         return '5 Minutes'
//         break;
//       case 900:
//       case '900':
//         return '15 Minutes'
//         break;
//       case 1800:
//       case '1800':
//         return '30 Minutes'
//         break;
//       case 3600:
//       case '3600':
//         return '60 Minutes'
//         break;
//     }
//   }

//   // const renderGoogleLogin = () => {
    

//   //   return (
//   //     <a onClick={ () => {   }} className="sign-in-button">
//   //       <img src='...' alt="Logo" className="google_logo"/>
//   //       Log in with Google
//   //     </a>
//   //   )
//   // }

//   const days_left = (date) => {

//     const renderCoupon = () => {
//       return (
//         <div>
//           { !couponMode &&
//             <div>
//               <button onClick={() => {
//                 setCouponMode(true)
//               }} style={{verticalAlign: 'top', marginBottom: 0}} className="btn-success btn-sm">Coupon code?</button>
//             </div>
//           }
//           { couponMode &&
//             <React.Fragment>
//               <form>
//                 <div style={{display:'flex'}} className="mb-1">
//                   <input style={{width: '30%'}} className="form-control form-control-sm" type="text" placeholder="Enter a code!" value={couponInput} onChange={(event) => setCouponInput(event.target.value)}/>
//                   <button onClick={() => {
//                     handleCoupon(couponInput)
//                   }} style={{verticalAlign: 'top', marginBottom: 0, paddingTop: 0, paddingBottom: 0}} type="button" className="ml-1 btn-success btn-sm">
//                     { couponLoading &&
//                       <React.Fragment>
//                         <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
//                         <span>Loading...</span>
//                       </React.Fragment>
//                     } 
//                     { !couponLoading &&
//                       <React.Fragment>
//                         <span>Submit</span>
//                       </React.Fragment>
//                     }
//                   </button>                  
//                 </div> 
//               </form>
//               <div className="text-danger" style={{fontSize: '0.75rem'}}><p>{couponError}</p></div>
//             </React.Fragment>
//           }
//         </div>
//       )
//     }

//     var then = moment(date)
//     var now = moment();
//     var level = acc_level
//     if (level == 'trial') {
//     return (
//       <div>
//         <h5>
//           Your trial will expire {now.to(then)}.
//         </h5>
//         {renderCoupon()}
//       </div>)
//     } else if (level == 'cancelled_period_not_over'){
//       return (<div><h5>You cancelled your <b>{props.initialValues.previous_account_level.toProperCase()}</b> plan.</h5><h5>Your plan features expire {now.to(then)}.</h5></div>)
//     } else if (level == 'core' || level == "pro") {
//       return (
//         <div>
//           <h5>
//             {(comp_access ? 
//               (<div>Complimentary {(level).toProperCase()} Plan</div>) : 
//               (<div>{(level).toProperCase()} Plan</div>))} 
//             </h5>
//         </div>)
//     } else if (level == 'free') {
//       return (
//         <div style={{height: '100%'}}>
//           <div>
//             <h5>
//                 {`${(level).toProperCase()} Plan`}
//             </h5>
//             {renderCoupon()}
//           </div>
          
//         </div>
//       )
//     }
//   }

//   const handleCoupon = async (couponText) => {

//     setCouponLoading(true)

//     var payload = {
//       user_id: props.initialValues.user_id, 
//       code: couponText
//     }

//     try {
//       var response = await axios.post((process.env.REACT_APP_SERVER) + '/api/v1/coupon/grant', payload)

//       setAccLevel(response.data.account_level)

//       set_comp_access(response.data.complimentary_access)

//       props.dispatchUpdateUser(response.data)

//       setCouponError('')

//       setCouponInput('')

//     } catch (error) {
//       var eToSet;
//       switch (error.response.data.status) {
//         case 'invalid-code': 
//           eToSet = "Invalid Code!"
//           break;
//         case 'coupon-out-of-date': 
//           eToSet = "Expired!"
//           break;
//         case 'coupon-reached-max':
//           eToSet = "Max already claimed"
//           break;
//         case 'user-does-not-exist':
//           eToSet = "User does not exist, you are a ghost!"
//           break;
//         case 'already-comp':
//           eToSet = "You already have this!"
//           break;
//         default: 
//           eToSet = "Whoops, something went wrong!"
//       }

//       setCouponError(eToSet)
//     }
    

//     // console.log(response)

//     setCouponLoading(false)

//   }

//   const openExternalPortal = (message, url) => {
//     const { ipcRenderer } = window.require('electron')
//     //send message to electron to start loopback server
//     ipcRenderer.send(message, url)
//   }

//   const handleStravaPortal = () => {
//     if (props.electronMode) {
//       const url = `https://www.strava.com/oauth/authorize?client_id=${process.env.REACT_APP_STRAVA_CLIENT_ID}&response_type=code&redirect_uri=http://127.0.0.1:42420/callback-strava&scope=read_all&scope=activity:write`;
//       openExternalPortal('strava-portal-opened', url)
//     } else {
//       const url = `https://www.strava.com/oauth/authorize?client_id=${process.env.REACT_APP_STRAVA_CLIENT_ID}&response_type=code&redirect_uri=${(process.env.REACT_APP_HOSTNAME)}/portal-redirect&scope=read_all&scope=activity:write`;
//       window.location.href = url
//     }
//   }

//   const handlePortal = () => {
//     const portal_request = (mode) => {
//       return fetch((process.env.REACT_APP_SERVER)+'/api/v1/payment/' + props.initialValues.user_id + '/customer-portal', {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json"
//         }, 
//         body: 
//           JSON.stringify({
//             electronMode: props.electronMode
//           }) 
//       }).then(async (res)=> {
//         const response = await res.json()
//         if (mode == 'web') {
//           window.location.replace(response.portal_url)
//         } else {
//           openExternalPortal('stripe-client-portal-opened', response.portal_url)
//         }
//       });
//     }
    
//     var level = acc_level
//     var complimentary_access = comp_access


//     //if you don't have complimentary access return portal button
//     if (!complimentary_access) {
//       //if user has a stripe account exising
//       if (level ==  'pro' || level == 'core' || level == 'payment_failed' || level == 'cancelled_period_not_over') {
//         //checks whether in electron or not
//         if (!props.electronMode) {
//           return (
//             <div>
//               <button type="button" className='header-button btn btn-info float-right' onClick={() => {portal_request('web')}}>{level == 'cancelled_period_not_over' ? 'Restore Account' : 'Manage Billing'}</button> 
//             </div> )
//         }
//         else {
//           return (
//             <div>
//               <button type="button" className='header-button btn btn-info float-right' onClick={() => {portal_request('electron')}}>{level == 'cancelled_period_not_over' ? 'Restore Account' : 'Manage Billing'}</button> 
//             </div> )
//         }
//         //provide link to pricing page if user has a free acccount
//       } else if (level == 'free') {
//         return (
//           <div>
//             <button type="button" className='header-button btn btn-info float-right' onClick={() => props.history.push('/pricing')}>Upgrade Now</button> 
//           </div> )
//       } else {
//         return (<div></div>)
//       }
//     } else {
//       return (<div></div>)
//     }
//   }}

ProfileForm = reduxForm({
  form: 'profile',
  validate,
  warn,
  enableReinitialize: true
})(ProfileForm)


const mapStateToProps = (state) => {
  const ui = state.ui
  const user = state.user

  return {
    isFormEditable: ui.isProfileFormEditable,
    electronMode: state.general.electronMode || false,
    initialValues:{
      display_name: user.display_name,
      first_name: user.first_name,
      last_name: user.last_name,
      weight: user.weight,
      height: user.height,
      enter_demo_mode_at: user.enter_demo_mode_at,
      gender: user.gender,
      members_riding_your_videos_notification: user.members_riding_your_videos_notification,
      strava_auto_save: user.strava_auto_save,
      user_id : user.id,
      send_most_ridden_tracks: user.send_most_ridden_tracks,
      send_who_rode_your_rides: user.send_who_rode_your_rides,
      send_weekly_fitness_summary: user.send_weekly_fitness_summary
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchToggleEditability: () => { dispatch(toggleProfileFormEditability())},
    dispatchUpdateUser: (values) => {
      dispatch(updateUser(values))
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileForm))
