import React, { Component } from 'react';
import { connect } from 'react-redux'
import Navbar from './navbar.component';
import ProfileForm from './profile-form.component'
import ProfileHeader from './profile-header.component'
import Footer from './footer.component'
import { updateUserAsync } from '../actions/userActions'
import { toggleProfileFormEditability } from '../actions/uiActions'


class Profile extends Component {

  constructor(props) {
    super(props)

    this.couponSuccess = this.couponSuccess.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount(){
    if(!this.props.user.isAuthenticated){
      this.props.history.push('/login?redirect=profile')
    }
  }

	handleSubmit(values) {
    this.props.dispatchUpdateUserSubmit(values)
  }

  couponSuccess(newValues) {
    this.props.dispatchUpdateUserCoupon(newValues)
  }


	render (){
		return (
			<div>
				<Navbar />
				<br />
        <ProfileHeader
          couponSuccess={this.couponSuccess}
        />
				<ProfileForm
          onSubmit={this.handleSubmit} 
        />
        {!this.props.electronMode &&
          <Footer />
        }
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    electronMode: state.general.electronMode
  }
}

const mapDispatchToProps = (dispatch, getState, ownProps) => {
  return {
  	dispatchUpdateUserSubmit: (values) => {
      dispatch(updateUserAsync(values)).then( () => {
        dispatch(toggleProfileFormEditability())
      })
  	},
    dispatchUpdateUserCoupon: (values) => {
      dispatch(updateUserAsync(values))    
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)

