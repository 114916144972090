import React, { Component } from 'react';
import { connect } from 'react-redux'
import store from '../index.js';
import config from '../config.json';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect }from "react-router-dom";
import { Link } from "react-router-dom";
import { Formik } from 'formik';
import { authenticate } from '../actions/userActions';


class PasswordRecovery extends Component {


  constructor(props){
    super(props);

    this.state = {
      general_error: '',
      email_sent: false
    }

  }

  shouldButtonbeDisabled(){
    if( this.state.general_error != '' || this.state.email_sent != false){
      return true
    }
    else{
      return ''
    }
  }

  validateEmail(email){
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
  }

  renderForm(){
    return (
      <div className="container">
        <div className="row">
          <div className="pt-3 col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 text-left">
            <Formik
              initialValues={{ email: ''}}
              validate={values => {

                let errors = {}

                if(values.email == ''){
                  errors.email = 'Required'
                } else {

                  let isEmailValid = this.validateEmail(values.email)



                  if(!isEmailValid){
                    errors.email = 'Invalid Email'
                  }

                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting, setErrors, setFieldError }) => {


                if(this.state.email_sent == true){
                  return
                }

                this.setState({ email_sent : true, general_error: null})

                const options = {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(values)
                }

                // setTimeout(() => {
                //   setSubmitting(false);
                // }, 400);

                return fetch((process.env.REACT_APP_SERVER)+'/api/v1/initiate-password-recovery', options)
                  .then((response) => {
                    return response.json()
                  })
                  .then((response) => {

                    if(response.status == 'failure' && response.message == 'user-not-found'){
                      this.setState({
                        general_error: 'An email has been sent to your account with further instruction.'
                      })
                    }

                    if(response.status == 'success'){
                      this.setState({ email_sent : true, general_error: null})
                    }

                  })


                  .catch(error => console.log('Error', error))

              }}
            >
            { ({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                submitForm
                /* and other goodies */
              }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-group pb-3">
                { !this.shouldButtonbeDisabled() &&
                  <input
                     type="email"
                     name="email"
                     className="form-control"
                     placeholder="Email"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.email}
                   />
                 }
                 <div className="col-sm-12 text-left">
                   <small id="passwordHelp" className="text-danger">
                     {errors.email && touched.email && errors.email}
                   </small>
                 </div>
                </div>

                { this.state.email_sent &&
                  <div id="pb-3 general-message text-center">
                    If an account exists with this email, we'll send you a link to reset your password now. After you reset your password, <Link to="./login">log in</Link>.
                    <br/><br/>
                  </div>
                }

                

                { !this.shouldButtonbeDisabled() &&
                  <React.Fragment>
                  <div className="pt-2 text-center">
                    <button className='btn btn-outline-secondary back-btn' onClick={ () => this.props.history.goBack() }>
                      Back
                    </button>
                    <button className='btn btn-outline-success sign-up-btn'>
                    Send Link
                    </button>
                  </div>
                  </React.Fragment>
                }

                 {/* { this.shouldButtonbeDisabled() &&
                  <button className='btn btn-outline-secondary sign-up-btn' onClick={ () => this.props.history.push('/') }>
                    Go to homepage
                  </button>
                } */}

              </form>
            )}

            </Formik>
          </div>
        </div>
      </div>
    )


  }



  render(){

    if(this.props.user.id != ''){
      return <Redirect to='/' />
    }

    return (
      <div className="login-panel">
          <div className="container-fluid container-centered pt-3">
              <div>
                  <div className="center-align">
                      <Link to="/"><img src="./vidfitness-red.png" className="logo-main"/></Link>
                  </div>
                  <div>
                    { !this.shouldButtonbeDisabled() &&
                      <div className="center-align mb-3">
                        Enter your email and we'll send you a link to reset your password.
                      </div>
                    }
                  </div>
                  <div className="center-align">
                    { this.renderForm() }
                  </div>
              </div>
          </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    electronMode: state.general.electronMode
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchAuthenticate: (user_info) => { dispatch(authenticate(user_info)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery);
