import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import scrollDown from '../images/scroll_down.png'
import InfiniteListBottom from './infinite-list-bottom.component'
import '../styles/activities.css'
import ChannelCard from './channel-card.component'

class ChannelList extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      channels: [],
      max: 0,
      isFetching: [],
      start: 0,
      finish: false,
      isFetching: false,
      noChannels: false,
    }

    this.PAGINATE_SIZE = 100
    this.handleScroll = this.handleScroll.bind(this)
  }

  handleScroll() {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
    if (!this.state.isFetching) {
      this.setState({isFetching: true}, () => this.fetchMoreTracks())
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    var end = 0 + this.PAGINATE_SIZE
    axios.get((process.env.REACT_APP_SERVER)+'/api/v1/users/channels/index', 
        {
          params: {
            start: 0,
            end: end
        }
      })
      .then(response => {
          const { count, rows } = response.data

          var realCount = parseInt(count[0].count)

          var filtered_rows = rows.filter((row) => row.races.length > 0)

          if (realCount > 0) {
            this.setState({
              start: end, 
              max: realCount, 
              channels: filtered_rows,
              loaded: true,
              //JUST TILL WE SOLVE PAGINATE ISSUE
              finish: true
            }, () => console.log(this.state))
          } else {
            console.log('No Channels')
            this.setState({
              finish: true,
              loaded: true,
              noChannels: true
            })
          }
      })

  }

  fetchMoreTracks() {
    console.log('running second fetch')
    if (!this.state.finish) {
      var end = this.state.start + this.PAGINATE_SIZE
      axios.get((process.env.REACT_APP_SERVER)+'/api/v1/users/channels/index', 
        {
          params: {
            start: this.state.start,
            end: end
        }
      })
      .then(response => {
          const { count, rows } = response.data

          var filtered_rows = rows.filter((row) => row.races.length > 0)

          var finish = false

          if (end >= this.state.max) {
            console.log('we are setting finish to true')
            finish = true
          }

          this.setState({
            channels: this.state.channels.concat(filtered_rows),
            finish: finish,
            isFetching: false,
            start: end
          }, () => console.log('MORE FETCH', this.state))
      })
      .catch(function (error){
          console.log(error);
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {

    return (
      <React.Fragment>

        <div>
          { !this.state.noChannels &&
          <div>
            { this.state.loaded ?
            <div>
              <div className="row px-sm-2">
                {this.state.channels.map((user) => <ChannelCard user={user} />)}
              </div>
              <div>
                <InfiniteListBottom isFetching={this.state.isFetching} finish={this.state.finish}/>
              </div>
            </div> :
            <div className="spinner-container">
              <div className="loading-spinner">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            }
          </div>
          }
        </div>

        <div>
          { this.state.noChannels && this.state.loaded &&
            <div className="text-center"><h3>There are no Channels currently.</h3></div>
          }
        </div>

      </React.Fragment>
    );
  }

}

const mapStateToProps = (state) => {  

  return {
    user:         state.user,
    electronMode: state.general.electronMode,
    demoMode:     state.general.demoMode,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelList);