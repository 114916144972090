import React, { Component, useState } from 'react';
import { connect } from 'react-redux'
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { updateTrack } from '../actions/trackActions'
import { getInfoFromVideoUrl } from '../utils/utils.js'
import { Link, withRouter } from "react-router-dom";
import { getMiles } from '../utils/utils'
import placeholder from '../images/placeholder2.png'
import stravaUpload  from '../images/strava.png'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import '../styles/activities.css'
// var tmp = require('tmp');
const fs = require('fs');
var strava = require('strava-v3');

//TO DO MAKE PROFILE PAGE DISAPPER ON RESIZE
//MAKE PARTICPANTS DISAPPEAR ON RESIZE

const Track = props => {

	const belongsToUser = props.user.userId == props.track.userId

	const disabledStyle = {
	  opacity:'0.4'
	};

  const [uploaded, setUploaded] = useState(props.track.uploaded)

	// these methods extract the video name from the entire url of the video
	const video_file_name = props.track.url.substring(props.track.url.lastIndexOf('/') + 1).replace(/\.[^/.]+$/, "")

  function imgError(ev) {
    ev.target.src = placeholder
    return true
  }

  const deleteTrack = (track, index) => {

    const MySwal = withReactContent(Swal)

    MySwal.fire({
      title: 'Are you sure you want to delete this track?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
      preConfirm: async () => {
        MySwal.showLoading()

        props.deleteTrack(track, index)
      }
    }).then(async (result) => {
      if (result.value) {

        // MySwal.fire(
        //   'Deleted!',
        //   'Your track has been deleted',
        //   'success',
        // )

      } else {
        console.log('Do nothing!')
      }
    })



  }


	const uploadTrack = (track) => {

    const MySwal = withReactContent(Swal)

    MySwal.fire({
      title: 'Are you sure you want to upload this track to Strava?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Upload',
      preConfirm: async () => {
        return uploadAsync(track)
          .then(response => {
            if (!response) {
              throw new Error('Something went wrong in your track upload')
            }
            return true
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      }
    }).then(async (result) => {

      console.log(result)
      if (result.value) {

        MySwal.fire(
          'Uploaded!',
          'Your track has been uploaded!',
          'success',
        )

      } else {

        MySwal.fire(
            'Dismissed!',
            'Your track was not uploaded!',
            'warning',
          )
      }
    })

		// Plan for the new upload
		// The following address is the download link for the gpx file that is used to upload to strava
		console.log('http://localhost:4000/api/v1/track/'+track.id+'/download')
		// if you take this file and manually upload to strava it correctly creates an activity (although you have to fill out more info)

		// The following is the API method to do the above but not manually
		// I've tried specifying the file as the download link off the server but that didn't work
		// So I think you'll need to have a loop to download this file locally and then do the following post
    const uploadAsync = (track) => {

      const options = {
        method: 'GET',
        mode: 'cors',
        cache: 'default'
      };

      return new Promise((resolve, reject) => {

        fetch((process.env.REACT_APP_SERVER) + '/api/v1/track/' + track.id + '/download', options)
          .then(response => {
            // Only marks track as uploaded if track download route returns a 200
            if(response.status == 200){
              let data = {
                track_id: track.id
              }

              const options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                  'Content-Type': 'application/json',
                },
                mode: 'cors',
                cache: 'default'
              };

              return fetch((process.env.REACT_APP_SERVER)+'/api/v1/track/mark_as_uploaded', options)
                .then(track_response => {
                  console.log('track_response', track_response)
                  console.log('Track marked as uploaded on backend')

                  props.dispatchUpdateTrack({id: track.id, uploaded:true})
                  console.log('Track marked as uploaded on frontend')
                  setUploaded(true)
                  // window.alert('Activity uploaded to Strava.')
                  resolve(true)
                })
                .then(response => {
                  console.log('response', response)
                })
                .catch(e => {
                  console.log('err', e)
                })
            } else {
              resolve(false)
            }

          })

      })

    }

	}

  function display_power(track) {
    if (track.duration == 0) {
      return NaN
    } else {
      return track.accumulated_power / track.duration
    }
  }




  function numberWithCommas(x) {
    if (x == null || x === undefined) {
      return 'NAN'
    } else {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  let [filename, thumb_url] = getInfoFromVideoUrl(props.track.url)

	return (

    <div className="mb-1">
      <div className="container-fluid mt-1 mb-1 px-0">

        <div className="row w-100 px-0 mx-0">
          <div className="d-none d-sm-block col-sm-5">
            <div className="aspect16x9 rounded">
              <img className="img-zoom w-100" onClick={() => props.history.push('/video/' + video_file_name)} style={{cursor: 'pointer', borderRadius:'.75rem'}} src={thumb_url} onError={imgError}/>
            </div>
          </div> 
          <div className="col-12 col-sm-7">
            <div className="row w-100 no-gutters">
              <div className="col-9 truncate-parent">
                <span className="activity-title truncate">
                  <Link className="text-dark" to={{
                  pathname:  "/video/" + video_file_name,
                  state: {
                  title: props.track.title
                  }
                  }} >{props.track.title}
                  </Link>
                </span>
              </div>
              <div className="col-3">
                <div className="float-right no-wrap">

                  { belongsToUser &&
                    <span style={{cursor:'pointer'}}><FontAwesomeIcon onClick={() => { deleteTrack(props.track, props.index) }} className='trash-icon mr-2' icon={['fa', 'trash']}/></span>
                  }


                  { belongsToUser && !uploaded && (props.track.uploaded == false || props.track.uploaded == null) && props.connected_to_strava &&
                    <span style={{cursor:'pointer'}}>
                      <img src={stravaUpload} onClick={() => { uploadTrack(props.track) }} className='trash-icon'/>
                    </span>
                  }

                  { belongsToUser && (props.track.uploaded == true || !props.connected_to_strava || uploaded) &&
                    <span style={disabledStyle} >

                        <img src={stravaUpload} disabled onClick={ () => console.log('Clicked!!') } className='trash-icon'/>

                    </span>
                  }
                </div>
              </div>
            </div>
            <div className="row mb-0">
              <div className="col-12 truncate-parent">
                <span className="activity-date truncate"><Moment format="LLL">{props.track.timestamp}</Moment></span>
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-12 activity-link">
              </div>
            </div>
            <div className="row">
              <div className="col-4 activity-header truncate">Distance</div>
              <div className="col-4 activity-header truncate">Duration</div>
              <div className="col-4 activity-header truncate">Avg. Power</div>
            </div>
            <div className="row pb-1">
              {/*<div className="col-4 activity-value activities-participants">
                <div>
                  {props.track.race_position}/{props.track.race_participants}
                </div>
                <span className="participants-actual-text" style={{fontSize:10, paddingLeft: 5, paddingTop: '1vh'}}>
                  participants
                </span>
              </div>*/}
              {/*Wierd number below converts distanace to miles*/}
              <div className="col-4 activity-value no-wrap">{numberWithCommas((props.track.total_distance*0.000621371192).toFixed(2))} mi</div>
              <div className="col-4 activity-value no-wrap">{new Date(props.track.duration * 1000).toISOString().substr(12, 7)}</div>
              <div className="col-4 activity-value no-wrap">{Math.round(display_power(props.track))} w</div>
            </div>
          </div>
        </div>
      </div>
    </div>

	)
}


const mapStateToProps = (state) => {
  
  return {
    user: state.user,
    connected_to_strava: state.user.strava_authorization_code
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  	dispatchUpdateTrack: (data) => { dispatch(updateTrack(data)) }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Track));

