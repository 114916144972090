import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter }from "react-router-dom";
import { Field, reduxForm } from 'redux-form'
import { toggleProfileFormEditability } from '../actions/uiActions'
import { updateUser } from '../actions/userActions'
import FileUploadProgress  from 'react-fileupload-progress';
import { whichChallengesToRecalculate, recalculateRankings } from '../utils/utils'
import '../styles/new-video.css';



// Valitation Function
const validate = values => {
  const errors = {}
  
  // Video Name

  if (!values.video_name) {
    errors.video_name = 'Required'
  }
  else if (values.video_name.length < 3) {
    errors.video_name = 'Must be 3 characters or more'
  }

  // Video file

  if (!values.video_file) {
    errors.video_file = 'Required'
  }

  return errors
}

// Warn Function
const warn = values => {
  const warnings = {}

  // Usage Example:
  // if (values.age < 19) {
  //   warnings.age = 'Hmm, you seem a bit young...'
  // }
  return warnings
}


class VideoForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      name: '',
      description: '',
      search_visible: true,
      channel_visible: true,
      isSubmitting: false
    }

    this.handleChange               = this.handleChange.bind(this);
    this.handleDescriptionChange    = this.handleDescriptionChange.bind(this);
    this.handleChangeChannelVisible = this.handleChangeChannelVisible.bind(this);
    this.handleChangeSearchVisible  = this.handleChangeSearchVisible.bind(this);

  }

  handleChange(event) {
    this.setState({name: event.target.value});
  }

  handleDescriptionChange(event) {
    this.setState({description: event.target.value});
  }

  handleChangeChannelVisible(event) {
    this.setState({channel_visible: !this.state.channel_visible})
  }

  handleChangeSearchVisible(event) {
    this.setState({search_visible: !this.state.search_visible})
  }

  render(){
    return(
      <div className="container">
        <div className="row justify-content-sm-center">
          <div className="col col-sm-8">
           <h3>Upload Your Video </h3>

           <hr className='vidRacer-hr'/>

           {/* Video Name */}

           <div className="row">
             <span className="form-subtitle col-sm-2 col-form-label">Details </span>
           </div>

           <hr className='vidRacer-hr-gray'/>

            <div className="form-group row grayed-row" >
               <label htmlFor="nameInput" className="profile-label col-sm-2 col-form-label">Name</label>
               <div className="col-sm-4">
                  <input type="text" value={this.state.name} onChange={this.handleChange} />
               </div>
               
            </div>
            <div className="form-group row grayed-row" >
              <label htmlFor="nameInput" className="profile-label col-sm-2 col-form-label">Description</label>
              <div className="col-sm-4">
                <input type="text" value={this.state.description} onChange={this.handleDescriptionChange} />
              </div>
            </div>


            <div className="row">
              <span className="form-subtitle col-sm-4 col-form-label"> Video File </span>
            </div>

            <hr className='vidRacer-hr-gray'/>

            <div className="form-group row grayed-row" >
              <div className="col-sm-4">
                <FileUploadProgress
                    method='POST'
                    key='ex1'
                    formCustomizer={ (formData) => {
                      formData.append("name", this.state.name);
                      formData.append("description", this.state.description);
                      formData.append("search_visible", true);
                      formData.append("channel_visible", true);
                      formData.append("user_id", this.props.user.id);

                      document.querySelectorAll('[type="submit"]')[0].disabled = true

                      return formData
                    }}
                    url= {(process.env.REACT_APP_SERVER) + '/api/v1/test-upload' }
                    onProgress={async (e, req, progress) => {
                      console.log('progress', req, progress);

                      if(progress == 100){

                        var challenge_ids = whichChallengesToRecalculate(this.props.user, null)

                        var { data: newUser } = await recalculateRankings(challenge_ids, this.props.user.id)
                        //update user
                        this.props.dispatchUpdateUser(newUser)

                        console.log('Successfully Uploaded File', req)
                        setTimeout( () => {
                          this.props.history.push('/videos')
                        }, 3000)
                      }

                    }}
                    onLoad={ (e, request) => {console.log('load', e, request);}}
                    onError={ (e, request) => {console.log('error', e, request);}}
                    onAbort={ (e, request) => {console.log('abort', e, request);}}
                    />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

VideoForm = reduxForm({
  form: 'profile',
  validate,
  warn
})(VideoForm)



const mapStateToProps = (state) => {
	const user = state.user
  const ui = state.ui

  return {
  	initialValues: {

  	},
    isFormEditable: true,
    isUploading: ui.isLoading,
    user:user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchToggleEditability: () => { dispatch(toggleProfileFormEditability())},
    dispatchUpdateUser: (newUser) => { dispatch(updateUser(newUser))},
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VideoForm))