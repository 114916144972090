import {createStore, applyMiddleware} from 'redux';
import rootReducer from '../reducers/rootReducer';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger'; // Logger with default options
import defaultInitialState from '../reducers/initialState';
import { saveStateToLocalStorage, loadStateFromLocalStorage } from '../localStorage'
import * as types from '../actions/actionTypes';

let initialState = {};
const persistedState = loadStateFromLocalStorage();

// First tries to fetch state from local storage
// if nothing is found we fill the store with the default initial state
if (typeof persistedState == 'undefined'){
  console.info('Loading default state')
  initialState = defaultInitialState;
} else {

  if(persistedState.user && (typeof persistedState.user.enter_demo_mode_at == 'undefined')){
    console.info('Interfering with persisted state, adding hardcoded value of enter_demo_mode_at')
    persistedState.user.enter_demo_mode_at = null
  }

  initialState = persistedState;
  
}


const configureStore = () => {

  let middleware = [thunk]

  if (process.env.REACT_APP_DEBUG_MODE !== 'production') {
    const logger = createLogger({
      // Avoids these logs to flood the console
      predicate: (getState, action) => {
        return true;
      }
    })
    middleware = [...middleware]
  }

  let store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middleware)
  );

  store.subscribe(() => {

    // We save into localStorage the initial state
    // initial state is overriden by redux store.
    // then we override showTerms and confirmedAgreement.
    // Since we want to enforce users to re-acept agreement every time
    saveStateToLocalStorage(
      Object.assign(
        {},
        initialState,
        {
          general: store.getState().general,
          user:    store.getState().user
        },
        {
          general: { showTerms: false, confirmedAgreement: false }
        }
      )
    );
  });

  return store;
}

export default configureStore 