import React, { useState, useEffect } from 'react';
import '../styles/loading.css'


const InfiniteScrollBottom = ({isFetching, finish}) => {
	if (isFetching && !finish) {
		return (
			<div style={{textAlign:'center'}}>
				<div>
          <div className="spinner-container">
            <div className="spinner-grow" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
			</div>
		)
	} else if (finish) {
		return (
			<div></div>
		)
	} else {
		return (
			<div></div>
		)
	}
};

const mapStateToProps = (state) => {	

}

const mapDispatchToProps = (dispatch) => {
}

export default InfiniteScrollBottom