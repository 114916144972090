import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect }from "react-router-dom";
import Navbar from './navbar.component';
import HomePage from './homepage.component'
import { updateUser } from '../actions/userActions'
import queryString from 'query-string'
import axios from 'axios'
import { Link } from "react-router-dom";
var moment = require('moment')

class PortalRedirect extends Component {

	constructor(props){
		super(props)
	}

	async componentDidMount() {
    const values = queryString.parse(this.props.location.search)

    if(this.props.user.id != ''){

      if('code' in values) {

        if( this.props.user.strava_authorization_code == null || this.props.user.strava_authorization_code != values.code ) {

           const {data: user} = await axios.post((process.env.REACT_APP_SERVER)+'/api/v1/auth/strava', { 
            code: values.code,
            user_id: this.props.user.id
          })

          this.props.dispatchUpdateUser(user)
        
        }

      } else {

        const {data: user} = await axios.get((process.env.REACT_APP_SERVER)+'/api/v1/users/' + this.props.user.id)

        this.props.dispatchUpdateUser(user)

      }

    }

  }

	delayRedirect () {
    const { history: { push } } = this.props;
    setTimeout(()=>push('/profile'), 5000);
  }

	render() {
		return (
			<div className="login-panel">
				<div className="container-centered">
				  <div>
			      <div className="center-align">
			        <Link to="/"><img src="./vidfitness-red.png" className="logo-main"/></Link>
			      </div>
				      <div className="center-align">
		            <span className="successfully-logged-in">Please wait while we update your account. We will redirect you in a second!</span>
		            <p style={{marginTop: '10px'}}>If you are not automatically redirected, please click <a href="/profile">here.</a></p>
		          </div>
			    	</div>
			    	{this.delayRedirect()}
				</div>
			</div>
		)
}

}

const mapStateToProps = (state) => {
	const { general, user } = state
  return {
  	user: user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  	dispatchUpdateUser: (data) => { dispatch(updateUser(data)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PortalRedirect);
