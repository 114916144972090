import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Gauges = ({tracker_data}) => {

	return (
      <div className="gauges">
        <span className="heartrate">
          <FontAwesomeIcon icon={['fa', 'heartbeat']}/>
          <span> {tracker_data.heart_rate ? tracker_data.heart_rate : '0'} </span>
        </span>
        <span className="power">
          <FontAwesomeIcon icon={['fa', 'bolt']}/>
          <span> {tracker_data.power ? tracker_data.power : '0'} </span>
        </span>
        <span className="cadence">
          <FontAwesomeIcon icon={['fa', 'certificate']}/>
          <span> {tracker_data.cadence ? tracker_data.cadence : '0'} </span>
        </span>
      </div>
	)
}

export default Gauges