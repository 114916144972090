import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Navbar from './navbar.component';
import Footer from './footer.component';
import { BrowserView, MobileView, MobileOnlyView, TabletView, isMacOs, isWindows, isBrowser, isMobile, browserName } from "react-device-detect";

import MainOverlay from './main-overlay.component';
import { showMainOverlay, hideMainOverlay } from '../actions/generalActions';

import Carousel from "react-multi-carousel";
// import HoverVideoPlayer from 'react-hover-video-player';
import "react-multi-carousel/lib/styles.css";
import "../styles/homepage-override.css";
import HomeCarousel from "./homecarousel.component.js"

class HomePage extends Component {

  constructor(props) {
    super(props);

    this.redirect = this.redirect.bind(this)

    this.state = {
      videohero:      'https://vidfitness-public.s3.amazonaws.com/website/vidfitness-hero-low720.mp4',
      heroheading:    'Get connected. Get fit.',
      herosubheading: 'Workout live with friends.',

      buttontitlemobile: 'Sign me up',
      buttontitledesktop: 'Sign me up',
      
      Xshowcaseheader1:  'Smart trainer? Train smarter.', 
      showcasetext1:    "If you’re using an indoor smart trainer, our desktop app lets you workout live with a friend, coach, group, or other members. See your fitness stats onscreen, track performance over time, and share achievements to Strava. Join challenges, subscribe to channels, and share your own videos.",
      showcaseimage1:   'home-vidfitnesstrainer16x9',
      showcaselink1:    '/videos',

    }

  }
  
  redirect(){
    if (!this.props.user.userId == '') {
      this.props.history.push('/pricing')
    } else {
      this.props.history.push('/login')
    }
  }

  cond_button(term, mobileOrBrowser) {
      if (term == 'header') {
        if (this.props.user.userId == '') {
          if (mobileOrBrowser == 'B') {
            return (
              <Link to="#" onClick={() => {this.redirect(); return false;}} className="btn btn-lg btn-light text-dark">{ this.state.buttontitledesktop }</Link>
            )
          } else {
            return (
              <Link to="#" onClick={() => {this.redirect(); return false;}} className="btn btn-sm btn-light text-dark">{ this.state.buttontitlemobile }</Link>
            )
          }
        }
      }
  }

  render() {
    return (
      <div className="homepage">

<React.Fragment>

{ this.props.guestMode &&

  <div className="hero-video-container">

    <video playsInline="playsInline" autoPlay="autoPlay" loop muted preload="none"><source src={ this.state.videohero } type="video/mp4"/></video>

    <div className="hero-video-overlay">

            <p className="text-center">
                <span className="hero-heading font-weight-bold no-wrap text-white">{ this.state.heroheading }</span>
            <br/>
                <span className="hero-subheading font-weight-normal no-wrap text-white" style={{ lineHeight:'3vh'}}> {this.state.herosubheading }</span>
                <br/><br/>
                    { isBrowser &&
                      this.cond_button('header', 'B')
                    }

                    { isMobile &&
                      this.cond_button('header', 'M')
                    }
              </p>
    </div>
  </div>
}
</React.Fragment>

<div>
  <div>
  { !this.props.electronMode &&
    <div className="text-center pt-5">
      <span className="home-featuredphrase d-inline-block no-wrap"> 
        <Link to="./events" className="text-dark text-decoration-none">View live events</Link>
        <Link to="./events" className="text-dark"><svg xmlns="http://www.w3.org/2000/svg" fill="#363A3F" style={{ height:'.7em' }} class="bi bi-chevron-right" viewBox="-5 -2 20 20"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" stroke="#363A3F" stroke-width="2.5"/></svg>
        </Link>
      </span> 
    </div>
    }
  { this.props.electronMode &&
    <div className="text-center pt-5">
      <span className="home-featuredphrase d-inline-block no-wrap"> 
        <Link to="./events" className="text-dark text-decoration-none">Join a live event</Link> 
        <Link to="./events" className="text-dark"><svg xmlns="http://www.w3.org/2000/svg" fill="#363A3F" style={{ height:'.7em' }} class="bi bi-chevron-right" viewBox="-5 -2 20 20"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" stroke="#363A3F" stroke-width="2.5"/></svg>
        </Link>
      </span> 
    </div>
    }
    { !this.props.electronMode &&
    <div className="container-fluid pt-3 pt-sm-5 pl-4 pr-1">
      <div className="row w-100 justify-content-center">
        <div className="col-12 text-center">
          <BrowserView>
          <span className="lead">To join or create an event and workout with others, <a className="lead text-dark font-weight-bold no-wrap" onClick={() => this.props.history.push('/setup')} href={ (isMacOs ? process.env.REACT_APP_INSTALLER_PATH_MAC : process.env.REACT_APP_INSTALLER_PATH_WIN) }>download &#x2913;</a> the app and connect your smart trainer. Or workout solo with our on-demand web previews.</span>
          </BrowserView>
          <MobileView>
          <span className="lead">To join or create an event and workout with others, use the VidFitness desktop app with your smart trainer. Or workout solo with our on-demand web previews.</span>
          </MobileView>          
        </div>
      </div>
    </div>
    }
  </div>
</div>

    <HomeCarousel/>

{ this.props.guestMode &&

          <section className="showcase pt-0">

            <div>
              <div>
                <div>&nbsp;
                </div>
              </div>
            </div>

            <div className="container-fluid bg-white mt-0 pt-0 pb-5">

              <div className="showcase row mt-3 pt-3 pb-3 mb-3 bg-light pr-1 pl-1">
                <div className="col-md-6 order-lg-2 text-white"><Link to="./about">
                  <picture onClick={() => this.props.history.push(this.state.showcaselink1)}>
                    <source type="image/webp" srcset={ this.state.showcaseimage1 + '.webp'} className="rounded" style={{width:'100%'}} />
                    <source type="image/jp2" srcset={ this.state.showcaseimage1 + '.jp2'} className="rounded" style={{width:'100%'}} />
                    <img src={ this.state.showcaseimage1 + '.jpg'} className="rounded" style={{width:'100%'}} alt="" />
                  </picture></Link>
                </div>
                <div className="col-md-6 order-lg-1 pt-5 my-auto showcase-row">
                  <h3 className="section-head"><Link to="./about" className="text-dark text-decoration-none">On a smart trainer?</Link><br/><Link to="./about" className="text-dark text-decoration-none">Train smarter.</Link></h3>
                  <br/><span className="lead"><Link to="./about" className="text-dark text-decoration-none">{ this.state.showcasetext1 }</Link>
                  <br/><br/>
                  <MobileView>
                  <Link to="./about" className="text-dark font-weight-bold no-wrap">Learn more.<svg xmlns="http://www.w3.org/2000/svg" fill="#363A3F" style={{ height:'.7em' }} class="bi bi-chevron-right" viewBox="-5 -2 20 20"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" stroke="#363A3F" stroke-width="2.5"/></svg></Link>
                  </MobileView>
                  <BrowserView>
                  <Link to="./about" className="text-dark font-weight-bold no-wrap">Learn more<svg xmlns="http://www.w3.org/2000/svg" fill="#363A3F" style={{ height:'.7em' }} class="bi bi-chevron-right" viewBox="-5 -1 20 20"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" stroke="#363A3F" stroke-width="2.5"/></svg></Link> or <a className="lead text-dark font-weight-bold no-wrap" onClick={() => this.props.history.push('/setup')} href={ (isMacOs ? process.env.REACT_APP_INSTALLER_PATH_MAC : process.env.REACT_APP_INSTALLER_PATH_WIN) }>Download now. &#x2913;</a>
                  </BrowserView>
                  </span>
                </div>

              </div>
            </div>

            </section>

}

{ this.props.guestMode &&

<React.Fragment>

          <div className="container-fluid">
            <div className="row mt-0 mb-4 px-2 mx-0 pb-0 pt-0 justify-content-center">
              <div>
                <h3 className="section-head text-center">Enjoy perks.</h3>
                <h5 className="pt-0 text-center">See your profile page for details.</h5>
                </div>
              </div>
          </div>

          <div className="container-fluid usac-promo">

            <div className="row pb-0 pt-3 pb-2 py-sm-4 justify-content-center">
              <div className="col">
                <h5 className="text-center text-warning">Get $20 off</h5>
              </div>
            </div>
            <div className="row">
              <div className="col">
                  <picture>
                    <source type="image/webp" srcSet="usacyclingbanner-lg.webp" className="d-block w-100"/>
                    <source type="image/jpeg" srcSet="usacyclingbanner-lg.jp2" className="d-block w-100"/>
                    <img src="../../public/usacyclingbanner-lg.jpg" alt="" className="d-block w-100"/>
                  </picture>
              </div>
            </div>
            <div className="row pt-3 pb-2 py-sm-4 justify-content-center">
              <div className="col">
                <h5 className="text-center">USA Cycling Membership</h5>
              </div>
            </div>

          </div>

          <div className="container py-0">
            <div>
              <div>&nbsp;
              </div>
            </div>
          </div>

</React.Fragment>

}

  { !this.props.electronMode &&
    <Footer />
  }

      </div>

    );
  }

  state = {
    loading: true
  };

  componentDidMount() {
    if (this.video) {
      this.video.addEventListener("loadeddata", () => {
        this.setState({ loading: false });
      });
    }
    if (isMobile){
      document.body.style.paddingTop = "54px"; 
    } else {
      document.body.style.paddingTop = "54px"; // "5.75vh"; 
    } 
 }

  componentWillUnmount() {
    if (this.video) {
      this.video.removeEventListener("loadeddata", () => {
      });
    }
    document.body.style.paddingTop = null;
  }
  
}
// End video component

// Gets the state -> pass part of it as properties
const mapStateToProps = (state) => {
  const { user } = state

  let guestMode = false

  if( user.userId == '' ){
    guestMode = true
  }

  return {
    user: user,
    electronMode: state.general.electronMode || false,
    guestMode
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  }
}

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage));
