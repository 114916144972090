import React, { Component } from 'react';
import { connect } from 'react-redux'
import store from '../index.js';
import config from '../config.json';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogo from '../images/google.png';
import GoogleLogin from 'react-google-login';
import { authenticate } from '../actions/userActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect }from "react-router-dom";
import { Link } from "react-router-dom";
import queryString from 'query-string';
import {
  isMacOs,
  isWindows
} from "react-device-detect";

// c<a href='#' onClick={() => { this.openExternalSetupPage() }} className="text-success">lick here</a>&nbsp;

class Upgrade extends Component {

  openExternalSetupPage(desired){
    const { shell } = window.require('electron')
    if (isMacOs) {
      shell.openExternal(`${process.env.REACT_APP_HOSTNAME}/setup?platform=mac`)
    } else {
      shell.openExternal(`${process.env.REACT_APP_HOSTNAME}/setup?platform=windows`)
    } 
    
  }

  render(){

    return (
      <div className="login-panel">
          <div className="container-centered">
              <div>
                  <div className="center-align"><a href='/'><Link to="/"><img src="./vidfitness-red.png" className="logo-main"/></Link></a></div>
                  <div>
                      <div className="center-align mb-3 terms-and-services-panel">
                        <p>We&apos;ve added new features to the VidFitness application that require you to download a new copy of the application. 
                        Please click below to download and install the latest version.</p>
                          <button class="btn-lg" onClick={() => this.openExternalSetupPage()}>Download</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    )
  }
}

export default (Upgrade);

