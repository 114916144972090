import initialState from './initialState';
import * as types from '../actions/actionTypes';

const overlayReducer = (state = initialState.overlay, action) => {
  switch (action.type) {

    case types.START_AUDIO_RECORDING: 
      return { ...state, audioRecording: true }

    case types.STOP_AUDIO_RECORDING:
      return { ...state, audioRecording: false }

    case types.UPDATE_AUDIO_LAYERS: 
      return { ...state, audioLayers: (action.payload)}

    case types.ADD_AUDIO_LAYER: 
      return { ...state, audioLayers: state.audioLayers.concat(action.payload) }

    case types.UPDATE_IN_RANGE:
      return { ...state, inRange: (action.payload) }

    case types.UPDATE_PLAYING_OVERLAY_CURRENTLY:
      return { ...state, playingOverlayCurrently: (action.payload)}
      
    default:
      return state;
  }
}

export default overlayReducer;
