import React, { useState, useEffect, Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios';
import scrollDown from '../images/scroll_down.png'
import '../styles/challenges.css'
import InfiniteListBottom from './infinite-list-bottom.component'
import ChallengeCard from './challenge-card.component'
import '../styles/activities.css'
var moment = require('moment')

class ChallengeList extends Component {
  constructor(props) {
    super(props)


    this.mapItems = this.mapItems.bind(this)


  }


  mapItems() {
    var mappedItems = this.props.challenges.map((challenge, i) => {
      return (
        <ChallengeCard 
          challenge={challenge}
          userChallenges={this.props.userChallenges}
        /> 
      )
    })

    return mappedItems
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <div className="row">
            {this.mapItems()}
          </div>
          <div>
            { !this.props.active &&
            <InfiniteListBottom isFetching={this.props.isFetching} finish={this.props.finish}/>
            }
          </div>
        </div> 
      </React.Fragment>
    )
  }

}


const mapStateToProps = (state) => {
  return {
  	user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChallengeList));

