import React, { Component } from 'react';
import { connect } from 'react-redux'
import Navbar from './navbar.component'
import Footer from './footer.component'
import axios from 'axios';
import Track from './track.component'
import { isUserStravaAcessTokenFresh, refreshStravaAccessToken, process_tracks } from '../utils/utils'
import {Helmet} from "react-helmet";
import Skeleton from 'react-loading-skeleton';
import '../styles/challenges.css'
import ChallengeList from './challenge-list.component'
import SplashStatus from './splash-status.component'
import ChallengesSideBar from './challenge-sidebar.component'
import WrapperTab from './wrapper-tab.component.js'
import races from '../images/races.png'
import time from '../images/time.png'
import power from '../images/power.png'
import distance from '../images/distance.png'
import { isVIP } from '../utils/utils'
import profileplaceholder from '../images/profileplaceholder.jpg'
const querystring = require('querystring');
var moment = require('moment')

class ProfileWrapper extends Component {

  constructor(props) {
    super(props);
    this.state = {
      challenge: {},
      userChallengeData: {},
      registeredUser: false,
      loaded: false
    };

    this.refreshUser = this.refreshUser.bind(this)
    this.fetchUserData = this.fetchUserData.bind(this)
    this.imgError = this.imgError.bind(this)

  }


  imgError(ev) {
    ev.target.src = profileplaceholder
    return true
  }

  fetchUserData(){
    console.log('we got here')

    axios.get((process.env.REACT_APP_SERVER)+'/api/v1/user/data',
    {
      params: {
        user_id: this.props.user.id,
      }
    })
    .then(response => {
        this.setState({
          userData: response.data,
          loadedUserData: true
        })
    })
    .catch(function (error){
        console.log(error);
    })
  }

  componentDidMount() {

    if (this.props.signed_in) {
  	 this.fetchUserData()
    }

  }

  refreshUser() {

    this.setState({ loadedUserData: false })

    this.fetchUserData()
  }

  render() {

    if(this.props.demoMode && this.props.electronMode){
      this.props.history.push('/demo')
    }

    return (

    <React.Fragment>
      <div>
        <div className="container">
          <div className="row justify-content-center">

            { this.props.signed_in && this.props.onActivities &&
            <div className="col-12 col-md-4 pr-md-5 sticky-column-activities">
              <div className="row justify-content-center" style={{marginLeft: '0px'}}>
                <div className="activities-card-container d-none d-md-block px-1 pb-1 pt-0">
                  <div className="card" style={{width:'18rem', minHeight: '21rem',borderRadius:'.75rem'}}>
                    <div className="headshot-container pt-5">
                      { this.props.user.photo_url &&
                        <img className="card-image" src={ this.props.user.photo_url} onError={this.imgError} />
                      }
                      { !this.props.user.photo_url &&
                        <img className="card-image" src={ 'https://picsum.photos/id/314/200/200.jpg' } />
                      }
                    </div>
                    <div className="card-body" style={{textAlign:'center'}}>
                      <h3 className="sidebar-title-profilename">{this.props.user.first_name}</h3>
                      <p className="card-text small">Member since {moment(this.props.user.createdAt).format("MMMM YYYY")}</p>
                    </div>
                  </div>
                </div>
                <div className="activities-card-container p-1">
                  <div className="card" style={{width:'18rem', minHeight: '21rem',borderRadius:'.75rem'}}>
                    { !this.state.loadedUserData ?
                      <div className="spinner-container">
                        <div className="loading-spinner">
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div> :
                    <div className="card-body">
                      <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                          <WrapperTab
                            weekly={(this.state.userData.processed_time.weeklyTime)}
                            monthly={(this.state.userData.processed_time.monthlyTime)}
                            yearly={(this.state.userData.processed_time.yearlyTime)}
                            alltime={(this.state.userData.processed_time.allTime)}
                            units={"mins"} />
                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                          <WrapperTab
                            weekly={this.state.userData.processed_rides.weeklyRides}
                            monthly={this.state.userData.processed_rides.monthlyRides}
                            yearly={this.state.userData.processed_rides.monthlyRides}
                            alltime={this.state.userData.processed_rides.allRides}
                            units={"rides"} />
                        </div>
                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                          {/*good idea to transiton this processing + the time stuff above to the backend*/}
                          <WrapperTab
                            weekly={this.state.userData.processed_power.weeklyPower}
                            monthly={this.state.userData.processed_power.monthlyPower}
                            yearly={this.state.userData.processed_power.yearlyPower}
                            alltime={this.state.userData.processed_power.allPower}
                            units={"avg. watts"} />
                        </div>
                        <div className="tab-pane fade" id="pills-distance" role="tabpanel" aria-labelledby="pills-distance-tab">
                          <WrapperTab
                            weekly={this.state.userData.processed_distance.weeklyDistance}
                            monthly={this.state.userData.processed_distance.monthlyDistance}
                            yearly={this.state.userData.processed_distance.yearlyDistance}
                            alltime={this.state.userData.processed_distance.allDistance}
                            units={"mi"} />
                        </div>
                      </div>
                      <div classname="mb-3 mt-1">
                        <ul className="nav nav-pills icon-container" id="pills-tab" role="tablist">
                          <li className="nav-item icon-tab">
                            <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">
                              <div>
                                <div>
                                  <img src={time} className="tab-image-sidebar" />
                                </div>
                                <div className="tab-sub-text">
                                  <span className="text-dark">Time</span>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li className="nav-item icon-tab">
                            <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">
                              <div>
                                <div>
                                  <img src={races} className="tab-image-sidebar" />
                                </div>
                                <div className="tab-sub-text">
                                  <span className="text-dark">Rides</span>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li className="nav-item icon-tab">
                            <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">
                              <div>
                                <div>
                                  <img src={power} className="tab-image-sidebar" />
                                </div>
                                <div className="tab-sub-text">
                                  <span className="text-dark">Power</span>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li className="nav-item icon-tab">
                            <a className="nav-link" id="pills-distance-tab" data-toggle="pill" href="#pills-distance" role="tab" aria-controls="pills-distance" aria-selected="false">
                              <div>
                                <div>
                                  <img src={distance} className="tab-image-sidebar" />
                                </div>
                                <div className="tab-sub-text">
                                  <span className="text-dark">Dist.</span>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    }
                  </div>
                </div>
                { this.props.VIP && this.props.have_challenges &&
                <div className="activities-card-container p-1">
                  <div className="card" style={{width:'18rem', minHeight: '21rem',borderRadius:'.75rem'}}>
                    { (!this.state.loadedUserData) ?
                      <div className="spinner-container">
                        <div className="loading-spinner">
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div> :
                      <div className="card-body">
                        <div>
                          <h3 className="mr-2 ml-2 sidebar-title">
                            My Challenges:
                          </h3>
                        </div>
                        <div>
                          <ChallengesSideBar />
                        </div>
                      </div>
                    }
                  </div>
                </div>
                }
              </div>
            </div>
            }
            <div className="col-12 col-md-8 pt-3 pt-md-0">
              <div className="row">
                {this.props.children(this.refreshUser)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
    )
  }
}


const mapStateToProps = (state) => {

  const { user_challenges } = state.user

  if (state.user.id == '') {
    var signed = false
  } else {
    var signed = true
  }
  return {
    tracks:       state.tracks,
    user:         state.user,
    electronMode: state.general.electronMode,
    demoMode:     state.general.demoMode,
    VIP: isVIP(state.user),
    signed_in: signed,
    have_challenges: (user_challenges.length > 0)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileWrapper);
