import * as types from './actionTypes';
import { updateUser } from './userActions'

export const saveTracks = (tracks) => {
	return { type: types.SAVE_TRACKS, payload: tracks }
}

export const deleteTrack = (index) => {
	return { type: types.DELETE_TRACK, payload: index}
}

export const updateTrack = (values) => {
	return { type: types.UPDATE_TRACK, payload: values }
}

export const appendTracks = (values) => {
	return { type: types.APPEND_TRACKS, payload: values }
}