import React, { Component } from 'react';
import { connect } from 'react-redux'
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { updateTrack } from '../actions/trackActions'
import { getInfoFromVideoUrl } from '../utils/utils.js'
import { isMacOs, isWindows, isMobile } from "react-device-detect";

import { withRouter } from 'react-router'
import { Link } from "react-router-dom";
import '../styles/video.css'

const fs = require('fs');
var strava = require('strava-v3');

const Indicator = props => {

  // var owner = false

  // if ('user_id' in props.race) {
  //   owner = (props.race.user_id == props.user.id ? true : false)
  // }
  

	return (
    <div className={"mode-indicator" + ((props.electronMode || props.video.theater_mode) ? '-electron' : '-electron')}>
      { !isMobile &&
      <div>
        { props.video.AudioOverlayMode && props.electronMode && 
          <div className="indicator-container-box mb-2">
            <span className="text-info text-center"><b>Audio Overlay Mode</b></span>
          </div>
        }

        { props.video.mode == 'play' && (props.video.mode != 'race') && props.electronMode && !props.video.AudioOverlayMode &&
          <div className={'indicator-container-box'} >
            <span className="text-info indicator-text text-center"><b>Preview Mode</b></span>
          </div>
        }

        {props.video.mode == 'play' && !props.electronMode &&
          <div style={{display: 'flex'}}>
            
            <div>
              <a className="btn btn-info no-wrap" style={{float: 'right'}} onClick={() => props.history.push('/setup')} href={ (isMacOs ? process.env.REACT_APP_INSTALLER_PATH_MAC : process.env.REACT_APP_INSTALLER_PATH_WIN) }>
                <b>Download our desktop app to workout interactively</b> &#x2913;
              </a>
            </div>
          </div>
        }
      </div>
      }
    </div>
	)
}

const mapStateToProps = (state) => {
  let owner

  return {
    user: state.user,
    video: state.video,
    electronMode: state.general.electronMode,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  	dispatchUpdateTrack: (data) => { dispatch(updateTrack(data)) }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Indicator));

