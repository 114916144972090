import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect }from "react-router-dom";
import Navbar from './navbar.component';
import HomePage from './homepage.component'
import { Link } from "react-router-dom";

var moment = require('moment')

class CancelSuccess extends Component {

	constructor(props){
		super(props)
	}

	delayRedirect () {
    const { history: { push } } = this.props;
    setTimeout(()=>push('/'), 8000);
  }

	render() {
		var then = moment(this.props.userValues.current_period_end_date)
  	var ans = then.fromNow() 
		return (
			<div className="login-panel">
				<div className="container-centered">
				  <div>
					<div className="center-align">
						<Link to="/"><img src="./vidfitness-red.png" className="logo-main"/></Link>
					</div>
					<div className="center-align mb-3">
						<span className="successfully-logged-in"> You have sucessfully cancelled your membership. Please wait while we redirect you.</span>
						<p style={{marginTop: '10px'}}>You will lose access to your subscription at the end of your billing period, which is <b>{ans}</b>.</p>
						<p style={{marginTop: '10px'}}>If you are not automatically redirected, please click <a href="/profile">here.</a></p>
					</div>
			      </div>
			    	{this.delayRedirect()}
				</div>
			</div>
		)
}

}

const mapStateToProps = (state) => {
	var user = state.user
  return {
  	userValues: {
  		current_period_end_date: user.current_period_end_date
     }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelSuccess);
