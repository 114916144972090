import React, { Component } from 'react';
import { connect } from 'react-redux'
import store from '../index.js';
import config from '../config.json';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogo from '../images/google.png';
import GoogleLogin from 'react-google-login';
import { authenticate } from '../actions/userActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect }from "react-router-dom";
import { Link } from "react-router-dom";
import { Formik } from 'formik';


// This mock component is needed to avoid getting an error
// on the web version
const VrGoogleLogin = () => {
  return null
}

const sendGoogleTokensToBackend = (response) => {

  const tokenBlob = new Blob([JSON.stringify({access_token: response.access_token}, null, 2)], {type : 'application/json'});

  const options = {
      method: 'POST',
      body: tokenBlob,
      mode: 'cors',
      cache: 'default'
  };

  fetch((process.env.REACT_APP_SERVER)+'/api/v1/auth/google', options).then(r => {

      const token = r.headers.get('x-auth-token');
      r.json().then(user => {
          if (token) {

            store.dispatch(authenticate({
              photoUrl: user.photo_url,
              email:    user.email,
              authorized: user.authorized,
              ...user,
              token })
            )

            localStorage.setItem('token', token);
            localStorage.setItem('user_id', user.id);
            localStorage.setItem('vr_env', process.env.NODE_ENV);
          }
      });
  })
}



class SignUp extends Component {


  constructor(props){
    super(props);

    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      email_taken: false
    };
  }

  componentDidMount(){

    if(this.props.electronMode){

      const { ipcRenderer } = window.require('electron')

      ipcRenderer.on('google-tokens-received', (event, arg) => {
        sendGoogleTokensToBackend(arg)
      })

    }

  }

  renderGoogleLogin(){
    const { ipcRenderer } = window.require('electron')

    return (
      <a onClick={ () => { ipcRenderer.send('external-browser-google-auth')  }} className="sign-in-button">
        <img src={GoogleLogo} alt="Logo" className="google_logo"/>
        Sign up with Google
      </a>
    )
  }

  googleResponse = (response) => {
      const tokenBlob = new Blob([JSON.stringify({access_token: response.accessToken}, null, 2)], {type : 'application/json'});

      const options = {
          method: 'POST',
          body: tokenBlob,
          mode: 'cors',
          cache: 'default'
      };
      fetch((process.env.REACT_APP_SERVER)+'/api/v1/auth/google', options).then(r => {

          const token = r.headers.get('x-auth-token');
          r.json().then(user => {
              if (token) {
                this.props.dispatchAuthenticate(
                  {
                    ...user,
                    photoUrl: response.profileObj.imageUrl,
                    email: response.profileObj.email,
                    authorized: user.authorized,
                    token }
                  )
                localStorage.setItem('token', token);
                localStorage.setItem('user_id', user.id);
                localStorage.setItem('vr_env', process.env.NODE_ENV);
              }
          });
      })
  };

  externalBrowserGoogleResponse = (response) => {
      const tokenBlob = new Blob([JSON.stringify({access_token: response.accessToken}, null, 2)], {type : 'application/json'});

      const options = {
          method: 'POST',
          body: tokenBlob,
          mode: 'cors',
          cache: 'default'
      };
      fetch((process.env.REACT_APP_SERVER)+'/api/v1/auth/google', options).then(r => {

          const token = r.headers.get('x-auth-token');
          r.json().then(user => {
              if (token) {
                this.props.dispatchAuthenticate(
                  {
                    ...user,
                    photoUrl: response.profileObj.imageUrl,
                    email: response.profileObj.email,
                    authorized: user.authorized,
                    token }
                  )
                localStorage.setItem('token', token);
                localStorage.setItem('user_id', user.id);
                localStorage.setItem('vr_env', process.env.NODE_ENV);
              }
          });
      })
  };

  validateEmail(email){
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
  }

  renderForm(){

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="pt-3 col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 text-left">
            <Formik
              initialValues={{ username: '', email: '', password: '', passwordConfirmation: '' }}
              validate={values => {
                let errors = {};

                if(values.username == ''){
                  errors.username = 'Required'
                }

                if(values.email == ''){
                  errors.email = 'Required'
                } else {

                  let isEmailValid = this.validateEmail(values.email)

                  if(!isEmailValid){
                    errors.email = 'Invalid Email'
                  }

                }

                if(values.password == ''){
                  errors.password = 'Required'
                } else {

                  let passwordTooShort = values.password.length < 6 ? true : false;

                  if(passwordTooShort){
                    errors.password = 'Password must be at least 6 characters long'
                  }

                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting, setErrors, setFieldError }) => {
                this.setState({ 'email_taken':  false })

                const options = {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(values)
                }

                console.log(options)

                setTimeout(() => {
                  setSubmitting(false);
                }, 400);

                return fetch((process.env.REACT_APP_SERVER)+'/api/v1/signup', options)
                  .then((response) => {
                     if(response.status == 409){
                       this.setState({ 'email_taken':  true })
                     }
                     else{
                       return response.json().then( (user) => {
                         this.props.history.push('/user-registered')
                       })
                     }
                  })

                  .catch(error => console.log('Error', error))

              }}
            >
            { ({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                submitForm
                /* and other goodies */
              }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                 <input
                   type="text"
                   name="username"
                   className="form-control"
                   placeholder="Username"
                   onChange={handleChange}
                   onBlur={handleBlur}
                   value={values.username}
                 />
                 <div className="col-sm-12 text-left">
                   <small id="passwordHelp" className="text-danger">
                     {errors.username && touched.username && errors.username}
                   </small>
                 </div>
                </div>

                <div className="form-group">
                 <input
                   type="email"
                   name="email"
                   className="form-control"
                   placeholder="Email"
                   onChange={handleChange}
                   onBlur={handleBlur}
                   value={values.email}
                 />
                 <div className="col-sm-12 text-left">
                   <small id="passwordHelp" className="text-danger">
                     {errors.email && touched.email && errors.email}
                   </small>
                 </div>
                </div>

                <div className="form-group">
                   <input
                     type="password"
                     name="password"
                     className="form-control"
                     placeholder="Password"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.password}
                   />
                   <div className="col-sm-12 text-left">
                     <small id="passwordHelp" className="text-danger">
                       {errors.password && touched.password && errors.password}
                     </small>
                   </div>

                   { this.state.email_taken &&
                     <div id="general-error">
                       <small>
                         An account with this email already exists. <Link to="./login">Sign in</Link> now, or <Link to='./password-recovery'>reset</Link> your password.
                       </small>
                     </div>
                   }

                </div>

                <div className="row mb-1">
                  <div className="col mt-1 mr-4 ml-4 text-center">
                    
                    <button className='btn btn-outline-secondary back-btn' onClick={ () => this.props.history.goBack() }>
                      Back
                    </button>
                      <button className='btn sign-up-btn btn-outline-success text-center' style={{margin: '0 auto', textAlign: 'center'}} onClick={ () => submitForm() } disabled={isSubmitting}>
                    Sign Up
                      </button>
                    
                  </div>
                </div>
              </form>
            )}
            </Formik>
          </div>
        </div>
      </div>
    )


  }



  render(){

    if(this.props.user.id != ''){
      return <Redirect to='/' />
    }

    return (
            <div className="login-panel">
              <div className="container-fluid container-centered pt-3">
                <div>
                  <div className="center-align">
                    <Link to="/"><img src="./vidfitness-red.png" className="logo-main"/></Link>
                  </div> 
                  <div className="row mb-2">
                    <div className="col mt-3 mr-4 ml-4 text-center">
                      <b>Sign up with email:</b>
                    </div>
                  </div>

                  { this.renderForm() }

                  {/* <hr style={{width: '50%'}}/> */}

                  {/* <div className="center-align pt-3 col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"> */}

                    {/* { this.props.electronMode && this.renderGoogleLogin() } */}

                    {/* { !this.props.electronMode &&
                      <GoogleLogin
                          clientId={config.GOOGLE_CLIENT_ID}
                          render={renderProps => (
                            <a onClick={renderProps.onClick} className="sign-in-button">
                              <img src={GoogleLogo} alt="Logo" className="google_logo"/>
                              Sign up with Google
                            </a>
                          )}
                          onSuccess={this.googleResponse}
                          onFailure={this.googleResponse}
                          cookiePolicy={'single_host_origin'}
                        ></GoogleLogin>
                    } */}
                  
                  {/* <div className="pt-3">
                    <React.Fragment>
                      <div className="center-align mb-3 terms-and-services-panel mt-3 pl-1 pr-1 small"  >
                      To continue, Google will share your name, email address, and profile picture with VidFitness. <b><Link to="/terms" target="_blank">Terms of Service</Link></b> and <b><Link to="/privacy-policy" target="_blank">Privacy Policy</Link></b>.
                      </div>
                    </React.Fragment>
                  </div> */}

                  {/* </div> */}
              </div>
          </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    electronMode: state.general.electronMode
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchAuthenticate: (user_info) => { dispatch(authenticate(user_info)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

