import React, { useState, useEffect, Component } from 'react'
import { connect } from 'react-redux'
import { withRouter }from "react-router-dom";
import { Field, reduxForm } from 'redux-form'
import { toggleProfileFormEditability } from '../actions/uiActions'
import { updateUser } from '../actions/userActions'
import axios from 'axios'
import { submit } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var moment = require('moment')

class ProfileHeader extends Component {

	constructor(props) {
		super(props)

		this.state = {
			couponMode: false,
			couponLoading: false,
			forceUpdate: 0,
			couponSuccess: null
		}

		this.days_left = this.days_left.bind(this)
	    this.handleCoupon = this.handleCoupon.bind(this)
	    this.openExternalPortal = this.openExternalPortal.bind(this)
	    this.handleStravaPortal = this.handleStravaPortal.bind(this)
	    this.handlePortal = this.handlePortal.bind(this)
	}

	componentDidMount() {
		if(this.props.electronMode) {
		  const { ipcRenderer } = window.require('electron')
		  ipcRenderer.on('stripe-client-portal-closed', (event, arg) => {
		    this.props.history.push('/portal-redirect')
		  })
		  ipcRenderer.on('strava-portal-closed', (event, arg) => {
		    this.props.history.push(`/portal-redirect${arg}`)
		  })
		}
	}

	openExternalPortal(message, url) {
		const { ipcRenderer } = window.require('electron')
		//send message to electron to start loopback server
		ipcRenderer.send(message, url)
	}

	days_left(date) {

	    var then = moment(date)
	    var now = moment();
	    var level = this.props.user.account_level
	    if (level == 'trial') {
		    return (
		      <div>
		        <h5>
		          Your trial will expire {now.to(then)}.
		        </h5>
		      </div>)
		    } else if (level == 'cancelled_period_not_over'){
		      return (<div><h5>You cancelled your <b>{this.props.user.previous_account_level.toProperCase()}</b> plan.</h5><h5>Your plan features expire {now.to(then)}.</h5></div>)
		    } else if (level == 'core' || level == "pro") {
		      return (
		        <div>
		          <h5>
		            {(this.props.user.complimentary_access ? 
		              (<div>Complimentary {(level).toProperCase()} Plan</div>) : 
		              (<div>{(level).toProperCase()} Plan</div>))} 
		            </h5>
		        </div>)
		    } else if (level == 'free') {
		      return (
		        <div style={{height: '100%'}}>
		          <div>
		            <h5>
		                {`${(level).toProperCase()} Plan`}
		            </h5>
		          </div>
		          
		        </div>
		      )
	    }
    }

    async handleCoupon() {

		this.setState({
		  couponLoading: true
		}, async () => {

		  var payload = {
		    user_id: this.props.user.id, 
		    code: this.state.couponText
		  }

		try {
		  var response = await axios.post((process.env.REACT_APP_SERVER) + '/api/v1/coupon/grant', payload)

		  this.props.couponSuccess(response.data)

		  this.setState({
		    couponError: '',
		    couponInput: '',
		    couponSuccess: `Congrats! We've upgraded you to a ${response.data.account_level.toProperCase()} plan.`,
		    //for some reason we need to force update the component
		    forceUpdate: this.state.forceUpdate + 1
		  })

		} catch (error) {
		  var eToSet;
		  var specialError = null;
		  switch (error.response.data.status) {
		    case 'invalid-code': 
		      eToSet = "Invalid code."
		      break;
		    case 'coupon-out-of-date': 
		      eToSet = "Code expired."
		      break;
		    case 'coupon-reached-max':
		      eToSet = "Maximum already claimed for this code."
		      break;
		    case 'user-does-not-exist':
		      eToSet = "User does not exist."
		      break;
		    case 'already-comp':
		      if (!this.props.user.complimentary_access) {
		      	specialError = true
		      	eToSet = ''
		      } else {
		      	eToSet = 'You already have this.'
		      }
		      break;
		    default: 
		      eToSet = "Whoops, something went wrong."
		  }

		  this.setState({
		    couponError: eToSet,
		    specialError: specialError
		  })
		}

		this.setState({
		  couponLoading: false
		})

   	 })
  }

	handlePortal() {
		const portal_request = (mode) => {
		  return fetch((process.env.REACT_APP_SERVER)+'/api/v1/payment/' + this.props.user.id + '/customer-portal', {
		    method: "POST",
		    headers: {
		      "Content-Type": "application/json"
		    }, 
		    body: 
		      JSON.stringify({
		        electronMode: this.props.electronMode
		      }) 
		  }).then(async (res)=> {
		    const response = await res.json()
		    if (mode == 'web') {
		      window.location.replace(response.portal_url)
		    } else {
		      this.openExternalPortal('stripe-client-portal-opened', response.portal_url)
		    }
		  });
		}

		var level = this.props.user.account_level
		var complimentary_access = this.props.user.complimentary_access


		//if you don't have complimentary access return portal button
		if (!complimentary_access) {
		  //if user has a stripe account exising
		  if (level ==  'pro' || level == 'core' || level == 'payment_failed' || level == 'cancelled_period_not_over') {
		    //checks whether in electron or not
		    if (!this.props.electronMode) {
		      return (
		        <div>
		          <button type="button" className='header-button btn btn-sm btn-outline-success float-right' onClick={() => {portal_request('web')}}>{level == 'cancelled_period_not_over' ? 'Restore Account' : 'Billing'}</button> 
		        </div> )
		    }
		    else {
		      return (
		        <div>
		          <button type="button" className='header-button btn btn-info float-right' onClick={() => {portal_request('electron')}}>{level == 'cancelled_period_not_over' ? 'Restore Account' : 'Manage Billing'}</button> 
		        </div> )
		    }
		    //provide link to pricing page if user has a free acccount
		  } else if (level == 'free') {
		    return (
		      <div>
		        <button type="button" className='header-button btn btn-info float-right' onClick={() => this.props.history.push('/pricing')}>Upgrade Now</button> 
		      </div> )
		  } else {
		    return (<div></div>)
		  }
		} else {
		  return (<div></div>)
		}
	}


	handleStravaPortal() {
		if (this.props.electronMode) {
		  const url = `https://www.strava.com/oauth/authorize?client_id=${process.env.REACT_APP_STRAVA_CLIENT_ID}&response_type=code&redirect_uri=http://127.0.0.1:42420/callback-strava&scope=read_all&scope=activity:write`;
		  this.openExternalPortal('strava-portal-opened', url)
		} else {
		  const url = `https://www.strava.com/oauth/authorize?client_id=${process.env.REACT_APP_STRAVA_CLIENT_ID}&response_type=code&redirect_uri=${(process.env.REACT_APP_HOSTNAME)}/portal-redirect&scope=read_all&scope=activity:write`;
		  window.location.href = url
		}
	}




	render() {
		return (
			<div className="container">
        <div className="row justify-content-sm-center">
          <div className="col col-sm-10">
            <div className="row w-100" style={{paddingBottom: '10px'}}>
              <div className="col-12">
                <div><h2 className="section-head">My Profile</h2>

                  {this.days_left(this.props.user.current_period_end_date)}
                
                </div>
              </div>
              <div className="row w-100">
              <div className="col-12">
                
                <div>

                <div>
                  {this.handlePortal()}
                </div>

                  { !this.props.isFormEditable &&
                    <button className="btn btn-sm btn-outline-success float-right header-button"
                            type="button"
                            onClick={this.props.dispatchToggleEditability}>
                      Edit
                    </button>
                  }

                  { this.props.isFormEditable &&
                    <button className="btn btn-sm btn-success float-right header-button" onClick={this.props.dispatchSubmitForm}>Save</button>
                  }
                </div>

                <div >
                  { this.props.is_connected_to_strava ? (
                    <button disabled type="button" className='btn btn-sm btn-outline-secondary pr-1 float-right header-button'> Connected to Strava </button>
                  ) : (
                    <button type="button" className='btn btn-sm btn-outline-success pr-1 float-right header-button' onClick={this.handleStravaPortal}> Connect to Strava </button>
                  ) }
                </div>
               


              </div>
              </div>
            </div>

           	<hr style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}/>

            <div className="pt-1">
              <b>Partner Benefits:</b> Get $20 off a USA Cycling membership! Enter <b>VidFitness20</b> during <a href="https://usacycling.org/memberships-new" className="text-dark font-weight-bold text-decoration-none no-wrap"> USAC signup. <sup><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/><path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/></svg></sup></a>
            </div>

            <hr style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}/>

            <div className="" style={{display: 'flex'}}>
              <span className="pt-1">
                <b>Got a Promo Code?</b> 
              </span>
              <React.Fragment>
                <form>
                  <div style={{display:'flex'}} className="ml-2">
                    <input style={{width: '50%'}} className="form-control form-control-sm" type="text" placeholder="Enter code." value={this.state.couponText} onChange={(event) => {
                      this.setState({
                        couponText: event.target.value
                      })
                    }}/>
                    <button onClick={this.handleCoupon} style={{verticalAlign: 'top', marginBottom: 0, paddingTop: 0, paddingBottom: 0}} type="button" className="btn ml-2 btn-outline-success btn-sm">
                      { this.state.couponLoading &&
                        <React.Fragment>
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                          <span>Loading...</span>
                        </React.Fragment>
                      } 
                      { !this.state.couponLoading &&
                        <React.Fragment>
                          <span>Apply</span>
                        </React.Fragment>
                      }
                    </button>                  
                  </div> 
                </form>
            </React.Fragment>
          </div>
          <div className="text-danger" style={{fontSize: '0.75rem'}}><p>{this.state.couponError}</p></div>
          { this.state.specialError &&
          	<div className="text-danger" style={{fontSize: '0.75rem'}}><p>Please <a href="mailto:contact@vidfitness.com">contact us</a> for help with this code.</p></div>
          }
          <div className="text-success" style={{fontSize: '0.75rem'}}><p>{this.state.couponSuccess}</p></div>
          <hr style={{ marginTop: '0.5rem', marginBottom: '0.75rem' }}/>
        </div>
    	</div>
    	</div>
		)
	}

}

const mapStateToProps = (state) => {
  const ui = state.ui
  const user = state.user

  return {
    isFormEditable: ui.isProfileFormEditable,
    electronMode: state.general.electronMode || false,
    user: user,
    is_connected_to_strava: (user.strava_authorization_code ? true : false)
   }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchToggleEditability: () => { dispatch(toggleProfileFormEditability())},
    dispatchUpdateUser: (values) => {dispatch(updateUser(values))},
    dispatchSubmitForm: () => {dispatch(submit('profile'))}
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileHeader))