import React from 'react';
import { Formik } from 'formik';
import { FontAwesomeIcon }   from '@fortawesome/react-fontawesome'

const ChannelForm = ({user_id, title, description, updateUser, turnOffEditMode}) => {

  return (
    <div>
      <Formik
        initialValues={{ channel_title: title, channel_description: description }}
        validate={values => {
          let errors = {};
          if (!values.channel_title) {
            errors.title = 'Required';
          }
          return errors;
        }}

        onSubmit={(values, { setSubmitting }) => {
          updateUser(values)
          turnOffEditMode()
          setTimeout(() => {
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          submitForm
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>

          <div>
            <button className='btn btn-outline-success float-right mr-1 channel-save-button' onClick={ () => submitForm() } disabled={isSubmitting}>
              Save
            </button>
          </div>

            <div className="form-group pl-4 pr-4 pt-5">
             <input
               type="text"
               name="channel_title"
               className="form-control channel-title-input"
               placeholder="Enter channel's title"
               onChange={handleChange}
               onBlur={handleBlur}
               value={values.channel_title}
             />
            </div>
            {errors.title && touched.title && errors.title}
            <div className="form-group pl-4 pr-4">
              <textarea
                name="channel_description"
                className="description-input channel-description-input"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.channel_description}
              />
            </div>
            {errors.description && touched.description && errors.description}
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ChannelForm;
