import React from 'react'

const TermsText = () => {

	return (
		<div>
			  <div className="container">
			    <h1 className="center-align section-head">Terms of Service</h1>
			    <div>
			      <p>Dated: November 10, 2019</p><h3><b>Welcome to VidFitness</b></h3><p><b>Introduction<br/></b>Thank you for using the VidFitness platform and the products, services and features we make available to you as part of the platform (collectively, the &quot;Service&quot;). &nbsp;</p>
			      <p><b>Our Service</b></p>
			      <p>The Service allows you to discover, watch and exercise on videos and other content, provides a forum for people to connect, collaboratively exercise, and inspire others across the globe, and acts as a distribution platform for original content creators and advertisers large and small.<br/></p>
			      <p><b>Your Service Provider</b></p>
			      <p>The entity providing the Service is VidFitness, a company operating under the laws of Delaware, located at 59 Elm Street, New Haven, CT 06510&nbsp;(referred to as &quot;<b>VidFitness</b>&quot;, &quot;<b>we</b>&quot;, &quot;<b>us</b>&quot;, or &quot;<b>our</b>&quot;). References to VidFitness’s &quot;Affiliates&quot; in these terms means the other associated companies.</p>
			      <p><b>Applicable Terms</b><br/>Your use of the Service is subject to these terms, the VidFitness Community Guidelines&nbsp;and the Policy, Safety and Copyright Policies&nbsp;which may be updated from time to time (together, this &quot;Agreement&quot;). Your Agreement with us will also include the Advertising on VidFitness Policies&nbsp;if you provide advertising or sponsorships to the Service or incorporate paid promotions in your content. Any other links or references provided in these terms are for informational use only and are not part of the Agreement.</p>
			      <p>Please read this Agreement carefully and make sure you understand it. If you do not understand the Agreement, or do not accept any part of it, then you may not use the Service.</p>
			      <h3><b>Who may use the Service?</b></h3>
			      <p><b>Age Requirements</b><br/>You must be at least 18 years old to use the Service.</p>
			      <p><b>Businesses</b><br/>If you are using the Service on behalf of a company or organisation, you represent that you have authority to act on behalf of that entity, and that such entity accepts this Agreement.</p>
			      <h3><b>Your Use of the Service</b></h3>
			      <p><b>Content on the Service</b><br/>The content on the Service includes videos, audio (for example music and other sounds), graphics, photos, text (such as comments and scripts), branding (including trade names, trademarks, service marks, or logos), exercise based interactive features, software, metrics, and other materials whether provided by you, VidFitness or a third-party (collectively, &quot;Content&quot;).</p>
			      <p>Content is the responsibility of the person or entity that provides it to the Service. VidFitness is under no obligation to host or serve Content. If you see any Content you believe does not comply with this Agreement, including by violating the Community Guidelines&nbsp;or the law, you can report it to us.</p>
			      <p><b>VidFitness Accounts and VidFitness Channels</b><br/>You can use parts of the Service, such as browsing and searching for Content,&nbsp;without having a VidFitness account. However, you do need a VidFitness account to use some features. With a VidFitness account, you may be able to exercise against videos, upload videos, subscribe to channels, create your own VidFitness channel, and more.</p>
			      <p>Creating&nbsp;a VidFitness channel will give you access to additional features and functions, such as uploading videos.</p>
			      <p>To protect your VidFitness account, keep your password confidential. You should not reuse your VidFitness account password on third-party applications. Notify us if you learn of any unauthorised use of your password or VidFitness account.</p>
			      <p><b>Your Information</b><br/>Our Privacy Policy&nbsp;explains how we treat your personal data and protect your privacy when you use the Service.</p>
			      <p><b>Permissions and Restrictions<br/></b>You may access and use the Service as made available to you, as long as you comply with this Agreement and applicable law. You may view or listen to Content for your personal, non-commercial use. You may also show VidFitness videos through the embeddable VidFitness player.</p>
			      <p>The following restrictions apply to your use of the Service. You are not allowed to:</p>
			      <ol>
			      <li>access, reproduce, download, distribute, transmit, broadcast, display, sell, license, alter, modify or otherwise use any part of the Service or any Content except: (a) as expressly authorized by the Service; or (b) with prior written permission from VidFitness and, if applicable, the respective rights holders;</li>
			      <li>circumvent, disable, fraudulently engage with, or otherwise interfere with any part of the Service (or attempt to do any of these things), including security-related features or features that (a) prevent or restrict the copying or other use of Content or (b) limit the use of the Service or Content;</li>
			      <li>access the Service using any automated means (such as robots, botnets or scrapers) except (a) in the case of public search engines, in accordance with VidFitness’s robots.txt file; or (b) with VidFitness’s prior written permission;&nbsp;</li>
			      <li>collect or harvest any information that might identify a person (for example, usernames), unless permitted by that person or allowed under section (3) above;</li>
			      <li>collect or harvest any information related to their activity on the site;</li>
			      <li>use the Service to distribute unsolicited promotional or commercial content or other unwanted or mass solicitations;</li>
			      <li>cause or encourage any inaccurate measurements of genuine user engagement with the Service, including by paying people or providing them with incentives to increase a video’s views, likes, or dislikes, or to increase a channel’s subscribers, or otherwise manipulate metrics in any manner;</li>
			      <li>misuse any reporting, flagging, complaint, dispute, or appeals process, including by making groundless, vexatious, or frivolous submissions;</li>
			      <li>run contests on or through the Service that do not comply with VidFitness’s contest policies and guidelines;</li>
			      <li>use the Service to&nbsp;view, exercise or listen to Content other than for personal, non-commercial use (for example, you may not publicly screen videos or stream music from the Service); or</li>
			      <li>use the Service to (a) sell any advertising, sponsorships, or promotions placed on, around, or within the Service or Content, other than those allowed in the Advertising on VidFitness&nbsp;policies (such as compliant product placements); or (b) sell advertising, sponsorships, or promotions on any page of any website or application that only contains Content from the Service or where Content from the Service is the primary basis for such sales (for example, selling ads on a webpage where VidFitness videos are the main draw for users visiting the webpage).</li>
			      </ol>
			      <p><b>Reservation</b></p>
			      <p>Using the Service does not give you ownership of or rights to any aspect of the Service, including user names or any other Content posted by others or VidFitness.</p>
			      <p><b>Changes to the Service</b></p>
			      <p>VidFitness is constantly changing and improving the Service. We may also need to alter or discontinue the Service, or any part of it, in order to make performance or security improvements, change functionality and features, make changes to comply with law, or prevent illegal activities on or abuse of our systems. These changes may affect all users, some users or even an individual user. Whenever reasonably possible, we will provide notice when we discontinue or make material changes to our Service that will have an adverse impact on the use of our Service.&nbsp;However, you understand and agree that there will be times when we make such changes without notice, such as where we feel we need to take action to improve the security and operability of our Service, prevent abuse, or comply with legal requirements.&nbsp;</p>
			      <h3><b>Your Content and Conduct</b></h3>
			      <p><b>Uploading Content</b></p>
			      <p>If you have a VidFitness account, you may be able to upload Content to the Service. You may use your Content to promote your business or artistic enterprise. If you choose to upload Content, you must not submit to the Service any Content that does not comply with this Agreement (including the VidFitness Community Guidelines) or the law. For example, the Content you submit must not include third-party intellectual property (such as copyrighted material) unless you have permission from that party or are otherwise legally entitled to do so. You are legally responsible for the Content you submit to the Service. We may use automated systems that analyze your Content to help detect infringement and abuse, such as spam, malware, and illegal content.</p>
			      <p><b>Rights you Grant</b></p>
			      <p>You retain ownership rights in&nbsp;your Content. However, we do require you to grant certain rights to VidFitness and other users of the Service, as described below.</p>
			      <p><b>License to VidFitness</b></p>
			      <p>By providing Content to the Service, you grant to VidFitness a worldwide, non-exclusive, royalty-free, sublicensable and transferable license to use that Content (including to reproduce, distribute, prepare derivative works,&nbsp;display and perform it) in connection with the Service and VidFitness’s (and its successors&apos; and Affiliates&apos;) business, including for the purpose of promoting and redistributing part or all of the Service.</p>
			      <p><b>License to Other Users</b></p>
			      <p>You also grant each other user of the Service a worldwide, non-exclusive, royalty-free license to access your Content through the Service, and to use that Content, including to reproduce, distribute, prepare derivative works, display, and perform it, only as enabled by a feature of the Service (such as video playback or embeds). For clarity, this license does not grant any rights or permissions for a user to make use of your Content independent of the Service.</p>
			      <p><b>Duration of License</b></p>
			      <p>The licenses granted by you continue for a commercially reasonable period of time after you remove or delete your Content from the Service. You understand and agree, however, that VidFitness may retain, but not display, distribute, or perform, server copies of your videos that have been removed or deleted.&nbsp;</p>
			      <p><b>Removing Your Content</b></p>
			      <p>You may remove your Content from the Service at any time. You must remove your Content if you no longer have the rights required by these terms.</p>
			      <p><b>Removal of Content By VidFitness</b></p>
			      <p>If we reasonably believe that any Content is in breach of this Agreement or&nbsp;may cause harm to VidFitness, our users, or third parties, we may remove or take down that Content in our discretion.&nbsp;We will notify you with the reason for our action unless we reasonably believe that to do so: (a) would breach the law or the direction of a legal enforcement authority or would otherwise risk legal liability for VidFitness or our Affiliates; (b) would compromise an investigation or the integrity or operation of the Service; or (c) would cause harm to any user, other third party, VidFitness or our Affiliates.</p>
			      <p><b>Copyright Protection</b></p>
			      <p>If you believe your copyright has been infringed on the Service, please send us a notice.</p>
			      <p>VidFitness&apos;s policies provide for the termination, in appropriate circumstances, of repeat infringers’ access to the Service.</p>
			      <h3><b>Account Suspension &amp; Termination</b></h3>
			      <p><b>Terminations by You</b><br/>You may stop using the Service at any time.</p>
			      <p><b>Terminations and Suspensions by VidFitness for Cause</b></p>
			      <p>VidFitness&nbsp;may suspend or terminate your access, your VidFitness account, or your VidFitness account’s access to all or part of the Service&nbsp;if (a) you materially or repeatedly breach this Agreement;&nbsp;(b) we are required to do so to comply with a legal requirement or a court order; or (c) we believe there has been conduct that creates (or could create) liability or harm to any user, other third party, VidFitness or our Affiliates.<br/></p>
			      <p><b>Terminations by VidFitness for Service Changes</b></p>
			      <p>VidFitness may terminate your access, or your VidFitness account’s access to all or part of the Service if VidFitness believes, in its sole discretion, that provision of the Service to you is no longer commercially viable.&nbsp;</p>
			      <p><b>Notice for Termination or Suspension</b></p>
			      <p>We will notify you with the reason for termination or suspension by VidFitness unless we reasonably believe that to do so: (a) would violate the law or the direction of a legal enforcement authority, or would otherwise risk legal liability for VidFitness or our Affiliates; (b) would compromise an investigation or the integrity or operation of the Service; or (c) would cause harm to any user, other third party, VidFitness or our Affiliates. Where VidFitness is terminating your access for Service changes, where reasonably possible, you will be provided with sufficient time to export your Content from the Service.</p>
			      <p><b>Effect of Account Suspension or Termination</b></p>
			      <p>If your VidFitness account is terminated or your VidFitness account’s access to the Service is restricted, you may continue using certain aspects of the Service (such as viewing only) without an account, and this Agreement will continue to apply to such use. If you believe your VidFitness account has been terminated in error, you can appeal by contacting via email.</p>
			      <h3><b>About Software in the Service</b></h3>
			      <p><b>Downloadable Software<br/></b>When the Service requires or includes downloadable software (such as the VidFitness Player), you give permission for that software to update automatically on your device once a new version or feature is available, subject to your device settings. Unless that software is governed by additional terms which provide a license, VidFitness gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software provided to you by VidFitness as part of the Service. This license is for the sole purpose of enabling you to use and enjoy the benefit of the Service as provided by VidFitness, in the manner permitted by this Agreement. You are not allowed to copy, modify, distribute, sell, or lease any part of the software, or to reverse-engineer or attempt to extract the source code of that software, unless laws prohibit these restrictions or you have VidFitness’s written permission.</p>
			      <p><b>Open Source<br/></b>Some software used in our Service may be offered under an open source license that we make available to you. There may be provisions in an open source license that expressly override some of these terms, so please be sure to read those licenses. &nbsp;</p>


			      <h3><b>Assumptions of Risk</b></h3>
			      <p>YOU UNDERSTAND THAT THE PLATFORM IS INTENDED TO BE USED IN CONNECTION WITH ATHLETIC AND FITNESS ACTIVITIES (INCLUDING CYCLING, RUNNING AND OTHER SPORTS). YOU EXPRESSLY ACKNOWLEDGE THAT ENGAGING IN ATHLETIC OR FITNESS ACTIVITIES AS PART OF THE PLATFORM CARRIES CERTAIN INHERENT AND SIGNIFICANT RISKS OF PROPERTY DAMAGE, BODILY INJURY OR DEATH AND THAT YOU VOLUNTARILY ASSUME ALL KNOWN AND UNKNOWN RISKS ASSOCIATED WITH THESE ACTIVITIES EVEN IF CAUSED IN WHOLE OR PART BY THE ACTION, INACTION OR NEGLIGENCE OF VIDFITNESS OR BY THE ACTION, INACTION OR NEGLIGENCE OF OTHERS. YOU ALSO EXPRESSLY AGREE THAT VIDFITNESS DOES NOT ASSUME RESPONSIBILITY FOR THE INSPECTION, SUPERVISION, PREPARATION, OR CONDUCT OF ANY RACE, COMPETITION, CONTEST, GROUP INTERACTION, GATHERING, OR EVENT THAT UTILIZES THE PLATFORM (WHETHER OR NOT WITH VIDFITNESS’S PERMISSION).</p>
			      <p>PLEASE CONSULT YOUR PHYSICIAN BEFORE USING THE PLATFORM IN CONNECTION WITH ANY ATHLETIC AND FITNESS ACTIVITIES. THIS IS ESPECIALLY IMPORTANT FOR PERSONS OVER AGE 35 OR PERSONS WITH PRE-EXISTING HEALTH PROBLEMS. DISCONTINUE ANY USE OF THE PLATFORM OR ENGAGING IN ANY SUCH ATHLETIC OR FITNESS ACTIVITIES THAT CAUSE YOU PAIN, FATIGUE, DISCOMFORT, NAUSEA, DIZZINESS, OR SHORTNESS OF BREATH AND CONSULT A MEDICAL EXPERT. START SLOWLY AND AT THE LEVEL THAT IS APPROPRIATE FOR YOU. DON’T OVEREXERT YOURSELF. TAKE BREAKS PERIODICALLY. STOP AND REST IF YOUR MUSCLES, JOINTS, OR EYES BECOME TIRED OR SORE. DO NOT USE THE SERVICE OR ENGAGE IN ANY SUCH ATHLETIC OR FITNESS ACTIVITIES UNDER THE INFLUENCE OF DRUGS OR ALCOHOL, AND MAKE SURE YOUR BALANCE AND PHYSICAL ABILITIES ARE SUFFICIENT FOR ANY MOVEMENTS AND ACTIVITIES WHILE USING THE PLATFORM.</p>

			      <h3><b>Other Legal Terms</b></h3>

			      <p><b>Warranty Disclaimer</b><br/>
			      OTHER THAN AS EXPRESSLY STATED IN THIS AGREEMENT OR AS REQUIRED BY LAW, THE SERVICE IS PROVIDED &quot;AS IS&quot; AND VidFitness DOES NOT MAKE ANY SPECIFIC COMMITMENTS OR WARRANTIES ABOUT THE SERVICE. FOR EXAMPLE, WE DON’T MAKE ANY WARRANTIES ABOUT: (A) THE CONTENT PROVIDED THROUGH THE SERVICE; (B) THE SPECIFIC FEATURES OF THE SERVICE, OR ITS ACCURACY, RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS; OR (C) THAT ANY CONTENT YOU SUBMIT WILL BE ACCESSIBLE ON THE SERVICE. &nbsp;</p>

			      <p><b>Limitation of Liability</b></p>
			      <p>EXCEPT AS REQUIRED BY APPLICABLE LAW, VIDFITNESS,&nbsp;ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES AND&nbsp;AGENTS WILL NOT BE RESPONSIBLE FOR&nbsp;ANY LOSS OF PROFITS, REVENUES, BUSINESS OPPORTUNITIES, GOODWILL, OR ANTICIPATED SAVINGS; LOSS OR CORRUPTION OF DATA; INDIRECT OR CONSEQUENTIAL LOSS; PUNITIVE DAMAGES CAUSED BY:</p>
			      <ol>
			      <li>ERRORS, MISTAKES, OR INACCURACIES ON THE SERVICE;</li>
			      <li>PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM YOUR USE OF THE SERVICE;</li>
			      <li>ANY UNAUTHORIZED ACCESS TO OR USE OF THE SERVICE;</li>
			      <li>ANY INTERRUPTION OR CESSATION OF THE SERVICE;</li>
			      <li>ANY VIRUSES OR MALICIOUS CODE TRANSMITTED TO OR THROUGH THE SERVICE BY ANY THIRD PARTY;</li>
			      <li>ANY CONTENT WHETHER SUBMITTED BY A USER OR VIDFITNESS, INCLUDING YOUR USE OF CONTENT; AND/OR</li>
			      <li>THE REMOVAL OR UNAVAILABILITY OF ANY CONTENT.</li>
			      </ol>
			      <p>THIS PROVISION APPLIES TO ANY CLAIM, REGARDLESS OF WHETHER THE CLAIM ASSERTED IS BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY.</p>
			      <p>VIDFITNESS AND ITS AFFILIATES’ TOTAL LIABILITY FOR ANY CLAIMS ARISING FROM OR RELATING TO THE SERVICE IS LIMITED TO THE GREATER OF: (A) THE AMOUNT OF REVENUE THAT VIDFITNESS HAS PAID TO YOU FROM YOUR USE OF THE SERVICE IN THE 12 MONTHS BEFORE THE DATE OF YOUR NOTICE, IN WRITING TO VIDFITNESS, OF THE CLAIM; AND (B) USD $100.</p>
			      <p><b>Indemnity</b></p>
			      <p>To the extent permitted by applicable law, you agree to defend, indemnify and hold harmless VidFitness, its Affiliates, officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney&apos;s fees) arising from: (i) your use of and access to the Service; (ii) your violation of any term of this Agreement; (iii) your violation of any third party right, including without limitation any copyright, property, or privacy right; or (iv) any claim that your Content caused damage to a third party. This defense and indemnification obligation will survive this Agreement and your use of the Service.</p>
			      <p><b>Third-Party Links<br/></b>The Service may contain links to third-party websites and online services that are not owned or controlled by VidFitness. VidFitness has no control over, and assumes no responsibility for, such websites and online services. Be aware when you leave the Service; we suggest you read the terms and privacy policy of each third-party website and online service that you visit.</p>
			      <h3><b>About this Agreement</b></h3>
			      <p><b>Modifying this Agreement</b><br/>We may modify this Agreement, for example, to reflect changes to our Service or for legal, regulatory, or security reasons. VidFitness will provide reasonable advance notice&nbsp;of&nbsp;any material modifications to this Agreement and the opportunity to review them, except that modifications addressing newly available features of the Service or modifications made for legal reasons may be effective immediately without notice. Modifications to this Agreement will only apply going forward. If you do not agree to the modified terms, you should remove any Content you have uploaded and discontinue your use of the Service.</p>
			      <p><b>Continuation of this Agreement<br/></b>If your use of the Service ends, the following terms of this Agreement will continue to apply to you: &quot;Other Legal Terms&quot;, &quot;About This Agreement&quot;, and the licenses granted by you will continue as described under &quot;Duration of License&quot;.</p>
			      <p><b>Severance</b><br/>If it turns out that a particular term of this Agreement is not enforceable for any reason, this will not affect any other terms.</p>
			      <p><b>No</b> <b>Waiver<br/></b>If you fail to comply with this Agreement and we do not take immediate action, this does not mean that we are giving up any rights that we may have (such as the right to take action in the future).</p>
			      <p><b>Interpretation</b></p>
			      <p>In these terms, &quot;include&quot; or &quot;including&quot; means &quot;including but not limited to,&quot; and any examples we give are for illustrative purposes.</p>
			      <p><b>Governing Law<br/></b>All claims arising out of or relating to these terms or the Service will be governed by Connecticut law. You and VidFitness consent to personal jurisdiction in those courts.</p>
			      <p><b>Limitation on Legal Action</b></p>
			      <p>YOU AND VIDFITNESS AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.</p>
			      <div>Effective as of November 10, 2019 </div>
			      </div>
			  </div>
			</div>
	)

}

export default TermsText