import React, { Component } from 'react';
import { withRouter, Prompt }from "react-router-dom";
import { connect } from 'react-redux'
import axios from 'axios'
import {
  saveRaceToView,
} from '../actions/videoActions'
import '../styles/video.css'


class FileUpload extends Component { 

	constructor(props) {
		super(props)
		this.state = {
			previous_filename: null,
			selectedFile: null,
			isSomethingSelected: false,
			filePath: '',
			display_loading: false,
			upload_finished: false,
			uploaded: false,
			upload_error: false,
			exitOverride: true,
			gpx_offset: 0
		}

		this.onFileChange = this.onFileChange.bind(this)
		this.onFileUpload = this.onFileUpload.bind(this)
		this.handleExternal = this.handleExternal.bind(this)
	}

	componentDidMount() {

		if (this.props.uploaded) {
			this.setState({
				uploaded: true,
				uploaded_filename: this.props.filename
			})
		}
		const { ipcRenderer } = window.require('electron') 

		ipcRenderer.on("upload-gpx-finished", () => {
			
			this.props.refreshRace()
			this.setState({
				display_loading: false,
				uploaded: true,
				uploaded_filename: this.state.selectedFile,
				cannotExit: false
			})
		})

		ipcRenderer.on("upload-error", () => {
			this.setState({
				upload_error: true,
				display_loading: false,
				cannotExit: false
			})
		})

	}
  
     
    // On file select (from the pop up) 
    async onFileChange(event) { 

		if (this.props.electronMode) {

			const electron = window.require('electron') 
			var resp = await electron.remote.dialog.showOpenDialogSync({
				filters: [
				    { name: 'gpx', extensions: ['gpx'] },
				 ],
				 properties: ["openFile"]
			});

			if (resp != undefined) {
				this.setState({ 
					filePath: resp[0],
					selectedFile: resp[0].split(/(\\|\/)/g).pop(),
					isSomethingSelected: true,
					uploaded_filename: null,
					cannotExit: true
				}); 
			}

		}	
     
    }; 
     
    // On file upload (click the upload button) 
    onFileUpload(){ 

		if (this.props.electronMode) {

			const { ipcRenderer } = window.require('electron') 

			this.setState({
				display_loading: true,
				isSomethingSelected: false,
			}, () => {
				ipcRenderer.send("upload-gpx",{
					path: this.state.filePath,
					video_url: this.props.video.race.url,
					offset: this.state.gpx_offset
				})
			})

		}
    }; 

    handleExternal() {
    	if (this.props.electronMode) {

    		this.setState({
    			exitOverride: true
    		})
			
			const { shell } = window.require('electron')

    		shell.openExternal(`https://vidracing-${process.env.REACT_APP_ENVIROMENT}-controls.s3.amazonaws.com/${this.props.video.race.url}.gpx`)
    	}
    }
    
     
    render() { 
     
      return ( 
      	<React.Fragment>
      		{ !this.state.exitOverride &&
		      	<Prompt
		      		when={this.state.cannotExit}
					message='Your files have not been uploaded yet, are you sure you want to leave?'
				/>
			}
	        <div className="mt-3"> 
	            <div className="mb-3" style={{display:'flex'}}>
	            	{ !this.state.display_loading &&
	            		<React.Fragment>
			            	<button className="btn btn-md btn-outline-success" onClick={this.onFileChange}>
			            		{this.state.uploaded ? 
			            			'Change File' : 
			            			'Choose File'
			            		}
			            	</button> 
		            	</React.Fragment>	
	            	}
	                { this.state.isSomethingSelected &&
	                	<React.Fragment>
			                <button className="btn btn-md btn-success ml-3" style={{verticalAlign: 'top'}} onClick={this.onFileUpload}> 
			                  Upload
			                </button> 
			                <span className="ml-3 mt-1"> 
								File Name: {this.state.selectedFile}
							</span> 
						</React.Fragment>
	            	}
	            </div>
	            <div>
	            	{ this.state.isSomethingSelected &&
	            		<React.Fragment>
	            			<div style={{display: 'flex'}} className="mb-1">
		            			<span style={{marginTop: '2.5px'}} >Start Offset Time (in seconds):</span>
			            		<input style={{width: '7.5%'}} className="form-control form-control-sm text-center ml-2" type="text" placeholder="Enter offset time." id="gpx_offset" value={this.state.gpx_offset} onChange={(event) => {
				                  this.setState({
				                    gpx_offset: event.target.value
				                  })
				                }}/>
			                </div>
			                <label className="mb-3 mt-1" for="gpx_offset">
			                	NOTE: If you started your video camera and GPS device at the same time but trimmed your video by entering an offset when you uploaded your video to VidFitness, enter that same number of seconds here also (if you happened to start your video camera some seconds after your GPS device started tracking, enter those seconds here). This will allow you to align your video with the slope data that your GPS device captured.
			                </label>
			            </React.Fragment>
		                
	            	}
	        	</div>
	            <div className="mb-2">
	          		{ this.state.uploaded_filename != null &&
	          			<React.Fragment>
	          			<div>
				  			<p>Current file name: <a href="#" className="text-dark font-weight-bold" onClick={this.handleExternal}>{this.state.uploaded_filename}</a></p> 
				  		</div> 
				  		{ this.props.video.race.slope_source_id == 0 && 
				  			<div>
				  				NOTE: Once the system processes your GPX file, slope information will be added to your video so that users will experience force feedback on their smart trainers when riding against your video.
				  			</div>
				  		}
				  		</React.Fragment>
	          		}
	          	</div>
	          		{ this.state.display_loading &&
			          	<div style={{display:'flex'}} className="mt-2 mr-2 mb-2">
			          		<div class="spinner-border text-dark mr-3" role="status">
							  <span class="sr-only">Loading...</span>
							</div>
							<div className="mt-1">
			          			<span className="text-success">Uploading...</span> {this.state.selectedFile}
			          		</div>
			          	</div>
					}
				{ this.state.upload_error &&
					<div className="text-danger">Something went wrong. Please try again later.</div>
				}
	        </div> 
	    </React.Fragment>
      ); 
    } 
  }

const mapStateToProps = (state) => {
	let user = state.user
  return {
    user: user,
    track: state.currentTrack,
    electronMode: state.general.electronMode,
    video: state.video
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  	dispatchSaveRaceToView:        (race) => { dispatch(saveRaceToView(race))},
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FileUpload))