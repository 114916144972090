// React Dependencies
import React, { Component } from 'react';
import { withRouter }from "react-router-dom";
import { connect } from 'react-redux'
import IdleTimer from 'react-idle-timer'
import $ from 'jquery';
import axios from 'axios'

// Styles
import '../App.css';
import '../styles/video.css'

// Redux Actions
import { saveCurrentTrack } from '../actions/currentTrackActions'
import { turnOnDemoMode, turnOffDemoMode } from '../actions/generalActions'
import {
  turnOnRaceMode,
  resetMode,
  turnOnPlayMode,
  turnOnTheaterMode,
  turnOffTheaterMode,
  toggleVideoMode,
  setPlayingState,
  setRecordingState,
  setPausedState,
  enterPlayMode,
  showCountdownOverlay,
  showOverlay,
  hideOverlay, 
  saveRaceToView,
  clearRaceFromView,
  updateAudioOverlayMode,
  setGlobalMuteStatus,
  overrideDuration
} from '../actions/videoActions'

import {
  stopAudioRecording,
  updateAudioLayers
} from '../actions/overlayActions'

import videojs from 'video.js';

import {isMobile, isTablet, BrowserView, MobileView, isMobileOnly} from 'react-device-detect';

import {getInfoFromVideoUrl } from '../utils/utils.js'

import {Helmet} from "react-helmet";

import { Prompt } from 'react-router'

import '../styles/recording.css'


// Other dependencies
import { FontAwesomeIcon }   from '@fortawesome/react-fontawesome'
import { Jumbotron, Button } from 'react-bootstrap'
import _ from 'lodash';
const uuidv4   = require('uuid/v4');
var classNames = require('classnames');

// defines a playing property for videos
Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
    get: function(){
        return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
    }
})

class raceLeaderboard extends Component {

  constructor(props){

    super(props);

    this.renderLeaderboardRacer = this.renderLeaderboardRacer.bind(this)
  	this.renderLeaderboardList = this.renderLeaderboardList.bind(this)
  	this.renderAlternativeViewOfRacers = this.renderAlternativeViewOfRacers.bind(this)
  	this.renderFirstNPositions = this.renderFirstNPositions.bind(this)
  	this.renderTransparentItem = this.renderTransparentItem.bind(this)
  	this.renderCloseRacers = this.renderCloseRacers.bind(this)
  	this.doRenderCloseRacers = this.doRenderCloseRacers.bind(this)

  }

  async componentDidMount(){
   

  }

  renderLeaderboardRacer(obj, position, currentTimeInt=null){

    // Calculates ave_power, tot_power and additional_class
    let ave_power = 0
    let additional_class = ''
    let tot_power = 0

    if((this.props.currentTime) && (this.props.currentTime>0)){
      ave_power = Math.round(obj['accumulated_power'] / this.props.currentTime)
      tot_power = Math.round(obj['accumulated_power'] / 36.0)/100.0
    }

    if ((typeof(this.props.track) != 'undefined' )
         && typeof(this.props.track.id) != 'undefined'
         && (this.props.track.id != '')
         && (this.props.track.id == obj['track_id'])
       ){
       additional_class = ' active'
    } else {
      if (this.props.user.id == obj['user_id']){
         additional_class = ' current'
      };
    }

    const inline_style ={
      // backgroundColor: color
    }


    var cadence;

    if (obj['cadence'] == null) {
      cadence = 0
    } else {
      cadence = obj['cadence']
    }

    return (
      <li key={uuidv4()} className={"racer-node" + additional_class} style={ inline_style } data-track-id={ obj['track_id'] } >
        <table>
          <tbody>
            <tr>
              <td className="position-col">
                { ++position }
              </td>
              <td className="power-col">
                { ave_power }W
              </td>
              <td className="name-col">
                { obj['username'] }
              </td>
              <td className="cadence-col">
                { cadence }
              </td>
            </tr>
          </tbody>
        </table>
      </li>
    )

  }

  renderLeaderboardList(racers){

    let currentUserPosition = racers.findIndex(point => point.active) + 1;

    return(
    	<ul id="leaderboard-list">

			<li className='titles-node'>
				<table>
					<tbody>
						<tr>
							<td className="position-col">
							Pos.
							</td>
							<td className="power-col">
							Pow.
							</td>
							<td className="name-col">
							Name
							</td>
							<td className="cadence-col">
							Cad.
							</td>
						</tr>
					</tbody>
				</table>
			</li>

	        { currentUserPosition != 0 && racers.length > 10 ?
	          (
	            currentUserPosition <= 10 ?
	            (
	              this.renderFirstNPositions(10, racers, this.props.currentTime)
	            ) : (
	              this.renderAlternativeViewOfRacers(racers, currentUserPosition)
	            )
	          ) :
	          (
	            this.renderFirstNPositions(10, racers, this.props.currentTime)
	          )
	        }
      </ul>
    )
  }


  renderAlternativeViewOfRacers(racers, currentUserPosition){
    var podium          = this.renderFirstNPositions(3, racers, this.props.currentTime);
    var transparentItem = this.renderTransparentItem();
    var closeRacers     = this.renderCloseRacers(racers, this.props.currentTime, currentUserPosition);

    return (
      <React.Fragment>
        <div>
          { podium }
        </div>
        <div>
          { transparentItem }
        </div>
        <div>
          { closeRacers }
        </div>
      </React.Fragment>
    )
  }

  // Renders first N positions
  renderFirstNPositions(n, data, currentTimeInt) {

    data = data.slice(0, n);

    let items = []
    for (var i = 0; i < n; i++){
      var obj = data[i]
      if(typeof obj != 'undefined'){
        items.push(this.renderLeaderboardRacer(obj, i, currentTimeInt))
      }
    }

    return items;
    // afterPrint()
  }

  renderTransparentItem(){
    return (
      <li className='transparent-item'>
        <span className='dot'></span>
        <span className='dot'></span>
        <span className='dot'></span>
      </li>
    )
  }

  // Prints racers up to two positions above and two positions below
  // includes current user
  renderCloseRacers(data, currentTimeInt, currentUserPosition){

    // if it's not on the last two positions
    if(data.length - 2 >= currentUserPosition){

      // Example: if current position 15
      // we need to get positions 13, 14, 15, 16, 17
      const racers_to_print = data.slice(currentUserPosition - 3, currentUserPosition + 2)
      return this.doRenderCloseRacers(racers_to_print, currentTimeInt, currentUserPosition, data.length, 'middle')
    }
    // if it's on the penultimate position
    else if(data.length - 1 == currentUserPosition){
      const racers_to_print = data.slice(currentUserPosition - 4, currentUserPosition + 1)
      return this.doRenderCloseRacers(racers_to_print, currentTimeInt, currentUserPosition, data.length, 'penultimate')
    }
    // If it's on the last position
    else if(data.length == currentUserPosition){
      const racers_to_print = data.slice(currentUserPosition - 5, currentUserPosition)
      return this.doRenderCloseRacers(racers_to_print, currentTimeInt, currentUserPosition, data.length, 'last')
    }

  }

  doRenderCloseRacers(data, currentTimeInt, currentUserPosition, totalParticipants, relativePosition){

    let positionToPrint = 0

    if (relativePosition == 'middle'){
      positionToPrint = currentUserPosition - 2
    }
    else if (relativePosition == 'penultimate'){
      positionToPrint = currentUserPosition - 3
    }
    else if (relativePosition == 'last'){
      positionToPrint = currentUserPosition - 4
    }

    return (
      <div>
        {data.map((obj) => {
          positionToPrint += 1
          return (this.renderLeaderboardRacer(obj, positionToPrint))
        })}
      </div>
    )

  }

	render () {

	    return (

			<React.Fragment>

				<div id="leaderboard" className="leaderboard">

			        <a className="vidracing-logo">
			          VidFitness
			        </a>

              { !this.props.groupMode &&
			        <React.Fragment>
  			        { Object.keys(this.props.leaderboard).length > 0 && ((this.props.currentTime - 1) in this.props.leaderboard) &&
  			          this.renderLeaderboardList(this.props.leaderboard[this.props.currentTime - 1])
  			        }
              </React.Fragment>
              }
              { this.props.groupMode &&
                <React.Fragment>
                { this.props.sync_leaderboard_store.length > 0 &&
                  this.renderLeaderboardList(this.props.sync_leaderboard_store)
                }
                </React.Fragment>
              }


			    </div>

			</React.Fragment>
		)
	}		
}

const mapStateToProps = (state) => {

  return {
    user: state.user,
    electronMode: state.general.electronMode,
    video: state.video,
    track: state.currentTrack,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(raceLeaderboard))