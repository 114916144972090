import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Jumbotron, Button } from 'react-bootstrap'
import { Link } from "react-router-dom";

const UserRegistered = ({}) => {
  return (
		<div className="login-panel">
			<div className="container-fluid container-centered text-center">
			  <div>
		      <div className="center-align">
          <Link to="/"><img src="./vidfitness-red.png" className="logo-main"/></Link>
          </div>
          <div className="p-4">
            <h6>Please verify using the link we just emailed you. Then <Link to='/login'>sign in</Link> and get fit!</h6> 
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserRegistered
