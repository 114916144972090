import * as types from './actionTypes';

export const toggleProfileFormEditability = () => {
	return { type: types.TOGGLE_PROFILE_FORM_EDITABILITY }
}

export const toggleVideoFormEditability = () => {
	return { type: types.TOGGLE_VIDEO_FORM_EDITABILITY }
}

export const toggleIsLoading = () => {
	return { type: types.TOGGLE_LOADING_STATE }
}

export const toggleShowAlert = () => {
	return { type: types.TOGGLE_SHOW_ALERT}
}
