import React, { useState, useEffect, Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ChallengeFinePrint from './challenge-fine-print.component'
import JoinRemoveButtton from './join-leave-challenge.component'
import { determineChallengeThumbnail, imgError } from '../utils/utils.js'
import axios from 'axios';

class ChallengeCard extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.challenge.id == nextProps.challenge.id) {
      return false
    } else {
      return true
    }
  }

  render() {

    if(this.props.demoMode && this.props.electronMode){
      this.props.history.push('/demo')
    }

    return (
      <React.Fragment>
        <div className={"col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-2"}>
          <div className={"rounded"} style={{position: 'relative'}}>
            <div className="aspect16x9 rounded challenge-image-container" style={{position:'relative'}}>
              <img
                className="w-100"
                style={{top: '0', borderRadius:'.75rem', 'cursor': 'pointer'}}
                src={determineChallengeThumbnail(this.props.challenge)}
                onClick={() => this.props.history.push(`/challenge/${this.props.challenge.id}`)}
                onError={imgError}
              />
            </div>
            <div classname="join-challenge-card">
              { this.props.signed_in && this.props.challenge.active &&
                <JoinRemoveButtton
                  challenge={this.props.challenge}
                  featured={false}
                />
              }
            </div>
            <div className="">
              <ChallengeFinePrint
                challenge={this.props.challenge}
                registeredUser={false}
                nonSplash={true}
              />
            </div>
        </div>
      </div>
    </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
	let signed_in

	if (state.user.id == '') {
		signed_in = false
	} else {
		signed_in = true
	}

  return {
  	user: state.user,
    signed_in: signed_in
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChallengeCard));
