import initialState from './initialState';
import * as types from '../actions/actionTypes';

const tracksReducer = (state = initialState.tracks, action) => {
  switch (action.type) {
    case types.SAVE_TRACKS:
    	return action.payload
    case types.UPDATE_TRACK:
      return state.map((track, index) => {
        // Find the item with the matching id
        if(track.id === action.payload.id) {
          // Return a new object
          return {
            ...track,
            ...action.payload
          }
        }
        // Leave every other item unchanged
        return track;
      });
    case types.DELETE_TRACK:
    	return [
    	    ...state.slice(0, action.payload),
    	    ...state.slice(action.payload + 1)
    	]
    case types.APPEND_TRACKS: 
      return { ...state.push(action.payload) }
    default:
      return state;
  }
}

export default tracksReducer;