// 3rd Party Dependencies
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { FontAwesomeIcon }   from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import axios from 'axios';

// User Actions
import { updateUserAsync } from '../actions/userActions'

// Styles
import '../styles/channel.css'

// Custom Components
import Navbar from './navbar.component';
import Footer from './footer.component';
import ChannelForm from './channel-form.component'
import VideosGallery from './videos-gallery.component.js'

import {Helmet} from "react-helmet";
import truncate from 'lodash/truncate';

class MyChannelScreen extends Component {

  constructor(props){

    super(props);

    this.turnOnEditMode   = this.turnOnEditMode.bind(this)
    this.turnOffEditMode  = this.turnOffEditMode.bind(this)
    this.updateDimensions  = this.updateDimensions.bind(this)

    this.state = {
      videos: [],
      onEditMode: false,
      pageSize: 30,
      pageCount: 0,
      currentPage: 0,
      externalChannel: false,
      channel_title: '',
      channel_description: '',
      width:0,
      height: 0,
      channel_image: ''
    }
  }

  componentDidMount() {

    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions);

    const { id } = this.props.match.params
    let user_id = this.props.user.id;

    this.setState({
      user_id: user_id
    }, () => {
      this.loadVideosAndUserFromServer()
    })

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  loadVideosAndUserFromServer(term=null) {
    let currentPage = this.state.currentPage;
    let pageSize  = this.state.pageSize;
    let user_id   = this.state.user_id
    let query_string;


    query_string = '?'
    query_string += 'currentPage=' + currentPage
    query_string += '&pageSize=' + pageSize
    query_string += '&user_id=' + user_id
    query_string += '&myChannel=' + 'TRUE'
    // Gets videos and current user info
    axios.get((process.env.REACT_APP_SERVER)+'/api/v1/channel-info/' + query_string)
      .then(response => {
        const racesInfo = response.data.races
        this.setState({
          videos: racesInfo.videos,
          pageCount: Math.ceil(racesInfo.total_count / racesInfo.page_size)
        });
      })
      .catch(function (error){
        console.log(error);
      })
   }

  turnOnEditMode(){
    this.setState({ onEditMode: true })
  }

  turnOffEditMode(){
    this.setState({ onEditMode: false })
  }

  getFirstImageURL(url){
    return process.env.REACT_APP_ELECTRON_VIDEOS_BUCKET + '/' + url + '/thumbs-'+url+'-00001.jpg';
  };

  updateDimensions(){
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };


  // Checks the window's size - and truncates the description if necessary
  // Notice that for small screens we don't need to trunctate since the text
  // fills the whole width
  renderDescription(){
    if(this.state.width < 992){
      return this.state.videos[0].description
    } else {
      return truncate(this.state.videos[0].description, {
        'length': 350,
        'separator': ' '
      })
    }
  }

  render (){

    if(this.props.demoMode && this.props.electronMode && !this.props.user.isAuthenticated){
      return <Redirect to='demo' />
    }

    return (

          <React.Fragment>
        <Helmet>
          <meta property="og:url" content="https://vidfitness.com/video/{this.state.video_url}"/>
          <meta property="og:title" content="{this.props.user.channel_title}"/>
          <meta property="og:image" content="{ this.getFirstImageURL(this.state.videos[0].url) }"/>
          <meta property="og:description" content="{this.props.user.channel_description}"/>
          <meta property="og:type" content="website" />
          <meta property="fb:app_id" content="432091207556688"/>
        </Helmet>
        <div>
          <Navbar />
          <br />
          <div className="container-fluid">
            { this.props.user.channel_image &&
              <React.Fragment>
                <img style={{borderRadius:'.75rem'}} className="w-100 pt-3 pb-5" src={"/"+this.props.user.channel_image}/>
              </React.Fragment>
            }

            { !this.state.onEditMode &&
              <React.Fragment>
              <div className="row">
                <div className="col-12">
                  <div>
                      <FontAwesomeIcon onClick={this.turnOnEditMode} className='mr-4 float-right' className='edit-button' icon={['fa', 'edit']}/>
                      <h2 className="text-left pt-5 pl-4 section-head">{ this.props.user.channel_title }</h2>
                  </div>
                  <div className="text-left pl-4 mb-4">
                    { this.props.user.channel_description }
                  </div>
                </div>
              </div>
              </React.Fragment>
            }

            { this.state.onEditMode &&
              <React.Fragment>
                <button className='btn btn-outline-danger float-right mr-4 channel-cancel-button' onClick={this.turnOffEditMode}>
                  Cancel
                </button>

                <ChannelForm
                  user_id={this.props.user.id}
                  title={this.props.user.channel_title}
                  description={this.props.user.channel_description}
                  updateUser={this.props.dispatchUpdateUser}
                  turnOffEditMode={this.turnOffEditMode}
                />
              </React.Fragment>
            }

            {  this.state.videos.length > 0 &&
              <div className="row p-2 m-0">
                <div className="col-12 col-lg-6">
                  <Link to={ '/video/' + this.state.videos[0].url } className="">
                    <div className="channel-first-video">
                      <div className="img-container mb-3">
                        <img className="VFImage" style={{ top:'0px', borderRadius:'.75rem'}} src={ this.getFirstImageURL(this.state.videos[0].url) } alt="" />
                      </div>
                   </div>
                  </Link>
                </div>
                  <div className="col-12 col-lg-3">
                   <div><h4>{this.state.videos[0].title}</h4>
                   </div>
                   <div className="block-with-text">{this.renderDescription()}
                   </div>
                  </div>
              </div>
            }

            { this.state.videos.length > 1 &&
              <VideosGallery videos={this.state.videos.slice(1)} />
            }

          </div>

          { !this.props.electronMode &&
            <Footer />
          }
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    electronMode: state.general.electronMode,
    demoMode:     state.general.demoMode,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatchUpdateUser: (values) => {
      dispatch(updateUserAsync(values))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyChannelScreen)

