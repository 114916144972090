import initialState from './initialState';
import * as types from '../actions/actionTypes';

const currentTrackReducer = (state = initialState.currentTrack, action) => {
  switch (action.type) {
    case types.SAVE_CURRENT_TRACK:
    	return {
    	  ...state,
    	  ...action.payload
    	}
    default:
      return state;
  }
}

export default currentTrackReducer;