import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect }from "react-router-dom";
import Navbar from './navbar.component';
import Footer from './footer.component'
import ScrollToTopOnMount from './scroll-top.component.js'
import {Helmet} from "react-helmet";
import  '../styles/setup.css'

class SetupPageWindows extends Component {

  constructor(props){
    super(props)

    this.app_path_mac = process.env.REACT_APP_INSTALLER_PATH_MAC
    this.app_path_win = process.env.REACT_APP_INSTALLER_PATH_WIN
  }

  render(){

    if(this.props.demoMode && this.props.electronMode){
      return <Redirect to='demo' />
    }

    return (
      <React.Fragment>

        <ScrollToTopOnMount />

        <Helmet>
          <meta name="Description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
          <meta property="og:url" content="https://vidfitness.com/setup-windows"/>
          <meta property="og:title" content="VidFitness - Setup the VidFitness Application for Windows"/>
          <meta property="og:image" content="https://vidfitness.com/vidfitness-screen.jpg"/>
          <meta property="og:description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
          <meta property="og:type" content="website" />
          <meta property="fb:app_id" content="432091207556688"/>
        </Helmet>

        <div>
          <Navbar />
          <br />

          <div className="container">

            <h3 className="text-center section-head">Windows Setup</h3>

            <div className="row pt-5">
              <div className="col-lg">
              <p>
                <img src="./winsetup-ant.png" className="float-left mr-2 mt-0 mb-2" />VidFitness can connect to your smart trainer via ANT+ or Bluetooth. Currently, since force feedback is only supported using ANT+, not Bluetooth, we recommend using a Garmin ANT+ USB stick (pictured), which has been tested for compatibility with VidFitness.
              </p>
              </div>
              <div className="col-lg">
              </div>
            </div>

            <hr/>

            <div className="row">
              <div className="col-lg">
                <p>
                On Windows machines, unless you have used an ANT+ stick with other software (e.g., Zwift, TrainerRoad, etc.), you may need to install a specific device driver to use ANT+. To test your ANT+ connection, install VidFitness and look for the flashing ANT+ logo indicating that the connection is working. The screenshot (pictured) shows the active ANT+ logo and connection to a Taxc smart trainer. If the ANT+ logo remains grayed out, follow the steps below.
                </p>
              </div>
              <div className="col-lg">
                <p><img src="./winsetup-connect.png" className="mt-0 mb-2"/></p>
              </div>
            </div>

            <hr/>

            <div className="row">
              <div className="col-lg">
                <p>
                  <h3 className="text-dark text-decoration-none">Solution 1: Reset the ANT+ connection.</h3>
                  <ol>
                    <li>Close the VidFitness application.</li>
                    <li>Remove your ANT+ device.</li>
                    <li>Plug the ANT+ device back.</li>
                    <li>Restart VidFitness.</li>
                  </ol>
                </p>
              </div>
              <div className="col-lg">
              </div>
            </div>

            <hr/>

            <div className="row">
              <div className="col-lg">
                <p>
                <h3 className="text-dark text-decoration-none">Solution 2: Install drivers for your ANT+ stick.</h3>
                </p>
                <p><a href="https://zadig.akeo.ie">Zadig</a> is open-source software used to install the correct WinUSB device driver for your ANT+ stick.</p>
              </div>
              <div className="col-lg">
              </div>
            </div>

            <hr/>

            <div className="row">
              <div className="col-lg">
                <p><b>1.</b> After installing, you should see the following:</p>
                <p><img src="./winsetup-1.png" className="mt-0 mb-2" /></p>
              </div>
              <div className="col-lg">
                <p><b>2.</b> Make sure your ANT+ device is plugged in, and select it from the dropdown list:</p>
                <p><img src="./winsetup-3.png" className="mt-0 mb-2" /></p>
              </div>
            </div>

            <hr/>

            <div className="row">
              <div className="col-lg">
                <p><b>3.</b> Click the Reinstall Driver button and the driver will begin installing:</p>
                <p><img src="./winsetup-4.png" className="mt-0 mb-2" /></p>
              </div>
              <div className="col-lg">
                <p><b>4.</b> Once the installation is complete, a confirmation dialog box will appear:</p>
                <p><img src="./winsetup-5.png" className="mt-0 mb-2" /></p>
              </div>
            </div>

            <hr/>

            <div className="row">
              <div className="col-lg">
              <p><b>5.</b>
                You should now be able to restart VidFitness and see the ANT+ connection working. You can also use Windows Device Manager to check that the driver has been correctly installed, as shown in the following screenshot:
              </p>
              <p><img src="./winsetup-6.png" className="mt-0 mb-2" /></p>
              </div>
              <div className="col-lg">
              </div>
            </div>

            <hr/>

            <div className="row">
              <div className="col-lg">
                <p>
                  <h3 className="text-dark text-decoration-none">Additional Troubleshooting</h3>
                </p>
                <p>
                Not all ANT+ USB devices are created equal. Older or cheaper ANT+ USB sticks are not compatible with most smart trainers. If you continue to have issues connecting to your smart trainer through ANT+ on Windows, please contact us.</p>
              </div>
              <div className="col-lg">
              </div>
            </div>

          </div>

              { !this.props.electronMode &&
              <Footer />
            }


        </div>

      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user:         state.user,
    electronMode: state.general.electronMode,
    demoMode:     state.general.demoMode
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupPageWindows);
