import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import axios from 'axios';
import scrollDown from '../images/scroll_down.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { removeLastSegment, getInfoFromVideoUrl, determineChallengeThumbnail } from '../utils/utils.js'
import '../styles/challenges.css'
import VideoGallery from './videos-gallery.component'
import { v4 as uuidv4 } from 'uuid';
import ChallengeFinePrint from './challenge-fine-print.component'
import JoinRemoveChallenge from './join-leave-challenge.component'
import ChallengeLeaderboard from './challenge-leaderboard.component'
var moment = require('moment')

// function SplashStatus ({userChallenge = null, challenge, user, registeredUser, ...props})

class SplashStatus extends Component {
	constructor(props) {
		super(props)

		this.state = {
			videos: [],
			refreshing: false,
			videosWatched: null,
    	status_data: null,
    	loaded: false,
    	leaderboard_loaded: false,
    	count_of_registered_users_for_leaderboard: 0,
		}

		this.goToVideo = this.goToVideo.bind(this)
		this.renderStatusText = this.renderStatusText.bind(this)
		this.refreshCallback = this.refreshCallback.bind(this)
		this.refreshLeaderboard = this.refreshLeaderboard.bind(this)
		this.refreshUserChallenge = this.refreshUserChallenge.bind(this)
	}

	async componentDidMount() {
		var goals = JSON.parse(this.props.challenge.goal_payload)

	  var payload = {
      values: goals.video_subset,
    }

    //intializing values
    var status_data = null
		var videosWatched = null
		var challenge = this.props.challenge
   	var registeredUser = false
   	var challenge_type = challenge.challenge_type
   	var race_response = []
   	let userChallenge = null

   	if (this.props.signed_in) {
	   	//get race subset videos

	   	var UC = await this.refreshUserChallenge()

	   	console.log(UC)

	   	if (UC.is_registered) {
				registeredUser = true
				var status_data = JSON.parse(UC.user_challenge.status_data)
				if (challenge_type == 'RACE_BASED') {
					var videosWatched = status_data.status.videosWatched
				}
			}

			userChallenge = UC.user_challenge

	  }

	  if (challenge_type == 'RACE_BASED') {
			const { data : resp } = await axios.post((process.env.REACT_APP_SERVER) + '/api/v1/races/subset', payload)
	  	race_response = resp
	  }

    this.setState({
    	videos: race_response,
    	registeredUser: registeredUser,
    	videosWatched: videosWatched,
    	status_data: status_data,
    	userChallenge: userChallenge,
    	challenge: challenge,
    	loaded: true,
    	challenge_type: challenge_type,
    	order: payload.values
    }, () => this.refreshLeaderboard())

	}

	async refreshUserChallenge() {

		console.log('here')

		var { data: userChallengeForChallenge } = await axios.get((process.env.REACT_APP_SERVER) + `/api/v1/challenge/user-challenge/?challenge_id=${this.props.challenge.id}&user_id=${this.props.user.id}`)

		return userChallengeForChallenge

	}

	async refreshLeaderboard() {

		const { data: { count, rows } } = await axios.get((process.env.REACT_APP_SERVER) + `/api/v1/challenge/leaderboard/${this.state.challenge.id}`)

    var sorted = rows.sort((a, b) => a.ranking - b.ranking);

    let halfwayThrough = Math.ceil(sorted.length / 2)

		let arrayFirstHalf = sorted.slice(0, halfwayThrough);
		let arraySecondHalf = sorted.slice(halfwayThrough, sorted.length);


    this.setState({
      leaderboard_loaded: true,
      count_of_registered_users_for_leaderboard: count,
      first_half_users_for_leaderboard: arrayFirstHalf,
      second_half_users_for_leaderboard: arraySecondHalf
    }, () => console.log(this.state))
	}

	async refreshCallback(user, challenge) {

		this.refreshLeaderboard()

		const { user_challenges } = user

		let registeredUser = false
		let videosWatched = null
		let status_data = null

		var UCforChallenge =  await this.refreshUserChallenge()

		if (UCforChallenge.is_registered) {
			registeredUser = true
			status_data = JSON.parse(UCforChallenge.user_challenge.status_data)
			videosWatched = status_data.status.videosWatched
		}

		this.setState({
			registeredUser: registeredUser,
			status_data: status_data,
			videosWatched: videosWatched,
			userChallenge: UCforChallenge.user_challenge
		})

	}

	goToVideo(filename) {
		this.props.history.push('/video/' + filename);
	}

	renderStatusText(percentage, type) {
		if (type == 'UPLOAD_BASED') {
			if (percentage == 0) {
		    return "Start Uploading!"
		  } else if (percentage > 0 && percentage < 0.5) {
		    return "Great Work!"
		  } else if (percentage > 0.5 && percentage < 1) {
		    return "Keep Sharing!"
		  } else if (percentage >= 1) {
		    return "Challenge complete!"
		  } else {
		    return "Great Work!"
		  }
		} else {
			if (percentage == 0) {
		    return "Get ready to workout!"
		  } else if (percentage > 0 && percentage < 0.5) {
		    return "Great start! Keep going."
		  } else if (percentage > 0.5 && percentage < 1) {
		    return "Almost there, you got this!"
		  } else if (percentage >= 1) {
		    return "You did it. Congrats!"
		  } else {
		    return "Getting there, keep going!"
		  }
		}
	}

	render() {
	  return (
	    <React.Fragment>
	    	{ this.state.loaded &&
	    	 	<div className="container">
	          <div className="pr-3 pr-md-5 pl-2 pl-md-2">
	            <div className="splash-more-info-image-box pl-0 pl-md-4">
	              <img className="splash-header-image" style={{ borderRadius:'.75rem' }} src={determineChallengeThumbnail(this.state.challenge)} />
	              <div className="splash-button">
								  	{ this.props.signed_in && this.state.challenge.active &&
											<div className="">
												<JoinRemoveChallenge
													challenge={this.state.challenge}
													featured={true}
													splash={true}
													refreshCallback={this.refreshCallback}
												/>
											</div>
										}
										{ !this.props.signed_in &&
											<div className="join-challenge-splash">
												<button
				                  onClick={(event)=> {
				                    this.props.history.push('/login')
				                  }}
				                  className="btn btn-success">
				                  Join
				                </button>
			                </div>
										}
								</div>
	            </div>
	            <div className="container">
	              <div className="mt-4 ml-4 mr-4 row" style={{textAlign:'left'}}>
              		<div className="col splash-top mb-1">
              			<h2 className="line-clamp-1">
	                  	<b>{this.state.challenge.title}</b>
	                	</h2>
              		</div>
	              </div>
	              <div className="splash-more-info-description-box ml-4 mr-4">
	                {this.state.challenge.description}
	               </div>
	            </div>
	            <div className="splash-bottom mb-1">
	              <div className="container mt-3">

					    			<div>
											<div>
												{ this.state.registeredUser &&
												<div>
													<div className="competion-box">
											  		<div className="ml-4 mr-4 flex">
											  			<h5>
											  				{this.state.userChallenge.complete ?
											  					<b className="text-success">Completed!</b> :
											  					<b>{this.renderStatusText(this.state.userChallenge.completion_percent, this.state.challenge_type)}</b>}
											  				<b style={{float:'right'}}>{`${(this.state.userChallenge.completion_percent * 100).toFixed(0)}%`}</b>
											  			</h5>
											  		</div>
											  		<div class="progress">
														  <div class="progress-bar" role="progressbar" style={{width: `${this.state.userChallenge.completion_percent * 100}%`}} aria-valuenow={`${this.state.userChallenge.completion_percent * 100}`} aria-valuemin="0" aria-valuemax="100"></div>
														</div>
											  	</div>
											  </div>
											  }
											</div>
										<div>
											<ChallengeFinePrint
												challenge={this.state.challenge}
												registeredUser={this.state.registeredUser}
												status_data={this.state.status_data}
												videosWatched={this.state.videosWatched}
											/>
										</div>
										{ this.state.challenge_type == 'RACE_BASED' &&
								  	<div>
								  		<VideoGallery order={this.state.order} videos={this.state.videos} type ={'CHALLENGE'} watched={this.state.videosWatched}/>
								  	</div>
								  	}
							  	</div>

						  </div>
	            </div>
	          </div>
	          <div className="mt-2">
	          	{ this.state.count_of_registered_users_for_leaderboard > 0 &&
		          <ChallengeLeaderboard
		          	loaded={this.state.leaderboard_loaded}
		          	count_of_registered_users={this.state.count_of_registered_users_for_leaderboard}
		          	first_half_users_for_leaderboard={this.state.first_half_users_for_leaderboard}
		          	second_half_users_for_leaderboard={this.state.second_half_users_for_leaderboard}
		          	type={this.state.challenge_type}
			        />
			      	}
		        </div>
	        </div>

	      }

	  </React.Fragment>
	  );
	}
};

const mapStateToProps = (state) => {
	let signed_in

	if (state.user.id == '') {
		signed_in = false
	} else {
		signed_in = true
	}

  return {
  	user: state.user,
  	userChallenges: state.user.user_challenges,
  	signed_in: signed_in
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SplashStatus));
