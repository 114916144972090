import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter }from "react-router-dom";
import Navbar from './navbar.component';
import HomePage from './homepage.component';
import Footer from './footer.component'
import queryString from 'query-string'
import '../styles/recording.css'


const RecordingStatus = ({overlay}) => {
  if (overlay.audioRecording) {  
    return (<div id="recButton" className="Rec recordbackground"></div>)
  } else {
    return (<div></div>)
  } 
}

const mapStateToProps = (state) => {
  return {
  	electronMode: state.general.electronMode,
  	overlay: state.overlay
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecordingStatus));

