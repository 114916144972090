// React Dependencies
import React, { Component, Fragment,  useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { Redirect, withRouter }from "react-router-dom";
import { Link } from "react-router-dom";
import videojs from 'video.js'
import { showMainOverlay, hideMainOverlay } from '../actions/generalActions'
import processing from '../images/processing.jpg'

// Other Dependencies
import { FontAwesomeIcon }   from '@fortawesome/react-fontawesome'
var classNames = require('classnames');


class VFImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      src: props.src,
      errored: false,
    };
  }

  onError = () => {
    if (!this.state.errored) {
      this.setState({
        src: processing,
        errored: true,
      });
    }
  }

  render() {
    const { src } = this.state;

    return (
      <img
        className="img-responsive rounded-top"
        src={src}
        onError={this.onError}
      />
    );
  }
}


const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VFImage))


