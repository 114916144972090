import initialState from './initialState';
import * as types from '../actions/actionTypes';

const generalReducer = (state = initialState.general, action) => {
  switch (action.type) {
  	case types.SHOW_TERMS:
  	  return { ...state, confirmedAgreement: false, showTerms: true }
    case types.CONFIRM_AGREEMENT:
      return { ...state, confirmedAgreement: true, showTerms: false }
    case types.SAVE_HOST_NAME:
      return { ...state, hostName: action.payload }
    case types.TURN_ON_ELECTRON_MODE:
      return { ...state, electronMode: true }
    case types.TOGGLE_ELECTRON_MODE:
      return { ...state, electronMode: !state.electronMode }
    case types.TOGGLE_START_SIGNAL:
      return { ...state, startSignal: !state.startSignal }
    case types.SAVE_SERVER_NAME:
      return { ...state, serverName: action.payload }
    case types.TURN_ON_DEMO_MODE:
      return { ...state, demoMode: true }
    case types.TURN_OFF_DEMO_MODE:
      return { ...state, demoMode: false }
    case types.SHOW_MAIN_OVERLAY:
      return { ...state, showMainOverlay: true }
    case types.HIDE_MAIN_OVERLAY:
      return { ...state, showMainOverlay: false }
    // Log Out forces to agree on terms again
    case types.LOG_OUT:
      return { ...state, confirmedAgreement: false, showTerms: false}
    default:
      return state;
  }
}

export default generalReducer;
