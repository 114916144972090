import {combineReducers} from 'redux';
import userReducer from './userReducer';
import generalReducer from './generalReducer';
import tracksReducer from './tracksReducer';
import currentTrackReducer from './currentTrackReducer';
import uiReducer from './uiReducer';
import videoReducer from './videoReducer';
import overlayReducer from './overlayReducer'
import { reducer as formReducer } from 'redux-form'

const rootReducer = combineReducers({
  user:    userReducer,
  general: generalReducer,
  currentTrack: currentTrackReducer,
  tracks:  tracksReducer,
  form:    formReducer,
  ui:      uiReducer,
  video:   videoReducer,
  overlay: overlayReducer,
});

export default rootReducer;