import React, { Component, createRef } from 'react';
import { withRouter }from "react-router-dom";
import { connect } from 'react-redux';
import WaveSurfer from 'wavesurfer.js';
import { PlayerIcon } from 'react-player-controls'
import disabled_play from '../images/disabled-play.png'
import ReactDOM from 'react-dom'

import { updateAudioRecordings,
         submitAudioRecording,
         startAudioRecording,
         stopAudioRecording,
         updateAudioLayers,
         updatePlayingOverlayCurrently,
         updateInRange} from '../actions/overlayActions';

import { toggleTheaterMode,
         enterPlayMode,
         setPlayingState,
         setPausedState } from '../actions/videoActions'

import videojs from 'video.js';

import '../styles/waveform.css';

class Waveform extends Component { 

  constructor(props) {
    super(props)

    this.renderPlayPause = this.renderPlayPause.bind(this)
    this.createSurfer   = this.createSurfer.bind(this)
    this.createKey       = this.createKey.bind(this)
    this.automation       =  this.automation.bind(this)
    this.controlSeek = this.controlSeek.bind(this)

    this.state = {
      start_index: null,
      end_index: null,
      PlayButtonClicked: false,
      duration: 0,
      automatedPlay: false,
      surfer: {},
      video: {},
      interactable: true,
      seekOff: false,
      override: false
    }
  }

  createKey(naieve_key){
    var naieve_key_string = naieve_key.split("")
    var naieve_key_without_decimals = naieve_key_string.filter((el) => el != '.')

    var numbers = naieve_key_without_decimals.map((num) => {
      var chr = String.fromCharCode(97 + parseInt(num));
      return chr
    })

    var str = numbers.join("");

    return str
  }

  componentDidMount() {

    const layer = this.props.layer 
    console.log(layer)

    var player = videojs('race-video')

    var uniq = this.createKey(`${layer.start_index}${layer.duration}`)
  
    this.setState({
      start_index: layer.start_index,
      end_index: layer.end_index,
      duration: layer.duration,
      peaks_data: layer.peaks_data,
      url: layer.url,
      video: player,
      load: true,
      id: uniq,
    }, () => { 
      this.createSurfer()
    })
  };

  automation() {
      function between(x, min, max) {
        return x >= min && x <= max;
      }

      if (this.props.overlay.inRange && !this.state.video.paused() && this.props.overlay.playingOverlayCurrently) {
        if (between(this.state.video.currentTime(), this.state.start_index, this.state.end_index) && (this.state.PlayButtonClicked || this.state.automatedPlay)) {
          if (!this.state.interactable) {
            this.setState({interactable: true}, () => {
              this.state.surfer.toggleInteraction()
            })
          }
        } else {
          if (this.state.interactable) {
            this.setState({interactable: false}, () => {
              this.state.surfer.toggleInteraction()
            })
          }
        }
      }

      if (!this.state.interactable && !this.props.overlay.playingOverlayCurrently && (!this.state.PlayButtonClicked && !this.state.automatedPlay)) {
        if (!this.state.interactable) {
          this.setState({interactable: true}, () => {
            this.state.surfer.toggleInteraction()
          })
        }
      }

      var time = this.state.video.currentTime()
      if (!this.state.PlayButtonClicked) {
        if (between(time, this.state.start_index + 0.3, this.state.end_index - 0.3)) {
          if (!this.props.overlay.inRange) {
            this.props.dispatchUpdateInRange(true)
          }
          
          if (!this.state.automatedPlay && !this.state.video.paused()) {
            this.setState({
              automatedPlay: true,
              seekOff: true,
            }, () => {

              var index_displace = time - this.state.start_index - 0.3
              var progress = index_displace / this.state.duration
              var buffer_zone = this.state.start_index + 1
              var relative_time = this.state.surfer.getCurrentTime() + this.state.start_index - 0.3

              if (time > buffer_zone) {
                this.state.surfer.seekTo(progress)
              } 

              if (relative_time - time > 1) {
                this.state.surfer.seekTo(progress)
                this.setState({override: true})
              } 

              if (!this.state.surfer.isPlaying()) {
                this.state.surfer.play()
              }

              if (!this.props.overlay.playingOverlayCurrently) {
                this.props.dispatchUpdatePlayingOverlayCurrently(true)
              }

              this.setState({seekOff: false})

              this.state.surfer.once('finish', () => {
                if (this.props.overlay.playingOverlayCurrently) {
                  this.props.dispatchUpdatePlayingOverlayCurrently(false)
                }

                if (this.props.overlay.inRange) {
                  this.props.dispatchUpdateInRange(false)
                }

                this.setState({
                  PlayButtonClicked: false,
                  automatedPlay: false,
                  override: false
                })
              })
            })
          }
        } 
      }
    }

  controlSeek() {

    if (!this.state.seekOff) {

      this.state.video.currentTime(this.state.start_index + this.state.surfer.getCurrentTime())

      if (!this.state.surfer.isPlaying()) {
        this.state.surfer.play()
      }

      if (this.state.video.paused()) {
        this.state.video.play()
        this.props.dispatchEnterPlayMode()
      }

    }

  }

  createSurfer() {

     

    var cont = `#${this.state.id}`
    var wavesurfer = WaveSurfer.create({
      container: cont,
      waveColor: '#847577',
      progressColor: '#247ba0',
      barWidth: 2,
      cursorWidth: 0,
      height: 70,
      hideScrollbar: true,
      responsive: true,
      backgroundColor: '#e5e6e4',
      partialRender: true
    })

    this.setState({surfer: wavesurfer}, () => {

      if (this.state.peaks_data == null) {
        this.state.surfer.load(this.state.url)
      } else {
        console.log(this.state.peaks_data.data)
        this.state.surfer.load(this.state.url)
      }

      if (this.props.video.race.mute_overlay_audio) {
        this.state.surfer.setMute(true)
      }

      //maybe add a audioprocess event to pause surfer if hears that video is paused

      this.state.surfer.on('audioprocess', () => {
        if (this.props.video.race.mute_overlay_audio || this.props.video.globalMuteStatus) {
          if (!this.state.surfer.getMute()) {
            this.state.surfer.setMute(true)
          }
        } else {
          if (this.state.surfer.getMute()) {
            this.state.surfer.setMute(false)
          }
        }
      })

      this.state.surfer.on('seek', this.controlSeek)

      this.state.video.on('timeupdate', this.automation)
      this.state.video.on('pause', () => {

        if (this.state.automatedPlay) {
          this.setState({automatedPlay: false})
        }

        if (this.state.PlayButtonClicked) {
          this.setState({PlayButtonClicked: false})
        }

        if (this.state.surfer.isPlaying()) {
          this.state.surfer.pause()
        }

        if (this.props.overlay.playingOverlayCurrently) {
          this.props.dispatchUpdatePlayingOverlayCurrently(false)
        }

        if (!this.state.interactable) {
          this.setState({interactable: true}, () =>{
            this.state.surfer.toggleInteraction()
          })
        }
      
      })
    })
  }

  componentWillUnmount(){
    this.state.surfer.unAll()
    this.state.surfer.destroy()
    // ReactDOM.unmountComponentAtNode(this.waveform)
    this.state.video.off('timeupdate', this.automation)
  }

  renderPlayPause() {
    if (this.state.load) {
      if (this.props.overlay.inRange && (!this.state.automatedPlay && !this.state.PlayButtonClicked) && !this.state.video.paused()) {
        return (<div>
                  <img src={disabled_play} className="play-pause-button" style={{width:48,height:48}} />
                </div>)
      } else {
        if ((this.state.PlayButtonClicked || this.state.automatedPlay) && !this.state.video.paused()) {
          return (
            <PlayerIcon.Pause onClick={() => {
            
             this.setState({
              automatedPlay: false,
              PlayButtonClicked: false
             }, () => {
              this.state.surfer.pause()
              this.state.video.pause()
              this.props.dispatchSetPausedState(true)
              if (this.props.overlay.playingOverlayCurrently) {
                this.props.dispatchUpdatePlayingOverlayCurrently(false)
              }
             })

            }} className="play-pause-button" width={48} height={48} />
          )
        } else {
            return (
              <PlayerIcon.Play onClick={() => {

              this.setState({
                automatedPlay: false,
                PlayButtonClicked: true,
                override: false
              })

              let time

              if (this.state.surfer.getCurrentTime() / this.state.duration < 0.95) {
                time = this.state.surfer.getCurrentTime()
              } else {
                time = 0
              }

              this.state.video.currentTime(this.state.start_index + time);

              this.state.surfer.play()

              this.state.video.play()
              this.props.dispatchEnterPlayMode()

              if (!this.props.overlay.inRange) {
                this.props.dispatchUpdateInRange(true)
              }

              if (!this.props.overlay.playingOverlayCurrently) {
                this.props.dispatchUpdatePlayingOverlayCurrently(true)
              }

              this.state.surfer.once('finish', () => {

                if (!this.state.override) {

                  this.state.video.pause()
                  this.props.dispatchSetPausedState(true)

                  if (this.props.overlay.playingOverlayCurrently) {
                    this.props.dispatchUpdatePlayingOverlayCurrently(false)
                  }

                  if (this.state.end_index + 0.5 < this.state.video.duration()) {
                    this.state.video.currentTime(this.state.end_index + 0.5)
                  }

                  if (this.props.overlay.inRange) {
                    this.props.dispatchUpdateInRange(false)
                  }

                  if (this.state.PlayButtonClicked) {
                    this.setState({PlayButtonClicked: false})
                  }

                }

              })

            }} className="play-pause-button" width={48} height={48} />)
        }
      }
    } else  { 
      return (<div></div>)
    }
  }

  render() {

    return (
      <div>
        <div className="row">
          <div className="col-1" >
              {this.renderPlayPause()}
          </div>
          <div className="col">
            {this.state.load &&
            <div className='waveform'>
              <div id={`${this.state.id}`}></div>
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    video: state.video,
    electronMode: state.electronMode,
    overlay: state.overlay
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatchEnterPlayMode:     (value)  => { dispatch(enterPlayMode(value))},
    dispatchSetPausedState:    (value)  => { dispatch(setPausedState(value))},
    dispatchUpdateAudioLayers: (value)  => {dispatch(updateAudioLayers(value))},
    dispatchUpdateInRange: (value)    => {dispatch(updateInRange(value))},
    dispatchUpdatePlayingOverlayCurrently: (value) => {dispatch(updatePlayingOverlayCurrently(value))},
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Waveform))