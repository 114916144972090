// 3rd Party Dependencies
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { FontAwesomeIcon }   from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import axios from 'axios';
import truncate from 'lodash/truncate';

// User Actions
import { updateUserAsync } from '../actions/userActions'

// Styles
import '../styles/channel.css';
import '../styles/gallery.css'

// Custom Components
import Navbar from './navbar.component';
import ChannelForm from './channel-form.component'
import VideosGallery from './videos-gallery.component.js'
import Footer from './footer.component.js'

import {Helmet} from "react-helmet";

class ChannelScreen extends Component {

	constructor(props){

		super(props);

		this.turnOnEditMode   = this.turnOnEditMode.bind(this)
		this.turnOffEditMode  = this.turnOffEditMode.bind(this)
    this.updateDimensions  = this.updateDimensions.bind(this)

		this.state = {
			videos: [],
			onEditMode: false,
			pageSize: 30,
			pageCount: 0,
			currentPage: 0,
			externalChannel: false,
			channel_title: '',
			channel_description: '',
      width:0,
      height: 0,
			channel_image: ''
		}
	}

	componentDidMount() {

    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions);

		const { id } = this.props.match.params

		if(id == this.props.user.id){
		  this.props.history.push('/my-channel')
		}

		let user_id;

		let is_not_my_channel = (typeof id != 'undefined')

		if(is_not_my_channel){
			user_id = id
		}
		else{
			// In case the id doesn't come from the URL, we use the one saved in the redux store
			// i.e the currently logged in user id
			user_id = this.props.user.id
		}

		this.setState({
			user_id: user_id
		}, () => {
			this.loadVideosAndUserFromServer()
		})

	}

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

	loadVideosAndUserFromServer(term=null) {
	  let currentPage = this.state.currentPage;
	  let pageSize  = this.state.pageSize;
	  let user_id   = this.state.user_id
	  let query_string;


	  query_string = '?'
	  query_string += 'currentPage=' + currentPage
	  query_string += '&pageSize=' + pageSize
	  query_string += '&user_id=' + user_id
	  query_string += '&publicChannel=' + 'TRUE'

	  // Gets videos and current user info
	  axios.get((process.env.REACT_APP_SERVER)+'/api/v1/channel-info/' + query_string)
	    .then(response => {
	      const racesInfo = response.data.races
	      const userInfo  = response.data.user
	      this.setState({
	        videos: racesInfo.videos,
	        pageCount: Math.ceil(racesInfo.total_count / racesInfo.page_size),
	        channel_title: userInfo.channel_title,
	        channel_description: userInfo.channel_description,
	        channel_image: userInfo.channel_image
	      });
	    })
	    .catch(function (error){
	      console.log(error);
	    })
	 }

	turnOnEditMode(){
	  this.setState({ onEditMode: true })
	}

	turnOffEditMode(){
	  this.setState({ onEditMode: false })
	}

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

	getFirstImageURL(url){
    return process.env.REACT_APP_ELECTRON_VIDEOS_BUCKET + '/' + url + '/thumbs-'+url+'-00001.jpg';
	};

  updateDimensions(){
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };


  // Checks the window's size - and truncates the description if necessary
  // Notice that for small screens we don't need to trunctate since the text
  // fills the whole width
  renderDescription(){
    if(this.state.width < 992){
      return this.state.videos[0].description
    } else {
      return truncate(this.state.videos[0].description, {
        'length': 350,
        'separator': ' '
      })
    }
  }


	render (){

		if(this.props.demoMode && this.props.electronMode && !this.props.user.isAuthenticated){
		  return <Redirect to='demo' />
		}

		return (

      <React.Fragment>
				<Helmet>
					<meta property="og:url" content="https://vidfitness.com/video/{this.state.video_url}"/>
					<meta property="og:title" content="{this.props.user.channel_title}"/>
					<meta property="og:image" content="{ this.getFirstImageURL(this.state.videos[0].url) }"/>
					<meta property="og:description" content="{this.props.user.channel_description}"/>
					<meta property="og:type" content="website" />
					<meta property="fb:app_id" content="432091207556688"/>
				</Helmet>
				<div>
					<Navbar />

					<div className="container-fluid channel-image">
						{ this.state.channel_image &&
							<React.Fragment>
							  <img className="w-100 pb-4" style={{top: '0', borderRadius:'.75rem'}} src={"/"+this.state.channel_image}/>
							</React.Fragment>
						}
          </div>
          <div className="container-fluid">

            {/* thereshold is 992px*/}

						{ !this.state.onEditMode &&
							<React.Fragment>
									{ this.state.user_id == this.props.user.id &&
										<FontAwesomeIcon onClick={this.turnOnEditMode} className='float-right' icon={['fa', 'edit']}/>
									}
								<h2 className="text-left pt-5 pl-4 section-head">
									{ this.state.channel_title }

								</h2>
								<div className="text-left pl-4 mb-4">
									{ this.state.channel_description }
								</div>
							</React.Fragment>
						}

						{ this.state.onEditMode &&
							<React.Fragment>
								<button className='btn btn-sm btn-outline-danger float-right' onClick={this.turnOffEditMode}>
								  Cancel
								</button>

								<ChannelForm
									user_id={this.props.user.id}
									title={this.props.user.channel_title}
									description={this.props.user.channel_description}
									updateUser={this.props.dispatchUpdateUser}
									turnOffEditMode={this.turnOffEditMode}
								/>
							</React.Fragment>
						}

						{  this.state.videos.length > 0 &&
						<div className="row p-2 m-0">
              <div className="col-12 col-lg-6">
                <Link to={ '/video/' + this.state.videos[0].url } className="">
                  <div className="channel-first-video">
                    <div className="img-container mb-3">
                      <img className="VFImage" style={{top: '0', borderRadius:'.75rem'}} src={ this.getFirstImageURL(this.state.videos[0].url) } alt="" />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-lg-6">
              	<div className="gallery-video-title"><b>{this.state.videos[0].title}</b></div>
              	<div className="block-with-text">{this.renderDescription()}</div>
              </div>
            </div>
						}

						{ this.state.videos.length > 1 &&
							<VideosGallery videos={this.state.videos.slice(1)} />
						}

					</div>

          { !this.props.electronMode &&
            <Footer />
          }

				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => {
  return {
  	user: state.user,
  	electronMode: state.general.electronMode,
  	demoMode:     state.general.demoMode,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  	dispatchUpdateUser: (values) => {
  		dispatch(updateUserAsync(values))
  	}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelScreen)
