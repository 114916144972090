import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ChallengeFinePrint from './challenge-fine-print.component'
import JoinRemoveChallenge from './join-leave-challenge.component'
import { determineChallengeThumbnail, imgError } from '../utils/utils.js'
import axios from 'axios';


const FeaturedChallenge = ({featuredChallenge, userChallenges, history, signed_in}) => {

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row"> 
          <div className="col-12 pl-4 pl-sm-5 mt-1 mb-3 pb-3 pb-sm-2">
            <h3 className="page-title">Challenges</h3>
          </div>
        </div>
        <div className="row p-2 m-0">
          <div className="col-12 col-lg-6 featured-challenge-desktop">
            <div className="channel-first-video">
              <div className="img-container mb-3" style={{position: 'relative'}}>
                <img
                  onClick={() => history.push(`/challenge/${featuredChallenge.id}`)}
                  className="VFImage"
                  style={{cursor: 'pointer', borderRadius:'.75rem'}}
                  src={ determineChallengeThumbnail(featuredChallenge) }
                  alt=""
                  onError={imgError}
                  />


                  {signed_in &&
                    <JoinRemoveChallenge
                      userChallenges={userChallenges}
                      challenge={featuredChallenge}
                    />
                  }

              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 featured-challenge-desktop">
            <div
              className="ml-4 mt-1 mr-4 title-container"
              style={{cursor: 'pointer'}}
              onClick={() => history.push(`/challenge/${featuredChallenge.id}`)}
            >
              <h1 className="truncate section-head">
              
                  {featuredChallenge.title}
                
              </h1>
            </div>
            <div className="block-with-text mt-3">

              <ChallengeFinePrint
                challenge={featuredChallenge}
                registeredUser={false}
                nonSplash={true}
                headliner={true}
              />

            </div>
              {/*this.state.featuredChallenge.participants > 0 ?
                  <p className="mt-2">{this.state.featuredChallenge.participants} {this.state.featuredChallenge.participants == 1 ? "participant" : "participants"}.</p>
                  : <div></div>*/}
          </div>


          <div className={"col-12 col-lg-6 featured-challenge-mobile"}>
            <div className={"rounded"}>
                <div className="rounded challenge-image-container" style={{position:'realtive'}}>
                  <img className="w-100"
                  style={{top: '0', borderRadius:'.75rem', cursor: 'pointer'}}
                  src={determineChallengeThumbnail(featuredChallenge)}
                  onClick={() => history.push(`/challenge/${featuredChallenge.id}`)}/>
                  <div style={{textAlign:'center'}}>
                    {signed_in &&
                      <JoinRemoveChallenge
                        challenge={featuredChallenge}
                        featured={true}
                      />
                    }
                  </div>
                </div>
                <div className="">
                  <ChallengeFinePrint
                    challenge={featuredChallenge}
                    registeredUser={false}
                    nonSplash={true}
                  />
                </div>
            </div>
          </div>

        </div>
      </div>
    </React.Fragment>
  )

}

const mapStateToProps = (state) => {
  let signed_in

  if (state.user.id == '') {
    signed_in = false
  } else {
    signed_in = true
  }

  return {
    user: state.user,
    signed_in: signed_in
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeaturedChallenge));
