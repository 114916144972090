import React, { Component } from 'react';
import { connect } from 'react-redux'
import Navbar from './navbar.component';
import VideoForm from './new-video-form.component'
import { toggleIsLoading } from '../actions/uiActions'
import { uploadVideoAsync } from '../actions/userActions'
import queryString from 'query-string'
import axios from 'axios'
import { sleep } from '../utils/utils.js'

class NewVideo extends Component {
  render (){
    return (
      <div>
        <Navbar />
        <br />
        <div id="duration" style={{display:'none'}}> </div>
        <VideoForm />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatchUploadVideo: (values) => {
      dispatch(uploadVideoAsync(values))
        .then( () => {
        })
    },
    dispatchToggleIsLoading: () => {
      dispatch(toggleIsLoading())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewVideo)
