import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect }from "react-router-dom";
import Navbar from './navbar.component';
import Footer from './footer.component'
import ScrollToTopOnMount from './scroll-top.component.js'
import {Helmet} from "react-helmet";
import {isBrowser, isMobile} from 'react-device-detect'
import $ from 'jquery'

const queryString = require('query-string');

class About extends Component {

  constructor(props){
    super(props)

    this.app_path_mac = process.env.REACT_APP_INSTALLER_PATH_MAC
    this.app_path_win = process.env.REACT_APP_INSTALLER_PATH_WIN

    this.state = {

      buttontitlesignedin:  'Workout now',
      buttontitlesignedout: 'Sign me up',

      showcaseheader1:  'Make fitness fun.',
      showcasetext1:    'Refresh your fitness regime with streaming workouts. If you’re using an indoor smart trainer, our desktop app lets you workout live with a friend, coach, group, or other members. See your fitness stats onscreen, track performance over time, and share achievements to Strava. Join challenges, subscribe to channels, and share your own videos.',
      showcaseimage1:   'home-vidfitnesstrainer16x9',
      showcaselink1: '/videos',

      showcaseheader2:  'Explore the world.',
      showcasetext2:    'Streaming workouts and challenges let you explore the world from coastal New England to the forests, hills, valleys, lakes, and ancient sites of England and Wales, from U.S National Parks to the Pyrenees and Swiss Alps, from mountain bike and linear trails to road races and the thrill of a velodrome.',
      showcaseimage2:   'about-challenge-explore',
      showcaselink2: '/videos',

      showcaseheader3:  'Support creators.',
      showcasetext3:    'Subscribe to help fund our premier content providers who create workouts and adventures to keep you engaged and motivated to stay healthy for the long run. Or become a content creator yourself and starting sharing your own fitness adventures.',
      showcaseimage3:   'about-channels',
      showcaselink3: '/channel/2',

      showcaseheader4:  'Share adventures.',
      showcasetext4:    'VidFitness makes it easy for you to create and upload your own workout videos for your friends and groups as well as other members to enjoy. Share your talent and adventures with others for the pure fun of it, to build a following, to promote your event, group, or coaching practice.',
      showcaseimage4:   'home-create16x9',

      showcaseheader5:  'Get fit now.',
      showcasetext5:    'With streaming videos and AI technology, you’ll feel like you’re right in the action. After your workout, automatically share your activity to Strava and win that motivation from friends to keep it up.',
      showcaseimage5:   'about-velodrome'
  }
}
  componentDidMount() {
    const parsed = queryString.parse(window.location.search);
    if ('platform' in parsed) {
      var a = document.createElement('a');
      if (parsed.platform == 'mac') {
        a.setAttribute('href', this.app_path_mac);
        a.setAttribute('download', 'VidFitness Installer');

        var aj = $(a);
        aj.appendTo('body');
        aj[0].click();
        aj.remove();
      }
      if (parsed.platform == 'windows') {
        a.setAttribute('href', this.app_path_win);
        a.setAttribute('download', 'VidFitness Installer');

        var aj = $(a);
        aj.appendTo('body');
        aj[0].click();
        aj.remove();
      }
    }

  }

  // cond_button(term, mobileOrBrowser) {
  //     if (term == 'header') {
  //       if (this.props.user.userId == '') {
  //         if (mobileOrBrowser == 'B') {
  //           return (
  //             <h1 className="text-center section-head"><a href="#" onClick={() => {this.redirect(); return false;}} className="btn btn-lg btn-success text-white">Join Now</a></h1>
  //           )
  //         } else {
  //           return (
  //             <h1 className="text-center section-head"><a href="#" onClick={() => {this.redirect(); return false;}} className="btn btn-sm btn-success text-white">Join Now</a></h1>
  //           )
  //         }
  //       }
  //     } else if (term == 'footer') {
  //       if (this.props.user.userId == '') {
  //         return (
  //           <div>
  //             <hr className="justify-content-center text-success w-75 mt-5" style={{ borderColor: '#28a745' }} />
  //               <div className="container-fluid">
  //                 <div className="row mt-0 mb-0 px-2 mx-0 pb-2 pt-4 justify-content-center">
  //                   <h1 className="text-center section-head">Start riding!</h1>
  //                 </div>
  //                 <div className="row mt-0 mb-2 mx-1 pb-2 pt-0 justify-content-center">
  //                   <h1 className="text-center section-head"><a href="/login" className="btn btn-lg btn-success">Join Now</a></h1>
  //                 </div>
  //                 <div className="row mt-0 mb-0 mx-1 pb-0 pt-0 justify-content-center">
  //                   <h6 className="text-center small">Always-free monthly samplers plus a
  //                   <br/>14-day trial of VidFitness Core.
  //                   <br/>No credit card needed. </h6>
  //                 </div>
  //               </div>
  //             <hr className="justify-content-center text-success w-75 mt-5" style={{ borderColor: '#28a745' }} />
  //           </div>
  //         )
  //       }
  //     } else {
  //       return <div></div>
  //     }
  // }

  render(){

    if(this.props.demoMode && this.props.electronMode){
      return <Redirect to='demo' />
    }

    return (
      <React.Fragment>
        <ScrollToTopOnMount />

        <Helmet>
          <meta name="Description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
          <meta property="og:url" content="https://vidfitness.com/about"/>
          <meta property="og:title" content="VidFitness - About"/>
          <meta property="og:image" content="https://vidfitness.com/vidfitness-screen.jpg"/>
          <meta property="og:description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
          <meta property="og:type" content="website" />
          <meta property="fb:app_id" content="432091207556688"/>
        </Helmet>

        <div>
          <Navbar />
          <br />

          <div className="pt-0 px-0">

            <div id="scrolldown" className="container-fluid">
              <div className="row mt-0 mb-0 p-3 mx-1 pb-0 pt-0 justify-content-center">
                <h2 className="text-center section-head"></h2>
              </div>
            </div>

            <div className="container-fluid bg-white mt-0 pt-0 pb-0">

              <div className="showcase row mt-3 mb-0 py-4 bg-light pr-2 pl-2">
                <div className="col-md-6 order-lg-2 text-white">
                  <picture onClick={() => this.props.history.push(this.state.showcaselink1)}>
                    <source type="image/webp" srcset={ this.state.showcaseimage1 + '.webp'} className="rounded" style={{width:'100%'}} />
                    <source type="image/jp2" srcset={ this.state.showcaseimage1 + '.jp2'} className="rounded" style={{width:'100%'}} />
                    <img src={ this.state.showcaseimage1 + '.jpg'} className="rounded" style={{width:'100%'}} alt="" />
                  </picture>
                </div>
                <div className="col-md-6 order-lg-1 my-auto showcase-row">
                  <h2 className="section-head">{ this.state.showcaseheader1 }</h2>
                  <p className="lead mb-0">{ this.state.showcasetext1 }
                  </p>
                </div>
              </div>

              <div className="showcase row mt-0 mt-sm-5 py-4 mb-0 bg-dark pr-2 pl-2">
                <div className="col-md-6 text-white">
                  <picture onClick={() => this.props.history.push(this.state.showcaselink2)}>
                    <source type="image/webp" srcset={ this.state.showcaseimage2 + '.webp'} className="rounded" style={{width:'100%'}} />
                    <source type="image/jp2" srcset={ this.state.showcaseimage2 + '.jp2'} className="rounded" style={{width:'100%'}} />
                    <img src={ this.state.showcaseimage2 + '.jpg'} className="rounded img-thumbnail" style={{width:'100%'}} alt="" />
                  </picture>
                </div>
                <div className="col-md-6 my-auto showcase-row">
                  <h2 className="text-white section-head">{ this.state.showcaseheader2 }</h2>
                  <p className="text-white lead mb-0">{ this.state.showcasetext2 }
                  </p>
                </div>
              </div>

              <div className="showcase row mt-0 mt-sm-5 py-4 mb-0 bg-light pr-2 pl-2">
                <div className="col-md-6 order-lg-2 text-white">
                  <picture onClick={() => this.props.history.push(this.state.showcaselink3)}>
                    <source type="image/webp" srcset={ this.state.showcaseimage3 + '.webp'} className="rounded" style={{width:'100%'}} />
                    <source type="image/jp2" srcset={ this.state.showcaseimage3 + '.jp2'} className="rounded" style={{width:'100%'}} />
                    <img src={ this.state.showcaseimage3 + '.jpg'} className="rounded img-thumbnail" style={{width:'100%'}} alt="" />
                  </picture>
                </div>
                <div className="col-md-6 order-lg-1 my-auto showcase-row">
                  <h2 className="section-head">{ this.state.showcaseheader3 }</h2>
                  <p className="lead mb-0">{ this.state.showcasetext3 }
                  </p>
                </div>
              </div>

              <div className="showcase row mt-0 mt-sm-5 py-4 mb-0 bg-dark  pr-2 pl-2">
                <div className="col-md-6 text-white">
                  <picture >
                    <source type="image/webp" srcset={ this.state.showcaseimage4 + '.webp'} className="rounded" style={{width:'100%'}} />
                    <source type="image/jp2" srcset={ this.state.showcaseimage4 + '.jp2'} className="rounded" style={{width:'100%'}} />
                    <img src={ this.state.showcaseimage4 + '.jpg'} className="rounded" style={{width:'100%'}} alt="" />
                  </picture>
                </div>
                <div className="col-md-6 my-auto showcase-row">
                  <h2 className="text-white section-head">{ this.state.showcaseheader4 }</h2>
                  <p className="text-white lead mb-0">{ this.state.showcasetext4 }
                  </p>
                </div>
              </div>

              <div className="showcase row mt-0 mt-sm-5 py-4 mb-0 bg-light pr-2 pl-2">
                <div className="col-md-6 order-lg-2 text-white">
                  <picture >
                    <source type="image/webp" srcset={ this.state.showcaseimage5 + '.webp'} className="rounded" style={{width:'100%'}} />
                    <source type="image/jp2" srcset={ this.state.showcaseimage5 + '.jp2'} className="rounded" style={{width:'100%'}} />
                    <img src={ this.state.showcaseimage5 + '.jpg'} className="rounded" style={{width:'100%'}} alt="" />
                  </picture>
                </div>
                <div className="col-md-6 order-lg-1 my-auto showcase-row">
                  <h2 className="section-head">{ this.state.showcaseheader5 }</h2>
                  <p className="lead mb-0">{ this.state.showcasetext5 }
                  </p>
                </div>
              </div>

            </div>
          </div>

          <div className="container-fluid pt-5">
            <div>
              <div>
                <p className="text-center">
                { !this.props.signed_in &&
                  <a href="/login" className="btn btn-lg btn-outline-dark">Sign me up</a>
                }
                { this.props.signed_in &&
                  <a href="/featured" className="btn btn-lg btn-outline-dark">Workout Now</a>
                }
                </p>
              </div>
            </div>
          </div>

          { !this.props.electronMode &&
            <Footer />
          }

        </div>
      </React.Fragment>
    )
  }
}

    const mapStateToProps = (state) => {
      let signed_in
    
      if (state.user.id == '') {
        signed_in = false
      } else {
        signed_in = true
      }
    
    return {
    user:         state.user,
    signed_in:    signed_in,
    electronMode: state.general.electronMode,
    demoMode:     state.general.demoMode
  }
}

const mapDispatchToProps = (dispatch) => {
return {
}
}

export default connect(mapStateToProps, mapDispatchToProps)(About);

