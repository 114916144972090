import React from 'react';
import { Formik } from 'formik';
import { FontAwesomeIcon }   from '@fortawesome/react-fontawesome'
import '../styles/video-form.css'

const VideoForm = ({id, title, description, channel_visible, search_visible, permalink, submit, cancel}) => {

  // let permalink_error = false;

  /* Old Permalinks code
  <label className="form-check-label form-label">
    <h5>
      Permalink:
      { !permalink_error &&
        <FontAwesomeIcon className='permalink-unique' icon={['fa', 'check']}/>
      }
    </h5>

  </label>
  <input
    type="text"
    name="permalink"
    className="title-input"
    onChange={handleChange}
    onKeyUp={ (e) => {

      // Validates the permalink is unique
      const permalink = e.target.value

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'default'
      };

      return fetch((process.env.REACT_APP_SERVER) + '/api/v1/is-permalink-unique?' + 'id=' + id + '&permalink=' + permalink, options)
        .then(response =>   response.json() )
        .then(response => {

           if(response.is_unique == false){
             setErrors({permalink: 'Sorry! Shareable URL already taken.'})
             permalink_error = true
           }

           if(permalink == '' || permalink.length < 4){
             permalink_error = true
             setErrors({permalink: 'Shareable URL too short'})
             return
           }

           if(permalink.match(/^[a-z0-9]+(?:-[a-z0-9]+)*$/g) == null){
             permalink_error = true
             setErrors({permalink: 'Invalid Shareable URL'})
             return
           }


        })


    }}
    value={values.permalink}
  />
  <span className="form-error">{errors.permalink}</span>
  */

  const handleCancel = (event) => {
    event.preventDefault()
    cancel(true)
  }

  return (
    <div>
      <Formik
        initialValues={
          {
            title: title,
            description: description,
            search_visible: search_visible,
            channel_visible: channel_visible,
            // permalink: permalink
          }
        }
        validate={ async (values) => {

          let errors = {}
          if (!values.title) {
            errors.title = 'Required';
          }
          // if (!values.permalink) {
          //   errors.permalink = 'Required';
          // }
          // if(values.permalink.match(/^[a-z0-9]+(?:-[a-z0-9]+)*$/g) == null){
          //   errors.permalink = 'Invalid URL 2'
          // }

          // Checks if:
          // permalink field doesn't have another error already
          // and if it's different from the initial
          // if those conditions are true we do a server validation

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          submit(values)
          setTimeout(() => {
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setErrors
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>

            <div className="form-group pb-5">
                <button className='mt-3 ml-1 float-right btn btn-sm btn-outline-success' type="submit" disabled={isSubmitting}>
                  Submit
                </button>
                <button className='mt-3 mr-1 float-right btn btn-sm btn-outline-danger' onClick={handleCancel} disabled={isSubmitting}>
                  Cancel 
                </button>        
            </div>

            <div className="form-group">

            <label>
              <h4>Video Title:</h4>
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
            />
            {errors.title && touched.title && errors.title}

            <br />
            <label>
              <h4>Video Description:</h4>
            </label>
            <textarea
              name="description"
              className="form-control"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
            />
            {errors.description && touched.description && errors.description}

            <br />
            </div>

            <div className="form-check pl-4">

                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={values.search_visible}
                    checked={values.search_visible}
                    onChange={ () => { setFieldValue('search_visible', !values.search_visible) }}
                  />

                  <label className="form-check-label">
                    <h6>
                    Show in search results.
                    </h6>
                  </label>
            </div>

            <div className="form-check pl-4">
   
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={values.channel_visible}
                    checked={values.channel_visible}
                    onChange={ () => { setFieldValue('channel_visible', !values.channel_visible) }}
                  />

                  <label className="form-check-label">

                    <h6>
                    Show in my channel.
                    </h6>
                  </label>

            </div>

          </form>
        )}
      </Formik>
    </div>
  )
};

export default VideoForm;
