import React, { Component } from 'react';
import { withRouter }from "react-router-dom";
import { connect } from 'react-redux';
import { startAudioRecording,
         stopAudioRecording,
         updateAudioLayers,
         addAudioLayer,
         updateInRange,
         updatePlayingOverlayCurrently} from '../actions/overlayActions';

import { toggleTheaterMode,
         enterPlayMode,
         setPlayingState,
         setPausedState,
         saveRaceToView, } from '../actions/videoActions'

import { extractPeaks } from '../utils/utils.js'

import {Helmet} from "react-helmet";

import 'video.js/dist/video-js.css';
import videojs from 'video.js';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import vmsg from "vmsg";

import getBlobDuration from 'get-blob-duration'

import axios from "axios";

import deleteIcon from "../images/delete_icon.png"

import { PlayerIcon } from 'react-player-controls'

import WaveSurfer from 'wavesurfer.js';

import "../styles/waveform.css"
import "../styles/overlay.css"

import WaveformContainer from './waveform-container.component'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import WaveformData from 'waveform-data';
import { Prompt } from 'react-router'

const recorder = new vmsg.Recorder({
  wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm"
});




class Audio_Overlay extends Component {
  constructor(props) {
    super(props);
    this.handleDownload           = this.handleDownload.bind(this)
    this.handleSaveToTrack        = this.handleSaveToTrack.bind(this)
    this.record                   = this.record.bind(this)
    this.handleChange             = this.handleChange.bind(this)
    this.handleSubmit             = this.handleSubmit.bind(this)
    this.setIntervals             = this.setIntervals.bind(this)
    this.overlaysRefresh      = this.overlaysRefresh.bind(this)
    this.createPayload        = this.createPayload.bind(this)
    this.renderDeleteButtons  = this.renderDeleteButtons.bind(this)
    this.handleMute           = this.handleMute.bind(this)
    this.handleMuteChange     = this.handleMuteChange.bind(this)
    this.renderSavedLayers    = this.renderSavedLayers.bind(this)

    this.state = {
      savedLayers: [],
      unsavedLayers: [],
      isLoading: false,
      currentTimeStart: 0,
      load: false,
      owner: false
    };
  }

  //TO FIX 
  //refactor deleting time ranges to singular function given start index
  //add swal to delete active overlay
  //make button disable rather than disappear

  componentDidMount() {

    if (this.props.video.race.user_id == this.props.user.id) {
      this.setState({owner: true})
    }
    
    var audioLayers = this.props.overlay.audioLayers
    if (audioLayers.length  > 0 ) {
      audioLayers.forEach( (layer) => {
        if (layer.start_index == 0) {
          this.props.dispatchUpdateInRange(true)
        }
      })
    }

    this.setState({
      video: videojs('race-video'),
      savedLayers: audioLayers,
    }, () => {
      this.setIntervals()
      this.setState({load:true})
      this.state.video.pause()
    })

  }

  overlaysRefresh (preLayers) {
    return new Promise(() => {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'default'
      };
      const owner_id = this.props.user.id
      const video_id = this.props.video.race.id
      fetch((process.env.REACT_APP_SERVER) + '/api/v1/overlay/video-owner/' + video_id + '/' + owner_id, options)
      .then(response => response.json() )
      .then( (overlays) => { 
        if (overlays.length > 0) {

          var loadsToSend = [ ]

          const payloads = []

          overlays.forEach((layer) => {
            var load = this.createPayload('SAVED', layer.url, layer.owner_id, this.props.video.race, layer.start_index, layer.duration,
                              (layer.start_index + layer.duration), layer.timestamp,layer.id, layer.peaks_data)
            payloads.push(load)
          })

          loadsToSend.push(...payloads)

          this.props.dispatchUpdateAudioLayers(loadsToSend)

          this.setState({savedLayers: payloads})
        }
      })
    })
  }

  createPayload(type, url, owner_id, video_metadata, start_index, duration, end_index, timestamp, id, peaks_data) {
    if (type == 'SAVED') {
      var parsed = JSON.parse(peaks_data)
      parsed.data = parsed.data.map(Number)
    } else {
      parsed = null
    }
    const payload = {
      type: type,
      url: url,
      owner_id: owner_id,
      video_metadata: video_metadata,
      start_index: start_index,
      duration: duration,
      end_index: end_index,
      timestamp: timestamp,
      id: (id != -1 ? id : 0),
      peaks_data: parsed
    }
    return payload
  }

  //intervals
  setIntervals() {

    //set electron intervals
    if (this.props.electronMode) {
      const { ipcRenderer } = window.require('electron')
      ipcRenderer.on('electron-audio-processing-complete', () => {
        this.setState({unsavedLayers: []})
        this.overlaysRefresh(this.props.overlay.audioLayers)
      })
    }
    
    //set videojs intervals
    var player = videojs('race-video')

    //stops recording when video reaches end
    //CHANGE TO isRECORDING!
    player.on('ended', () => {
      if (this.props.overlay.audioRecording) {
        this.record()
      }
    })

    player.on('timeupdate', () => {
      if (this.props.overlay.inRange) {
        if (this.props.overlay.audioRecording) {
          this.record()
        }
      }
    })

    player.on('timeupdate', () => {
      if (!this.props.overlay.playingOverlayCurrently && this.props.overlay.inRange && !this.state.video.paused()) {
        this.props.dispatchUpdateInRange(false)
      }
    })

    player.on('pause', () => {
      //need some way of stopping overlay manually
    })

  }

  //handles recording features
  async record() {
    this.setState({ isLoading: true });
    var player = videojs('race-video');

    //if currently recording
    if (this.props.overlay.audioRecording) {
      //stops recording
      const blob = await recorder.stopRecording();
      const blobURL = URL.createObjectURL(blob)
      const duration = await getBlobDuration(blob)
      this.props.dispatchStopAudioRecording()
      var tstamp = Number(new Date())
      var payload = this.createPayload('UNSAVED',
                                  blobURL, 
                                  this.props.user.id, 
                                  this.props.video.race, 
                                  this.state.currentTimeStart, 
                                  (duration), 
                                  (this.state.currentTimeStart + duration), 
                                  tstamp,
                                  -1, {})
      this.setState({
        isLoading: false,
        currentTimeStart: 0,
      }, () => {
        //submits payload
        this.props.dispatchAddAudioLayer(payload)
        console.log(this.props.overlay.audioLayers)
        var newUnsavedLayers = this.state.unsavedLayers.concat(payload)
        this.setState({unsavedLayers: newUnsavedLayers})
        //pauses video
        if (!this.state.video.paused()) {
          this.state.video.pause()
          this.props.dispatchSetPausedState(true)
          this.handleMute(false)
          //adds one second to end index if possible (spaces out recordings by 1 second)
          if (!this.state.video.ended() && (this.state.video.currentTime() + 1 < this.state.video.duration())) {
            this.state.video.currentTime(this.state.video.currentTime() + 1)
          }
        }
        this.handleDownload(payload)});
    } else {
      try {
        //start recording
        await recorder.initAudio();
        await recorder.initWorker();
        recorder.startRecording();
        this.props.dispatchStartAudioRecording()
        this.setState({ 
            isLoading: false, 
            currentTimeStart: player.currentTime()
          }, () => {
          //play video on mute
          this.handleMute(true)
          this.state.video.play()
        });
      } catch (e) {
        console.error(e);
        this.setState({ isLoading: false });
      }
    }
  };

  componentWillUnmount() {
    if (this.props.overlay.inRange) {
      this.props.dispatchUpdateInRange(false)
    }

    if (this.props.electronMode) {
      const { ipcRenderer } = window.require('electron')
      ipcRenderer.send('purge-temp')
    }

  }

  //speaks to electron to handle download
  async handleDownload(payload) {
    if (this.props.electronMode) {
      const { ipcRenderer } = window.require('electron')
      var peaks = await extractPeaks(payload)
      payload.peaks_data = peaks
      ipcRenderer.send('download-audio', payload)
    }
  }

  //helper function for quick step
  handleChange(event) {
    this.setState({value: event.target.value});
  }

  //handle submission of quick step
  handleSubmit(event) {
    event.preventDefault()
    if (!this.props.overlay.updatePlayingOverlayCurrently) {
      this.props.dispatchUpdateInRange(false)
      this.props.dispatchUpdatePlayingOverlayCurrently(false)
      this.state.video.currentTime(this.state.value)
      this.props.dispatchEnterPlayMode()
      this.state.video.play()
    }
  }

  handleSaveToTrack() {
    const MySwal = withReactContent(Swal)

    MySwal.fire({
      title: 'Are you sure you want to save your recordings?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Save',
      preConfirm: async () => {
        MySwal.showLoading()

        this.state.unsavedLayers.forEach( (layer) => {
          URL.revokeObjectURL(layer.url)
        })
        
        if (this.props.electronMode) {
          const { ipcRenderer } = window.require('electron')
          const result = await ipcRenderer.invoke('process-and-upload-audio'); 
        }

      }
    }).then(async (result) => {
      if (result.value) {

        // MySwal.fire(
        //   'Saved!',
        //   'Your recording has been saved.',
        //   'success',
        // )
        
      } else {
        console.log('Do nothing!')
      }
    })
  }

  async deleteLayer(layer) {

    function between(x, min, max) {
      return x >= min && x <= max;
    }

    if (!this.state.video.paused()) {
      this.state.video.pause()
      this.props.dispatchSetPausedState(true)
    }

    Swal.fire({
      title: 'Are you sure you want to delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete!'
    }).then(async (result) => {

      if (result.value) {

        if (layer.type == 'SAVED') {
          const task = async () => {
            return new Promise(async (resolve, reject) => {
              const options = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                mode: 'cors',
                cache: 'default'
              };
              const url = (process.env.REACT_APP_SERVER) + '/api/v1/overlay/' + layer.id + '/delete'
              fetch(url, options)
              .then( () => { 

                if (between(this.state.video.currentTime(), layer.start_index, (layer.end_index))) {
                  if (this.props.overlay.inRange) {
                    this.props.dispatchUpdateInRange(false)
                  }
                }

                var layersToPush = []

                const savedsWithoutDeletion = this.state.savedLayers.filter( (el) => layer.id != el.id) 
                
                layersToPush.push(...savedsWithoutDeletion)

                var unsaved = this.state.unsavedLayers

                layersToPush.push(...unsaved)

                this.props.dispatchUpdateAudioLayers(layersToPush)
                this.setState({
                  savedLayers: savedsWithoutDeletion
                })
                resolve(true)
              })
            })
          }

          await task()

          this.render()

          Swal.fire(
            'Deleted!',
            'Your recording has been deleted.',
            'success'
          )

        } else {

          if (between(this.state.video.currentTime(), layer.start_index, layer.end_index)) {
            if (this.props.overlay.inRange) {
              this.props.dispatchUpdateInRange(false)
            }
          }

          URL.revokeObjectURL(layer.url)

          var layersToPush = []

          const unsavedsWithoutDeletion = this.state.unsavedLayers.filter( (el) => `${layer.timestamp}_${layer.start_index}` != `${el.timestamp}_${el.start_index}`) 
          
          layersToPush.push(...unsavedsWithoutDeletion)

          var saved = this.state.savedLayers

          layersToPush.push(...saved)

          this.props.dispatchUpdateAudioLayers(layersToPush)
          this.setState({
            unsavedLayers: unsavedsWithoutDeletion
          })

          if (this.props.electronMode) {
            const { ipcRenderer } = window.require('electron')
            ipcRenderer.send('delete-audio-layer', layer)
          }
          
          Swal.fire(
            'Deleted!',
            'Your recording has been deleted.',
            'success'
          )

          this.render()

        }

      } else {
        Swal.fire(
          'Cancelled!',
          'Your recording is safe.',
          'error'
        )
      }
    })
  }

  handleMuteChange(type) {

    let data = {
      id: this.props.video.race.id,
      to_change: type
    }

    const options = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'default'
    };

    fetch((process.env.REACT_APP_SERVER) + '/api/v1/race/handle-mutes', options)
      .then(response => response.json() )
      .then(response => {
        this.props.dispatchSaveRaceToView(response)
        console.log(response)
      })

  }

  handleMute(direction) {
    this.state.video.muted(direction)  
  }

  renderSavedLayers(){
    if (this.state.load) {
      if (this.state.savedLayers.length > 0) {
        if (this.props.video.race.mute_overlay_audio && !this.state.owner) {
          
        } else {
          return (
            <div>
              { this.state.owner && this.props.electronMode &&
                <hr />
              }
              <div class="section-title" style={{paddingBottom:10}}>
                <h4><b>
                  Saved Layers
                </b></h4>
                { this.state.owner && this.props.video.race.mute_overlay_audio && !this.props.electronMode &&
                  <p>You own this video but muted the voiceover audio. Please visit the desktop app to unmute these voiceovers.</p>
                }
              </div>
              <div class="row">
                <div class="col">
                  <WaveformContainer layers_subset={this.state.savedLayers}/>
                </div>
                { this.state.owner && this.props.electronMode &&
                  <div class="col-1">
                    <ul class="list-group" style={{ listStyle: "none", padding: 0 }}>
                      {this.renderDeleteButtons('SAVED')}
                    </ul>
                  </div>
                }
                
              </div>
            </div>
          )
        }
      }
    }

    if (this.state.unsavedLayers.length == 0 && this.state.savedLayers.length == 0) {
      return (
      <div className="container text-left mt-2">
        <h1 className="text-left">There are no voiceovers for this video.</h1>
      </div>) 
    }

      

  }

  renderDeleteButtons(desired) {
    // var delete_buttons_jsx
    // var subset = []
    // if (desired == 'UNSAVED') {
    //   subset = this.state.unsavedLayers
    // } else {
    //   subset = this.state.savedLayers
    // }

    // subset.forEach((layer) => {
    //   delete_buttons_jsx.push 
    // })

    var unsaved = this.state.unsavedLayers
    var saved = this.state.savedLayers


    if (desired == 'UNSAVED') {
      if (unsaved.length > 0) {
        const unSavedlayerColumn = unsaved.map((layer) => 
          <li style={{paddingTop:5, paddingBottom:5}}>
            <div class="row">
              <button style={{cursor:'pointer', height:70}} onClick={() => {this.deleteLayer(layer)}} type="button" className="btn btn-danger delete-overlay-button btn-block"><FontAwesomeIcon icon={['fa', 'trash']} /></button>
            </div>
          </li>
        )

      return (
        unSavedlayerColumn
      )} else {
        return (<div></div>)
      }
    } else {
      if (saved.length > 0) {
        const savedLayerColumn = saved.map((layer) => 
          <li style={{paddingTop:5, paddingBottom:5}}>
            <div class="row">
              <button style={{cursor:'pointer', height:70}} onClick={() => {this.deleteLayer(layer)}} type="button" className="btn btn-danger delete-overlay-button btn-block"><FontAwesomeIcon icon={['fa', 'trash']} /></button>
            </div>
          </li>
        )
      return (
        savedLayerColumn
      )} else {
        return (<div></div>)
      }
    }
  }   

 
  render() {
    function between(x, min, max) {
      return x >= min && x <= max;
    }
    const { isLoading } = this.state;
    const isRecording = this.props.overlay.audioRecording
    const inRange = this.props.overlay.inRange
    return (

      <React.Fragment>
      { this.state.load &&
        <div class="tabber">
          
          <div>
            <Prompt
              when={this.state.unsavedLayers.length != 0}
              message="Are you sure you want to leave? You have unsaved recordings."
            />
          </div>

          { this.state.owner && this.props.electronMode &&
          <div class="row">
            <div class="col-4" style={{textAlign:'left'}}>
                <form onSubmit={this.handleSubmit}>
                  <div className="row" style={{height:48}}>
                    <div className="col form-group time-entry-column">
                      { !this.props.overlay.playingOverlayCurrently ? 
                      <input
                        type="number" 
                        style={{borderRaduis:0}}
                        class="time-entry-input form-control-lg" 
                        placeholder="Seconds" 
                        aria-describedby="button-addon2"
                        id="quantity" 
                        name="quantity" 
                        min={0} 
                        max={this.state.video.duration()} 
                        value={this.state.value} onChange={this.handleChange} /> :
                      <input
                        type="number" 
                        style={{borderRaduis:0}}
                        class="time-entry-input form-control-lg" 
                        placeholder="Seconds" 
                        disabled /> 
                      }
                    </div>
                    <div className="col-4 time-entry-button">
                      { !this.props.overlay.playingOverlayCurrently ?
                        <button type="submit" value="Submit" id="button-addon2" class="btn btn-outline-dark btn-lg btn-block actual-button-entry">Skip</button> :
                        <button class="btn btn-outline-dark btn-lg btn-block actual-button-entry" disabled>Skip</button>
                      }
                    </div>
                  </div>
                  <div>
                    <label style={{paddingTop:5}}>Between 0 and {Math.round(this.state.video.duration())} seconds</label>
                  </div>
                </form>

            </div>

            <div class="col record-column">
              
              {isRecording ? 
                  <button type="button" className="btn btn-dark btn-lg btn-block" onClick={this.record} style={{height:48}} ><FontAwesomeIcon icon={['fa', 'stop']} />&nbsp;Stop</button> : 
                  <button type="button" disabled={isLoading || inRange} className="btn btn-danger btn-lg btn-block" onClick={this.record} style={{height:48}}><FontAwesomeIcon icon={['fa', 'circle']} />&nbsp;Record</button> }
              {(inRange ? "Overlaps existing voiceover, Skip to different section" : '')}
            </div>

            <div class="col-3 save-column">
              {(this.state.unsavedLayers.length != 0 && !isRecording ? 
                <button type="button" className="btn btn-success btn-lg btn-block" onClick={() => {this.handleSaveToTrack()}} style={{height:48}}>&nbsp;Save</button> : 
                <button type="button" className="btn btn-secondary btn-lg btn-block disabled" style={{height:48}}>&nbsp;Save</button>)}

              <div style={{paddingTop: 10}}>
                { this.props.video.globalMuteStatus ? 
                  "Unmute video to access switches..." :
                  "Viewers can hear..."
                }
              </div>

              <div class="custom-control custom-switch">
                { !this.props.video.globalMuteStatus ? 
                  <input 
                  type="checkbox" 
                  checked={!this.props.video.race.mute_video_audio} 
                  onChange={() => {
                    this.handleMuteChange('VIDEO')
                  }}
                  class="custom-control-input"
                  id="customSwitch1" /> :

                  <input 
                  type="checkbox" 
                  checked={!this.props.video.race.mute_video_audio} 
                  disabled
                  class="custom-control-input"
                  id="customSwitch1" /> 
                }
                
                <label class="custom-control-label" for="customSwitch1">Video audio</label>
              </div>

              <div class="custom-control custom-switch">
              { !this.props.video.globalMuteStatus ? 
                <input 
                type="checkbox" 
                checked={!this.props.video.race.mute_overlay_audio} 
                onChange={() => {
                  this.handleMuteChange('OVERLAY')
                }}
                class="custom-control-input" 
                id="customSwitch2" /> :

                <input 
                  type="checkbox" 
                  checked={!this.props.video.race.mute_overlay_audio} 
                  disabled
                  class="custom-control-input" 
                  id="customSwitch2" />

                }
                <label class="custom-control-label" for="customSwitch2">Voiceover audio</label>
              </div>

            </div>

          </div>
          }

          { this.state.load && this.state.unsavedLayers.length > 0 &&
          <div>
            <hr />
            <div class="section-title" style={{paddingBottom:10}}>
              <h4>
                Unsaved Layers 
              </h4>
            </div>
            <div class="row">
              <div class="col">
                <WaveformContainer layers_subset={this.state.unsavedLayers}/>
              </div>
              <div class="col-1">
                <ul class="list-group" style={{ listStyle: "none", padding: 0 }}>
                  {this.renderDeleteButtons('UNSAVED')}
                </ul>
              </div>
            </div>
          </div>
          }

          {this.renderSavedLayers()}
          
        
        </div>
      }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    video: state.video,
    electronMode: state.general.electronMode,
    overlay: state.overlay
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatchSetPausedState:    (value)  => { dispatch(setPausedState(value))},
    dispatchToggleTheaterMode: (value)  => { dispatch(toggleTheaterMode())},
    dispatchEnterPlayMode:     (value)  => { dispatch(enterPlayMode(value))},
    dispatchStartAudioRecording: ()     => { dispatch(startAudioRecording())},
    dispatchStopAudioRecording: ()     => { dispatch(stopAudioRecording())},
    dispatchUpdateAudioLayers: (value)  => {dispatch(updateAudioLayers(value))},
    dispatchAddAudioLayer:     (value)     => {dispatch(addAudioLayer(value))},
    dispatchUpdateInRange: (value)    => {dispatch(updateInRange(value))},
    dispatchUpdatePlayingOverlayCurrently: (value) => {dispatch(updatePlayingOverlayCurrently(value))},
    dispatchSaveRaceToView: (value)    => {dispatch(saveRaceToView(value))},
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Audio_Overlay))