// React Dependencies
import React, { Component, Fragment,  useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { Redirect, withRouter }from "react-router-dom";
import { Link } from "react-router-dom";
import videojs from 'video.js'
import { showMainOverlay, hideMainOverlay } from '../actions/generalActions'

// Styles
import '../styles/overlay.css';

// Other Dependencies
import { FontAwesomeIcon }   from '@fortawesome/react-fontawesome'
var classNames = require('classnames');

class MainOverlay extends React.Component {

  componentDidMount() {

    const videoJsOptions = {
      autoplay: true,
      controls: true,
      muted: true,
      sources: [
        {
          src: 'https://vidracing-production-website.s3.amazonaws.com/VidFitnessAdvert_2020_03_06/hls/index.m3u8',
          type: 'application/x-mpegURL'
        },
        {
          src: 'https://vidracing-production-website.s3.amazonaws.com/VidFitnessAdvert_2020_03_06/dash/index.mpd',
          type: 'application/dash+xml'
        }
      ]
    }

    this.player = videojs(this.videoNode, videoJsOptions, function onPlayerReady() {
      console.log('onPlayerReady', this)
    });

  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  render(){
    return (
      <React.Fragment>

        <span onClick={ () => { this.props.dispatchHideMainOverlay() }} className='big-x'> x </span>
        <div id='whole-screen'>
        </div>
        <div id="main-overlay">
            <div data-vjs-player id='main-overlay-video' style={{ width: '100%', height: '100%' }}>
              <video ref={ node => this.videoNode = node } className="video-js"></video>
            </div>
          </div>
      </React.Fragment>
    )
  }

}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchShowMainOverlay: ()    => { dispatch(showMainOverlay()) },
    dispatchHideMainOverlay: ()    => { dispatch(hideMainOverlay()) },
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainOverlay))




