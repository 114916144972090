import React from 'react'

export default function wrapperTab ({ weekly, monthly, yearly, alltime, units }) {
  function numberWithCommas(x) {
    if (x == null || x === undefined) {
      return 'NAN'
    } else {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

	return (
		<div>
			<h3 className="sidebar-title">My Weekly Stats:</h3>
      <div className="headline-number-container">
        <h1 className="headline-number-text">
          {numberWithCommas(weekly)}
          <span className="headline-number-units">
            {units}
          </span>
        </h1>
      </div>
      <div className="additional-number-container">
        <p>
          <span className="small">This Month: {numberWithCommas(monthly)} {units}
          </span>
          <br/>
          <span className="small">This Year: {numberWithCommas(yearly)} {units}
          </span>
          <br />
          <span className="small">All Time: {numberWithCommas(alltime)} {units}
          </span>
        </p>
      </div>
		</div>
	)
}
