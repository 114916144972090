import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link, withRouter, Redirect } from "react-router-dom";
import { BrowserView, MobileOnlyView, TabletView, isMacOs, isWindows, isBrowser, isMobile } from "react-device-detect";
import { getInfoFromVideoUrl } from '../utils/utils.js'
import InfiniteListBottom from './infinite-list-bottom.component'
import Navbar from './navbar.component'
import Footer from './footer.component'
import axios from 'axios';
import Moment from 'react-moment';
import { fasAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import '../styles/events.css'

import {Helmet} from "react-helmet";

class Events extends Component {

    constructor(props) {
        super(props);

        this.state = {
          races: [],
          isFetching: false, 
          canFetch: true,
          selectedFilter: 'all',
          loaded: false,
          refreshEventsInterval: null,
          pageNumber: 10,
          completed_finish: false
        }

        this.selectFilter = this.selectFilter.bind(this)
        this.handleJoin = this.handleJoin.bind(this)
        this.refreshEvents = this.refreshEvents.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
        this.fetchCompletedRaces = this.fetchCompletedRaces.bind(this)
        this.PAGINATE_SIZE = 10
        this.FETCH_DELAY = 3000

    }

    handleScroll() {
      if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
      if (this.state.canFetch && !this.state.completed_finish) {
         this.setState({isFetching: true, canFetch: false}, () => this.fetchCompletedRaces())
      }
    }

    fetchCompletedRaces() {
    if (!this.state.completed_finish) {
      var end = this.state.pageNumber + this.PAGINATE_SIZE
      axios.get(process.env.REACT_APP_SERVER + `/api/v1/event/completed-page?start=${this.state.pageNumber}&paginate=${this.PAGINATE_SIZE}`)
      .then(({ data }) => {

          var fin = this.state.completed_finish

          if ( end >= this.state.completed_count ) {
            fin = true
          }

          //FIX INFINITE SCROLL
          this.setState({
            pageNumber: end,
            completed_races: this.state.completed_races.concat(data),
            completed_finish: fin,
          }, () => {
            setTimeout(() => this.setState({ canFetch: true,
                        isFetching: false, }), this.FETCH_DELAY)
          })
      })
      .catch(function (error){
          console.log(error);
      })
    }
  }

    async componentDidMount() {

      window.addEventListener('scroll', this.handleScroll);

      var { data } = await axios.get(process.env.REACT_APP_SERVER + '/api/v1/event/count-completed-events')

      this.setState({
        completed_count: data.count
      })

      var getHappeningNow = axios.get(process.env.REACT_APP_SERVER + '/api/v1/event/active-page')
      var getCompleted = axios.get(process.env.REACT_APP_SERVER + `/api/v1/event/completed-page?start=0&paginate=${this.PAGINATE_SIZE}`)

      Promise.all([getHappeningNow, getCompleted]).then(([now, completed]) => {

        this.setState({
          races: now['data'].concat(completed['data']),
          loaded: true,
          completed_races: completed['data']
        })

      })

      let refreshEventsInterval = setInterval( () => {

        this.refreshEvents()

      }, 5000)

      this.setState({
        refreshEventsInterval
      })      

    }

    async refreshEvents() {

      var { data } = await axios.get(process.env.REACT_APP_SERVER + '/api/v1/event/active-page')
      
      this.setState({
        races: data.concat(this.state.completed_races)
      })

    }

    componentWillUnmount() {
      clearInterval(this.state.refreshEventsInterval)

      this.setState({
        refreshEventsInterval: null
      })
    }

    selectFilter(event) {
      this.setState({
        selectedFilter: event.target.id
      })
    }

    handleJoin(race) {

      if (this.props.logged_in) {

        if (this.props.electronMode) {

          var { status, race_url, id } = race

          this.props.history.push({
            pathname: `/video/${race_url}`,
            state: {
              eventId: id,
              groupRide: true,
              eventStatus: status
            }
          })

        } else  {

          this.props.history.push('/setup')

        }

      } else {

        this.props.history.push('/login')

      }

    }

    render() {

      return (

      <React.Fragment>
        <Helmet>
            <meta name="Description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
            <meta property="og:url" content="https://vidfitness.com/videos"/>
            <meta property="og:title" content="VidFitness - Videos to Ride"/>
            <meta property="og:image" content="https://vidfitness.com/vidfitness-screen.jpg"/>
            <meta property="og:description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
            <meta property="og:type" content="website" />
            <meta property="fb:app_id" content="432091207556688"/>
        </Helmet>
        <div>
          <Navbar />
          <br />
          <div>
            <div className="mt-3">
              <div className="container-fluid">
                <div className="row w-100 my-0 pt-1 pt-sm-2 no-gutters justify-content-center">
                  <div className="col-12 mx-auto text-center">
                    <h4 className="events-first-time-heading mx-0 px-0 mt-0 mt-sm-2 mb-0 text-center">Join a live event.</h4>
                    <br/>


                    { this.props.electronMode &&
                      <React.Fragment>
                      <h5>
                        <a class="text-dark" data-toggle="collapse" href="#more" role="button" aria-expanded="false" aria-controls="more">Learn more <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/></svg></a>
                      </h5>
                      <div class="collapse" id="more">
                        <div class="card card-body border-1 col-12 col-sm-10 col-md-6 mx-auto text-left pt-3">
                          <h5>
                          Workout simultaneously with a friend, coach, group, or other VidFitness members.<br/><br/>The live leaderboard of participants and the regular motivation from a workout partner or coach helps keep you moving toward your fitness goals.<br/><br/> To create a workout for others to join, connect your smart trainer, pick a video from our <Link to="/featured" className="lead text-dark font-weight-bold no-wrap">collection</Link>, and click "Start".
                          </h5>
                        </div>
                      </div>
                    </React.Fragment>
                    }
                    { !this.props.electronMode &&
                    <React.Fragment>
                      <MobileOnlyView>
                      <span>
                        <a class="text-dark" data-toggle="collapse" href="#more" role="button" aria-expanded="false" aria-controls="more">Learn more <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/></svg></a>
                      </span>
                      <div class="collapse" id="more">
                        <div class="card card-body border-1 col-12 col-sm-10 col-md-6 mx-auto text-left pt-3">
                          <span>
                            Workout simultaneously with a friend, coach, group, or other VidFitness members.<br/><br/>The live leaderboard of participants and the regular motivation from a workout partner or coach helps keep you moving toward your fitness goals.<br/><br/>To create or join an event, use the VidFitness desktop app with your smart trainer.
                          </span>
                        </div>
                      </div>
                      </MobileOnlyView>    
                      <TabletView>
                      <h5>
                        <a class="text-dark" data-toggle="collapse" href="#more" role="button" aria-expanded="false" aria-controls="more">Learn more <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/></svg></a>
                      </h5>
                      <div class="collapse" id="more">
                        <div class="card card-body border-1 col-12 col-sm-10 col-md-6 mx-auto text-left pt-3">
                          <h5>
                          Workout simultaneously with a friend, coach, group, or other VidFitness members.<br/><br/>The live leaderboard of participants and the regular motivation from a workout partner or coach helps keep you moving toward your fitness goals.<br/><br/>To create or join an event, use the VidFitness desktop app with your smart trainer.
                          </h5>
                        </div>
                      </div>
                      </TabletView>                           
                      <BrowserView>
                        <h5>
                          <a class="text-dark" data-toggle="collapse" href="#more" role="button" aria-expanded="false" aria-controls="more">Learn more <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/></svg></a>
                        </h5>
                        <div class="collapse" id="more">
                          <div class="card card-body border-1 col-12 col-sm-10 col-md-6 mx-auto text-left pt-3">
                            <h5>
                            Workout simultaneously with a friend, coach, group, or other VidFitness members.<br/><br/>The live leaderboard of participants and the regular motivation from a workout partner or coach keeps you moving toward your fitness goals.<br/><br/>To create or join an event, <a className="lead text-dark font-weight-bold no-wrap" onClick={() => this.props.history.push('/setup')} href={ (isMacOs ? process.env.REACT_APP_INSTALLER_PATH_MAC : process.env.REACT_APP_INSTALLER_PATH_WIN) }>download &#x2913;</a> the app and connect your smart trainer. 
                            </h5>
                          </div>
                        </div>
                      </BrowserView> 
                    </React.Fragment>
                    }   

                  </div>
                </div>
              </div>
            </div>

          { this.state.loaded && this.state.races.length > 0 &&
          
          <div className="container-fluid">

            <div className="row w-100 justify-content-center no-gutters"> 
               <div className="pb-3 pt-5 px-3">

                  <input className="btn-check" type="radio" name="exampleRadios" id="all" value="option1" checked={(this.state.selectedFilter=='all')} onClick={this.selectFilter}/>
                  <label className="form-check-label pr-2" for="allRaces">
                    &nbsp;<b>All</b>&nbsp;
                  </label>

                  <input className="btn-check" type="radio" name="exampleRadios" id="in-progress" value="option2" checked={(this.state.selectedFilter=='in-progress')} onClick={this.selectFilter}/>
                  <label className="form-check-label pr-2 no-wrap" for="in-progress">
                    &nbsp;<b>Live</b>&nbsp;
                  </label>

                  <input className="btn-check" type="radio" name="exampleRadios" id="waiting-room" value="option2" checked={(this.state.selectedFilter=='waiting-room')} onClick={this.selectFilter}/>
                  <label className="form-check-label pr-2" for="waiting-room">
                    &nbsp;<b>Upcoming</b>&nbsp; 
                  </label>

              </div>
            </div>


            <div className="row w-100 justify-content-center no-gutters">
              
                { this.state.races.length > 0 &&
                <div className="col-12 col-sm-10 col-md-6">
                  { this.state.races.map((race) => {

                      if (race.status == this.state.selectedFilter || this.state.selectedFilter == 'all') {

                        var [ _ , thumb_url ] = getInfoFromVideoUrl(race.race_url)

                        var status_sentence;

                        if (race.status == 'waiting-room') {
                          status_sentence = 'About to Happen!'
                        } else if (race.status == 'in-progress') {
                          status_sentence = 'Happening Now!'
                        } else {
                          status_sentence = 'Completed'
                        }

                        return (

                            <div className="row w-100 p-3 justify-content-center no-gutters">

                              <div className="col-12 aspect16x9 bg-white rounded">
                                <a href="#" onClick={() => this.props.history.push(`/video/${race.race_url}`)}><img className="w-100 img-zoom" src={thumb_url}/></a>
                                <div className="event-status-real">
                                  <button className="btn btn-sm btn-light">{status_sentence}</button>
                                </div>
                                <div className="event-status">
                                  { race.status != 'complete' &&
                                    <button className="btn btn-sm btn-success" onClick={() => {
                                      this.handleJoin(race)
                                    }}>Join</button>
                                  }
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="mt-1 no-wrap text-truncate">
                                  <a href="#" onClick={() => this.props.history.push(`/video/${race.race_url}`)}><span style={{ color: '#000000', fontWeight: 'bold'}}>{ race.title }</span></a>
                                </div>
                                <div className="mt-0 text-truncate" style={{lineHeight:'.75em'}}>
                                  <span className="small">Hosted by { race.host_user_display_name ? race.host_user_display_name : 'a racer' }.</span>
                                </div>
                              </div>

                            </div>
                          
                        )

                      } else {

                        return null

                      }
                    })
                  }
                </div>
                }

                { this.state.loaded &&
                  <InfiniteListBottom isFetching={this.state.isFetching} finish={this.state.completed_finish}/>
                }
                <div className="mb-5">&nbsp;</div>
            </div>
          </div>
            }

          <div className="container-fluid pl-5">
            <div>
              <div>
              { this.state.loaded && this.state.races.length == 0 &&
                <h4 className="text-center mt-5" >There are currently no events.</h4>
              }
              </div>
            </div>
          </div>



        </div>
      </div>
      </React.Fragment>
      )
    } 
}

const mapStateToProps = (state) => {

  var logged_in = ( state.user.id != '' )

  return {
    user:         state.user,
    electronMode: state.general.electronMode,
    demoMode:     state.general.demoMode,
    logged_in: logged_in
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Events));
