import initialState from './initialState';
import * as types from '../actions/actionTypes';

const userReducer = (state = initialState.user, action) => {
  switch (action.type) {
    case types.AUTHENTICATE:
      return {
        ...state,
        ...action.payload,
        userId: action.payload.id,
        isAuthenticated: true
      }
    case types.LOG_OUT:
      localStorage.setItem('token', '');
      localStorage.setItem('user_id', '');
      return { ...initialState.user }
    case types.UPDATE_USER:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
}

export default userReducer;