import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Jumbotron, Button } from 'react-bootstrap'
import { Link } from "react-router-dom";

const EmailVerified = ({}) => {
  return (
    <div className="login-panel">
      <div className="container-fluid container-centered pt-3">
        <div>
            <div className="center-align">
                <Link to="/"><img src="./vidfitness-red.png" className="logo-main"/></Link>
            </div>
            <div className="p-4 center-align mb-3">
              Your email has been verified. You can now <Link to='/login'>log in</Link> to our website, or open the desktop app to login to the app. 
              <FontAwesomeIcon className='wrench-icon' icon={['far', 'envelope-open']}/>
            </div>
          </div>
        </div>
      </div>

  )
}

export default EmailVerified
