import * as types from './actionTypes';

export const authenticate = (user_info) => {
	return ( dispatch, getState ) => {
		dispatch(doAuthenticate(user_info))

		localStorage.setItem('user', JSON.stringify(user_info));

		// if(user_info.loginCount == 1){
		// 	window.location = 'profile'
		// }
	}
}

export const doAuthenticate = ( user_info ) => {
	return { type: types.AUTHENTICATE, payload: user_info }
}

export const logOut = () => {
	return { type: types.LOG_OUT }
}

export const updateUser = (values) => {
	return { type: types.UPDATE_USER, payload: values }
}

export const updateUserAsync = (values) => {

	return ( dispatch, getState ) => {

		const user_id = getState().user.userId

		const options = {
			method: 'put',
			headers: {
          'Content-Type': 'application/json'
      },
		  body: JSON.stringify(values)
		}

		return fetch((process.env.REACT_APP_SERVER)+'/api/v1/user/' + user_id + '/update', options)
			.then((response) => {
				return response.json()
			})
			.then((json) => {
				if(json.status == 'success'){
					dispatch(updateUser(values));
					return
				}
			})
	}

}

//New account upgrade stuff
// export const changeUserType = (values) => {
// 	return { type: types.CHANGE_ACCOUNT_TYPE, payload: values }
// }


// export const changeUserTypeAsync = (values) => {

// 	return ( dispatch, getState ) => {

// 		const user_id = getState().user.userId

// 		const options = {
// 			method: 'put',
// 			headers: {
//           'Content-Type': 'application/json'
//       },
// 		  body: JSON.stringify(values)
// 		}

// 		return fetch((process.env.REACT_APP_SERVER)+'/api/v1/user/' + user_id + '/change-account-status', options)
// 			console.log()
// 			.then((response) => {
// 				return response.json()
// 			})
// 			.then((json) => {
// 				if(json.status == 'success'){
// 					dispatch(updateUser(values));
// 					return
// 				}
// 			})
// 	}

// }

export const uploadVideoAsync = (values) => {

	return ( ) => {
		return true
	}

	// return ( dispatch, getState ) => {

	// 	const user_id = getState().user.userId

	// 	const options = {
	// 		method: 'put',
	// 		headers: {
 //          'Content-Type': 'application/json'
 //      },
	// 	  body: JSON.stringify(values)
	// 	}

	// 	return fetch((process.env.REACT_APP_SERVER)+'/api/v1/user/' + user_id + '/update', options)
	// 		.then((response) => {
	// 			return response.json()
	// 		})
	// 		.then((json) => {
	// 			if(json.status == 'success'){
	// 				dispatch(updateUser(values));
	// 				return
	// 			}
	// 		})
	// }

}

//pranjal added
export const refreshUserAsync = (values) => {
	console.log('Refreshing User...')
	return ( dispatch, getState ) => {

		const user_id = getState().user.userId

		const options = {
			method: 'get',
			headers: {
          'Content-Type': 'application/json'
      },
		}

		return fetch((process.env.REACT_APP_SERVER)+'/api/v1/users/' + user_id, options)
			.then((response) => {
				return response.json()
			})
			.then((json) => {
				dispatch(updateUser(json));
				console.log('sending these values', json)
			})
	}
}





