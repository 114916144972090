import React, { Component } from 'react';
import { connect } from 'react-redux'
import store from '../index.js';
import config from '../config.json';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect }from "react-router-dom";
import { Link } from "react-router-dom";
import { Formik } from 'formik';
import { authenticate } from '../actions/userActions';


class PasswordRecoverySecondStep extends Component {


  constructor(props){
    super(props);

    this.state = {
      general_error: '',
      success: false
    }

  }

  renderForm(){
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <Formik
              initialValues={{ email: ''}}
              validate={values => {

                let errors = {}

                return errors;
              }}
              onSubmit={(values, { setSubmitting, setErrors, setFieldError }) => {

                const { code } = this.props.match.params
                values.code = code

                const options = {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(values)
                }

                setTimeout(() => {
                  setSubmitting(false);
                }, 400);

                return fetch((process.env.REACT_APP_SERVER)+'/api/v1/update-password', options)
                  .then((response) => {
                    return response.json()
                  })
                  .then((response) => {

                    console.log('received response', response)

                    // if(response.status == 'failure' && response.message == 'user-not-found'){
                    //   this.setState({
                    //     general_error: 'Incorrect email address'
                    //   })
                    // }

                    if(response.status == 'success'){
                      this.setState({ success : true })
                      this.props.history.push('/')
                    }
                    else if(response.status == 'failure'){
                      this.setState({
                        general_error: 'Passwords need to match'
                      })
                    }

                  })

                  .catch(error => console.log('Error', error))

              }}
            >
            { ({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                submitForm
                /* and other goodies */
              }) => (
              <form onSubmit={handleSubmit}>

                <div className="form-group">
                 <input
                   type="password"
                   name="password"
                   className="form-control"
                   placeholder="Password"
                   onChange={handleChange}
                   onBlur={handleBlur}
                   value={values.password}
                 />
                 <div className="col-sm-12 text-left">
                   <small id="newPasswordHelp" className="text-danger">
                     {errors.new_password && touched.new_password && errors.new_password}
                   </small>
                 </div>
                </div>

                <div className="form-group">
                 <input
                   type="password"
                   name="password_confirmation"
                   className="form-control"
                   placeholder="Password Confirmation"
                   onChange={handleChange}
                   onBlur={handleBlur}
                   value={values.password_confirmation}
                 />
                 <div className="col-sm-12 text-left">
                   <small id="newPasswordHelp" className="text-danger">
                     {errors.password_confirmation && touched.password_confirmation && errors.password_confirmation}
                   </small>
                 </div>
                </div>

                <div id="login-error" className="text-danger">
                  { this.state.general_error }
                </div>

                { this.state.email_sent &&
                  <div id="general-message">
                    Your password has been correctly updated!
                  </div>
                }

                <hr />
                <button className='btn btn-primary sign-up-btn' onClick={ () => submitForm() } disabled={isSubmitting}>
                  Change Password
                </button>
              </form>
            )}

            </Formik>
          </div>
        </div>
      </div>
    )


  }



  render(){

    if(this.props.user.id != ''){
      return <Redirect to='/' />
    }

    return (
      <div className="login-panel">
          <div className="container-centered">
              <div>
                  <div className="center-align">
                    <Link to="/"><img src="./vidfitness-red.png" className="logo-main"/></Link>
                  </div> 
                  <div>
                      <div className="center-align mb-3 terms-and-services-panel">
                      </div>
                  </div>

                  <div className="center-align">
                    { this.renderForm() }
                  </div>
              </div>
          </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    electronMode: state.general.electronMode
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchAuthenticate: (user_info) => { dispatch(authenticate(user_info)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecoverySecondStep);
