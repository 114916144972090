import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import Track from './track.component'
import { deleteTrack } from '../actions/trackActions'
import { refreshUserAsync, updateUser } from '../actions/userActions'
import { whichChallengesToRecalculate, recalculateRankings } from '../utils/utils'
import axios from 'axios';
import scrollDown from '../images/scroll_down.png'
import InfiniteListBottom from './infinite-list-bottom.component'
import '../styles/activities.css'
import ActivitiesPlaceholder from './activities-placeholder.component'

class ActivityList extends Component {

	constructor(props) {
		super(props)
		this.state = {
			tracks: [],
			max: 0,
			isFetching: [],
			start: 0,
			end: 0,
			finish: false,
			isFetching: false,
			noTracks: false,
		}

		this.PAGINATE_SIZE = 100
		this.handleScroll = this.handleScroll.bind(this)
		this.openExternalSetupPage = this.openExternalSetupPage.bind(this)
		this.deleteSelectedTrack = this.deleteSelectedTrack.bind(this)
	}

	handleScroll() {
	  if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
	  this.fetchMoreTracks()
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);

		var end = this.state.start + this.PAGINATE_SIZE
		axios.get((process.env.REACT_APP_SERVER)+'/api/v1/tracks/index',
	      {
		      params: {
		        user_id: this.props.user.id,
		        start: this.state.start,
		        end: end
	      }
	    })
	    .then(response => {
	    		const { count, rows } = response.data

	    		if (count > 0) {
	    			this.setState({
	    				start: end,
	    				max: count,
	    				tracks: rows,
	    				loaded: true,
	    				//JUST TILL WE SOLVE PAGINATE ISSUE
              finish: true
	    			})
		      } else {
		      	console.log('NO TRACKS')
		      	this.setState({
	    				finish: true,
	    				loaded: true,
	    				noTracks: true
	    			})
		      }
	    })

	}

	fetchMoreTracks() {
		if (!this.state.finish) {
			var end = this.state.start + this.PAGINATE_SIZE
			axios.get((process.env.REACT_APP_SERVER)+'/api/v1/tracks/index',
	      {
		      params: {
		        user_id: this.props.user.id,
		        start: this.state.start,
		        end: end
	      }
	    })
	    .then(response => {
	    		const { count, rows } = response.data
	    		var finish = false

	    		if (end >= this.state.max) {
	    			finish = true
	    		}

	    		this.setState({
	    			tracks: this.state.tracks.concat(rows),
						finish: finish,
						isFetching: false,
						start: end
					})
	    })
	    .catch(function (err){
	        console.log(err);
	    })
	  }
	}

	async deleteSelectedTrack(track, index) {

		//delete track on backend
		await axios.get((process.env.REACT_APP_SERVER)+'/api/v1/tracks/' + track.id + '/delete')

		//delete track from redux store
		this.props.dispatchDeleteTrack(index)

		//get challenge ids to recalculate
		var challenge_ids = whichChallengesToRecalculate(this.props.user, track.url)

		//get new user after recalculation
		var { data: newUser } = await recalculateRankings(challenge_ids, this.props.user.id)

		//update user
		this.props.dispatchUpdateUser(newUser)

		//get current tracks on activity list
		var currentTracks = this.state.tracks

		//splice track from list
		currentTracks.splice(index, 1)

		//reset list
		this.setState({
			tracks: currentTracks
		})

		//refresh userData
		this.props.refreshUser()

	}

	openExternalSetupPage(){
    const { shell } = window.require('electron')
    shell.openExternal('https://vidfitness.com/setup')
  }

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	render() {

		return (
	    <React.Fragment>

	    	<div className="container-fluid mx-auto ml-sm-3 mr-sm-3">
	    		{ !this.state.noTracks &&
		    	<div className="container-fluid pt-3 px-0 card" style={{ borderRadius:'.75rem' }}>
		    		<div className="mb-2">
						<h3 className="mt-2 ml-4 page-title">
							My Activities:
						</h3>
					</div>
					{ this.state.loaded ?
			    	<React.Fragment>
				      	{this.state.tracks.map((currentPoint, i) => <Track index={i} track={currentPoint} deleteTrack={this.deleteSelectedTrack}  key={i} />)}
				    		<InfiniteListBottom isFetching={this.state.isFetching} finish={this.state.finish}/>
				    </React.Fragment> :
			    	<div className="spinner-container">
						<div className="loading-spinner">
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
						</div>
					</div>
			    	}
			    </div>
			    }
	   		</div>

	   		<div>
	   			{ (this.state.noTracks || !this.props.signed_in) &&
	   				<ActivitiesPlaceholder />
	   			}
	   		</div>

	    </React.Fragment>
	  );
	}

}

const mapStateToProps = (state) => {
	let signed_in
	if (state.user.id == '') {
		signed_in = false
	} else {
		signed_in = true
	}

  return {
    user:         state.user,
    electronMode: state.general.electronMode,
    demoMode:     state.general.demoMode,
    signed_in: signed_in
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchRefreshUser: (newUser) => { dispatch(refreshUserAsync(newUser))},
    dispatchUpdateUser: (newUser) => { dispatch(updateUser(newUser))},
    dispatchDeleteTrack: (index) => { dispatch(deleteTrack(index))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityList);
