// React Dependencies
import React, { Component, Fragment,  useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { Redirect, withRouter }from "react-router-dom";
import { Link } from "react-router-dom";
import { BrowserView, MobileView, isMacOs, isWindows, isBrowser, isMobile, isMobileOnly } from "react-device-detect";
import axios from 'axios'

// Redux Actions
import {
	enterRaceMode,
	enterPlayMode,
	turnOnTheaterMode,
	toggleVideoMode,
	showOverlay,
	setPlayingState,
	setRecordingState,
	setPausedState,
	turnOffTheaterMode,
	turnOnPlayMode,
	showCountdownOverlay,
	overrideDuration
} from '../actions/videoActions'

import {
	updateUser
} from '../actions/userActions'

import { 
	whichChallengesToRecalculate, recalculateRankings
} from '../utils/utils'

// Styles
import '../styles/overlay.css';

import videojs from 'video.js';

// Other Dependencies
import { FontAwesomeIcon }   from '@fortawesome/react-fontawesome'
var classNames = require('classnames');

String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

class VideoOverlay extends Component {

	constructor(props) {
		super(props)
		this.state = {

		}
	}

	componentDidMount() {

	}

	render() {

		let buttons = null

		let padbottom = ''

		let accumulated_power = this.props.accumulated_power

		if (!isMobileOnly) {

			if (!this.props.video.AudioOverlayMode) {

				if(this.props.electronMode) {

					if (this.props.video.mode == 'play' || this.props.video.mode == null ) {

						buttons = (
							<div className='overlay-buttons'>

								<div className="overlay-button-container">
									<button className="btn-lg btn-success overlay-clickable" onClick={ () => {
										if(this.props.isTrackerConnected){
											this.props.toggleGroupMode(true)
										} else {
											this.props.openTrackerPanel()
										}
									}} href="#">Start</button>
								</div>
								<div className="overlay-button-container">
									<button className="btn-lg btn-light overlay-clickable" onClick={ this.props.fullscreenPlayMode }>Preview</button>
								</div>
							</div>
						)

					}
			
					if (this.props.video.mode == 'race') {
			
				      buttons = (
				      	<div className='overlay-buttons'>

					      	{ accumulated_power > 0 &&
					            <Fragment>
						            <div className="overlay-button-container">
						            	<button onClick={ () =>{
						            		this.props.stopTrack(false)
						            	}} className="btn-lg btn-success overlay-clickable">Save</button>
										</div>
										<div className="overlay-button-container">
											<button onClick={ () => { 
												if (window.confirm('Are you sure?')) {
													this.props.stopTrack(true) 
												}
											}} className="btn-lg btn-light overlay-clickable">Discard</button>
										</div>
					            </Fragment>
				          	}

				          	{ accumulated_power == 0 && this.props.currentTime != 0 &&
					            <Fragment>
					            	<div className="overlay-button-container">
					            		<button onClick={ () => { this.props.stopTrack(true) } } className="btn-lg overlay-clickable btn-danger">
						                	No input was detected.
						                	<br/>
						                	Discard
					              		</button>
										</div>    
					            </Fragment>
				          	}
										
							</div>
					  	)
					}
			
				} else {

					buttons = (
						<div className='overlay-buttons'>
							<div className="overlay-button-container">
								<button className="btn-lg btn btn-light overlay-clickable" onClick={ this.props.fullscreenPlayMode } href="#">Preview</button>
							</div>
						</div>
					)

				}
			}

		} else {

			buttons = (
				<div>
					 <FontAwesomeIcon icon={['fa', 'play']} onClick = {this.props.fullscreenPlayMode}/>
				</div>
			)

		}

		// const top  = (video_height / 2.0) - 100
		// const left = (window.screen.width / 2.0) - 150
		var top = "40%"
		var left = "40%"



	  // The issue seems to be here:
	  // visible prop depends on redux
	  // it is managed using showOverlay and hideOverlay
	  // but on videoScreen component, line 519
	  // we use directly overlay.classList.toggle('overlay-visible')
	  // bypassing redux


		var overlayClasses = classNames({
		  'video-overlay': true,
		  'overlay-visible': this.props.visible
		});

		// video_height == 0 means the video is not loaded yet
		if (this.props.video.isPlaying == true) {

	   	return null

		} else {

	    return (
	    	<React.Fragment>

	        { this.props.processingVideo &&
	          <div id="video-overlay" className={ overlayClasses } style={{paddingLeft:'1rem', paddingRight:'1rem'}}>
	            <div className="video-being-processed-msg">Video is being processed.</div>
	          </div>
	        }
	        { !this.props.processingVideo && !this.props.video.AudioOverlayMode &&
	          <div id="video-overlay" className={ overlayClasses }>
	            { buttons }
	          </div>
	        }

	    	</React.Fragment>
		)
	}



	}



}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    track: state.currentTrack,
    visible: state.video.overlay.visible,
    video: state.video,
    electronMode: state.general.electronMode
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  	dispatchTurnOnTheaterMode: (value)  => { dispatch(turnOnTheaterMode())},
  	dispatchTurnOffTheaterMode: (value)  => { dispatch(turnOffTheaterMode(value))},
  	dispatchTurnOnPlayMode:     (value)  => { dispatch(turnOnPlayMode(value))},
  	dispatchSetPlayingState:    (value)  => { dispatch(setPlayingState(value))},
  	dispatchSetRecordingState:  (value)  => { dispatch(setRecordingState(value))},
  	dispatchSetPausedState:     (value)  => { dispatch(setPausedState(value))},
  	dispatchUpdateUser: (value) => {dispatch(updateUser(value))},
  	dispatchShowCountdownOverlay:  ()     => { dispatch(showCountdownOverlay()) },
  	dispatchOverrideDuration: (value) => { dispatch(overrideDuration(value))},
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VideoOverlay))
