import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Navbar from './navbar.component';
import Footer from './footer.component';
import { BrowserView, MobileView, isMacOs, isWindows, isBrowser, isMobile } from "react-device-detect";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../styles/homepage-override.css";
// import HoverVideoPlayer from 'react-hover-video-player';

class HomeCarousel extends Component {

  constructor(props) {
    super(props);

  this.responsive = {
  // naming is arbitrary:
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 2400 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 2400, min: 992 },
      items: 4
    },
    smDesktop: {
      breakpoint: { max: 992, min: 768 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 768, min: 576 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1
    }
  };

    this.state = {

      mediabucket:      'https://vidracing-production-video-optimized.s3.amazonaws.com/',

      livetitle1:       'Track Buttgen',
      livegradient1:    '',
      liveduration1:    '12:10',
      livethumb1:       '1583863880408',
      livelink1:        '1583863880408',
      livechanneltitle1: 'Break Away Cycling',
      livechannelid1:   '119',

      livetitle2:       'Pine Creek Rock Trail',
      livegradient2:    '',
      liveduration2:    '24:59',
      livethumb2:       '1581134456656',
      livelink2:        '1581134456656',
      livechanneltitle2: "Bryan W.'s Channel",
      livechannelid2:   '120',

      livetitle3:       "Ride with Team VidFitness",
      livegradient3: '',
      liveduration3:    '',
      livethumb3:       '1589187357421',
      livelink3:        '22',
      livechanneltitle3: "ChannelName",
      livechannelid3:   '2',

      livetitle4:       "Ride with Team VidFitness",
      livegradient4: '',
      liveduration4:    '',
      livethumb4:       '1602378889081',
      livelink4:        '27',
      livechanneltitle4: "ChannelName",
      livechannelid4:   '2',

      livetitle5:       "Ride with Team VidFitness",
      livegradient5: '',
      liveduration5:    '',
      livethumb5:       '1577484108842',
      livelink5:        '/challenge/14',
      livechanneltitle5: "ChannelName",
      livechannelid5:   '2',

      livetitle6:       "Ride with Team VidFitness",
      livegradient6: '',
      liveduration6:    '',
      livethumb6:       '1589187357421',
      livelink6:        '22',
      livechanneltitle6: "ChannelName",
      livechannelid6:   '2',

      workouttitle1:       'Track Buttgen',
      workoutgradient1:    '',
      workoutduration1:    '12:10',
      workoutthumb1:       '1583863880408',
      workoutlink1:        '1583863880408',
      workoutchanneltitle1: 'Break Away Cycling',
      workoutchannelid1:   '119',

      workouttitle2:       'Pine Creek Rock Trail',
      workoutgradient2:    '',
      workoutduration2:    '24:59',
      workoutthumb2:       '1581134456656',
      workoutlink2:        '1581134456656',
      workoutchanneltitle2: "Bryan W.'s Channel",
      workoutchannelid2:   '120',

      workouttitle3:       'Wales: Blaen-Y-Cwm Valley Horseshoe. Part 4',
      workoutgradient3:    'd-block',
      workoutduration3:    '11:52',
      workoutthumb3:       '1599683248811',
      workoutlink3:        '1599683248811',
      workoutchanneltitle3: "Scenic UK Rides",
      workoutchannelid3:   '41',

      workouttitle4:       'East Rock Fall 2019',
      workoutgradient4:    '',
      workoutduration4:    '33:07',
      workoutthumb4:       '1572298990774',
      workoutlink4:        '1572298990774',
      workoutchanneltitle4: "College Street Cycles",
      workoutchannelid4:   '37',

      workouttitle5:       'New Haven Gran Prix',
      workoutgradient5: '',
      workoutduration5:    '',
      workoutthumb5:       '1577484108842',
      workoutlink5:        '1577484108842',
      workoutchanneltitle5: "JG Race n' Ride",
      workoutchannelid5:   '3',

      workouttitle6:       'Farmington Canal: Great Blue Heron Surprise',
      workoutgradient6: '',
      workoutduration6:    '',
      workoutthumb6:       '1589187357421',
      workoutlink6:        '1589187357421',
      workoutchanneltitle6: "Random Rides",
      workoutchannelid6:   '81',

      challengetitle1:       'The USA Cycling Medley',
      challengegradient1: '',
      challengeduration1:    '',
      challengethumb1:       '1572211608175',
      challengelink1:        '14',
      challengechanneltitle1: "Ride all 3 videos to complete this challenge!",
      challengechannelid1:   '14',

      challengetitle2:       'California Hill Climb',
      challengegradient2: '',
      challengeduration2:    '',
      challengethumb2:       '../card-challenge-cali.jpg',
      challengelink2:        '27',
      challengechanneltitle2: "Ride all 3 videos to complete this challenge!",
      challengechannelid2:   '27',

      challengetitle3:       'The Gateway Cup Crits',
      challengegradient3: '',
      challengeduration3:    '',
      challengethumb3:       '1597372031417',
      challengelink3:        '17',
      challengechanneltitle3: "Ride all 3 videos to complete this challenge!",
      challengechannelid3:   '17',

      challengetitle4:       'Ride the Velodrome',
      challengegradient4: '',
      challengeduration4:    '',
      challengethumb4:       '1599601280575',
      challengelink4:        '18',
      challengechanneltitle4: "Ride all 3 videos to complete this challenge!",
      challengechannelid4:   '18',

      challengetitle5:       'Acadia National Park Challenge',
      challengegradient5:    '',
      challengeduration5:    '',
      challengethumb5:       '1599547770727',
      challengelink5:        '25',
      challengechanneltitle5: "Ride all 3 videos to complete this challenge!",
      challengechannelid5:   '25',

      challengetitle6:       'The Yorkshire Scenic Challenge',
      challengegradient6:    '',
      challengeduration6:    '',
      challengethumb6:       '1576130048998',
      challengelink6:        '21',
      challengechanneltitle6: "Ride all 3 videos to complete this challenge!",
      challengechannelid6:   '21',

      channeltitle1:       'Coach Chris Navin',
      channelgradient1:    '',
      channelduration1:    '',
      channelthumb1:       '../card-coachchris.jpg',
      channellink1:        '271',
      channelchanneltitle1: "19 workouts",
      channelchannelid1:   '271',

      channeltitle2:       "Justin McQuerry's Race Vids",
      channelgradient2: '',
      channelduration2:    '',
      channelthumb2:       '../card-justin.png',
      channellink2:        '246',
      channelchanneltitle2: "11 workouts",
      channelchannelid2:   '246',

      channeltitle3:       "Break Away Cycling",
      channelgradient3: '',
      channelduration3:    '',
      channelthumb3:       '../card-breakaway.jpg',
      channellink3:        '119',
      channelchanneltitle3: "5 workouts",
      channelchannelid3:   '119',

      channeltitle4:       'Scenic UK Rides',
      channelgradient4: '',
      channelduration4:    '',
      channelthumb4:       '../card-ukrides.jpg',
      channellink4:        '41',
      channelchanneltitle4: "16 workouts",
      channelchannelid4:   '41',

      channeltitle5:       "Steve's Channel",
      channelgradient5: '',
      channelduration5:    '',
      channelthumb5:       '../card-steve.jpg',
      channellink5:        '2',
      channelchanneltitle5: "21 workouts",
      channelchannelid5:   '2',

      channeltitle6:       'Random Rides',
      channelgradient6: '',
      channelduration6:    '',
      channelthumb6:       '1599980447940',
      channellink6:        '81',
      channelchanneltitle6: "12 workouts",
      channelchannelid6:   '81'
    }
  }

  render() {
    return (

      <div className="portraitview">

        <div className="container-fluid px-4 py-0 pt-4"
          style={{
            position: 'relative',
            backgroundColor: '#ffffff',
            zIndex:'1000'
          }}>
          <div className="row border-bottom">
            <div className="col px-0 px-sm-3">
              <div className="feature-row-heading pb-1 pt-1 px-3 align-items-end"> <h3 className="feature-row-title"><Link to="/videos" className="text-dark">Workouts</Link></h3> <Link to="/videos" className="feature-see-all pb-2 no-wrap" aria-label="See All">See All<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="currentColor" style={{ width:'1rem' }} class="bi bi-chevron-right" viewBox="-2 -1 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" stroke="black" stroke-width="1"/></svg></Link>
              </div>

              <Carousel swipeable={true} slidesToSlide={1} responsive={ this.responsive}>

                <div className="px-2 py-2">
                  <div className="aspect16x9 rounded"> <Link to={ '/video/' + this.state.workoutlink1 + '-vr' }><img className="img-zoom w-100" src={ this.state.mediabucket + this.state.workoutthumb1 + '-vr/thumbs-' + this.state.workoutthumb1 + '-vr-00001.jpg' }/></Link>
                  </div>
                  <div>
                    <div className="time-indicator">{ this.state.workoutduration1 }
                    </div>
                    <div> <img className={ 'gradient-icon d-none' +  this.state.workoutgradient1 } src="https://img.icons8.com/ios-glyphs/2x/FFFFFF/bar-chart.png" />
                    </div>
                  </div>
                  <div className="card-footer border-0 bg-transparent text-left text-truncate pl-0 pt-1 mt-1"><Link to={ '/video/' + this.state.workoutlink1 + '-vr' }><b>{ this.state.workouttitle1 }</b></Link>&nbsp;
                    <br/><span className="card-footer-subtext text-decoration-underline">from <Link to={ '/channel/' + this.state.workoutchannelid1 }>{ this.state.workoutchanneltitle1 }<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="currentColor" style={{ width:'1rem' }} class="bi bi-chevron-right" viewBox="-2 -1 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" stroke="black" stroke-width="1"/></svg></Link></span>
                  </div>
                </div>

                <div className="px-2 py-2">
                  <div className="aspect16x9 rounded"> <Link to={ '/video/' + this.state.workoutlink2 + '-vr' }><img className="img-zoom w-100" src={ this.state.mediabucket + this.state.workoutthumb2  + '-vr/thumbs-' + this.state.workoutthumb2 + '-vr-00001.jpg'}/></Link>
                  </div>
                  <div>
                    <div className="time-indicator">{ this.state.workoutduration2 }
                    </div>
                    <div> <img className={ 'gradient-icon d-none' +  this.state.workoutgradient2 } src="https://img.icons8.com/ios-glyphs/2x/FFFFFF/bar-chart.png" />
                    </div>
                  </div>
                  <div className="card-footer border-0 bg-transparent text-left text-truncate pl-0 pt-1 mt-1"><Link to={ '/video/' + this.state.workoutlink2 + '-vr' }><b>{ this.state.workouttitle2 }</b></Link>&nbsp;
                    <br/><span className="card-footer-subtext text-decoration-underline">from <Link to={ '/channel/' + this.state.workoutchannelid2 }>{ this.state.workoutchanneltitle2 }<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="currentColor" style={{ width:'1rem' }} class="bi bi-chevron-right" viewBox="-2 -1 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" stroke="black" stroke-width="1"/></svg></Link></span>
                  </div>
                </div>

                <div className="px-2 py-2">
                  <div className="aspect16x9 rounded"> <Link to={ '/video/' + this.state.workoutlink3 + '-vr' }><img className="img-zoom w-100" src={ this.state.mediabucket + this.state.workoutthumb3 + '-vr/thumbs-' + this.state.workoutthumb3 + '-vr-00001.jpg' }/></Link>
                  </div>
                  <div>
                    <div className="time-indicator">{ this.state.workoutduration3 }
                    </div>
                    <div> <img className={ 'gradient-icon d-none' +  this.state.workoutgradient3 } src="https://img.icons8.com/ios-glyphs/2x/FFFFFF/bar-chart.png" />
                    </div>
                  </div>
                  <div className="card-footer border-0 bg-transparent text-left text-truncate pl-0 pt-1 mt-1"><Link to={ '/video/' + this.state.workoutlink3 + '-vr' }><b>{ this.state.workouttitle3 }</b></Link>&nbsp;
                    <br/><span className="card-footer-subtext text-decoration-underline">from <Link to={ '/channel/' + this.state.workoutchannelid3 }>{ this.state.workoutchanneltitle3 }<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="currentColor" style={{ width:'1rem' }} class="bi bi-chevron-right" viewBox="-2 -1 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" stroke="black" stroke-width="1"/></svg></Link></span>
                  </div>
                </div>

                <div className="px-2 py-2">
                  <div className="aspect16x9 rounded"> <Link to={ '/video/' + this.state.workoutlink4 + '-vr' }><img className="img-zoom w-100" src={ this.state.mediabucket + this.state.workoutthumb4 + '-vr/thumbs-' + this.state.workoutthumb4 + '-vr-00001.jpg' }/></Link>
                  </div>
                  <div>
                    <div className="time-indicator">{ this.state.workoutduration4 }
                    </div>
                    <div> <img className={ 'gradient-icon d-none' +  this.state.workoutgradient4 } src="https://img.icons8.com/ios-glyphs/2x/FFFFFF/bar-chart.png" />
                    </div>
                  </div>
                  <div className="card-footer border-0 bg-transparent text-left text-truncate pl-0 pt-1 mt-1"><Link to={ '/video/' + this.state.workoutlink4 + '-vr' }><b>{ this.state.workouttitle4 }</b></Link>&nbsp;
                    <br/><span className="card-footer-subtext text-decoration-underline">from <Link to={ '/channel/' + this.state.workoutchannelid4 }>{ this.state.workoutchanneltitle4 }<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="currentColor" style={{ width:'1rem' }} class="bi bi-chevron-right" viewBox="-2 -1 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" stroke="black" stroke-width="1"/></svg></Link></span>
                  </div>
                </div>

                <div className="px-2 py-2">
                  <div className="aspect16x9 rounded"> <Link to={ '/video/' + this.state.workoutlink5 + '-vr' }><img className="img-zoom w-100" src={ this.state.mediabucket + this.state.workoutthumb5 + '-vr/thumbs-' + this.state.workoutthumb5 + '-vr-00001.jpg' }/></Link>
                  </div>
                  <div>
                    <div className="time-indicator">{ this.state.workoutduration5 }
                    </div>
                    <div> <img className={ 'gradient-icon d-none' +  this.state.workoutgradient5 } src="https://img.icons8.com/ios-glyphs/2x/FFFFFF/bar-chart.png" />
                    </div>
                  </div>
                  <div className="card-footer border-0 bg-transparent text-left text-truncate pl-0 pt-1 mt-1"><Link to={ '/video/' + this.state.workoutlink5 + '-vr' }><b>{ this.state.workouttitle5 }</b></Link>&nbsp;
                    <br/><span className="card-footer-subtext text-decoration-underline">from <Link to={ '/channel/' + this.state.workoutchannelid5 }>{ this.state.workoutchanneltitle5 }<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="currentColor" style={{ width:'1rem' }} class="bi bi-chevron-right" viewBox="-2 -1 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" stroke="black" stroke-width="1"/></svg></Link></span>
                  </div>
                </div>

                <div className="px-2 py-2">
                  <div className="aspect16x9 rounded"> <Link to={ '/video/' + this.state.workoutlink6 + '-vr' }><img className="img-zoom w-100" src={ this.state.mediabucket + this.state.workoutthumb6 + '-vr/thumbs-' + this.state.workoutthumb6 + '-vr-00001.jpg' }/></Link>
                  </div>
                  <div>
                    <div className="time-indicator">{ this.state.workoutduration6 }
                    </div>
                    <div> <img className={ 'gradient-icon d-none' +  this.state.workoutgradient6 } src="https://img.icons8.com/ios-glyphs/2x/FFFFFF/bar-chart.png" />
                    </div>
                  </div>
                  <div className="card-footer border-0 bg-transparent text-left text-truncate pl-0 pt-1 mt-1"><Link to={ '/video/' + this.state.workoutlink6 + '-vr' }><b>{ this.state.workouttitle6 }</b></Link>&nbsp;
                    <br/><span className="card-footer-subtext text-decoration-underline">from <Link to={ '/channel/' + this.state.workoutchannelid6 }>{ this.state.workoutchanneltitle6 }<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="currentColor" style={{ width:'1rem' }} class="bi bi-chevron-right" viewBox="-2 -1 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" stroke="black" stroke-width="1"/></svg></Link></span>
                  </div>
                </div>

              </Carousel>

            </div>
          </div>
        </div>

        <div className="container-fluid px-4 py-0">
          <div className="row border-bottom">
            <div className="col px-0 px-sm-3">
              <div className="feature-row-heading pb-1 pt-1 px-3 align-items-end"> <h3 className="feature-row-title"><Link to="/explore-channels" className="text-dark">Channels</Link></h3> <Link to="/explore-channels" className="feature-see-all pb-2 no-wrap" aria-label="See All">See All<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="currentColor" style={{ width:'1rem' }} class="bi bi-chevron-right" viewBox="-2 -1 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" stroke="black" stroke-width="1"/></svg></Link> 
              </div>

              <Carousel swipeable={true} slidesToSlide={1} responsive={ this.responsive }>

                <div className="px-2 py-2">
                  <div className="aspect16x9 rounded"> <Link to={ '/channel/' + this.state.channellink1 }><img className="img-zoom w-100" src={ this.state.channelthumb1 }/></Link>
                  </div>
                  <div>
                    <div className="time-indicator">{ this.state.channelduration1 }
                    </div>
                    <div> <img className={ 'gradient-icon d-none' +  this.state.channelgradient1 } src="https://img.icons8.com/ios-glyphs/2x/FFFFFF/bar-chart.png" />
                    </div>
                  </div>
                  <div className="card-footer border-0 bg-transparent text-left text-truncate pl-0 pt-1 mt-1"><Link to={ '/channel/' + this.state.channellink1 }><b>{ this.state.channeltitle1 }</b></Link>&nbsp;
                    <br/><span className="card-footer-subtext"><Link to={ '/channel/' + this.state.channelchannelid1 }>{ this.state.channelchanneltitle1 }</Link></span>
                  </div>
                </div>

                <div className="px-2 py-2">
                  <div className="aspect16x9 rounded"> <Link to={ '/channel/' + this.state.channellink2 }><img className="img-zoom w-100" src={ this.state.channelthumb2 }/></Link>
                  </div>
                  <div>
                    <div className="time-indicator">{ this.state.channelduration2 }
                    </div>
                    <div> <img className={ 'gradient-icon d-none' +  this.state.channelgradient2 } src="https://img.icons8.com/ios-glyphs/2x/FFFFFF/bar-chart.png" />
                    </div>
                  </div>
                  <div className="card-footer border-0 bg-transparent text-left text-truncate pl-0 pt-1 mt-1"><Link to={ '/channel/' + this.state.channellink2 }><b>{ this.state.channeltitle2 }</b></Link>&nbsp;
                    <br/><span className="card-footer-subtext"><Link to={ '/channel/' + this.state.channelchannelid2 }>{ this.state.channelchanneltitle2 }</Link></span>
                  </div>
                </div>

                <div className="px-2 py-2">
                  <div className="aspect16x9 rounded"> <Link to={ '/channel/' + this.state.channellink3 }><img className="img-zoom w-100" src={ this.state.channelthumb3 }/></Link>
                  </div>
                  <div>
                    <div className="time-indicator">{ this.state.channelduration3 }
                    </div>
                    <div> <img className={ 'gradient-icon d-none' +  this.state.channelgradient3 } src="https://img.icons8.com/ios-glyphs/2x/FFFFFF/bar-chart.png" />
                    </div>
                  </div>
                  <div className="card-footer border-0 bg-transparent text-left text-truncate pl-0 pt-1 mt-1"><Link to={ '/channel/' + this.state.channellink3 }><b>{ this.state.channeltitle3 }</b></Link>&nbsp;
                    <br/><span className="card-footer-subtext"><Link to={ '/channel/' + this.state.channelchannelid3 }>{ this.state.channelchanneltitle3 }</Link></span>
                  </div>
                </div>

                <div className="px-2 py-2">
                  <div className="aspect16x9 rounded"> <Link to={ '/channel/' + this.state.channellink4 }><img className="img-zoom w-100" src={ this.state.channelthumb4 }/></Link>
                  </div>
                  <div>
                    <div className="time-indicator">{ this.state.channelduration4 }
                    </div>
                    <div> <img className={ 'gradient-icon d-none' +  this.state.channelgradient4 } src="https://img.icons8.com/ios-glyphs/2x/FFFFFF/bar-chart.png" />
                    </div>
                  </div>
                  <div className="card-footer border-0 bg-transparent text-left text-truncate pl-0 pt-1 mt-1"><Link to={ '/channel/' + this.state.channellink4 }><b>{ this.state.channeltitle4 }</b></Link>&nbsp;
                    <br/><span className="card-footer-subtext"><Link to={ '/channel/' + this.state.channelchannelid4 }>{ this.state.channelchanneltitle4 }</Link></span>
                  </div>
                </div>

                <div className="px-2 py-2">
                  <div className="aspect16x9 rounded"> <Link to={ '/channel/' + this.state.channellink5 }><img className="img-zoom w-100" src={ this.state.channelthumb5 }/></Link>
                  </div>
                  <div>
                    <div className="time-indicator">{ this.state.channelduration5 }
                    </div>
                    <div> <img className={ 'gradient-icon d-none' +  this.state.channelgradient5 } src="https://img.icons8.com/ios-glyphs/2x/FFFFFF/bar-chart.png" />
                    </div>
                  </div>
                  <div className="card-footer border-0 bg-transparent text-left text-truncate pl-0 pt-1 mt-1"><Link to={ '/channel/' + this.state.channellink5 }><b>{ this.state.channeltitle5 }</b></Link>&nbsp;
                    <br/><span className="card-footer-subtext"><Link to={ '/channel/' + this.state.channelchannelid5 }>{ this.state.channelchanneltitle5 }</Link></span>
                  </div>
                </div>

                <div className="px-2 py-2">
                  <div className="aspect16x9 rounded"> <Link to={ '/channel/' + this.state.channellink6 }><img className="img-zoom w-100" src={ this.state.mediabucket + this.state.channelthumb6 + '-vr/thumbs-' + this.state.channelthumb6 + '-vr-00001.jpg' }/></Link>
                  </div>
                  <div>
                    <div className="time-indicator">{ this.state.channelduration6 }
                    </div>
                    <div> <img className={ 'gradient-icon d-none' +  this.state.channelgradient6 } src="https://img.icons8.com/ios-glyphs/2x/FFFFFF/bar-chart.png" />
                    </div>
                  </div>
                  <div className="card-footer border-0 bg-transparent text-left text-truncate pl-0 pt-1 mt-1"><Link to={ '/channel/' + this.state.channellink6 }><b>{ this.state.channeltitle6 }</b></Link>&nbsp;
                    <br/><span className="card-footer-subtext"><Link to={ '/channel/' + this.state.channelchannelid6 }>{ this.state.channelchanneltitle6 }</Link></span>
                  </div>
                </div>

              </Carousel>

            </div>
          </div>
        </div>

        <div className="container-fluid px-4 py-0">
          <div className="row border-bottom">
            <div className="col px-0 px-sm-3">
              <div className="feature-row-heading pb-1 pt-1 px-3 align-items-end"> <h3 className="feature-row-title"><Link to="/challenges" className="text-dark">Challenges</Link></h3> <Link to="/challenges" className="feature-see-all pb-2 no-wrap" aria-label="See All">See All<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="currentColor" style={{ width:'1rem' }} class="bi bi-chevron-right" viewBox="-2 -1 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" stroke="black" stroke-width="1"/></svg></Link>
              </div>

              <Carousel swipeable={true} slidesToSlide={1} responsive={ this.responsive }>

                <div className="px-2 py-2">
                  <div className="aspect16x9 rounded"> <Link to={ '/challenge/' + this.state.challengelink1 }><img className="img-zoom w-100" src={ this.state.mediabucket + this.state.challengethumb1 + '-vr/thumbs-' + this.state.challengethumb1 + '-vr-00001.jpg' }/></Link>
                  </div>
                  <div>
                    <div className="time-indicator">{ this.state.challengeduration1 }
                    </div>
                    <div> <img className={ 'gradient-icon d-none' +  this.state.challengegradient1 } src="https://img.icons8.com/ios-glyphs/2x/FFFFFF/bar-chart.png" />
                    </div>
                  </div>
                  <div className="card-footer border-0 bg-transparent text-left text-truncate pl-0 pt-1 mt-1"><Link to={ '/challenge/' + this.state.challengelink1 }><b>{ this.state.challengetitle1 }</b></Link>&nbsp;
                    <br/><span className="card-footer-subtext"><Link to={ '/challenge/' + this.state.challengechannelid1 }>{ this.state.challengechanneltitle1 }</Link></span>
                  </div>
                </div>

                <div className="px-2 py-2">
                  <div className="aspect16x9 rounded"> <Link to={ '/challenge/' + this.state.challengelink2 }><img className="img-zoom w-100" src={ this.state.challengethumb2 }/></Link>
                  </div>
                  <div>
                    <div className="time-indicator">{ this.state.challengeduration2 }
                    </div>
                    <div> <img className={ 'gradient-icon d-none' +  this.state.challengegradient2 } src="https://img.icons8.com/ios-glyphs/2x/FFFFFF/bar-chart.png" />
                    </div>
                  </div>
                  <div className="card-footer border-0 bg-transparent text-left text-truncate pl-0 pt-1 mt-1"><Link to={ '/challenge/' + this.state.challengelink2 }><b>{ this.state.challengetitle2 }</b></Link>&nbsp;
                    <br/><span className="card-footer-subtext"><Link to={ '/challenge/' + this.state.challengechannelid2 }>{ this.state.challengechanneltitle2 }</Link></span>
                  </div>
                </div>

                <div className="px-2 py-2">
                  <div className="aspect16x9 rounded"> <Link to={ '/challenge/' + this.state.challengelink3 }><img className="img-zoom w-100" src={ this.state.mediabucket + this.state.challengethumb3 + '-vr/thumbs-' + this.state.challengethumb3 + '-vr-00001.jpg' }/></Link>
                  </div>
                  <div>
                    <div className="time-indicator">{ this.state.challengeduration3 }
                    </div>
                    <div> <img className={ 'gradient-icon d-none' +  this.state.challengegradient3 } src="https://img.icons8.com/ios-glyphs/2x/FFFFFF/bar-chart.png" />
                    </div>
                  </div>
                  <div className="card-footer border-0 bg-transparent text-left text-truncate pl-0 pt-1 mt-1"><Link to={ '/challenge/' + this.state.challengelink3 }><b>{ this.state.challengetitle3 }</b></Link>&nbsp;
                    <br/><span className="card-footer-subtext"><Link to={ '/challenge/' + this.state.challengechannelid3 }>{ this.state.challengechanneltitle3 }</Link></span>
                  </div>
                </div>

                <div className="px-2 py-2">
                  <div className="aspect16x9 rounded"> <Link to={ '/challenge/' + this.state.challengelink4 }><img className="img-zoom w-100" src={ this.state.mediabucket + this.state.challengethumb4 + '-vr/thumbs-' + this.state.challengethumb4 + '-vr-00001.jpg' }/></Link>
                  </div>
                  <div>
                    <div className="time-indicator">{ this.state.challengeduration4 }
                    </div>
                    <div> <img className={ 'gradient-icon d-none' +  this.state.challengegradient4 } src="https://img.icons8.com/ios-glyphs/2x/FFFFFF/bar-chart.png" />
                    </div>
                  </div>
                  <div className="card-footer border-0 bg-transparent text-left text-truncate pl-0 pt-1 mt-1"><Link to={ '/challenge/' + this.state.challengelink4 }><b>{ this.state.challengetitle4 }</b></Link>&nbsp;
                    <br/><span className="card-footer-subtext"><Link to={ '/challenge/' + this.state.challengechannelid4 }>{ this.state.challengechanneltitle4 }</Link></span>
                  </div>
                </div>

                <div className="px-2 py-2">
                  <div className="aspect16x9 rounded"> <Link to={ '/challenge/' + this.state.challengelink5 }><img className="img-zoom w-100" src={ this.state.mediabucket + this.state.challengethumb5 + '-vr/thumbs-' + this.state.challengethumb5 + '-vr-00001.jpg' }/></Link>
                  </div>
                  <div>
                    <div className="time-indicator">{ this.state.challengeduration5 }
                    </div>
                    <div> <img className={ 'gradient-icon d-none' +  this.state.challengegradient5 } src="https://img.icons8.com/ios-glyphs/2x/FFFFFF/bar-chart.png" />
                    </div>
                  </div>
                  <div className="card-footer border-0 bg-transparent text-left text-truncate pl-0 pt-1 mt-1"><Link to={ '/challenge/' + this.state.challengelink5 }><b>{ this.state.challengetitle5 }</b></Link>&nbsp;
                    <br/><span className="card-footer-subtext"><Link to={ '/challenge/' + this.state.challengechannelid5 }>{ this.state.challengechanneltitle5 }</Link></span>
                  </div>
                </div>

                <div className="px-2 py-2">
                  <div className="aspect16x9 rounded"> <Link to={ '/challenge/' + this.state.challengelink6 }><img className="img-zoom w-100" src={ this.state.mediabucket + this.state.challengethumb6 + '-vr/thumbs-' + this.state.challengethumb6 + '-vr-00001.jpg' }/></Link>
                  </div>
                  <div>
                    <div className="time-indicator">{ this.state.challengeduration6 }
                    </div>
                    <div> <img className={ 'gradient-icon d-none' +  this.state.challengegradient6 } src="https://img.icons8.com/ios-glyphs/2x/FFFFFF/bar-chart.png" />
                    </div>
                  </div>
                  <div className="card-footer border-0 bg-transparent text-left text-truncate pl-0 pt-1 mt-1"><Link to={ '/challenge/' + this.state.challengelink6 }><b>{ this.state.challengetitle6 }</b></Link>&nbsp;
                    <br/><span className="card-footer-subtext"><Link to={ '/challenge/' + this.state.challengechannelid6 }>{ this.state.challengechanneltitle6 }</Link></span>
                  </div>
                </div>

              </Carousel>

            </div>
          </div>
        </div>

      </div>

    );
  }

}
// End video component

// Gets the state -> pass part of it as properties
const mapStateToProps = (state) => {
  const { user } = state

  let guestMode = false

  if( user.userId == '' ){
    guestMode = true
  }

  return {
    user: user,
    electronMode: state.general.electronMode || false,
    guestMode,
    showMainOverlay: state.general.showMainOverlay
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeCarousel));
