import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-redux'
import profileplaceholder from '../images/profileplaceholder.jpg'
import '../styles/challenges.css'

class UserList extends Component {

	constructor(props) {
		super(props)
		this.imgError = this.imgError.bind(this)
	}

	componentDidMount() {

	}

	imgError(ev) {
    ev.target.src = profileplaceholder
    return true
  }

	render() {

		var currentUsers = Object.keys(this.props.users)

		return (
			<div>
				{ currentUsers.map((id) => {

						return (
							<div className={"challenge-leaderboard-card"} style={{position: 'relative'}}>
				                <div style={{display: 'flex'}}>
				                  <div className="challenge-leaderboard-image-container mt-2 mb-2 ml-3 mr-2">
				                    <img className="challenge-leaderboard-image" src={this.props.users[id].photo_url} onError={this.imgError}/>
				                  </div>
				                  <div style={{display: 'block'}} className="mt-1">
				                    <div>{this.props.users[id].display_name}</div>
				                  </div>
				                </div>
				              </div>
						)
					})
				}
			</div>
		)
	}
}

export default UserList