import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter }from "react-router-dom";
import Navbar from './navbar.component';
import HomePage from './homepage.component';
import Footer from './footer.component'
import queryString from 'query-string'
import PrivacyText from './privacy-text.component.js'


const PrivacyPolicy = ({electronMode, location}) => {

  const query_values = queryString.parse(location.search)

	return (
		<React.Fragment>
    { typeof(query_values.electronMode) == 'undefined' &&
      <Navbar />
    }
		<br />

    <PrivacyText />

    { typeof(query_values.electronMode) == 'undefined' &&
      <Footer />
    }

		</React.Fragment>
	)
}

const mapStateToProps = (state) => {
  return {
  	electronMode: state.general.electronMode
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy));

