// General Actions

export const CONFIRM_AGREEMENT      = 'CONFIRM_AGREEMENT';
export const SHOW_TERMS             = 'SHOW_TERMS';
export const SAVE_HOST_NAME         = 'SAVE_HOST_NAME';
export const SAVE_SERVER_NAME       = 'SAVE_SERVER_NAME';
export const TURN_ON_ELECTRON_MODE  = 'TURN_ON_ELECTRON_MODE';
export const TOGGLE_ELECTRON_MODE   = 'TOGGLE_ELECTRON_MODE';
export const TOGGLE_START_SIGNAL    = 'TOGGLE_START_SIGNAL';
export const TURN_ON_DEMO_MODE      = 'TURN_ON_DEMO_MODE';
export const TURN_OFF_DEMO_MODE     = 'TURN_OFF_DEMO_MODE';
export const SHOW_MAIN_OVERLAY      = 'SHOW_MAIN_OVERLAY';
export const HIDE_MAIN_OVERLAY      = 'HIDE_MAIN_OVERLAY';

// User Actions

export const AUTHENTICATE      = 'AUTHENTICATE';
export const LOG_OUT           = 'LOG_OUT';
export const UPDATE_USER       = 'UPDATE_USER';
export const CHANGE_ACCOUNT_TYPE = 'CHANGE_ACCOUNT_TYPE';

// Track Actions

export const SAVE_TRACKS       = 'SAVE_TRACKS'
export const UPDATE_TRACK      = 'UPDATE_TRACK'
export const DELETE_TRACK      = 'DELETE_TRACK'
export const APPEND_TRACKS		 = 'APPEND_TRACKS'

// Current Track Actions

export const SAVE_CURRENT_TRACK  = 'SAVE_CURRENT_TRACK'

// UI Actions

export const TOGGLE_PROFILE_FORM_EDITABILITY  = 'TOGGLE_PROFILE_FORM_EDITABILITY'
export const TOGGLE_VIDEO_FORM_EDITABILITY    = 'TOGGLE_VIDEO_FORM_EDITABILITY'
export const TOGGLE_LOADING_STATE             = 'TOGGLE_LOADING_STATE'
export const TOGGLE_SHOW_ALERT			 					= 'TOGGLE_SHOW_ALERT'

// Video Actions

export const TURN_ON_PLAY_MODE      = 'TURN_ON_PLAY_MODE'
export const TURN_ON_RACE_MODE      = 'TURN_ON_RACE_MODE'
export const RESET_MODE             = 'RESET_MODE'
export const TOGGLE_THEATER_MODE    = 'TOGGLE_THEATER_MODE'
export const TURN_ON_THEATER_MODE   = 'TURN_ON_THEATER_MODE'
export const TURN_OFF_THEATER_MODE  = 'TURN_OFF_THEATER_MODE'
export const SHOW_OVERLAY           = 'SHOW_OVERLAY'
export const HIDE_OVERLAY           = 'HIDE_OVERLAY'
export const SHOW_COUNTDOWN_OVERLAY = 'SHOW_COUNTDOWN_OVERLAY'
export const HIDE_COUNTDOWN_OVERLAY = 'HIDE_COUNTDOWN_OVERLAY'
export const SET_PLAYING_STATE      = 'SET_PLAYING_STATE'
export const SET_RECORDING_STATE    = 'SET_RECORDING_STATE'
export const SET_PAUSED_STATE       = 'SET_PAUSED_STATE'
export const SET_PREVIEW_COMPLETE   = 'SET_PREVIEW_COMPLETE'
export const SAVE_RACE_TO_VIEW 			= 'SAVE_RACE_TO_VIEW'
export const CLEAR_RACE_FROM_VIEW   = 'CLEAR_RACE_FROM_VIEW'
export const UPDATE_AUDIO_OVERLAY_MODE 				= 'UPDATE_AUDIO_OVERLAY_MODE'
export const UPDATE_GLOBAL_MUTE_STATUS = 'UPDATE_GLOBAL_MUTE_STATUS'
export const OVERRIDE_DURATION = 'OVERRIDE_DURATION'

//UserOverlay Actions

export const START_AUDIO_RECORDING   = 'START_AUDIO_RECORDING'
export const STOP_AUDIO_RECORDING    = 'STOP_AUDIO_RECORDING'
export const UPDATE_AUDIO_LAYERS			= 'UPDATE_AUDIO_LAYERS'
export const ADD_AUDIO_LAYER 					= 'ADD_AUDIO_LAYER'
export const UPDATE_IN_RANGE 					= 'UPDATE_IN_RANGE'
export const UPDATE_PLAYING_OVERLAY_CURRENTLY = 'UPDATE_PLAYING_OVERLAY_CURRENTLY'




