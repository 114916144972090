import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect }from "react-router-dom";
import Navbar from './navbar.component';
import Footer from './footer.component'
import ScrollToTopOnMount from './scroll-top.component.js'
import {Helmet} from "react-helmet"

class UsaCycling extends Component {

  constructor(props){
    super(props)

  }

  render(){

    if(this.props.demoMode && this.props.electronMode){
      return <Redirect to='demo' />
    }

    return (
      <React.Fragment>

        <ScrollToTopOnMount />

        <Helmet>
          <meta name="Description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
          <meta property="og:url" content="https://vidfitness.com/usacycling"/>
          <meta property="og:title" content="VidFitness - USA Cycling and VidFitness"/>
          <meta property="og:image" content="https://vidfitness.com/vidfitness-screen.jpg"/>
          <meta property="og:description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
          <meta property="og:type" content="website" />
          <meta property="fb:app_id" content="432091207556688"/>
        </Helmet>

        <div>

          <Navbar />
    <br />
          <div className="container">

            <h1 className="center-align">Welcome USA Cycling Members</h1>
            <p>...</p>



                </div>

                  { !this.props.electronMode &&
                    <Footer />
                  }

                </div>


            </React.Fragment>
            )
    }
}

    const mapStateToProps = (state) => {
    return {
    user:         state.user,
    electronMode: state.general.electronMode,
    demoMode:     state.general.demoMode
  }
}

const mapDispatchToProps = (dispatch) => {
return {
}
}

export default connect(mapStateToProps, mapDispatchToProps)(UsaCycling);


