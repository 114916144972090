import React, { useState, useEffect, Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import trophy from '../images/trophy.png'
import { updateUser } from '../actions/userActions'
import {
  BrowserView,
  MobileView,
} from "react-device-detect";

class JoinRemoveChallenge extends Component {
  constructor(props) {
    super(props)

    this.state = {
      registered: false, 
      buttonText: 'Joined',
      loading: true,
      completed: false,
    }

    this.checkIfComplete = this.checkIfComplete.bind(this)
    this.handleJoin = this.handleJoin.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
  }

  componentDidMount() {
    let registered
    if (this.props.userChallenges.map((UC) => UC.challenge_id).includes(this.props.challenge.id)) {
      registered = true
    } else {
      registered = false
    }

    var styleParams = {}

    if (this.props.splash) {
      styleParams['textAlign'] = 'right'
    }

    this.setState({
      registered: registered,
      loading: false,
      styleParams: styleParams,
    })

    this.checkIfComplete(this.props.user.user_challenges)

  }

  checkIfComplete(user_challenges) {
    var UChal = user_challenges.filter((UC) => UC.challenge_id == this.props.challenge.id)
    if (UChal.length > 0) {
      if (UChal[0].complete == true) {
        this.setState({completed: true})
      }
    }
  }

  handleJoin() {
    var payload = {
      user_id: this.props.user.id, 
      challenge_id: this.props.challenge.id
    }
    axios.post((process.env.REACT_APP_SERVER) + '/api/v1/challenge/add-user', payload).then(({ data: user }) => {
      // console.log('THIS IS THE SERVER RETURN USER', user)
      this.props.dispatchUpdateUser(user)

      const { user_challenges } = user

      this.checkIfComplete(user_challenges)

      if (this.props.splash) {
        this.props.refreshCallback(user, this.props.challenge)
      }
    })
  }

  handleRemove() {
    var payload = {
      user_id: this.props.user.id, 
      challenge_id: this.props.challenge.id
    }
    axios.post((process.env.REACT_APP_SERVER) + '/api/v1/challenge/remove-user', payload).then(({ data : user }) => {
      this.props.dispatchUpdateUser(user)
      if (this.props.splash) {
        this.props.refreshCallback(user, this.props.challenge)
      }
    })
  }


  render() {
    return (
      <React.Fragment>
        { !this.state.loading ?
        <div>
          <BrowserView>
            <div className={ this.props.featured ? "join-challenge-splash" : "join-challenge-button" } style={this.state.styleParams}>
              <div>
                {this.state.registered ? 
                  ( this.state.completed ?
                    <button style={{width: '50px', height: '40px'}}
                      // onClick={ () => this.props.history.push(`/challenge/${this.state.id}`)}
                      className="btn btn-warning">
                      <div>
                      <img src={trophy} className="trophy-button" />
                      </div>
                    </button> :
                    <button
                      onMouseOver={(event) => {
                        event.target.classList.remove('btn-light')
                        event.target.classList.add('btn-danger')
                        this.setState({buttonText: 'Leave'})
                      }}
                      onMouseOut={(event) => {
                        event.target.classList.remove('btn-danger')
                        event.target.classList.add('btn-light')
                        this.setState({buttonText: 'Joined'})
                      }}
                      onClick={(event)=> {
                        event.preventDefault()
                        this.handleRemove()
                        this.setState({registered: false})
                      }} 
                      className="btn btn-light">{this.state.buttonText}
                    </button> 
                  ) : 
                <button 
                  onClick={(event)=> {
                    event.preventDefault()
                    this.handleJoin()
                    this.setState({registered: true})
                  }} 
                  className="btn btn-success">Join</button>
                }
              </div> 
            </div>
          </BrowserView>

          <MobileView>
            <div style={{right: '5%'}} className={ this.props.featured ? "join-challenge-splash" : "join-challenge-button" }>
             

                {this.state.registered ? 
                  ( this.state.completed ?
                    <button style={{width: '50px', height: '40px'}}
                      // onClick={ () => this.props.history.push(`/challenge/${this.state.id}`)}
                      className="btn btn-warning">
                      <span>
                      <img src={trophy} className="trophy-button" />
                      </span>
                    </button> :
                    <button
                      onClick={(event)=> {
                        event.preventDefault()
                        this.handleRemove()
                        this.setState({registered: false})
                      }} 
                      className="btn btn-light">Joined
                    </button> 
                  ) : 
                <button 
                  onClick={(event)=> {
                    event.preventDefault()
                    this.handleJoin()
                    this.setState({registered: true})
                  }} 
                  className="btn btn-success">Join</button>
                }

              
            </div>
          </MobileView>
        </div>
        :
        <div>
          <button className="btn btn-info" type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
          </button>
        </div>
        }
      </React.Fragment>
    )
  }


}

const mapStateToProps = (state) => {
  
  return {
    user: state.user,
    userChallenges: state.user.user_challenges
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchUpdateUser: (value) => { dispatch(updateUser(value))},
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JoinRemoveChallenge));