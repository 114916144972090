import React, { Component } from 'react';
import { connect } from 'react-redux'
import store from '../index.js';
import config from '../config.json';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect }from "react-router-dom";
import { Link } from "react-router-dom";
import { Formik } from 'formik';
import { authenticate } from '../actions/userActions';


class LoginEmail extends Component {


  constructor(props){
    super(props);

    this.state = {
      login_error: ''
    }

  }
 
  renderForm(){
    return (
      <div className="container">
        <div className="row">
          <div className="pt-3 col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 text-left">
            <Formik
              initialValues={{ email: '', password: ''}}
              validate={values => {
                let errors = {};

                if(values.email == ''){
                  errors.email = 'Required'
                }

                if(values.password == ''){
                  errors.password = 'Required'
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting, setErrors, setFieldError }) => {
                console.log('form submitted', values)


                const options = {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(values)
                }

                setTimeout(() => {
                  setSubmitting(false);
                }, 400);

                return fetch((process.env.REACT_APP_SERVER)+'/api/v1/email-signin', options)
                  .then((response) => {

                    return response.json()

                  })
                  .then((response) => {

                    console.log('received response', response)

                    if(response.status == 'failure' && response.message == 'user-not-found'){
                      this.setState({
                        login_error: 'Username or password is incorrect.'
                      })
                    }

                    if(response.status == 'failure' && response.message == 'password-incorrect'){
                      this.setState({
                        login_error: 'Username or password is incorrect.'
                      })
                    }

                    if(response.status == 'failure' && response.message == 'email-unverified'){
                      this.setState({
                        login_error: 'Please verify your email address before logging in.'
                      })
                    }

                    if(response.status == 'success'){

                      this.props.dispatchAuthenticate(response.user)
                    }

                  })


                  .catch(error => console.log('Error', error))

              }}
            >
            { ({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                submitForm
                /* and other goodies */
              }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                 <input
                   type="email"
                   name="email"
                   className="form-control"
                   placeholder="Email"
                   onChange={handleChange}
                   onBlur={handleBlur}
                   value={values.email}
                 />
                 <div className="col-sm-12 text-left">
                   <small id="passwordHelp" className="text-danger">
                     {errors.email && touched.email && errors.email}
                   </small>
                 </div>
                </div>

                <div className="form-group">
                   <input
                     type="password"
                     name="password"
                     className="form-control"
                     placeholder="Password"
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values.password}
                   />
                   <div className="col-sm-12 text-left">
                     <small id="passwordHelp" className="text-danger">
                       {errors.password && touched.password && errors.password}
                     </small>
                   </div>
                </div>


                <div id="login-error" className="text-danger">
                  { this.state.login_error }
                </div>

                <br />
                <div className="text-center">
                    <button className='btn btn-outline-secondary back-btn' onClick={ () => this.props.history.goBack() }>
                      Back
                    </button>
                  <button className='btn btn-outline-success sign-up-btn' onClick={ () => submitForm() } disabled={isSubmitting}>
                    Log In
                  </button>
                </div>
                <hr />

                <div className='forgot-password'>
                  <span >
                    <Link to='./password-recovery'> Forgot your password? </Link>
                  </span>
                </div>
              </form>
            )}

            </Formik>
          </div>
        </div>
      </div>
    )


  }


// this redirect applies to non-Gmail users logging in:
  render(){

    if(this.props.user.id != ''){
      return <Redirect to='/featured' />
    }

    return (
      <div className="login-panel">
        <div className="container-fluid container-centered pt-3">
          <div>
            <div className="center-align">
              <Link to="/"><img src="./vidfitness-red.png" className="logo-main"/></Link>
            </div> 
            <div className="row mb-2">
              <div className="col mt-3 mr-4 ml-4 text-center">
                <b>Log in with email:</b>
              </div>
            </div>
            <div className="center-align">
              { this.renderForm() }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    electronMode: state.general.electronMode
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchAuthenticate: (user_info) => { dispatch(authenticate(user_info)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginEmail);
