import React from 'react'

const PrivacyText = () => {

	return (
		<div className="container">

      <h1 className="center-align section-head">Privacy Policy</h1>
			<div>
				<p>Dated: November 10, 2019</p>

				<p>VidFitness is an at home social fitness service. We create virtual experiences, structured workouts, and group events so that you can reach your training goals and interact with other people who use VidFitness. This means that VidFitness is a social platform, and that your profile information and information about your rides, runs, races, and other workouts is shared with other users and, with your permission, shared with companies that allow users to connect with each other on other platforms.</p>

				<p>The privacy of our users is important to us and we have prepared this Privacy Policy to explain to you how we collect, use, and share information we obtain through your use of the websites, applications, virtual cycling and other workout games, and other services (“Services”) that are operated or controlled by VidFitness, Inc. (collectively, “we,” “us” or “our”).</p>

				<p>We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice (such as adding a statement to our homepage or sending you a notification). We encourage you to review the Privacy Policy whenever you access the Services or otherwise interact with us to stay informed about our information practices and the choices available to you.</p>

				<p><b>COLLECTION OF INFORMATION</b></p>
				<p><b>INFORMATION YOU PROVIDE TO US</b></p>
				<p>To work out with VidFitness, you’ll need to create an account. To create an account, we ask you to provide your first and last name, your email address, your country, and information about you such as your date of birth, gender, height, and weight, so that we can accurately calculate information about your workout. We also collect any information you provide to customize your profile, such as a profile picture or avatar. Your profile information, including your name, gender, age, height, and weight, is public, so you should use caution in deciding what name to use.</p>

				<p>If you subscribe to the Services or make a purchase, we collect your payment method and your home address. Finally, we collect any other information you provide to us, such as to sign up for an event, fill out a form, participate in a survey or contest, apply for a job, communicate with us via third party social media sites, or request customer support.</p>

				<p><b>INFORMATION ABOUT YOUR WORKOUTS</b></p>
				<p>When you work out with VidFitness, we collect information about that workout, such as your selection of video, your cadence, your effort, how long you rode or ran, your total output and total calories burned, elevation gain, and, if you are using a heart rate monitor, your heart rate. We also collect information about any events, activities, and fitness test results.</p>

				<p><b>OTHER INFORMATION ABOUT YOUR USE OF THE SERVICES</b></p>
				<p>In the Services, you can connect with other users, give them feedback, and find and follow other users. Whatever you do, we collect and store information about that activity.</p>

				<p><b>AUTOMATICALLY COLLECTED INFORMATION</b></p>
				<p>When you access or use our Services, we automatically collect information about you, including:</p>

				<p>Log Information:  We collect log information about your use of the Services, including the type of browser you use, app version, access times, pages viewed, your IP address and the page you visited before navigating to our Services.
				Device Information:  We collect information about the computer or mobile device you use to access our Services, including the hardware model, operating system and version, unique device identifiers, and mobile network information.
				Information Collected by Cookies and Other Tracking Technologies:  We use different technologies to collect information, including cookies, web beacons, and software development kits or “SDKs.” Cookies are small data files stored on your hard drive or in device memory that help us improve our Services and your experience, see which areas and features of our Services are popular, and count visits. Web beacons are electronic images that may be used in our Services or emails and help deliver cookies, count visits, and understand usage and campaign effectiveness. SDKs operate similarly to cookies and web beacons, but in applications rather than on websites. For more information about cookies and how to disable them, please see "Your Choices" below.</p>

				<p><b>INFORMATION WE COLLECT FROM OTHER SOURCES</b></p>
				<p>If you create or log into your account through a social media site or ask another fitness service to share information with us, we will have access to certain information from that site or service, such as your name, account information, and friends lists, in accordance with the authorization procedures determined by such social media or fitness site or service.</p>

				<p><b>USE OF INFORMATION</b></p>
				<p>We use the information we collect to provide, maintain, and improve our Services, such as to track your workouts, show you information about who else is working out with you, publish information about your workouts to other users, and allow you to connect with and engage with other users. We also use the information we collect to:</p>

				<p>Send you technical notices, updates, security alerts, and support and administrative messages and to respond to your comments, questions, and customer service requests; Communicate with you about products, services, offers, and events offered by VidFitness and others, and provide news and information we think will be of interest to you; Monitor and analyze trends, usage, and activities in connection with our Services; Detect, investigate, and prevent fraudulent transactions and other illegal activities and protect the rights and property of VidFitness and others; Personalize and improve the Services and provide advertisements, content, or features that match user profiles or interests; Facilitate contests, sweepstakes, and promotions and process and deliver entries and rewards; and
				Carry out any other purpose described to you at the time the information was collected.</p>

				<p><b>SHARING OF INFORMATION</b></p>
				<p>Part of the fun of VidFitness is connecting with other users around the world.  So, when you use VidFitness Services, we share information about you with other users. This includes your profile information (including your first and last name, country, gender, height, weight, and age) and information about your workouts (such as what ride or run you took and when, how fast you went, and how you performed). This information is available to other users both while you work out and after, and your workout may be broadcast on a service like Twitch TV or YouTube by VidFitness or by other users. In addition, your posts on message boards and in discussion rooms will be visible to VidFitness and other VidFitness users. We do not share your date of birth or your email address with other users without your consent.</p>

				<p>VidFitness’s Services may also allow you to share your information outside of VidFitness. For instance, you may integrate your VidFitness account with third-party fitness apps such Strava, Fitbit, Garmin, and others. When you integrate with other services in this manner, we will share information in accordance with your interactions and preferences. In addition, you may choose to share your profile and workout information with select VidFitness partners, to enable them to extend your VidFitness experience. When you share information in this way, your information may be publicly available.</p>

				<p>We do not share your information outside of VidFitness and the VidFitness community except in the following circumstances:</p>

				<p>With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf; In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law or legal process, including lawful requests by public authorities to meet national security or law enforcement requirements; If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, and safety of VidFitness or others; and In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company. We may also share aggregated information, which cannot reasonably be used to identify you.</p>

				<p><b>SOCIAL SHARING FEATURES</b></p>
				<p>The Services may offer social sharing features and other integrated tools (such as the Facebook “Like” button), which let you share actions you take on our Services with other media. Your use of such features enables the sharing of information with your friends or the public, depending on the settings you establish with the entity that provides the social sharing feature.  For more information about the purpose and scope of data collection and processing in connection with social sharing features, please visit the privacy policies of the entities that provide these features.</p>

				<p><b>ADVERTISING AND ANALYTICS SERVICES PROVIDED BY OTHERS</b></p>
				<p>We may allow others to provide analytics services and serve advertisements on our behalf across the internet and in applications. These entities may use cookies, web beacons, SDKs, device identifiers, and other technologies to collect information about your use of the Services and other websites and applications, including your IP address, web browser, mobile network information, pages viewed, time spent on pages or in apps, links clicked, and conversion information. This information may be used by VidFitness and others to, among other things, analyze and track data, determine the popularity of certain content, deliver advertising and content targeted to your interests on our Services and other websites and better understand your online activity. For more information about interest-based ads, or to opt out of having your web browsing information used for behavioral advertising purposes, please visit www.aboutads.info/choices, or, if you are in the European Economic Area (“EEA”) (“EEA”), http://www.youronlinechoices.eu/. Your device may also include a feature (“Limit Ad Tracking” on iOS or “Opt Out of Interest-Based Ads” or “Opt Out of Ads Personalization” on Android) that allows you to opt out of having certain information collected through apps used for behavioral advertising purposes.</p>

				<p><b>DATA RETENTION</b></p>
				<p>We store the information we collect about you for as long as is necessary for the purpose(s) for which we originally collected it. We may retain certain information for legitimate business purposes or as required by law.</p>

				<p><b>TRANSFER OF INFORMATION TO THE U.S. AND OTHER COUNTRIES &amp; PRIVACY SHIELD</b></p>
				<p>VidFitness is based in the United States and we process and store information in the United States.  Therefore, we and our service providers may transfer your information to, or store or access it in, jurisdictions that may not provide equivalent levels of data protection as your home jurisdiction.  We will take steps to ensure that your personal data receives an adequate level of protection in the jurisdictions in which we process it.</p>

				<p><b>YOUR CHOICES</b></p>
				<p><b>Account Information</b></p>

				<p>You may update, correct, or delete information about you at any time by logging into your account or emailing us at privacy@vidfitness.com. If you wish to delete or deactivate your account, please contact us at privacy@vidfitness.com and follow the instructions. Note that we may retain certain information as required by law or for legitimate business purposes. We may also retain cached or archived copies of information about you for a certain period of time.</p>

				<p><b>Cookies</b></p>

				<p>Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our Services.</p>

				<p><b>Promotional Communications</b></p>
				<p>You may opt out of receiving promotional emails from VidFitness by following the instructions in those emails. If you opt out, we may still send you non-promotional emails, such as those about your account or updates to our Services.</p>

				<p><b>Mobile Push Notifications/Alerts</b></p>
				<p>With your consent, we may send promotional and non-promotional push notifications or alerts to your mobile device. You can deactivate these messages at any time by changing the notification settings on your mobile device.</p>

				<p><b>CHILDREN’S PRIVACY</b></p>
				<p>We do not knowingly collect information from children and we do not target the Services to children. If you believe a child under 13 (in the United States) or 16 (in the EEA) has submitted personal information to us, please contact us at privacy@vidfitness.com and we will use our best efforts to promptly remove such information from our files.</p>

				<p><b>RESIDENTS OF THE EUROPEAN ECONOMIC AREA</b></p>
				<p>If you are a resident of the European Economic Area, you have certain rights and protections under the law regarding the processing of your personal data.</p>

				<p><b>LEGAL BASIS FOR PROCESSING</b></p>
				<p>If you are a resident of the EEA, when we process your personal data we will only do so in the following situations:</p>

				<p>We have your consent to do so (e.g., sharing your data with others in the VidFitness community to help make our workouts interactive)We need to use your personal data to perform our responsibilities under our contract with you (e.g., processing payments for and providing the VidFitness Services you have requested).We have a legitimate interest in processing your personal data. For example, we may process your personal data to communicate with you about changes to our Services, and to provide, secure, and improve our Services.</p>

				<p><b>DATA SUBJECT REQUESTS</b></p>
				<p>If you are a resident of the EEA, you have the right to access personal data we hold about you and to ask that your personal data be corrected, erased, or transferred. You may also have the right to object to, or request that we restrict, certain processing. If you would like to exercise any of these rights, you can log into your account and edit your profile. Alternatively, you may contact us as indicated below.</p>

				<p><b>QUESTIONS OR COMPLAINTS</b></p>
				<p>If you are a resident of the EEA and have a concern about our processing of personal data that we are not able to resolve, you have the right to lodge a complaint with the data privacy authority where you reside. For contact details of your local Data Protection Authority, please see: Data Protection Authorities.</p>

				<p><b>YOUR CALIFORNIA PRIVACY RIGHTS</b></p>
				<p>California law permits residents of California to request certain details about how their information is shared with third parties for direct marketing purposes. If you are a California resident and would like to make such a request, please contact us at privacy@vidfitness.com.</p>

				<p><b>CONTACT US</b></p>
				<p>If you have any questions about this Privacy Policy, please contact us at privacy@vidfitness.com or:</p>
				<p>59 Elm Street, New Haven, CT USA</p>
			</div>
		</div>
	)

}

export default PrivacyText