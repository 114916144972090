import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ChallengeFinePrint from './challenge-fine-print.component'
import JoinRemoveButtton from './join-leave-challenge.component'
import trophy from '../images/trophy.png'
import axios from 'axios';
import time from '../images/time.png'
import power from '../images/power.png'
import distance from '../images/distance.png'
import racer from '../images/racer.png'
import upload from '../images/upload.png'
var moment = require('moment')

const ChallengesSidebar = ({user_challenges, history}) => {

  // .sort((a,b) => moment(a.createdAt).subtract(moment(b.createdAt)))

  var challengesToDisplay = user_challenges.slice(0,5)

  function getIcon(type){
    let img_src

    switch (type) {
      case 'RACE_BASED':
        img_src = racer
        break;
      case 'DISTANCE_BASED':
        img_src = distance
        break;
      case 'POWER_BASED':
        img_src = power
        break;
      case 'TIME_BASED':
        img_src = time
        break;
      case 'UPLOAD_BASED':
        img_src = upload
      default:
        img_src = racer
        break;
    }

    return img_src
  }

  return (
    <div className="mt-3">
      <ul className="challenge-sidebar-list-container" style={{listStyle: 'none'}}>
        {
          challengesToDisplay.map((UC) =>
            <li
              style={{cursor:'pointer'}}
              className="challenge-sidebar-list truncate-parent"
              onClick={() => history.push(`/challenge/${UC.challenge_id}`)}
              >
              <span>
                <img src={getIcon(UC.challenge_type)} className="trophy-button-sidebar" />
              </span>&nbsp;&nbsp;
              <span className="truncate small" >{UC.title}</span>
          </li>)
        }
        <li
          className="challenge-sidebar-list mt-1 no-wrap"
          onClick={() => history.push(`/challenges`)}
          style={{textAlign:'right', paddingLeft: '0rem!important', cursor:'pointer', position: 'absolute', bottom: '0', right: '0', marginBottom: '25px', marginRight: '15px' }}><small><b>See All <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" style={{ width:'.6rem' }} class="bi bi-chevron-right" viewBox="-3 -3 22 22"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" stroke="black" stroke-width="3"/></svg></b></small></li>
      </ul>
    </div>
  )

}

const mapStateToProps = (state) => {
  const { user_challenges } = state.user

  let signed_in

  if (state.user.id == '') {
    signed_in = false
  } else {
    signed_in = true
  }

  return {
    user: state.user,
    signed_in: signed_in,
    user_challenges: user_challenges
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChallengesSidebar));
