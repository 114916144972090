import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios'
import gold from '../images/gold.png'
import silver from '../images/silver.png'
import bronze from '../images/bronze.png'
import profileplaceholder from '../images/profileplaceholder.jpg'

class ChallengeLeaderboard extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loaded: false
    }

    this.renderUsers = this.renderUsers.bind(this)
    this.imgError = this.imgError.bind(this)
  }

  imgError(ev) {
    ev.target.src = profileplaceholder
    return true
  }

  renderUsers(users, side) {
    const renderRanking = (rank) => {
      if (rank == 1) {
        return (<img style={{backgroundColor: 'white'}} className="challenge-leaderboard-medal" src={gold} />)
      } else if (rank == 2) {
        return (<img style={{backgroundColor: 'white'}} className="challenge-leaderboard-medal" src={silver} />)
      } else if (rank == 3) {
        return (<img style={{backgroundColor: 'white'}} className="challenge-leaderboard-medal" src={bronze} />)
      } else {
        return <span>{rank}</span>
      }
    }

    return (users.map((user) => {
              var data_to_display = 0
              var unit = ''
              const payload = JSON.parse(user.status_data).status

              console.log('PAYLOAD', payload)

              switch (this.props.type) {
                case 'RACE_BASED':
                  data_to_display = payload.average_power
                  unit = 'watts'
                  break;
                case 'DISTANCE_BASED':
                  data_to_display = payload.current_total
                  unit = 'mi'
                  break;
                case 'POWER_BASED':
                  data_to_display = payload.current_total
                  unit = 'watts'
                  break;
                case 'TIME_BASED':
                  data_to_display = payload.current_total
                  unit = "minutes"
                  break;
                case 'UPLOAD_BASED':
                  data_to_display = payload.current_total
                  unit = "videos"
                  break;
                default:
                  data_to_display = payload.current_total
                  break;
              }

              return (<div className={"challenge-leaderboard-card border-top border-bottom" + (side == "left" ? "ml-3" : "mr-3")} style={{position: 'relative'}}>
                  <div style={{display: 'flex'}}>
                    <div className="challenge-leaderboard-image-container mt-2 mb-2 ml-3 mr-2">
                      <img className="challenge-leaderboard-image" src={user.user.photo_url} onError={this.imgError}/>
                    </div>
                    <div style={{display: 'block'}} className="mt-1">
                      <div>{user.user.display_name}</div>
                      <div>{data_to_display ? data_to_display.toFixed(0) : 0} {unit}</div>
                    </div>
                  </div>
                  <div className="challenge-leaderboard-ranking text-center" >
                    {renderRanking(user.ranking)}
                  </div>
                </div>)
              }))
  }

  render() {
    console.log(this.props)
    return (
      <React.Fragment>
          <div style={{borderRadius:'.75rem'}}>
            { this.props.loaded &&
              <React.Fragment>
                <div class="row"><div class="col mt-3 mr-4 ml-4"><small><b>CHALLENGE LEADERBOARD</b></small></div></div>
                <div className="row">
                  <div className="col mb-3 challenge-leaderboard-col-left">
                    <div className="mt-3 ml-3">
                      { this.renderUsers(this.props.first_half_users_for_leaderboard, "left") }
                    </div>
                  </div>
                  <div className="col mb-3 challenge-leaderboard-col-right">
                    <div className="mt-3 mr-3">
                    { this.renderUsers(this.props.second_half_users_for_leaderboard, "right") }
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
            { !this.props.loaded &&
              <div>
              </div>
            }

          </div>
      </React.Fragment>

    )
  }

}

const mapStateToProps = (state) => {

  return {
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChallengeLeaderboard));
