//Standard Imports
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

//utils
import { convertToDoubleDigits, arraysEqual } from '../utils/utils'

//external packages
import { isMobileOnly, isBrowser } from  'react-device-detect'
import videojs from 'video.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PlayerIcon } from 'react-player-controls'

//Components
import ProgressBar from './progress-bar.component';

//Redux
import { showOverlay,
         showCountdownOverlay,
         hideCountdownOverlay,
         turnOnTheaterMode,
         turnOffTheaterMode,
         setPlayingState,
         setRecordingState,
         setPausedState,
         setPreviewComplete,
         clearRaceFromView,
         overrideDuration,
         setGlobalMuteStatus } from '../actions/videoActions'

//images
import gradient from '../images/gradient.png'

class VideoControls extends Component {

	constructor(props) {
		super(props)

		this.state = {

		}

		this.renderPlayModeButtons = this.renderPlayModeButtons.bind(this)
		this.renderRecordModeButtons = this.renderRecordModeButtons.bind(this)
		this.renderVolumeControls = this.renderVolumeControls.bind(this)
		this.renderTime = this.renderTime.bind(this)
		this.getMinutes = this.getMinutes.bind(this)
		this.getSeconds = this.getSeconds.bind(this)
		this.renderSlope = this.renderSlope.bind(this)
		this.renderFullScreenButtons = this.renderFullScreenButtons.bind(this)

	}

	renderTime() {

    var video = videojs('race-video')

    //console.log(video.currentSrc())

    if (this.props.videoRef != null && !Number.isNaN(this.props.currentRace.duration)) {

      let duration;

      //FIX
      //PRANJAL

      if (!this.props.electronMode) {
        if (this.props.currentRace.duration >= 600) {
          duration = 600
        } else {
          duration = this.props.currentRace.duration
        }
      } else {
        duration = this.props.currentRace.duration
      }

      if(duration == NaN) {
        return null
      }

      let time = this.getMinutes(this.props.currentTime) + ':' + this.getSeconds(this.props.currentTime)
      + ' / ' + this.getMinutes(duration) + ':' + this.getSeconds(duration)

      return (
        <span className="video-time"> { time } </span>
      )

    } else {
      return null
    }

  }

  getMinutes(time){
    return convertToDoubleDigits(Math.floor(time / 60));
  }

  getSeconds(time){
    return convertToDoubleDigits(Math.floor(time - this.getMinutes(time) * 60))
  }

  renderSlope(){
    return (
    	<React.Fragment>
      	<span className={"tracker-slope " + (this.props.feedback ? 'disable' : '')}> { this.props.control_store[this.props.currentTime] ? (Math.round(this.props.control_store[this.props.currentTime].slope*10)/10).toFixed(1) : '0.0' }&deg; </span>
      	{ this.props.electronMode &&
          <span onClick={() => {
            if (this.props.feedback) {
              this.props.muteFeedback(false)
            } else {
              this.props.muteFeedback(true)
            }
          }} className={"tracker-slope-gradient " + (this.props.feedback ? 'disable' : '')}><img className="tracker-slope-image"  src={gradient} /></span>
        }
    	</React.Fragment>
    )
  }

	renderPlayModeButtons(){
		return(
		  <React.Fragment>

		    {/* Play Button */}

		    { !this.props.disableTracker && !this.props.video.isRecording && !this.props.video.isPlaying &&
		      <span onClick={this.props.play} className="control-icon play-icon">
		        <FontAwesomeIcon icon={['fa', 'play']}/>
		      </span>
		    }

		    { (this.props.disableTracker || this.props.video.isRecording) &&
		      <span className="control-icon play-icon disabled">
		        <FontAwesomeIcon icon={['fa', 'play']}/>
		      </span>
		    }

		    {/* This is the only case we perform a button replacement*/}
		    { !this.props.video.isRecording && this.props.video.isPlaying &&
		      <span onClick={this.props.pause} className="control-icon pause-icon">
		        <FontAwesomeIcon icon={['fa', 'pause']}/>
		      </span>
		    }

		    {/* Restart Button */}

		    { !this.props.disableTracker && !this.props.video.isRecording && this.props.currentTime != 0 &&
		      <span onClick={this.props.restart} className="control-icon restart-icon">
		        <FontAwesomeIcon icon={['fa', 'fast-backward']}/>
		      </span>
		    }

		    { (this.props.disableTracker || this.props.video.isRecording || this.props.currentTime == 0  ) &&
		      <span className="control-icon restart-icon disabled">
		        <FontAwesomeIcon icon={['fa', 'fast-backward']}/>
		      </span>
		    }

		  </React.Fragment>
		)
	}

	renderVolumeControls(){

    if (!this.props.video.globalMuteStatus) {
      return <div class="invert control-icon" onClick={() => {
        this.props.setMute(true)
      }}><PlayerIcon.SoundOn width={32} height={32} /></div>
    } else {
      return <div class="invert control-icon" onClick={() => {
      	this.props.setMute(false)
      }}><PlayerIcon.SoundOff width={32} height={32} /></div>
    }
    // var player = videojs('race-video')
    // if (player.muted()) {
    //   return <span className="video-time" >Muted</span>
    // } else {
    //   return <span className="video-time">Not Muted</span>
    // }

  }

	renderRecordModeButtons() {


    return(
      <React.Fragment>

        {/* This one is actually a record mode button */}
        { this.props.video.isRecording &&
          <React.Fragment>
            <span onClick={this.props.stop} className="control-icon stop-icon-recording">
              <FontAwesomeIcon icon={['fa', 'stop']}/>
            </span>
          </React.Fragment>
        }

        { !this.props.video.isRecording &&
          <span className="control-icon stop-icon-recording disabled">
            <FontAwesomeIcon icon={['fa', 'stop']}/>
          </span>
        }

      </React.Fragment>
    )
  }

  renderFullScreenButtons() {
  	if (!this.props.video.theater_mode) {
  		return (
				<span className="fullscreen-toggle">
					<FontAwesomeIcon onClick={() => {
            this.props.toggleFull(true)
          }} icon={['fa', 'expand']}/>
				</span>
  		)
  	} else {
  		return (
  			<span className="fullscreen-toggle">
					<FontAwesomeIcon onClick={() => {
            this.props.toggleFull(false)
          }} icon={['fa', 'compress']}/>
				</span>
  		)
  	}
  }


	render() {
		return (
			<React.Fragment>

				{ !isMobileOnly &&

					<div>

						<div className="tracker-wrapper">

								<div className="tracker">

									{ (this.props.video.mode == 'play') &&
							
										<div className="tracker-controls">

											{ this.renderPlayModeButtons() }

										</div>

									}

									{ this.props.video.mode == 'race' &&
				
										<div className="tracker-controls">

										{ this.renderRecordModeButtons() }

										</div>

									}

                  { !this.props.disableTracker && this.props.video.mode != null &&

                    <React.Fragment>
        
                      <div className='vertical-line' />
          
                      <div className="tracker-volume" style={{position: 'relative'}}>
        
                        { this.renderVolumeControls() }
        
                      </div>

                      <div className='vertical-line' />

                    </React.Fragment>

                  }

									{ !this.props.disableTracker && this.props.video.mode != null &&

										<React.Fragment>

											{ this.renderSlope() }

											<div className='vertical-line' />
				
											<div className="tracker-time">
			
												{	this.renderTime() }
			
											</div>
				
											<div className='vertical-line' />
							
											<ProgressBar 
												disable={this.props.disableTracker}
                        currentTime={this.props.currentTime}
                        setTime={this.props.setTime}
											/>

										</React.Fragment>
				
									}

									{ this.renderFullScreenButtons() }

								</div>
				
						</div>

					</div>

				} 

			</React.Fragment>
		)
	}

}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    track: state.currentTrack,
    start_signal: state.general.startSignal,
    video: state.video,
    electronMode: state.general.electronMode || false,
    currentRace: state.video.race
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatchTurnOnTheaterMode: (value)  => { dispatch(turnOnTheaterMode())},
    dispatchTurnOffTheaterMode: (value) => { dispatch(turnOffTheaterMode())},
    dispatchClearRaceFromView:      ()    => {dispatch(clearRaceFromView())},

    dispatchHideCountdownOverlay:  ()     => { dispatch(hideCountdownOverlay()) },
    dispatchShowCountdownOverlay: () => { dispatch(showCountdownOverlay())},
    dispatchOverrideDuration: (value) => { dispatch(overrideDuration(value))},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoControls);