import React, { Component } from 'react';
import { connect } from 'react-redux'
import Navbar from './navbar.component'
import Footer from './footer.component'
import axios from 'axios';
import Track from './track.component'
import { isUserStravaAcessTokenFresh, refreshStravaAccessToken, process_tracks } from '../utils/utils'
import {Helmet} from "react-helmet";
import Skeleton from 'react-loading-skeleton';
import ChallengeFinePrint from './challenge-fine-print.component'
import JoinRemoveChallenge from './join-leave-challenge.component'
import '../styles/challenges.css'
import '../styles/channel.css';
import '../styles/gallery.css'
import ChallengeList from './challenge-list.component'
import FeaturedChallenge from './featured-challenge.component'
var moment = require('moment')

class Challenges extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      canFetch: true,

      active_loaded: false,
      no_active_challenge: false,
      active_challenges: null,

      feature_loaded: false,
      featured_challenge: null,
      feature_exists: false,

      deactivate_loaded: false,
      deactivate_start: 0,
      deactivated_finish: false,
      deactivated_challenges:null,
      deactivated_challenges_count: 0
    };

    this.PAGINATE_SIZE = 6
    this.FETCH_DELAY = 500
    this.handleScroll = this.handleScroll.bind(this)
    this.fetchMoreDeactivatedTracks = this.fetchMoreDeactivatedTracks.bind(this)

  }

  handleScroll() {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
    if (this.state.canFetch && !this.state.deactivated_finish) {
       this.setState({isFetching: true, canFetch: false}, () => this.fetchMoreDeactivatedTracks())
    }
  }

  fetchMoreDeactivatedTracks() {
    if (!this.state.deactivated_finish) {
      var end = this.state.deactivate_start + this.PAGINATE_SIZE
      axios.get((process.env.REACT_APP_SERVER)+'/api/v1/challenge/deactivated/index',
        {
          params: {
            user_id: this.props.user.id,
            start: this.state.deactivate_start,
            paginate: this.PAGINATE_SIZE
        }
      })
      .then(response => {
          const { rows } = response.data

          console.log(rows)

          var fin = this.state.deactivated_finish

          if ( end >= this.state.deactivated_challenges_count ) {
            fin = true
          }

          //FIX INFINITE SCROLL
          this.setState({
            deactivate_start: end,
            deactivated_challenges: this.state.deactivated_challenges.concat(rows),
            deactivated_finish: fin,
            isFetching: false,
          }, () => {
            setTimeout(() => this.setState({ canFetch: true }), this.FETCH_DELAY)
          })
      })
      .catch(function (error){
          console.log(error);
      })
    }
  }

  componentDidMount() {
    try {

      window.addEventListener('scroll', this.handleScroll);

      axios.get((process.env.REACT_APP_SERVER) + '/api/v1/challenge/active').then((resp) => {

        const challenges = resp.data

        var featured_chal = null

        var count_of_active_challenges = challenges.length

        var i;
        var triggered = false;

        //iterate through all challenges, if featured found set featured to challenge, set triggered to true, remove from active challenges array
        for (i = 0; i < count_of_active_challenges; i++) {
          if (challenges[i].featured == true) {

            featured_chal = challenges[i]
            challenges.splice(i, 1)
            triggered = true
            break;

          }
        }

        //if no featured found and the count of the challenges is not zero set featured to first challenge available and remove challenge from challenges
        if (!triggered && count_of_active_challenges != 0) {
          featured_chal = challenges[0]
          challenges.splice(0, 1)
        }

        if (count_of_active_challenges > 1) {
          this.setState({
            active_loaded: true,
            feature_loaded: true,
            featured_challenge: featured_chal,
            active_challenges: challenges,
            feature_exists: true,
          })
        } else if (count_of_active_challenges == 1) {
          this.setState({
            active_loaded: true,
            feature_loaded: true,
            featured_challenge: featured_chal,
            feature_exists: true,
            no_active_challenges: true
          })
        } else {
          this.setState({
            active_loaded: true,
            no_active_challenges: true
          })
        }
      })

      var end = this.state.deactivate_start + this.PAGINATE_SIZE

      axios.get((process.env.REACT_APP_SERVER)+'/api/v1/challenge/deactivated/index',
        {
          params: {
            start: this.state.deactivate_start,
            paginate: this.PAGINATE_SIZE
        }
      }).then(response => {
          const { count , rows } = response.data

          var index = 0


          if (count > 1) {
            this.setState({
              deactivate_start: end,
              deactivated_challenges_count: count,
              deactivated_challenges: rows,
              deactivate_loaded: true,
            })
          } else if (count == 1) {
            this.setState({
              deactivated_finish: true,
              deactivated_challenges: rows,
              deactivate_loaded: true,
            })
          } else {
            this.setState({
              deactivated_finish: true,
              deactivate_loaded: true,
              no_deactivated_challenges: true
            })
          }
      })

    } catch (error) {
      console.log(error)
      this.setState({error: true})
    }

  }

  render() {

    if(this.props.demoMode && this.props.electronMode){
      this.props.history.push('/demo')
    }

    return (

    <React.Fragment>
      <Helmet>
          <meta name="Description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
          <meta property="og:url" content="https://vidfitness.com/activities"/>
          <meta property="og:title" content="VidFitness - Activities of Members"/>
          <meta property="og:image" content="https://vidfitness.com/vidfitness-screen.jpg"/>
          <meta property="og:description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
          <meta property="og:type" content="website" />
          <meta property="fb:app_id" content="432091207556688"/>
      </Helmet>
      <div>
        <Navbar />
        <br />

        <div className="mb-3">
          <div className="mt-0">
            <div>
              { this.state.feature_loaded && this.state.feature_exists &&
                <FeaturedChallenge
                  featuredChallenge={this.state.featured_challenge}
                  userChallenges={this.props.userChallenges}
                />
              }
            </div>
            <div className="more-challenges-box mb-2 ml-4 mr-4">
              { this.state.active_loaded && !this.state.no_active_challenges &&
                <div>
                    <ChallengeList
                      userChallenges={this.props.userChallenges}
                      challenges={this.state.active_challenges}
                      active={true}
                    />
                </div>
              }
            </div>
            {/*
            <div className="more-challenges-box mb-2 ml-4 mr-4">
              { this.state.deactivate_loaded && !this.state.no_deactivated_challenges &&
                <React.Fragment>
                  <div className="mb-3" style={{fontSize:'2rem'}}>
                    <b>Past Challenges</b>
                  </div>
                  <div>
                    <ChallengeList
                      userChallenges={this.props.userChallenges}
                      challenges={this.state.deactivated_challenges}
                      active={false}
                      isFetching={this.state.isFetching}
                      finish={this.state.deactivated_finish}
                    />
                  </div>
                </React.Fragment>
              }
            </div>
          */}
          </div>
          <div>
            { this.state.no_active_challenges && this.state.no_deactivated_challenges &&
            <div className="no-challenges-box mt-5 text-center">
              <h2>There are no challenges right now.</h2>
            </div>
            }
          </div>
        </div>

      { !this.props.electronMode &&
        <Footer />
      }

      </div>
    </React.Fragment>
    )
  }
}


const mapStateToProps = (state) => {
  let signed_in

  if (state.user.id == '' ) {
    signed_in = false
  } else {
    signed_in = true
  }

  return {
    tracks:       state.tracks,
    user:         state.user,
    electronMode: state.general.electronMode,
    demoMode:     state.general.demoMode,
    userChallenges: state.user.user_challenges,
    signed_in: signed_in
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Challenges);
