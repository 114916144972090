import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect }from "react-router-dom";
import HomePage from './homepage.component'
import { loadStripe } from '@stripe/stripe-js';
//note - pranjal - I couldn't figure out how why I wasn't able to read this from the ENV variables but this is the publishable key for the atlas account, if the key changes this key needs to change as well
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);
const querystring = require('querystring');

class CheckoutRedirect extends Component {

	constructor(props){
		super(props)
	}

	async componentDidMount() {
		const stripe = await stripePromise;
    const url = querystring.parse(window.location.href, '?')
    const sessionId = url.session_id
    const { error } = await stripe.redirectToCheckout({sessionId: sessionId});
  }

	render() {
    return null
}

}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutRedirect);
