import React, { Component, useState, useEffect } from 'react';
import { Link, withRouter} from "react-router-dom";
import { connect } from 'react-redux'
import Track from './track.component'
import { deleteTrack } from '../actions/trackActions'
import { BrowserView, MobileOnlyView, TabletView, isMacOs, isWindows, isBrowser, isMobile } from "react-device-detect";
import { refreshUserAsync, updateUser } from '../actions/userActions'
import { whichChallengesToRecalculate, recalculateRankings } from '../utils/utils'
import axios from 'axios';
import scrollDown from '../images/scroll_down.png'
import SetupPage from "../components/setup-page.component.js"
import '../styles/activities.css'
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';

class ActivitiesPlaceholder extends Component {

    // constructor(props) {
    //   super(props)
  //   this.state = {
  //     featurevideotitle1:       'Buttgen Velodrome',
  //     featurevideodescription1: '',
  //     featurevideoduration1:    '12:10',
  //     featurevideothumb1:       'https://vidracing-production-video-optimized.s3.amazonaws.com/1583863880408-vr/thumbs-1583863880408-vr-00001.jpg',
  //     featurevideolink1:        '/video/1583863880408-vr',

  //     featurevideotitle2:       'New Haven Grand Prix',
  //     featurevideodescription2: '',
  //     featurevideoduration2:    '14:35',
  //     featurevideothumb2:       'https://vidracing-production-video-optimized.s3.amazonaws.com/1577484108842-vr/thumbs-1577484108842-vr-00001.jpg',
  //     featurevideolink2:        '/video/1577484108842-vr',

  //     featurevideotitle3:       'Acadia National Park',
  //     featurevideodescription3: '',
  //     featurevideoduration3:    '24:48',
  //     featurevideothumb3:       'https://vidracing-production-video-optimized.s3.amazonaws.com/1599547770727-vr/thumbs-1599547770727-vr-00001.jpg',
  //     featurevideolink3:        '/video/1599547770727-vr',
  //   }
  // }

  // openExternalSetupPage(){
  //   const { shell } = window.require('electron')
  //   shell.openExternal('https://vidfitness.com/setup')
  // }

  render() {
    return (
      <div className="container-fluid">
        <div className="row w-100 my-0 pt-1 pt-sm-2 no-gutters justify-content-center">
          <div className="col-12 mx-auto text-center">
                <h4 className="activities-first-time-heading mx-0 px-0 mt-0 mt-sm-2 mb-0 text-center">
                Track your fitness.
                </h4>
                <br/>

                { this.props.electronMode &&
                  <React.Fragment>
                  <h5>
                    <a class="text-dark" data-toggle="collapse" href="#more" role="button" aria-expanded="false" aria-controls="more">learn more <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/></svg></a>
                  </h5>
                  <div class="collapse" id="more">
                    <div class="card card-body border-1 col-12 col-sm-10 mx-auto text-left pt-3">
                      <h5>
                      If you use an indoor smart trainer or heart rate monitor, <a onClick={ () => { this.openTracker() }}  className="text-dark font-weight-bold" href="#">connect</a> your devices to see your live fitness data as you work out.<br/><br/>On this screen, you'll see your activities, challenges, and fitness performance over time.<br/><br/>And you can share your achievements to Strava to get kudos from friends for that extra motivation.
                      </h5>
                    </div>
                  </div>
                </React.Fragment>                  
                }
                { !this.props.electronMode &&
                <React.Fragment>
                    <MobileOnlyView>
                      <span>
                        <a class="text-dark" data-toggle="collapse" href="#more" role="button" aria-expanded="false" aria-controls="more">learn more <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/></svg></a>
                      </span>
                      <div class="collapse" id="more">
                        <div class="card card-body border-1 col-12 col-sm-10 mx-auto text-left pt-3">
                          <span> 
                          If you use an indoor smart trainer or heart rate monitor, use the VidFitness desktop app to connect your devices and see your live fitness data as you work out.<br/><br/>You can keep track of your activities, challenges, and fitness performance over time.<br/><br/>Then, share your achievements to Strava to get kudos from friends for that extra motivation.
                          </span>
                        </div>
                      </div>
                    </MobileOnlyView>
                    <TabletView>
                      <h5>
                        <a class="text-dark" data-toggle="collapse" href="#more" role="button" aria-expanded="false" aria-controls="more">learn more <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/></svg></a>
                      </h5>
                      <div class="collapse" id="more">
                        { this.props.signed_in &&
                        <div class="card card-body border-1 col-12 col-sm-10 mx-auto text-left pt-3">
                          <h5>
                          If you use an indoor smart trainer or heart rate monitor, use the VidFitness desktop app to connect your devices and see your live fitness data as you work out.<br/><br/>You can keep track of your activities, challenges, and fitness performance over time.<br/><br/>Then, share your achievements to Strava to get kudos from friends for that extra motivation.
                          </h5>
                        </div>
                        }
                        { !this.props.signed_in && 
                        <div class="card card-body border-1 col-12 col-sm-10 col-md-6 mx-auto text-left pt-3">
                        <h5>
                        If you use an indoor smart trainer or heart rate monitor, download the desktop app and connect your devices to see your live fitness data as you work out.<br/><br/>You can keep track of your activities, challenges, and fitness performance over time.<br/><br/>Then, share your achievements to Strava to get kudos from friends for that extra motivation.
                        </h5> 
                        </div>
                        } 
                      </div>
                      </TabletView>                           
                      <BrowserView>
                      <p>
                      <h5>
                        <a class="text-dark" data-toggle="collapse" href="#more" role="button" aria-expanded="false" aria-controls="more">learn more <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/></svg></a>
                      </h5>
                      </p>
                      <div class="collapse" id="more">
                        { this.props.signed_in && 
                        <div class="card card-block card-body border-1 col-12 col-sm-10 mx-auto text-left pt-3">
                          <h5>
                          If you use an indoor smart trainer or heart rate monitor, <a className="lead text-dark font-weight-bold no-wrap" onClick={() => this.props.history.push('/setup')} href={ (isMacOs ? process.env.REACT_APP_INSTALLER_PATH_MAC : process.env.REACT_APP_INSTALLER_PATH_WIN) }>download &#x2913;</a> the desktop app and connect your devices to see your live fitness data as you work out.<br/><br/>You can keep track of your activities, challenges, and fitness performance over time.<br/><br/>Then, share your achievements to Strava to get kudos from friends for that extra motivation.
                          </h5>
                        </div>
                        }
                        { !this.props.signed_in && 
                          <div class="card card-body border-1 col-12 col-sm-10 col-md-6 mx-auto text-left pt-3">
                          <h5>
                          If you use an indoor smart trainer or heart rate monitor, <a className="lead text-dark font-weight-bold no-wrap" onClick={() => this.props.history.push('/setup')} href={ (isMacOs ? process.env.REACT_APP_INSTALLER_PATH_MAC : process.env.REACT_APP_INSTALLER_PATH_WIN) }>download &#x2913;</a> the desktop app and connect your devices to see your live fitness data as you work out.<br/><br/>You can keep track of your activities, challenges, and fitness performance over time.<br/><br/>Then, share your achievements to Strava to get kudos from friends for that extra motivation.
                          </h5>
                        </div>
                        }
                      </div>
                      </BrowserView> 
                </React.Fragment>
                }
                
                <img src="./activities-sample.gif" style={{width:'100%', height:'auto'}} className="pt-5 pt-sm-2 pb-5"/>
                
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let signed_in
  if (state.user.id == '') {
    signed_in = false
  } else {
    signed_in = true
  }

  return {
    user: state.user,
    signed_in: signed_in,
    electronMode: state.general.electronMode,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivitiesPlaceholder));
