import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import { Redirect, withRouter }from "react-router-dom";
import { logOut } from '../actions/userActions';
import { toggleElectronMode } from '../actions/generalActions';
import { toggleShowAlert } from '../actions/uiActions'
import SetupPage from "../components/setup-page.component.js"
import Pricing from "../components/pricing.component.js"
import Switch from "react-switch";
import power from '../images/power.png'
import { BrowserView, MobileView, TabletView, MobileOnlyView                          , isMacOs, isWindows, isBrowser, isMobile } from "react-device-detect";
import profileplaceholder from '../images/profileplaceholder.jpg'
import { isVIP } from '../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var moment = require('moment')

class NavbarMobile extends Component {

	constructor({props,history}){
		super(props)
		this.history = history
		this.openTranscoder = this.openTranscoder.bind(this)
		this.openTracker = this.openTracker.bind(this)
		this.logout = this.logout.bind(this)
		this.nav_alert = this.nav_alert.bind(this)
		this.imgError = this.imgError.bind(this)

    if (isMacOs) { {/* make this isMacOs after updating pkg */}
       this.downloadlink = process.env.REACT_APP_INSTALLER_PATH_MAC
      }

    if (isWindows) { {/* make this isWindows after updating pkg */}
       this.downloadlink = process.env.REACT_APP_INSTALLER_PATH_WIN
      }
     }


    imgError(ev) {
	    ev.target.src = profileplaceholder
	    return true
  	}

	componentDidMount(){

    if (this.props.electronMode) {
	    const { ipcRenderer } = window.require('electron')

    	this.setState({
    		ipcRenderer
    	})
		}

	}

	openTranscoder(){
		this.state.ipcRenderer.send('open-transcoder', {
			user_id: this.props.user.id,
			transcoder_url: ''
		})
	}

	openTracker(){

    this.state.ipcRenderer.send('open-tracker', {
			user_id: this.props.user.id,
			tracker_url: ''
		})

	}

	logout(){
		this.props.dispatchLogOut(this.history, this.props.electronMode);
		if (this.props.electronMode) {
			this.state.ipcRenderer.send('clear-browser-cache', '');
		}
	}

  quit(){

     let electron = window.require('electron');
     let win = electron.remote.getCurrentWindow();

     win.close()

  }

  openExternalSetupPage(){
    const { shell } = window.require('electron')
    shell.openExternal('https://vidfitness.com/setup')
  }

   openExternalPricing(){
    const { shell } = window.require('electron')
    shell.openExternal(`${process.env.REACT_APP_HOSTNAME}/pricing`)
  }

   nav_alert(current_period_end_date){
   	if (this.props.ui.showAlert) {
	  	var then = moment(current_period_end_date)
	  	var ans = then.fromNow()
	  	var account_level = this.props.user.account_level
	  	var account_expired = this.props.user.account_expired
	  	let text
	  	if ((account_level == 'trial')) {
				text = (<div><strong>Your trial will expire {ans}!</strong> To keep working out, <a href="#" onClick={() => this.props.history.push('/pricing')}>subscribe now!</a></div>)
		 	} else if ((account_level == 'payment_failed')) {
		 		text = (<div><strong>It looks like your payment method has expired. </strong> Please visit the pricing page to purchase a new subscription.</div>)
		 	} else if ((account_level == 'cancelled')) {
		 		text = (<div><strong>It looks like you cancelled your subscription. </strong> Please visit the pricing page to purchase a new subscription.</div>)
		 	} else if ((account_level == 'cancelled_period_not_over')){
		 		text = (<div><strong>It looks like you cancelled your subscription. </strong> You will lose access to VidFitness {ans}! Please visit your profile page to reinstate your subscription.</div>)
		 	} else {
		 		text = null
		 	}
	  	if (text == null) {
	  		return <div></div>
	  	} else {
		  	return (<div className="alert alert-info" role="alert" style={{marginBottom:0}}>
								<button type="button" className="close" data-dismiss="alert" onClick={ () => this.props.dispatchToggleShowAlert()}>×</button>
								{text}
							</div>)
	  	}
	  } else {
	  	return (<div></div>)
	  }
  }

  openExternalSetupPage(){
    const { shell } = window.require('electron')
    shell.openExternal(`${process.env.REACT_APP_HOSTNAME}/setup`)
  }

  openExternalPricing(){
    const { shell } = window.require('electron')
    shell.openExternal(`${process.env.REACT_APP_HOSTNAME}/pricing`)
  }

	render(){
		return (

		<React.Fragment>

		{this.nav_alert(this.props.user.current_period_end_date)}

		<nav className="
	    navbar navbar-main
	    navbar-collapse 
	    navbar-default
	    navbar-dark
	    fixed-top 
	    nav-color
	    " 
	    style={{ zIndex:'2000!Important' }}>
	    <Link to="/" className="navbar-brand flex-grow-1">
	    	<img src="../logo-transparent-white-sm.png" style={{height:'45px', marginLeft: '-20px'}}/>
	 		</Link>
		    <button className="navbar-toggler ml-auto mr-3" type="button" data-toggle="collapse" data-target="#vidracing-navbar-mobile" aria-controls="vidracing-navbar-mobile" aria-expanded="false" aria-label="Toggle navigation">
		      <span className="navbar-toggler-icon"></span>
		    </button>
	    <div className="collapse navbar-collapse pr-3" id="vidracing-navbar-mobile">
        { !this.props.electronMode &&
        <ul className="navbar-nav ml-auto mb-4 mb-sm-0">
          <li className="nav-item pt-2">
            <Link to="/about" className="nav-link">About</Link>
          </li>
          <li className="nav-item pt-2">
            <Link to="/setup" className="nav-link">Help</Link>
          </li>
          <li className="nav-item pt-2">
            <Link to="/pricing" className="nav-link">Pricing</Link>
          </li>
          <BrowserView>
	      	<li className="nav-item pt-2">
	          <a className="nav-link" onClick={() => this.props.history.push('/setup')} href={ (isMacOs ? process.env.REACT_APP_INSTALLER_PATH_MAC : process.env.REACT_APP_INSTALLER_PATH_WIN) } role="button">Download</a>
	       	</li>
          </BrowserView>
        </ul>
	      }
	      { this.props.electronMode &&
        <ul className="navbar-nav ml-auto mb-4 mb-sm-0">
	        <li className="nav-item pt-2">
	          <a href='#' onClick={() => { this.openExternalSetupPage() }} className="nav-link">Help</a>
	        </li>
	        <li className="nav-item pt-2">
	          <a href='#' onClick={() => { this.openExternalPricing() }} className="nav-link">Pricing</a>
	        </li>
        </ul>
	      } 
  	  </div>
		</nav>

		<nav className="
	    navbar navbar-main
	    navbar-expand 
	    navbar-default
	    navbar-dark
	    fixed-bottom 
			d-flex justify-content-center
	    nav-color
			navbar-bottom
	    " 
	    style={{ zIndex:'2000!Important' }}>
	    <div className="container-fluid">
        <ul className="navbar-nav mb-0 nav-fill w-100">
          <li className="nav-item pt-2">
          	<Link to="/featured" className="nav-link">
          	<FontAwesomeIcon icon={['fa', 'bolt']} style={{color:'white', padding: '0', margin:'0'}}/><br/>Workouts</Link>
          </li>            
          <li className="nav-item pt-2">
		  			<Link to="/events" className="nav-link">
          	<FontAwesomeIcon icon={['fa', 'calendar']} style={{color:'white', padding: '0', margin:'0'}}/><br/>Events</Link>
          </li>
        	<li className="nav-item pt-2">
        		<Link to="/activities" className="nav-link">
          	<FontAwesomeIcon icon={['fa', 'heartbeat']} style={{color:'white', padding: '0', margin:'0'}}/><br/>Stats</Link>
          </li>
   			  { this.props.guestMode &&
        	<li className="nav-item pt-2">
            <Link to="/login" className="nav-link"> 
            <FontAwesomeIcon icon={['fa', 'user']} style={{color:'white', padding: '0', margin:'0'}}/><br/>Log In</Link>
          </li>
          }
	        { !this.props.guestMode &&
			  	<li className="nav-item dropdown dropup pt-2">
            <Link className="nav-link dropdown-toggle dropdown-profile" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              { this.props.user.photo_url &&	                    
              <img src={ this.props.user.photo_url} onError={this.imgError} style={{ height: '20px',width: '20px', paddingTop:'0px',paddingBottom:'0px', borderRadius: '50%'}}/>
                }
              { !this.props.user.photo_url &&
              <img src={ 'https://picsum.photos/id/314/200/200.jpg'} style={{ height: '20px',width: '20px', paddingTop:'0px',paddingBottom:'0px', borderRadius: '50%'}}/>
              }
            <br/>Profile</Link>
						<div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
              <Link to="/profile" className="dropdown-item">My Profile</Link>
              <Link to="/my-channel" className="dropdown-item">My Channel</Link>
	          	{ (!this.props.electronMode || true) &&
              <Link to="#" onClick={ () => { this.logout()  }} className="dropdown-item">Log Out</Link>
              }
              { this.props.electronMode &&
            	<React.Fragment>
              	<div className="dropdown-divider"></div>
              	<a onClick={ () => { this.openTracker() }} className="dropdown-item" href="#">Connect Device</a>
              	<a onClick={ () => { this.openTranscoder() }} className="dropdown-item" href="#">Upload Videos</a>
                <div className="dropdown-divider"></div>
                <a onClick={ () => { this.quit() }} className="dropdown-item" href="#">Quit App</a>
              </React.Fragment>
              }
		        </div>
		      </li>
	        }
        </ul>
	    </div>
		</nav>
		
		</React.Fragment>

		)
	}
}

// Gets the state -> pass part of it as properties
const mapStateToProps = (state) => {
  const { user, video, ui } = state

  let guestMode = false

  if( user.userId == '' ){
  	guestMode = true
  }

  return {
  	video: video,
    user: user,
    ui: ui,
    VIP: isVIP(state.user),
    electronMode: state.general.electronMode || false,
    guestMode
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  	dispatchLogOut: (history, electronMode) => {
  		dispatch(logOut());
      if(electronMode){
        history.push('/login')
      }
      else{
        history.push('/');
      }

  	},
  	dispatchToggleElectronMode: () => {
  		dispatch(toggleElectronMode());
  	},
  	dispatchToggleShowAlert: () => {
  		dispatch(toggleShowAlert())
  	}
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavbarMobile));
