import * as types from './actionTypes';

export const confirmAgreement = () => {
	return { type: types.CONFIRM_AGREEMENT }
}

export const showTerms = () => {
	return { type: types.SHOW_TERMS }
}

export const saveHostName = (host_name) => {
	return { type: types.SAVE_HOST_NAME, payload: host_name }
}

export const saveServerName = (server_name) => {
	return { type: types.SAVE_SERVER_NAME, payload: server_name }
}

export const turnOnElectronMode = () => {
	return { type: types.TURN_ON_ELECTRON_MODE }
}

export const toggleElectronMode = () => {
	return { type: types.TOGGLE_ELECTRON_MODE }
}

export const toggleStartSignal = () => {
	return { type: types.TOGGLE_START_SIGNAL }
}

export const turnOnDemoMode = () => {
	return { type: types.TURN_ON_DEMO_MODE }
}

export const turnOffDemoMode = () => {
	return { type: types.TURN_OFF_DEMO_MODE }
}

export const showMainOverlay = () => {
  return { type: types.SHOW_MAIN_OVERLAY }
}

export const hideMainOverlay = () => {
  return { type: types.HIDE_MAIN_OVERLAY }
}
