import React, { useState, useEffect, Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios';
import { imgError } from '../utils/utils'
import { Link } from 'react-router-dom'

const ChannelCard = ({history, user}) => {

  function getFirstImageURL(url){
    return process.env.REACT_APP_ELECTRON_VIDEOS_BUCKET + '/' + url + '/thumbs-'+url+'-00001.jpg';
  };

  return (
    <React.Fragment>
      <div onClick={ () => { }} className="gallery-ride-channels col-lg-3 col-md-4 col-sm-6 col-xs-12 p-2">
        <div className="rounded">
          <Link to={ '/channel/' + user.id } className="text-dark">
            <div className="videobox aspect16x9 bg-white rounded">
                <img className="w-100 img-zoom" style={{top: '0', borderRadius:'.75rem'}} src={ getFirstImageURL(user.races[0].url) } alt="" onError={imgError}/>
                <div className="line-clamp-5 p-3">
                  <p className="line-clamp-5 small text-left text-dark">{user.channel_description}</p>
                </div>
            </div>
          </Link>
            <div className="gallery-video-title text-left text-truncate bg-white pt-1 pl-2 pr-2"><b>{user.channel_title}</b>
            </div>
            <div className="text-left bg-white mb-0 pb-0">&nbsp;&nbsp;
            </div>
        </div>
      </div>
    </React.Fragment>
  )

}

const mapStateToProps = (state) => {

  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChannelCard));
