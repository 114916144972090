import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getInfoFromVideoUrl, sleep } from '../utils/utils.js'
import { getS3VideoUrlHlsWebVersion, getS3VideoUrlDashWebVersion, getS3VideoUrlElectronVersion, getS3VideoUrlHlsElectronVersion} from '../utils/utils.js'
import videojs from 'video.js';
import 'video.js/dist/video-js.css'
import {isMobile, BrowserView, MobileView} from 'react-device-detect';

import { toggleTheaterMode } from '../actions/videoActions'

var classNames = require('classnames');

class Video extends Component {

	constructor(props){

		super(props);

		this.state = {
		  	processingVideo: false,
		}

	}

	componentDidMount() {

		if(this.videoNode){
			this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
			}).on('error', (e) => {
			  console.log('The following error occurred:', e);
        this.props.markVideoAsBeingProcessed()
      });
    }
      //added by Vee:
      // document.body.style.backgroundImage = "bg-nav";
      if(!isMobile) {
      document.body.style.paddingTop = "0px";
      }
      // } else {
      //document.body.style.paddingTop = "0px"
      // }
      //
	}

//added by Vee:
componentWillUnmount(){
  //document.body.style.backgroundImage = null;
     document.body.style.paddingTop = null;
 }


  componentDidUpdate(prevProps){

    if(prevProps.onTheaterMode != this.props.onTheaterMode){
      this.player = videojs(this.videoNode, this.props)
    }

  }

	render(){

		const [filename, poster_url] = getInfoFromVideoUrl(this.props.url, 1)

		var actualVideoClass = classNames({
		  'full-width-video-container': this.props.onTheaterMode
		})

    var newVideoClasses = classNames({
        'vjs-fill': !this.props.onTheaterMode,
        'vjs-16-9': !this.props.onTheaterMode,
        'video-js': !this.props.onTheaterMode,
        'theater-mode' : this.props.onTheaterMode,
        'not-theater-mode' : !this.props.onTheaterMode
    })

    var wrapperClasses = classNames({
      'video-wrapper':true,
      'full-height': this.props.onTheaterMode
    })

    // if(this.props)



    if(this.props.electronMode == true){

      if(this.props.processingVideo == true){
        return (
          <div className="image">
            <div className="wrap">
              <video id="race-video" autoPlay loop muted>
                 <source  src={'https://vidracing-production-video-optimized.s3.amazonaws.com/processing.mp4'} type="video/mp4" />
              </video>
            </div>
          </div>
        )
      }
      else{
        return (
          <div className="image">
            <div className="wrap">
              <video ref={ node => this.videoNode = node } className={actualVideoClass} id="race-video">
                 <source src={getS3VideoUrlElectronVersion(this.props.url)} poster={poster_url} type="application/dash+xml" />
              </video>
            </div>
          </div>
        )
      }

    }
    else{
      return (
        <React.Fragment>
          <div className={wrapperClasses}>

            <div className={newVideoClasses}>
              { this.props.processingVideo == false &&
                <video ref={ node => this.videoNode = node } id="race-video" poster={poster_url} >
                    { this.props.electronMode == false &&
                      <Fragment >
                        <source src={getS3VideoUrlHlsWebVersion(this.props.url)}  type="application/x-mpegURL" />
                        <source src={getS3VideoUrlDashWebVersion(this.props.url)} type="application/dash+xml" />
                      </Fragment>
                    }
                </video>
              }

              { this.props.processingVideo == true &&
                <video ref={ node => this.videoNode = node } id="race-video" autoPlay loop muted>
                  <Fragment >
                    <source  src={'https://vidracing-production-video-optimized.s3.amazonaws.com/processing.mp4'} type="video/mp4" />
                  </Fragment>
                </video>
              }
            </div>
          </div>
        </React.Fragment>
      )
    }

	}

}

const mapStateToProps = (state) => {

  return {
    electronMode: state.general.electronMode || false,
    video: state.video
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Video);
