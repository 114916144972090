import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Navbar from './navbar.component';
import HomePage from './homepage.component'
import Footer from './footer.component'
import axios from 'axios';
import queryString from 'query-string'
import { Redirect }from "react-router-dom";
import Waveform from './waveform.component'
import ProfileStatusWrapper from './profile-status-wrapper.component'
import {
  isMacOs,
  isWindows
} from "react-device-detect";
import { whichChallengesToRecalculate, recalculateRankings } from '../utils/utils'
import { updateUser } from '../actions/userActions'
var moment = require('moment')

class Sandbox extends Component {

  constructor(props) {
    super(props);
    console.log(props)
    // this.handleClick1 = this.handleClick1.bind(this)
    // this.handleClick2 = this.handleClick2.bind(this)
    // this.handleClick3 = this.handleClick3.bind(this)
    this.handleFeatured = this.handleFeatured.bind(this)
    this.handleCreateCoupon = this.handleCreateCoupon.bind(this)
    this.handleCreateChallenge = this.handleCreateChallenge.bind(this)

    this.state = {
        featuredText: '',
        couponSuccess: false,
        couponError: true
    }
  }

  componentDidMount() {

    if (!(this.props.user.email == 'pranjalsingh@college.harvard.edu' || this.props.user.email == 'vee@vidfitness.com' )) {
        this.props.history.push('/')
    }

  }

  handleFeatured() {
    var server_url = process.env.REACT_APP_SERVER
    axios.post((server_url) + `/api/v1/challenge/set-featured/${this.state.featuredText}`).then(({data}) => {
      if (data.status == 'success') {
        this.setState({
          featuredSuccess: true
        })
      } else {
        this.setState({
          featuredError: true
        })
      }             
    })
  }

  handleCreateCoupon() {
    var payload = {
        coupon_type: 'account_level',
        start_date: moment(this.state.couponStartDate, "MM-DD-YYYY").toDate(),
        end_date: moment(this.state.couponEndDate, "MM-DD-YYYY").toDate(),
        code: this.state.couponCode,
        grants: {
            account_level: 'core',
            time_to_grant: [this.state.couponTime, this.state.couponTimeIdentifier]
        },
        max_entries: this.state.couponMaxEntries
    }

    axios.post((process.env.REACT_APP_SERVER) + '/api/v1/coupon/create', payload).then(({data}) => {
      if (data.status == 'success') {
        this.setState({
          couponSuccess: true
        })
      } else {
        this.setState({
          couponError: true
        })
      }
    })


  }

  handleCreateChallenge() {

    try {
      var type;
      var goal_payload;

      switch(this.state.newChallengeType) {
        case 'race':
          type = "RACE_BASED"
          var subset = this.state.newChallengeVideos.trim().split(',')
           goal_payload = {
              goal: subset.length,
              video_subset: subset
           }
          break;
        case 'power':
          type = "RACE_BASED"
          goal_payload = {
              goal: this.state.challengeGoal
           }
          break;
        case 'ride':
          type = "RIDE_BASED"
          goal_payload = {
              goal: this.state.challengeGoal
          }
          break;
        case 'time':
          type = "TIME_BASED"
          goal_payload = {
              goal: this.state.challengeGoal
           }
          break;
        case 'upload':
          type = "UPLOAD_BASED"
          goal_payload = {
              goal: this.state.challengeGoal
           }
          break;
        default:
          console.log('You messed up.')
      }

      if (type === undefined || goal_payload === undefined) {
        throw new Error('Check your inputs.')
      }

      var payload = {
        title: this.state.challengeTitle,
        description: this.state.challengeDescription,
        start_date: moment(this.state.challengeStartDate, "MM-DD-YYYY").toDate(),
        end_date: moment(this.state.challengeEndDate, "MM-DD-YYYY").toDate(),
        active: false,
        challenge_type: 'RACE_BASED',
        image_url: '',
        goal_payload: goal_payload, 
        featured: false
      }

      axios.post((process.env.REACT_APP_SERVER) + '/api/v1/coupon/create', payload).then(({data}) => {
      if (data.status == 'success') {
        this.setState({
          challengeSuccess: true
        })
      } else {
        this.setState({
          challengeError: true
        })
      }
      })
    } catch(error) {
      this.setState({
        challengeError: true
      })

      console.log(error)
    }

    
  }



  render() {

    //vf_core20 - 1 month trial
    //vf_comp20 - 1 year trial


    

    return (
      

      <div>
        <Navbar />
          <div className="row">
            <div className="col">
              <div className="text-danger mb-3">
                  <h1>Enter a Challenge - THIS IS NOT DONE, DO NOT USE</h1>
              </div>

              <div style={{display: 'flex', flexFlow: 'column wrap', alignItems: 'center'}}>
                <input style={{width: '100%'}} class="form-control form-control-sm" type="text" placeholder="Enter code." id="newChallengeType" value={this.state.newChallengeType} onChange={(event) => {
                  this.setState({
                    newChallengeType: event.target.value
                  })
                }}/>
                <label className="mb-3" for="newChallengeType">
                  <div>Enter a type of Challenge, use the following key words, MAINTAIN CASE:</div>
                    <div>"race" = race_based</div>
                    <div>"power" = power_based</div>
                    <div>"ride" = ride_based</div>
                    <div>"upload" = upload_based</div>
                    <div>"time" = time_based</div>
                </label>
              </div>

              <div style={{display: 'flex', flexFlow: 'column wrap', alignItems: 'center'}}>
                <input style={{width: '100%'}} class="form-control form-control-sm" type="text" placeholder="Enter a Goal for the Challenge" id="challengeGoal" value={this.state.challengeGoal} onChange={(event) => {
                  this.setState({
                    challengeGoal: event.target.value
                  })
                }}/>
                <label className="mb-3" for="challengeGoal">Input an integer goal for the challenge (1000W, 10Rides, 5 Uploads, etc.) LEAVE BLANK IF RACE_BASED!</label>
              </div>

              <div style={{display: 'flex', flexFlow: 'column wrap', alignItems: 'center'}}>
                <input style={{width: '100%'}} class="form-control form-control-sm" type="text" placeholder="Enter Videos, SEE INSTRUCTIONS BELOW" id="newChallengeVideos" value={this.state.newChallengeVideos} onChange={(event) => {
                  this.setState({
                    newChallengeVideos: event.target.value
                  })
                }}/>
                <label className="mb-3" for="newChallengeVideos">
                  <div>If this is a race based challenge enter a the videos you want to add, SEPERATED BY COMMAS (NO SPACES) ex: "1601047871442-vr,1600875967171-vr,1600557096144-vr"</div>
                </label>
              </div>

              <div style={{display: 'flex', flexFlow: 'column wrap', alignItems: 'center'}}>
                <input style={{width: '100%'}} class="form-control form-control-sm" type="text" placeholder="Enter the start date" id="challengeStartDate" value={this.state.challengeStartDate} onChange={(event) => {
                  this.setState({
                    challengeStartDate: event.target.value
                  })
                }}/>
                <label className="mb-3" for="challengeStartDate">Input Start Date in the format "MM-DD-YYYY"</label>       
              </div>

              <div style={{display: 'flex', flexFlow: 'column wrap', alignItems: 'center'}}>
                <input style={{width: '100%'}} class="form-control form-control-sm" type="text" placeholder="Enter the end date" id="challengeEndDate" value={this.state.challengeEndDate} onChange={(event) => {
                  this.setState({
                    challengeEndDate: event.target.value
                  })
                }}/>
                <label className="mb-3" for="challengeEndDate">Input End Date in the format "MM-DD-YYYY"</label>
              </div>

              <div style={{display: 'flex', flexFlow: 'column wrap', alignItems: 'center'}}>
                <input style={{width: '100%'}} class="form-control form-control-sm" type="text" placeholder="Enter a description" id="challengeDescription" value={this.state.challengeDescription} onChange={(event) => {
                  this.setState({
                    challengeDescription: event.target.value
                  })
                }}/>
                <label className="mb-3" for="challengeDescription">Input End Date in the format "MM-DD-YYYY"</label>
              </div>

              <div style={{display: 'flex', flexFlow: 'column wrap', alignItems: 'center'}}>
                <input style={{width: '100%'}} class="form-control form-control-sm" type="text" placeholder="Enter the title for the Challenge" id="challengeTitle" value={this.state.challengeTitle} onChange={(event) => {
                  this.setState({
                    challengeTitle: event.target.value
                  })
                }}/>
                <label className="mb-3" for="challengeTitle">Input End Date in the format "MM-DD-YYYY"</label>
              </div>

              <div style={{display: 'flex', flexFlow: 'column wrap', alignItems: 'center'}}>
                <input style={{width: '100%'}} class="form-control form-control-sm" type="text" placeholder="Enter an optional image url" id="challengeImage" value={this.state.challengeImage} onChange={(event) => {
                  this.setState({
                    challengeImage: event.target.value
                  })
                }}/>
                <label className="mb-3" for="challengeImage">Define a custom image url, if no image is included whill just take the thumb from the first video inputted</label>
              </div>

              <button onClick={this.handleCreateChallenge} style={{verticalAlign: 'top', marginBottom: 0, paddingTop: 0, paddingBottom: 0}} type="button" className="mt-2 ml-2 btn-success btn-sm">
                    Submit
              </button>

              <div>
                {this.state.challengeSuccess &&
                  <div className="text-success">Congrats! Created</div>
                }
                {this.state.challengeError &&
                  <div className="text-danger">That did not work.</div>
                }
              </div>


            </div>
            <div className="col">
              <div className="text-center" style={{display: 'flex', flexFlow: 'column wrap', alignItems: 'center'}}>
                <div className="text-success mb-3">
                  <h1>Enter a Coupon - automatically grants to core</h1>
                </div>

                <div style={{display: 'flex', flexFlow: 'column wrap', alignItems: 'center'}}>
                  <input style={{width: '100%'}} class="form-control form-control-sm" type="text" placeholder="Enter code." id="coupon_text" value={this.state.couponCode} onChange={(event) => {
                    this.setState({
                      couponCode: event.target.value
                    })
                  }}/>
                  <label className="mb-3" for="coupon_text">Coupon text</label>
                </div>
                <div style={{display: 'flex', flexFlow: 'column wrap', alignItems: 'center'}}>
                  <input style={{width: '100%'}} class="form-control form-control-sm" type="text" placeholder="Enter the max number of entires" id="couponMaxEntries" value={this.state.couponMaxEntries} onChange={(event) => {
                    this.setState({
                      couponMaxEntries: event.target.value
                    })
                  }}/>
                  <label className="mb-3" for="couponMaxEntriese">Input the maximum entires as an integer</label>
                </div>
                <div style={{display: 'flex', flexFlow: 'column wrap', alignItems: 'center'}}>
                  <input style={{width: '100%'}} class="form-control form-control-sm" type="text" placeholder="Enter the start date" id="couponStartDate" value={this.state.couponStartDate} onChange={(event) => {
                    this.setState({
                      couponStartDate: event.target.value
                    })
                  }}/>
                  <label className="mb-3" for="couponStartDate">Input Start Date in the format "MM-DD-YYYY"</label>       
                </div>
                <div style={{display: 'flex', flexFlow: 'column wrap', alignItems: 'center'}}>
                  <input style={{width: '100%'}} class="form-control form-control-sm" type="text" placeholder="Enter the end date" id="couponEndDate" value={this.state.couponEndDate} onChange={(event) => {
                    this.setState({
                      couponEndDate: event.target.value
                    })
                  }}/>
                  <label className="mb-3" for="couponEndDate">Input End Date in the format "MM-DD-YYYY"</label>
                </div>
                <div style={{display: 'flex', flexFlow: 'column wrap', alignItems: 'center'}}>
                  <input style={{width: '100%'}} class="form-control form-control-sm" type="text" placeholder="Enter the time Idenitifer" id="time_identifier" value={this.state.couponTimeIdentifier} onChange={(event) => {
                    this.setState({
                      couponTimeIdentifier: event.target.value
                    })
                  }}/>
                  <label className="mb-3" for="time_identifier">Time Idenitifer, years y,
                                                              quarters  Q,
                                                              months  M,
                                                              weeks w,
                                                              days  d,
                                                              hours h,
                                                              minutes m,
                                                              seconds s,
                                                              milliseconds  ms</label>
                </div> 
                <div style={{display: 'flex', flexFlow: 'column wrap', alignItems: 'center'}}>                                             
                  <input style={{width: '100%'}} class="form-control form-control-sm" type="text" placeholder="Enter the amount of time the coupon grants" id="time_amount" value={this.state.couponTime} onChange={(event) => {
                    this.setState({
                      couponTime: event.target.value
                    })
                  }}/>
                  <label className="mb-3" for="time_amount">Amount of Time in number</label>
                </div>
                

                <button onClick={this.handleCreateCoupon} style={{verticalAlign: 'top', marginBottom: 0, paddingTop: 0, paddingBottom: 0}} type="button" className="mt-2 ml-2 btn-success btn-sm">
                    Submit
                </button>

                <div>
                  {this.state.couponSuccess &&
                    <div className="text-success">Congrats! Created</div>
                  }
                  {this.state.couponError &&
                    <div className="text-danger">That did not work.</div>
                  }
                </div>

              </div>
            </div>
          </div>
          <div className="text-center mt-5" style={{display: 'flex', flexFlow: 'column wrap', alignItems: 'center'}}>
            <div>
              Set A featured Challenge and Hit submit, only put in a single integer
            </div>
            <input style={{width: '50%'}} class="form-control form-control-sm" type="text" placeholder="Enter code." value={this.state.featuredText} onChange={(event) => {
              this.setState({
                featuredText: event.target.value
              })
            }}/>
            <button onClick={this.handleFeatured} style={{verticalAlign: 'top', marginBottom: 0, paddingTop: 0, paddingBottom: 0}} type="button" className="mt-2 ml-2 btn-success btn-sm">
                Submit
            </button>

            <div>
                {this.state.featuredSuccess &&
                  <div className="text-success">Congrats! Created</div>
                }
                {this.state.featuredError &&
                  <div className="text-danger">That did not work.</div>
                }
              </div>
          </div>
        <Footer />
      </div>


    )
  }
}

const mapStateToProps = (state) => {
  const user = state.user

  return {
    user: user,
    electronMode: state.general.electronMode || false,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchUpdateUser: (newUser) => { dispatch(updateUser(newUser))}
  }
}

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(Sandbox));



// async handleClick1(){

//     //RACE CHALLENGE
//     var now = new Date()
//     var previous = moment(now).subtract(16, 'd')
//     var upcoming = moment(now).subtract(8, 'd')
//     var payloadChallengeOne = {
//       title: 'The USA Cycling Medley',
//       description: 'Start off with a short segment of the 2019 New Haven Gran Prix to get the feel for a crit! Then experience the 2019 Mansfield Hollow CX in Connecticut, and then the Castelli Road Race in New York! Get ready to ride!',
//       start_date: previous.toString(),
//       end_date: upcoming.toString(),
//       active: true,
//       challenge_type: 'RACE_BASED',
//       image_url: '',
//       goal_payload: {
//         goal: 3,
//         video_subset: [
//             "1577484108842-vr",
//             "1571925659264-vr",
//             "1572211608175-vr",
//         ]
//       }, 
//       featured: true
//     }

//     var payloadChallengeTwo = {
//       title: 'The Yorkshire Scenic Challenge',
//       description: 'A sampling of short videos from Rick\'s Scenic UK Rides channel. Enjoy gorgeous countryside, with short bit of voiceover narration to highlight a couple of thousand-year old historical sites that appear in these videos!',
//       start_date: previous.toString(),
//       end_date: upcoming.toString(),
//       active: true,
//       challenge_type: 'RACE_BASED',
//       image_url: '',
//       goal_payload: {
//         goal: 3,
//         video_subset: [
//             "1576130048998-vr",
//             "1588731187126-vr",
//             "1591117737655-vr",
//         ]
//       }, 
//       featured: true
//     }

//     var payloadChallengeThree = {
//       title: 'The Gateway Cup Crits',
//       description: 'Ride exciting footage from four days of the Pro Men\'s 2019 Gateway Cup with Justin McQuerry, complete with a downpour on Day 1 and a small crash on Day 4! Get ready to ride!',
//       start_date: previous.toString(),
//       end_date: upcoming.toString(),
//       active: true,
//       challenge_type: 'RACE_BASED',
//       image_url: '',
//       goal_payload: {
//         goal: 4,
//         video_subset: [
//             "1597372031417-vr",
//             "1597386946727-vr",
//             "1597436351710-vr",
//             "1597771971136-vr"
//         ]
//       }, 
//       featured: true
//     }

//     var payloadChallengeFour = {
//       title: 'Ride the Velodrome',
//       description: 'Ever wanted to ride an indoor track? Learn the basics of track racing, warm up with a couple of short rides, then start a longer race!',
//       start_date: previous.toString(),
//       end_date: upcoming.toString(),
//       active: true,
//       challenge_type: 'RACE_BASED',
//       image_url: '',
//       goal_payload: {
//         goal: 3,
//         video_subset: [
//             "1599758747267-vr",
//             "1583863880408-vr",
//             "1599601280575-vr",        ]
//       }, 
//       featured: true
//     }

//     var payloadChallengeFive = {
//       title: 'Acadia National Park Challenge',
//       description: 'Bike the carriage roads at Acadia National Park in Maine, USA for some amazing views in this three-part challenge! Starts off at the trail access at the far end of the Hulls Cove Visitor Center parking lot and meanders through Mount Desert Island, riding past ponds, lakes, and views of the Atlantic ocean beyond!',
//       start_date: previous.toString(),
//       end_date: upcoming.toString(),
//       active: true,
//       challenge_type: 'RACE_BASED',
//       image_url: '',
//       goal_payload: {
//         goal: 3,
//         video_subset: [
//             "1599547770727-vr",
//             "1599630560916-vr",
//             "1599720402457-vr", 
//       ]
//       }, 
//       featured: true
//     }

//     var payloadChallengeSix = {
//       title: 'The Welsh Tour',
//       description: 'An amazing tour of the Wales countryside, featuring hills and valleys, forests, streams, and lakes, gorgeous views! Watch out for the sheep and dog in Part 4!',
//       start_date: previous.toString(),
//       end_date: upcoming.toString(),
//       active: true,
//       challenge_type: 'RACE_BASED',
//       image_url: '',
//       goal_payload: {
//         goal: 4,
//         video_subset: [
//             "1599876310651-vr",
//             "1599955544962-vr",
//             "1599675970932-vr", 
//             "1599683248811-vr",
//       ]
//       }, 
//       featured: true
//     }

//     var payloadChallengeUpload = {
//       title: 'The Upload Challenge',
//       description: 'Share videos of your awesome cycling adventures with other members! Upload four videos to Vidfitness to complete this challenge. And after completing this challenge, keep uploadingto reach the top of the leaderboard and be crowned KOM -- King of the Movies!',
//       start_date: previous.toString(),
//       end_date: upcoming.toString(),
//       active: true,
//       challenge_type: 'UPLOAD_BASED',
//       image_url: '',
//       goal_payload: {
//         goal: 4
//       }, 
//       featured: true
//     }


//     var server_url = process.env.REACT_APP_SERVER
    
//     axios.post((server_url) + '/api/v1/challenge/create', payloadChallengeOne).then((response) => {
//         axios.post((server_url) + '/api/v1/challenge/create', payloadChallengeTwo).then((response) => {
//             axios.post((server_url) + '/api/v1/challenge/create', payloadChallengeThree).then((response) => {
//                 axios.post((server_url) + '/api/v1/challenge/create', payloadChallengeFour).then((response) => {
//                     axios.post((server_url) + '/api/v1/challenge/create', payloadChallengeFive).then((response) => {
//                         axios.post((server_url) + '/api/v1/challenge/create', payloadChallengeSix).then((response) => {
//                             axios.post((server_url) + '/api/v1/challenge/create', payloadChallengeUpload).then((response) => {
    
//                             })
//                         })
//                     })
//                 })
//             })
//         })
//     })



//   }

//   async handleClick2() {
//     var payload1 = {
//         coupon_type: 'account_level',
//         start_date: moment('01-01-2020', "MM-DD-YYYY").toDate(),
//         end_date: moment('12-31-2030', "MM-DD-YYYY").toDate(),
//         code: 'usacyclingcore',
//         grants: {
//             account_level: 'core',
//             time_to_grant: [5, 'y']
//         },
//         max_entries: 1000000
//     }

//     var payload2 = {
//         coupon_type: 'account_level',
//         start_date: moment('09-30-2020', "MM-DD-YYYY").toDate(),
//         end_date: moment('12-31-2030', "MM-DD-YYYY").toDate(),
//         code: '4starcore',
//         grants: {
//             account_level: 'core',
//             time_to_grant: [1, 'M']
//         },
//         max_entries: 20
//     }

//     var payload3 = {
//         coupon_type: 'account_level',
//         start_date: moment('09-30-2020', "MM-DD-YYYY").toDate(),
//         end_date: moment('12-31-2030', "MM-DD-YYYY").toDate(),
//         code: '4starcomp',
//         grants: {
//             account_level: 'core',
//             time_to_grant: [1, 'y']
//         },
//         max_entries: 5
//     }



//     axios.post((process.env.REACT_APP_SERVER) + '/api/v1/coupon/create', payload1).then(({data}) => {
//         axios.post((process.env.REACT_APP_SERVER) + '/api/v1/coupon/create', payload2).then(({data}) => {
//             axios.post((process.env.REACT_APP_SERVER) + '/api/v1/coupon/create', payload3).then(({data}) => {

//             })
//         })
//     })


    
//   }

//    async handleClick3() {
//     var payloadChallenge1 = {
//       title: 'The Yorkshire Scenic Challenge',
//       description: 'A sampling of short videos from Rick\'s Scenic UK Rides channel. Enjoy gorgeous countryside, with short bit of voiceover narration to highlight a couple of thousand-year old historical sites that appear in these videos!',
//       start_date: moment('09-27-2020', "MM-DD-YYYY").toDate(),
//       end_date: moment('10-03-2020', "MM-DD-YYYY").toDate(),
//       active: false,
//       challenge_type: 'RACE_BASED',
//       image_url: '',
//       goal_payload: {
//         goal: 3,
//         video_subset: [
//             "1576130048998-vr",
//             "1588731187126-vr",
//             "1591117737655-vr",        
//         ]
//       }, 
//       featured: true
//     }

//     var payloadChallenge2 = {
//       title: 'Farmington Canal Trail Mix',
//       description: 'Ride the Farmington Canal Heritage Trial, an 81-mile linear recreational path that leads north from New Haven, CT to Northampton, MA (USA). This challenge features three 1/2-hour segments: one beginning in New Haven, one beginning in northern Hamden, and one beginning in Cheshire that heads south. Enjoy a lot of lush green along the way and a Great Blue Heron that makes a surprise landing (in the Hamden clip at 18:40)! This trail is part of 3000 miles of trails running through 15 states from Maine to Florida, supported by the East Coast Greenway Alliance: https://www.greenway.org',
//       start_date: moment('10-01-2020', "MM-DD-YYYY").toDate(),
//       end_date: moment('10-31-2020', "MM-DD-YYYY").toDate(),
//       active: false,
//       challenge_type: 'RACE_BASED',
//       image_url: '',
//       goal_payload: {
//         goal: 3,
//         video_subset: [
//             "1572056008595-vr",
//             "1589187357421-vr",
//             "1593241340858-vr", 
//       ]
//       }, 
//       featured: true
//     }

//     var payloadChallenge3 = {
//       title: 'The Swiss Challenge',
//       description: 'Ride around the Valais region of Switzerland, across hills, the Rhone River, and to the headquarters, training center, and velodrome for the Union Cycliste Internationale (UCI) in Aigle.',
//       start_date: moment('10-01-2020', "MM-DD-YYYY").toDate(),
//       end_date: moment('10-31-2020', "MM-DD-YYYY").toDate(),
//       active: true,
//       challenge_type: 'RACE_BASED',
//       image_url: '',
//       goal_payload: {
//         goal: 3,
//         video_subset: [
//             "1600394322733-vr",
//             "1600374759941-vr",
//             "1600391169343-vr", 
//       ]
//       }, 
//       featured: true
//     }

//     var payloadChallengeUpload = {
//       title: 'October Upload Challenge',
//       description: 'Share videos of your awesome cycling adventures with other members! Upload four videos to Vidfitness to complete this challenge. And after completing this challenge, keep uploadingto reach the top of the leaderboard and be crowned KOM -- King of the Movies!',
//       start_date: moment('10-01-2020', "MM-DD-YYYY").toDate(),
//       end_date: moment('10-31-2020', "MM-DD-YYYY").toDate(),
//       active: true,
//       challenge_type: 'UPLOAD_BASED',
//       image_url: '',
//       goal_payload: {
//         goal: 4
//       }, 
//       featured: true
//     }


//     var server_url = process.env.REACT_APP_SERVER
    
//     axios.post((server_url) + '/api/v1/challenge/create', payloadChallenge1).then((response) => {
//         axios.post((server_url) + '/api/v1/challenge/create', payloadChallenge2).then((response) => {
//             axios.post((server_url) + '/api/v1/challenge/create', payloadChallenge3).then((response) => {
//                 axios.post((server_url) + '/api/v1/challenge/create', payloadChallengeUpload).then((response) => {
                    
//                 })
//             })
//         })
//     })


    
//   }


