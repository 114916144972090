import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Redirect }from "react-router-dom";
import Navbar from './navbar.component';
import Footer from './footer.component';
import { BrowserView, MobileView, isMacOs, isWindows, isBrowser, isMobile } from "react-device-detect";
import ScrollToTopOnMount from './scroll-top.component.js'
import {Helmet} from "react-helmet";

import Carousel from "react-multi-carousel";
// import HoverVideoPlayer from 'react-hover-video-player';
import "react-multi-carousel/lib/styles.css";
import "../styles/homepage-override.css";

import HomeCarousel from "./homecarousel.component.js"

const queryString = require('query-string');

class Featured extends Component {

  render(){

    if(this.props.demoMode && this.props.electronMode){
      return <Redirect to='demo' />
    }

    return (
      <React.Fragment>
        <ScrollToTopOnMount />

        <Helmet>
          <meta name="Description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
          <meta property="og:url" content="https://vidfitness.com/about"/>
          <meta property="og:title" content="VidFitness - Featured Training"/>
          <meta property="og:image" content="https://vidfitness.com/vidfitness-screen.jpg"/>
          <meta property="og:description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
          <meta property="og:type" content="website" />
          <meta property="fb:app_id" content="432091207556688"/>
        </Helmet>

        <div>
          <Navbar />

          <HomeCarousel />

          { !this.props.electronMode &&
            <Footer />
          }

        </div>
      </React.Fragment>
    )
  }
}

    const mapStateToProps = (state) => {
    return {
    user:         state.user,
    electronMode: state.general.electronMode,
    demoMode:     state.general.demoMode
  }
}

const mapDispatchToProps = (dispatch) => {
return {
}
}

export default connect(mapStateToProps, mapDispatchToProps)(Featured);

