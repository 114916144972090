// React
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

// Main App
import App from './App';

// Redux
import configureStore from './store/configureStore';
import { Provider } from 'react-redux'

// Global Styles
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.min.js';

// Icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import {
 faUser,
 faCalendar,
 faWrench,
 faUpload,
 faPlusSquare,
 faPlus,
 faCertificate, 
 faBolt,
 faHeartbeat,
 faPlayCircle,
 faFastBackward,
 faPlay,
 faStop,
 faPause,
 faArrowAltFromLeft,
 faCircle,
 faBicycle,
 faIndex,
 faExpand,
 faEdit,
 faTrash,
 faCheck,
 faTimes,
 faCompress,
 faEnvelope,
 faEnvelopeOpen,
} from '@fortawesome/free-solid-svg-icons'

// Adds them to library
library.add(faFacebookSquare)
library.add(faUser)
library.add(faCalendar)
library.add(faWrench)
library.add(faTrash)
library.add(faUpload)
library.add(faPlusSquare)
library.add(faPlus)
library.add(faCertificate)
library.add(faBolt)
library.add(faHeartbeat)
library.add(faPlayCircle)
library.add(faFastBackward)
library.add(faPlay)
library.add(faStop)
library.add(faPause)
library.add(faCircle)
library.add(faBicycle)
library.add(faExpand)
library.add(faEdit)
library.add(faTrash)
library.add(faCheck)
library.add(faTimes)
library.add(faCompress)
library.add(faEnvelope)
library.add(faEnvelopeOpen)

const store = configureStore();

if (process.env.REACT_APP_DEBUG_MODE === 'production') {
  console.log = function () {};
}

ReactDOM.render(
	(
		<Provider store={store}>
			<App />
		</Provider>
	)
	, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Allows store access from the console
window.store = store;

export default store;

