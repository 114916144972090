// React Dependencies
import React, { Component } from 'react';
import { withRouter }from "react-router-dom";
import { connect } from 'react-redux'
//import IdleTimer from 'react-idle-timer'
import axios from 'axios'

// Styles
import '../App.css';
import '../styles/video.css'
import '../styles/recording.css'

// Components
import Navbar from './navbar.component';
import Video from './video.component';
import Tracker from './tracker.component';
import Footer from './footer.component';
import VideoBottom from './video-bottom.component'
import RecordingStatus from './recording-status.component'

// Redux Actions
import { saveCurrentTrack } from '../actions/currentTrackActions'
import { turnOffDemoMode } from '../actions/generalActions'
import {
  resetMode,
  turnOnTheaterMode,
  turnOffTheaterMode,
  enterRaceMode,
  setPlayingState,
  setRecordingState,
  setPausedState,
  enterPlayMode,
  showCountdownOverlay,
  showOverlay,
  hideOverlay, 
  saveRaceToView,
  clearRaceFromView,
  updateAudioOverlayMode,
  setGlobalMuteStatus,
  overrideDuration
} from '../actions/videoActions'

import {
  updateAudioLayers
} from '../actions/overlayActions'

import videojs from 'video.js';

import { isMobile, isTablet, BrowserView, MobileView, isMobileOnly } from 'react-device-detect';

import { getInfoFromVideoUrl } from '../utils/utils.js'

import { Helmet } from "react-helmet";

import { FontAwesomeIcon }   from '@fortawesome/react-fontawesome'

import { Jumbotron, Button } from 'react-bootstrap'

// Other dependencies
import _ from 'lodash';

var classNames = require('classnames');

class VideoScreen extends Component {

  constructor(props){

    super(props);

    // Set bindings
    this.markVideoAsBeingProcessed = this.markVideoAsBeingProcessed.bind(this)
    this.refreshVoiceovers       = this.refreshVoiceovers.bind(this)
    this.refreshRace               = this.refreshRace.bind(this)

    this.lastUpdateDate = new Date();

    this.state = {
      isVideoBeingProcessed: false,
      race: {},
      video_url: this.props.match.params.id,
      socket: null,
      video: null,
      demo_mode: false,
      overlayMessage: '',
      loaded: false,
      overlaysLoaded: false,
      autoStart: false,
    }

  }

  async componentDidMount(){

    window.scrollTo(0, 0)
    //added by Vee:
    document.body.style.paddingTop = "0px";

    if(this.props.history.location.state && this.props.history.location.state.autoStart == true){
      this.props.dispatchEnterRaceMode(false)
      this.props.dispatchTurnOnTheaterMode();

      if (this.props.electronMode) {
        let electron = null
        let win = null
        win.setFullScreen(true);
      };
    }

    // Reset mode
    this.props.dispatchResetMode()

    try {

      var race = await this.refreshRace()

      this.props.dispatchShowOverlay()

      var layers = await this.refreshVoiceovers(race)

      this.setState({
          race,
          layers,
          loaded: true,
          owner: (this.props.user.id == race.user_id)
      })

    } catch (err) {
      console.log(err)
      this.markVideoAsBeingProcessed()
    }

      // if (!this.props.electronMode && !this.state.isVideoBeingProcessed) {
      //   this.props.dispatchEnterPlayMode()
      //   this.state.video.pause()
      //   this.props.dispatchSetPausedState(true)
      // }

  }

  async refreshRace() {
     try {

      var { data : race } = await axios.get((process.env.REACT_APP_SERVER) + '/api/v1/race/' + this.state.video_url)

      if(race == null){
        throw new Error('race id was not found!')
      }

      this.props.dispatchSaveRaceToView(race)

      return race

    } catch (err) {

      console.log(err)
      this.markVideoAsBeingProcessed()

    }
  }

  async refreshVoiceovers (race) {

    var { data: overlays } = await axios.get((process.env.REACT_APP_SERVER) + '/api/v1/overlay/for-video/' + race.id  )

    if (overlays.length > 0) {

      const payloads = overlays.map((layer) => {

        var parsed;

        if (layer.peaks_data == null) {

          parsed = null

        } else {

          parsed = JSON.parse(layer.peaks_data)

          parsed.data = parsed.data.map(Number)

        }

        const payload = {
          type: 'SAVED',
          url: layer.url,
          owner_id: layer.owner_id,
          video_metadata: race,
          start_index: layer.start_index,
          duration: layer.duration,
          end_index: (layer.start_index + layer.duration),
          timestamp: layer.timestamp,
          peaks_data: parsed,
          id: (layer.id != -1 ? layer.id : 0),
        }
          
        return payload

      })

      this.props.dispatchUpdateAudioLayers(payloads) 

      return payloads

    } else {

      this.props.dispatchUpdateAudioLayers([]) 

      return []

    }
  }


  shouldComponentUpdate(nextProps, nextState) {

    if (this.state.isVideoBeingProcessed != nextState.isVideoBeingProcessed){
      return true
    }

    if (this.state.race.title != nextState.race.title){
      return true
    }

    if (this.state.race.description != nextState.race.description){
      return true
    }

    if (this.props.countdown_overlay_visible != nextProps.countdown_overlay_visible){
      return true
    }

    if (this.props.video.theater_mode != nextProps.video.theater_mode){
      return true
    }

    if (this.props.appDemoMode == false && nextProps.appDemoMode == true){
      // Do not allow app demo mode while this component is rendered
      console.info('AVOIDING APP DEMO MODE TO BE TURNED ON WHILE ON A VIDEO')
      this.props.dispatchTurnOffDemoMode()
      return false
    }

    // Throttles updates, allowing updates only once every 500ms
    const now = new Date();
    var miliseconds = (now.getTime() - this.lastUpdateDate.getTime());
    return miliseconds >= 300;
  }

  whyDidComponentUpdate(prevProps, prevState){
    // Object.entries(this.props).forEach(([key, val]) => {
    //   prevProps[key] !== val && console.log(`Video Screen re-rendered because Prop '${key}' changed`, val)
    // }
    // );
    // if (this.state) {
    //   Object.entries(this.state).forEach(([key, val]) =>
    //     prevState[key] !== val  && console.log(`Video Screen re-rendered because State '${key}' changed`)
    //   );
    // }
  }

  componentDidUpdate(prevProps, prevState){

    //this.whyDidComponentUpdate(prevProps, prevState)

    if (!isMobile) {
      this.lastUpdateDate = new Date();
    }

  }

  componentWillUnmount(){

    this.setState({ demo_mode: false })
    this.props.dispatchSetPlayingState(false)
    this.props.dispatchHideOverlay()
    this.props.dispatchTurnOffDemoMode()
    this.props.dispatchUpdateAudioOverlayMode(false)
    this.props.dispatchUpdateAudioLayers([])
    this.props.dispatchSetGlobalMuteStatus(false)
    this.props.dispatchClearRaceFromView()
    this.props.dispatchOverrideDuration({
      status: false,
      newduration: 0
    })

    var player = videojs('race-video');
    if (player) {
      player.dispose();
    }

  }

  markVideoAsBeingProcessed(){
    this.setState({ isVideoBeingProcessed: true})
  }

  render (){
      
    //console.log('THEATER MODE', this.props.theater_mode)

    if(this.state == null){
      return null
    }

    var videoClass = classNames({
      'theater-video-container': this.props.video.theater_mode,
      'video-container': !this.props.video.theater_mode && this.props.electronMode
    });

    return (

      <React.Fragment>
      <Helmet>
        <meta property="og:url" content={'https://vidfitness.com/video/'+this.state.video_url}/>
        <meta property="og:title" content={this.state.race.title}/>
        <meta property="og:image" content={ getInfoFromVideoUrl(this.state.video_url,1,process.env.VIDEOS_BUCKET_URL)[1] }/>
        <meta property="og:description" content={this.state.race.description}/>
        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content="432091207556688"/>
      </Helmet>

        { !this.props.video.theater_mode &&
          <React.Fragment>
            <Navbar />
          </React.Fragment>
        }

        <div className={videoClass} style={{position: 'relative'}}>

          <RecordingStatus />

          { this.state.isVideoBeingProcessed &&
             <Video processingVideo={true}
                    markVideoAsBeingProcessed={ () => {} }
             />
          }

          { !this.state.isVideoBeingProcessed &&
             <Video onTheaterMode={this.props.video.theater_mode}
                    url={ this.state.video_url }
                    markVideoAsBeingProcessed={ this.markVideoAsBeingProcessed }
                    processingVideo={false}
                    metadata = {this.state.race}
             />
          }

          { this.state.loaded &&
          	<Tracker disableTracker        = { this.state.isVideoBeingProcessed }
                  	video_url             = { this.state.video_url   }
                  	onTheaterMode         = { this.props.video.theater_mode }
                  	demo_mode             = { this.state.demo_mode   }
                   	metadata              = { this.state.race }
            />
          }

        </div>

        { this.state.loaded &&
          <VideoBottom 
            updateRace={(values) => {
              this.setState({
                race: values
              })
            }}
            race={this.state.race} 
            refreshRace={this.refreshRace}
          />
        }

        { !this.props.electronMode && !this.props.video.theater_mode &&
          <Footer />
        }

      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {

  const { user } = state

  let guestMode = false

  if( user.userId == '' ){
    guestMode = true
  }

  return {
    user: user,
    electronMode: state.general.electronMode,
    countdown_overlay_visible: state.video.countdown_overlay_visible,
    guestMode: guestMode,
    video: state.video,
    appDemoMode: state.general.demoMode,
    overlay: state.overlay,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatchSaveCurrentTrack:  (track)     => { dispatch(saveCurrentTrack(track)) },
    dispatchSetPlayingState:   (value)    => { dispatch(setPlayingState(value))},
    dispatchSetRecordingState: (value)    => { dispatch(setRecordingState(value))},
    dispatchSetPausedState:    (value)    => { dispatch(setPausedState(value))},
    dispatchTurnOnTheaterMode: (value)    => { dispatch(turnOnTheaterMode())},
    dispatchTurnOffTheaterMode:(value)    => { dispatch(turnOffTheaterMode())},
    dispatchTurnOffDemoMode:   ()         => { dispatch(turnOffDemoMode()) },
    dispatchEnterPlayMode:     ()         => { dispatch(enterPlayMode()) },
    dispatchEnterRaceMode:     (start_signal)  => { dispatch(enterRaceMode(start_signal)) },
    dispatchResetMode:         ()         => { dispatch(resetMode())},
    dispatchHideOverlay:       ()         => { dispatch(hideOverlay())},
    dispatchShowOverlay:     (message)    => { dispatch(showOverlay(message)) },
    dispatchShowCountdownOverlay:  ()     => { dispatch(showCountdownOverlay()) },
    dispatchSaveRaceToView:        (race) => { dispatch(saveRaceToView(race))},
    dispatchUpdateAudioOverlayMode:         (value)   => { dispatch(updateAudioOverlayMode(value))},
    dispatchUpdateAudioLayers: (value)       => {dispatch(updateAudioLayers(value))},
    dispatchSetGlobalMuteStatus: (value) => { dispatch(setGlobalMuteStatus(value))},
    dispatchOverrideDuration: (value) => { dispatch(overrideDuration(value))},
    dispatchClearRaceFromView:      ()    => {dispatch(clearRaceFromView())},
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VideoScreen))
