import React, { Component } from 'react';
import '../styles/progress-bar.css'
import videojs from 'video.js';
import { connect } from 'react-redux'
import { Redirect, withRouter }from "react-router-dom";
import { 
		setPlayingState,
	   } from '../actions/videoActions'

class ProgressBar extends Component {

	constructor(props){
		super(props)

		this.handleProgress = this.handleProgress.bind(this)
		this.scrub          = this.scrub.bind(this)

		this.state = {
		}
	}

	componentDidMount(){

		var player = videojs('race-video')

		var timed;

		if (!this.props.electronMode) {
			if (this.props.currentRace.duration < 600) {
				timed = this.props.currentRace.duration
			} else {
				timed = 600
			}
		} else {
			if (isNaN(player.duration())) {
				timed = this.props.currentRace.duration
			} else {
				timed = player.duration()
			}
		}


		this.setState({
			video: player,
			progress:  document.querySelector(".progress"),
			progressBar: document.querySelector(".progress__filled"),
			duration: timed
		}, () => {

			this.state.progress.addEventListener('click', this.scrub)
			this.state.video.on('timeupdate', this.handleProgress);

		})
	}


	handleProgress(){
		const percent = (this.props.currentTime / this.state.duration) * 100
    	this.state.progressBar.style.flexBasis = `${percent}%`
	}

	scrub(e){
		
		if (this.props.video.mode != 'race') {

			this.props.dispatchSetPlayingState(true)

			this.state.video.pause()

			const scrubTime = (e.offsetX / this.state.progress.offsetWidth) * this.state.duration;
			this.props.setTime(scrubTime);

			this.state.video.play()

		}
	}

	render(){
		return (
			<div className="progress">
        		<div className="progress__filled"></div>
  			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    track: state.currentTrack,
    visible: state.video.overlay.visible,
    message: state.video.overlay.message,
    video: state.video,
    electronMode: state.general.electronMode,
    currentRace: state.video.race
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  	dispatchSetPlayingState:   (value)  => { dispatch(setPlayingState(value))},
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProgressBar))