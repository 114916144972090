import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import racer from '../images/racer.png'
import calender from '../images/calender.png'
import info from '../images/info.png'
import trophy from '../images/trophy.png'
import time from '../images/time.png'
import power from '../images/power.png'
import distance from '../images/distance.png'
import upload from '../images/upload.png'
import '../styles/video.css'
import '../styles/challenges.css'
import truncate from 'lodash/truncate';
import { formatPower, formatTime, formatDistance } from '../utils/utils'
var moment = require('moment')

const ChallengeFinePrint = ({ challenge, registeredUser = false, status_data = null, videosWatched = [], nonSplash = false, headliner = false, history}) => {
	const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)
  const [show, setShow] = useState(false)

  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  function updateDimensions(){
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  };

  function renderDescription(text){
    if(width < 992){
      return text
    } else {
      return truncate(text, {
        'length': 350,
        'separator': ' '
      })
    }
  }


  function numberWithCommas(x) {
    if (x == null || x === undefined) {
      return 'NAN'
    } else {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  function getGoalText(type, goal_payload) {
		var goal = goal_payload.goal

		var payload = {
			descriptionOfGoal: '',
			completion: '',
      img_src: null
		}

		switch (type) {
			case 'RACE_BASED':
				payload.descriptionOfGoal = `Ride all ${goal} videos.`
        payload.img_src = racer
        break;
			case 'DISTANCE_BASED':
				payload.descriptionOfGoal = `Ride ${formatDistance(goal).toFixed(0)} miles.`
        payload.img_src = distance
        break;
			case 'POWER_BASED':
				payload.descriptionOfGoal = `Average ${formatPower(goal).toFixed(0)} watts.`
        payload.img_src = power
			  break;
      case 'TIME_BASED':
			  payload.descriptionOfGoal = `Ride for ${formatTime(goal).toFixed(0)} minutes. `
        payload.img_src = time
			  break;
      case 'UPLOAD_BASED':
        payload.descriptionOfGoal = `Upload ${goal} videos. `
        payload.img_src = upload
        break;
      default:
				payload.descriptionOfGoal = `Ride all ${goal} videos.`
        payload.img_src = racer
        break;
		}

    if (registeredUser) {
      switch (type) {
        case 'RACE_BASED':
          payload.completion = `${videosWatched.length} out of ${goal_payload.video_subset.length} rides.`
          break;
        case 'DISTANCE_BASED':
          payload.completion = `${formatDistance(status_data.status.current_total).toFixed(2)} out of ${formatDistance(goal).toFixed(0)} miles.`
          break;
        case 'POWER_BASED':
          payload.completion = `${formatPower(status_data.status.current_total).toFixed(2)} out of ${formatPower(goal).toFixed(0)} watts.`
          break;
        case 'TIME_BASED':
          payload.completion = `${formatTime(status_data.status.current_total).toFixed(0)} out of ${formatTime(goal).toFixed(0)} minutes.`
          break;
        case 'UPLOAD_BASED':
          payload.completion = `${(status_data.status.current_total)} out of ${goal} videos.`
          break;
        default:
          payload.completion = `${videosWatched.length.current_total} out of ${goal_payload.video_subset.length} rides.`
          break;
      }
    }

		return payload
	}

  const goal_payload = JSON.parse(challenge.goal_payload)
  const { descriptionOfGoal, completion, img_src } = getGoalText(challenge.challenge_type, goal_payload)

	return (
		<div className="fine-print-box mt-2 ml-4 mr-4 mb-0">
			{ nonSplash && !headliner &&
  			  <div className="mt-0 pb-0 title-fine-print">
            <b><h3 className="truncate" style={{cursor: 'pointer'}} onClick={() => history.push(`/challenge/${challenge.id}`)}>{challenge.title}</h3></b>
          </div>
  		}
  		<div className="description-box mt-0">
  			<img src={calender} className="image-fine-print" />&nbsp;&nbsp;&nbsp;
  			<p>{moment(challenge.start_date).format("MMM D 'YY")} to {moment(challenge.end_date).format("MMM D 'YY")}</p>
  		</div>
  		{/*<div className="description-box mt-1">
  			<FontAwesomeIcon disabled className='trash-icon' icon={['fa', 'upload']} onClick={ () => console.log('Clicked!!') }/>&nbsp;&nbsp;
  			{challenge.participants > 0 ?
                  <p className="mt-2">{challenge.participants} {challenge.participants == 1 ? "participant" : "participants"}.</p>
                  : <div></div>}
  		</div>*/}
  		<div className="description-box mt-0">
  			<img src={img_src} className="image-fine-print" />&nbsp;&nbsp;&nbsp;
  			<p>{renderDescription(descriptionOfGoal)}</p>
  		</div>
  		{ registeredUser  && !nonSplash &&
  		<div className="description-box mt-0">
  			<img src={trophy} className="image-fine-print" />&nbsp;&nbsp;&nbsp;
  			<p>{completion}</p>
  		</div>
  		}
  		{ nonSplash &&
  			<div className="description-box mt-0 pb-0">
          <img src={info} className="image-fine-print" />&nbsp;&nbsp;&nbsp;
          {/* If not on splash page, if not the featured challenge clamp the description*/}
          <span>
            <p id={challenge.id} className={"fine-print-description-truncate " +( nonSplash ? (headliner ? "" : (show ? "" : "line-clamp-2")) : "")}>
              {renderDescription(challenge.description)}
            </p>
            {nonSplash &&
              <p className="show-hide-button pb-3 text-right" style={{cursor: 'pointer'}} onClick={() => {
                //setShow(!show)
                history.push(`/challenge/${challenge.id}`)
              }}><b>{show ? "Hide" : ( <span className="no-wrap challenges-detail-link">Details<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" style={{ width:'1rem' }} class="bi bi-chevron-right" viewBox="-3 -3 22 22"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" stroke="black" stroke-width="3"/></svg></span> ) }</b>
              </p>
            }
          </span>
        </div>
  		}
  	</div>
	)
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChallengeFinePrint));
