import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import { Redirect, withRouter }from "react-router-dom";
import { logOut } from '../actions/userActions';
import { toggleElectronMode } from '../actions/generalActions';
import { toggleShowAlert } from '../actions/uiActions'
import SetupPage from "../components/setup-page.component.js"
import Pricing from "../components/pricing.component.js"
import NavbarMobile from "../components/navbar-mobile.component.js"
import NavbarNonMobile from "../components/navbar-non-mobile.component.js"
import Switch from "react-switch";
import power from '../images/power.png'
import { BrowserView, MobileView, TabletView, MobileOnlyView, isMacOs, isWindows, isBrowser, isMobile } from "react-device-detect";
import profileplaceholder from '../images/profileplaceholder.jpg'
import { isVIP } from '../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var moment = require('moment')

class Navbar extends Component {

	constructor({props,history}){
		super(props)
		this.history = history
		this.openTranscoder = this.openTranscoder.bind(this)
		this.openTracker = this.openTracker.bind(this)
		this.logout = this.logout.bind(this)
		this.nav_alert = this.nav_alert.bind(this)
		this.imgError = this.imgError.bind(this)

    if (isMacOs) { {/* make this isMacOs after updating pkg */}
       this.downloadlink = process.env.REACT_APP_INSTALLER_PATH_MAC
      }

    if (isWindows) { {/* make this isWindows after updating pkg */}
       this.downloadlink = process.env.REACT_APP_INSTALLER_PATH_WIN
      }
     }


    imgError(ev) {
	    ev.target.src = profileplaceholder
	    return true
  	}

	componentDidMount(){

    if (this.props.electronMode) {
	    const { ipcRenderer } = window.require('electron')

    	this.setState({
    		ipcRenderer
    	})
		}

	}

	openTranscoder(){
		this.state.ipcRenderer.send('open-transcoder', {
			user_id: this.props.user.id,
			transcoder_url: ''
		})
	}

	openTracker(){

    this.state.ipcRenderer.send('open-tracker', {
			user_id: this.props.user.id,
			tracker_url: ''
		})

	}

	logout(){
		this.props.dispatchLogOut(this.history, this.props.electronMode);
		if (this.props.electronMode) {
			this.state.ipcRenderer.send('clear-browser-cache', '');
		}
	}

  quit(){

     let electron = window.require('electron');
     let win = electron.remote.getCurrentWindow();

     win.close()

  }

  openExternalSetupPage(){
    const { shell } = window.require('electron')
    shell.openExternal('https://vidfitness.com/setup')
  }

   openExternalPricing(){
    const { shell } = window.require('electron')
    shell.openExternal(`${process.env.REACT_APP_HOSTNAME}/pricing`)
  }

   nav_alert(current_period_end_date){
   	if (this.props.ui.showAlert) {
	  	var then = moment(current_period_end_date)
	  	var ans = then.fromNow()
	  	var account_level = this.props.user.account_level
	  	var account_expired = this.props.user.account_expired
	  	let text
	  	if ((account_level == 'trial')) {
				text = (<div><strong>Your trial will expire {ans}!</strong> To keep working out, <a href="#" onClick={() => this.props.history.push('/pricing')}>subscribe now!</a></div>)
		 	} else if ((account_level == 'payment_failed')) {
		 		text = (<div><strong>It looks like your payment method has expired. </strong> Please visit the pricing page to purchase a new subscription.</div>)
		 	} else if ((account_level == 'cancelled')) {
		 		text = (<div><strong>It looks like you cancelled your subscription. </strong> Please visit the pricing page to purchase a new subscription.</div>)
		 	} else if ((account_level == 'cancelled_period_not_over')){
		 		text = (<div><strong>It looks like you cancelled your subscription. </strong> You will lose access to VidFitness {ans}! Please visit your profile page to reinstate your subscription.</div>)
		 	} else {
		 		text = null
		 	}
	  	if (text == null) {
	  		return <div></div>
	  	} else {
		  	return (<div className="alert alert-info" role="alert" style={{marginBottom:0}}>
								<button type="button" className="close" data-dismiss="alert" onClick={ () => this.props.dispatchToggleShowAlert()}>×</button>
								{text}
							</div>)
	  	}
	  } else {
	  	return (<div></div>)
	  }
  }

  openExternalSetupPage(){
    const { shell } = window.require('electron')
    shell.openExternal(`${process.env.REACT_APP_HOSTNAME}/setup`)
  }

  openExternalPricing(){
    const { shell } = window.require('electron')
    shell.openExternal(`${process.env.REACT_APP_HOSTNAME}/pricing`)
  }

	render(){
		return (


	<React.Fragment>
		

		<MobileOnlyView>

			<NavbarMobile/>
	
		</MobileOnlyView>


		<TabletView>

			<NavbarNonMobile/>

		</TabletView>


		<BrowserView>

			<NavbarNonMobile/>

		</BrowserView>


	</React.Fragment>

		)
	}
}

// Gets the state -> pass part of it as properties
const mapStateToProps = (state) => {
  const { user, video, ui } = state

  let guestMode = false

  if( user.userId == '' ){
  	guestMode = true
  }

  return {
  	video: video,
    user: user,
    ui: ui,
    VIP: isVIP(state.user),
    electronMode: state.general.electronMode || false,
    guestMode
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  	dispatchLogOut: (history, electronMode) => {
  		dispatch(logOut());
      if(electronMode){
        history.push('/login')
      }
      else{
        history.push('/');
      }

  	},
  	dispatchToggleElectronMode: () => {
  		dispatch(toggleElectronMode());
  	},
  	dispatchToggleShowAlert: () => {
  		dispatch(toggleShowAlert())
  	}
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
