import React, { Component, createRef } from 'react';
import { withRouter }from "react-router-dom";
import { connect } from 'react-redux';
import WaveSurfer from 'wavesurfer.js';
import { PlayerIcon } from 'react-player-controls'

import videojs from 'video.js';

import Waveform from './waveform.component'
//import WaveformTest from './waveform-test.component'

import '../styles/waveform.css';

class WaveformContainer extends Component { 

  componentDidMount(){
  }

  constructor(props) {
    super(props)

    this.fancyTimeFormat = this.fancyTimeFormat.bind(this)
    this.createKey      = this.createKey.bind(this)
  }

  createKey(naieve_key){
    var naieve_key_string = naieve_key.split("")
    var naieve_key_without_decimals = naieve_key_string.filter((el) => el != '.')

    var numbers = naieve_key_without_decimals.map((num) => {
      var chr = String.fromCharCode(97 + parseInt(num));
      return chr
    })

    var str = numbers.join("");

    return str
  }

  //helper function to format seconds time to be readable
  fancyTimeFormat(time) {
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";
    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

                  //   <WaveformTest 
                  //   start_index={layer.start_index}
                  //   end_index={layer.end_index}
                  //   duration={layer.duration}
                  //   url={layer.url}
                  // />
                 
  render() {
    var layers = this.props.layers_subset
    layers.sort(function(a,b) { return a.start_index - b.start_index });
    return (
      <div>
        <ul class="list-group" style={{ listStyle: "none", padding: 0 }}>
          {layers.map((layer, index) => {
            var uniq = this.createKey(`${layer.start_index}${layer.duration}`)
            return (
            <li style={{paddingTop:5, paddingBottom:5}}>
              <div class="row">
                <div class="col">
                  <Waveform key={uniq} layer={layer}/>
                </div>
                <div class="col-2 time-container">
                  {this.fancyTimeFormat(layer.start_index)} to {this.fancyTimeFormat(layer.end_index)}. Total: {layer.duration.toFixed(2)} seconds 
                </div>
              </div>
            </li>
          )})}
        </ul>
      </div>
    )

  }
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    video: state.video,
    electronMode: state.general.electronMode,
    overlay: state.overlay
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WaveformContainer))