// Dependencies
import React, { Component, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import IdleTimer from 'react-idle-timer'
import queryString from 'query-string'

// Components
import Home from './components/home.component'
import Featured from './components/featured.component'
import About from './components/about.component'
import SetupPage from './components/setup-page.component'
import SetupPageWindows from './components/setup-page-windows.component'
import Pricing from './components/pricing.component'
import UsaCycling from './components/usa-cycling.component'
import Login from './components/login.component'
import Upgrade from './components/upgrade.component'
import LoginEmail from './components/login-email.component'
import LoginSuccess from './components/login-success.component'
import Profile from './components/profile.component'
import NewVideo from './components/new-video.component'
import VideoScreen from './components/video-screen.component'
import MyChannelScreen from './components/my-channel-screen.component'
import ChannelScreen from './components/channel-screen.component'
import NotAuthorized from './components/not-authorized.component'
import { BrowserRouter as Router, Route} from "react-router-dom";
import VideosList from "./components/videos-list.component";
import ChannelsScreen from './components/channels-screen.component'
import TracksList from "./components/tracks-list.component";
import TermsAndServices from "./components/terms-and-services.component"
import PrivacyPolicy from "./components/privacy-policy.component"
import NewTermsAndServices from "./components/terms-and-services-new.component"
import SignUp from "./components/sign-up.component"
import EmailVerified from "./components/email-verified.component"
import UserRegistered from "./components/user-registered.component"
import PasswordRecovery from "./components/password-recovery.component"
import PasswordRecoverySecondStep from "./components/password-recovery-second-step.component"
import PaymentSucess from "./components/payment-sucess.component"
import CancelSucess from "./components/cancellation-sucess.component"
import Sandbox from "./components/sandbox.component"
import PortalRedirect from './components/portal-redirect.component'
import CheckoutRedirect from './components/checkout-redirect.component'
import VideoUpgrade from './components/video-upgrade.component'
import Challenges from './components/challenges.component'
import ChallengeSplash from './components/challenge-splash.component'
import Events from './components/events.component'

// Actions
import { saveServerName, saveHostName } from './actions/generalActions';
import { updateUser } from './actions/userActions'
import { turnOnElectronMode, turnOnDemoMode, turnOffDemoMode } from './actions/generalActions'
import { isUserStravaAcessTokenFresh, refreshStravaAccessToken, isVIP } from './utils/utils'

// Styles
import './App.css';

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'

var strava = require('strava-v3');


// on the problem of console logging, the way we can fix the logging for production is to do the following:
// uncomment this piece of code to the index.js file:
// if (process.env.REACT_APP_DEBUG_MODE) {
//   console.log = function () {};
// }
//
// set the ENV variable to production:
// REACT_APP_DEBUG_MODE=production
//
// remove the logger from the configureStore.js file:
//   let store = createStore(
//     rootReducer,
//     initialState,
//     applyMiddleware(thunk, logger)
//   );
// =>>>>>>>>>>>>>>>
//   let store = createStore(
//     rootReducer,
//     initialState,
//     applyMiddleware(thunk)
//   );

const validVersionsOfElectron = ['8.2.1', '2.3.5', '2.3.6', '2.3.7', '2.3.8', '2.3.9', '2.4.0']

const App = (props) => {

  let idleTimerRef = useRef();
  let appIdleTimer = null

  // This whole component was converted to a functional component
  // to allow an external library to use react hooks
  // eventually the external library was removed but I decided to leave this hook
  // so we can easiliy see how they're used should we need to start using hooks in the future
  useEffect(() => reloadUser(), []);

  const reloadUser = () => {

    if(props.user.id != ''){

      const options = {
          method: 'GET',
          mode: 'cors',
          cache: 'default'
      };

      fetch((process.env.REACT_APP_SERVER)+'/api/v1/users/' + props.user.id, options)
        .then(response => {

          response.json()
            .then(user => {
              console.log('User reloaded', user)
              props.dispatchUpdateUser(user)
            });
        })

    }

  }

  const _onAction = () => {
   // EVERY ACTION - We shouldn't use it probably
  }

  const _onIdle = (e) => {

    // Possible solution:
    // if the video is playing, avoid entering demo mode
    if(props.video.isPlaying == true){
      return
    }

    let win = window.require('electron').remote.getCurrentWindow();

    // If tracker or transcoder window is open, we don't enter demo mode
    // And reset idle timer
    if (props.electronMode && IdleTimer != null) {
      const { ipcRenderer } = window.require('electron')

      if(ipcRenderer.sendSync('is-tracker-or-transcoder-visible')){
        console.log('Idle timer reset')
        appIdleTimer.reset()
        return
      }
    }

    // If window is not fullscreen, we debounce turning the demo mode one second
    // While we wait
    if (!win.isFullScreen()){
      props.dispatchTurnOnDemoMode();
      win.setFullScreen(true);
    }
    else {
      props.dispatchTurnOnDemoMode();
    }

  }

  const VIPRoute = ({component: Component, ...rest}) => {
    console.log(props.user)
    if (isVIP(props.user)) {
      return <Route {...rest} component={Component} />
    } else {
      return <Route {...rest} render={props => <Redirect to="/"/>} />
    }
  }

  //paid route to check if the user's account is expired,
  //leave this code because will probably be useful later on


  //checks if in electron
  // const ElectronCheckerRoute = ({component: Component, ...rest}) => {
  //   if(!props.electronMode) {
  //       var userAgent = navigator.userAgent.toLowerCase();
  //       if (userAgent.indexOf(' electron/') > -1) {
  //         console.log('Detected mismatch. turning on electron mode!')
  //         props.dispatchTurnOnElectronMode()

  //       }
  //   }

  //   return <Route {...rest} component={Component} />
  // }

  const urlParams = new URLSearchParams(window.location.search);
  const version = urlParams.get('v');
  return (

    <div className="App">

      {/* We only use this component to ENTER demo mode now. */}
      { props.user.enter_demo_mode_at && (!isNaN(props.user.enter_demo_mode_at) && props.electronMode) &&
        <IdleTimer
          ref={ref => { appIdleTimer = ref;}}
          element={document}
          onIdle={_onIdle}
          onAction={_onAction}
          throttle={100}
          timeout={parseInt(props.user.enter_demo_mode_at) * 1000 }
        ></IdleTimer>
      }

      <Router>
        <Route path="/" exact
          render={
            (route_props, param2) => {
              const values = queryString.parse(route_props.location.search)

              const user = props.user

              // PROGRAMATICALLY PRESSES A KEY //
              // This is a workaround for an issue that happens sometimes after leaving
              // a demo race - the app requited a mouse movement or a key press
              // in order to reset the app idle timer for some reason
              var keyboardEvent = document.createEvent("KeyboardEvent");
              var initMethod = typeof keyboardEvent.initKeyboardEvent !== 'undefined' ? "initKeyboardEvent" : "initKeyEvent";

              keyboardEvent[initMethod](
                "keydown", // event type: keydown, keyup, keypress
                true,      // bubbles
                true,      // cancelable
                window,    // view: should be window
                false,     // ctrlKey
                false,     // altKey
                false,     // shiftKey
                false,     // metaKey
                40,        // keyCode: unsigned long - the virtual key code, else 0
                0          // charCode: unsigned long - the Unicode character associated with the depressed key, else 0
              );
              document.dispatchEvent(keyboardEvent);

              // End of press key code //

              // If using electron check for validVersions of the application

              if('v' in values){
                if (!validVersionsOfElectron.includes(values.v)){
                  return <Redirect to="/upgrade" />
                }
              }

              if('electronMode' in values){
                props.dispatchTurnOnElectronMode()

                const { ipcRenderer } = window.require('electron')

                ipcRenderer.send('open-tracker', {
                  user_id: props.user.id,
                  tracker_url: ''
                })

                return <Redirect to="/login" />
              }


              //I made this on portal-r



              // Makes a request to the backend to auth/strava
              // if we recieve the code in the URL AND
              // the authorization code is not present OR
              // is different from the one we have OR
              // access token is not fresh

              // if('code' in values){

              //   if(
              //     props.user.strava_authorization_code == null ||
              //     props.user.strava_authorization_code != values.code
              //   ) {

              //     let data = {
              //       code: values.code,
              //       user_id: props.user.id
              //     }

              //     const options = {
              //         method: 'POST',
              //         body: JSON.stringify(data),
              //         headers: {
              //           'Content-Type': 'application/json',
              //         },
              //         mode: 'cors',
              //         cache: 'default'
              //     };

              //     fetch((process.env.REACT_APP_SERVER)+'/api/v1/auth/strava', options)
              //       .then(response => {
              //         response.json()
              //         .then(user => {
              //           props.dispatchUpdateUser(user)
              //         })

              //       })
              //     }

              // }

              // enforce authentication
              if(!props.user.isAuthenticated & ('electronMode' in values)){
                return props.history.push('/login');
              }


              return (<Home userEmail={props.user.email} userName={props.user.displayName} /> )
            }
          }/>
        <Route path="/activities" render={ () => {
          return <TracksList />
        }} />
        <Route path="/videos" render={routeProps => {
          const user = props.user

           return <VideosList />
        }} />
        <Route path="/featured"               component={Featured} />
        <Route path="/setup"                  component={SetupPage} />
        <Route path="/about"                  component={About} />
        <Route path="/setup-windows"          component={SetupPageWindows} />
        <Route path="/pricing"                component={Pricing} />
        <Route path="/usacycling"             component={UsaCycling} />
        <Route path="/video/:id"              component={VideoScreen} />
        <Route path="/my-channel"             component={MyChannelScreen} />
        <Route path="/channel/:id"            component={ChannelScreen} />
        <Route path="/explore-channels"       component={ChannelsScreen} />
        <Route path="/new-video"              component={NewVideo} />
        <Route path="/terms"                  component={NewTermsAndServices} />
        <Route path="/privacy-policy"         component={PrivacyPolicy} />
        <Route path="/profile"                component={Profile}/>
        <Route path="/login"                  component={Login} />
        <Route path="/upgrade"                component={Upgrade} />
        <Route path="/login-email"            component={LoginEmail} />
        <Route path="/sign-up"                component={SignUp} />
        <Route path="/login-success"          component={LoginSuccess} />
        <Route path="/user-registered"        component={UserRegistered} />
        <Route path="/email-verified"         component={EmailVerified} />
        <Route path="/password-recovery"      component={PasswordRecovery} />
        <Route path="/change-password/:code"  component={PasswordRecoverySecondStep} />
        <Route path="/payment-sucess"         component={PaymentSucess} />
        <Route path="/sandbox"                component={Sandbox} />
        <Route path="/cancellation-success"    component={CancelSucess} />
        <Route path="/portal-redirect"        component={PortalRedirect} />
        <Route path="/checkout-redirect"      component={CheckoutRedirect} />
        <Route path='/video-upgrade'          component={VideoUpgrade} />
        <Route path="/challenges"             component={Challenges}/>
        <Route path="/challenge/:id"  exact   component={ChallengeSplash}/>
        <Route path="/events"                 component={Events}/>
      </Router>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { general, user } = state

  return {
    confirmedAgreement: general.confirmedAgreement,
    user: user,
    video: state.video,
    electronMode: general.electronMode,
    demoMode: state.general.demoMode,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchUpdateUser:         (data) => { dispatch(updateUser(data)) },
    dispatchTurnOnElectronMode: ()     => { dispatch(turnOnElectronMode()) },
    dispatchTurnOnDemoMode:     ()     => { dispatch(turnOnDemoMode()) },
    dispatchTurnOffDemoMode:    ()     => { dispatch(turnOffDemoMode()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);





