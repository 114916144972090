import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import UserList from './user-list.component.js'
import '../styles/video.css'
var classNames = require('classnames')

class WaitingRoom extends Component {

	constructor(props) {
		super(props)

		this.state = {

		}
	}

	componentDidMount() {

	}


	render() {

		var numUsers = Object.keys(this.props.userList).length

		return (
			<div className="WR-container">
				<div className="WR-userlist WR-bg">
					<React.Fragment>
						{ !this.props.waitingOnTracker &&
							<div className="WR-userlist-title text-black text-center mt-5" style={{fontSize: '2rem'}}>
								<b>Who's here:</b>
							</div>
						}
						{ this.props.waitingOnTracker &&
							<div className="WR-userlist-title text-black text-center" style={{fontSize: '1.5rem'}}>
								<div className="p-3">The event has started. Connect to a smart trainer to join!</div>
							</div>
						}
						{ numUsers > 0 &&
							<div className="WR-userlist-wrapper">
								<UserList
									users={this.props.userList}
								/>
							</div>
						}
						{ numUsers == 0 &&
							<div style={{position: 'relative'}} className="WR-userlist-wrapper-spin">
								<div className="WR-spinnerbox">
									<div class="spinner-border" role="status">
								  	<span class="sr-only">Loading...</span>
									</div>
									<div className="mt-2">
										<i>Wait for others to join this event. Or start now...</i>
									</div>
								</div>
							</div>
						}
						
					</React.Fragment>
					<div className="WR-button-container">
						{ this.props.isGroupHost &&
							<button onClick={this.props.fullscreenRaceMode} className="btn btn-success btn-lg mr-3">Start</button>
						}
						{ this.props.waitingOnTracker && this.props.groupRaceStarted &&
							<button onClick={this.props.openTrackerPanel} className="btn btn-lg btn-success">Connect</button>
						}
						{ !this.props.waitingOnTracker && this.props.groupRaceStarted &&
							<button onClick={this.props.joinSync} className="btn btn-success btn-lg mr-3">Join</button>
						}
						<button onClick={this.props.toggleGroupMode} className={"btn btn-lg btn-danger ml-3"}>Leave</button>
					</div>
				</div>
				
				{/*<div className="row WR-row">
					<div className="col WR-col">
						Audio and Video Check
					</div>
					
				</div>
				<div className="row WR-row">
					<div className="col WR-col">
						Video Details
					</div>
					<div className="col WR-col">
						Chat
					</div>
				</div>*/}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    track: state.currentTrack,
    start_signal: state.general.startSignal,
    video: state.video,
    electronMode: state.general.electronMode || false,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WaitingRoom));