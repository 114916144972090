import * as types from './actionTypes';

export const startAudioRecording = () => {
	return { type: types.START_AUDIO_RECORDING }
}

export const stopAudioRecording = () => {
	return { type: types.STOP_AUDIO_RECORDING }
}

export const updateAudioLayers = (value) => {
	return { type: types.UPDATE_AUDIO_LAYERS, payload: value}
}

export const addAudioLayer = (value) => {
	return { type: types.ADD_AUDIO_LAYER, payload: value }
}

export const updateInRange = (value) => {
	return { type: types.UPDATE_IN_RANGE, payload: value }
}

export const updatePlayingOverlayCurrently = (value) => {
	return { type: types.UPDATE_PLAYING_OVERLAY_CURRENTLY, payload: value }
}
