import initialState from './initialState';
import * as types from '../actions/actionTypes';

const videoReducer = (state = initialState.video, action) => {
  switch (action.type) {

  	case types.TURN_ON_PLAY_MODE:
  	  return { ...state, mode: 'play' }

  	case types.TURN_ON_RACE_MODE:
  	  return { ...state, mode: 'race' }

    case types.RESET_MODE:
      return { ...state, mode: null }

    case types.TURN_ON_THEATER_MODE:
      return { ...state, theater_mode: true }

    case types.TURN_OFF_THEATER_MODE:
      return { ...state, theater_mode: false }

    case types.TOGGLE_THEATER_MODE:
      return { ...state, theater_mode: !state.theater_mode }

    case types.SHOW_OVERLAY:
    	return { ...state, overlay: { visible: true, message: action.message }}

    case types.HIDE_OVERLAY:
    	return { ...state, overlay: { visible: false, message: null }}

    case types.SHOW_COUNTDOWN_OVERLAY:
      return { ...state, countdown_overlay_visible: true}

    case types.HIDE_COUNTDOWN_OVERLAY:
      return { ...state, countdown_overlay_visible: false}

    case types.SET_PLAYING_STATE:
      return { ...state, isPlaying: action.payload, isRecording: false, isPaused: false }

    case types.SET_RECORDING_STATE:
      return { ...state, isPlaying: action.payload, isRecording: action.payload, isPaused: false }

    case types.SET_PAUSED_STATE:
      return { ...state, isPlaying: false, isRecording: false, isPaused: action.payload }

    case types.SET_PREVIEW_COMPLETE:
      return { ...state, isPlaying: false, isRecording: false, isPaused: action.payload, previewComplete: true}

    case types.SAVE_RACE_TO_VIEW:
      return { ...state, race: action.payload }

    case types.OVERRIDE_DURATION: 
      return { ...state, override: action.payload }

    case types.CLEAR_RACE_FROM_VIEW:
      return { ...state, race: {} }

    case types.UPDATE_AUDIO_OVERLAY_MODE:
      return { ...state, AudioOverlayMode: action.payload }

    case types.UPDATE_GLOBAL_MUTE_STATUS:
      return { ...state, globalMuteStatus: action.payload }

    default:
      return state;
  }
}

export default videoReducer;
