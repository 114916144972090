import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect }from "react-router-dom";
import { withRouter } from "react-router-dom";
import Navbar from './navbar.component';
import HomePage from './homepage.component'
import Footer from './footer.component'
import axios from 'axios';
import queryString from 'query-string'
import { loadStripe } from '@stripe/stripe-js';
import ScrollToTopOnMount from './scroll-top.component.js'
import {Helmet} from "react-helmet";
require('dotenv').config()
var moment = require('moment');

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

class Pricing extends Component {

  constructor(props){
    super(props);
    this.state = {
      logged_in: false,
      publishableKey : '',
      corePriceId : '',
      proPriceId : '',
      corePriceYearId: '',
      proPriceYearId: '',
      CoreMonthlyCheckBox: true,
      ProMonthlyCheckBox: true,
    };
    this.handleClick = this.handleClick.bind(this)
    this.days_left = this.days_left.bind(this)
    this.ChangeTitleBasedOnExpiry = this.ChangeTitleBasedOnExpiry.bind(this)
    this.CardRendering = this.CardRendering.bind(this)
    this.toggleCheckbox = this.toggleCheckbox.bind(this)
    this.handlePortal = this.handlePortal.bind(this)
  }

  componentDidMount() {
    //electron settings
    if(this.props.electronMode){
      const { ipcRenderer } = window.require('electron')
      ipcRenderer.on('stripe-checkout-session-status-change', (event, arg) => {
        this.props.history.push('/portal-redirect')
      })
      ipcRenderer.on('stripe-client-portal-closed', (event, arg) => {
        this.props.history.push('/portal-redirect')
      })
    }

    //if user is logged in do setup tasks 
    if (this.props.CurrentUser.user_id != '' ) {
      //set logged in state to true
      this.setState({logged_in: true}); 

      //grab price settings from backend
      fetch((process.env.REACT_APP_SERVER)+'/api/v1/payment' + "/setup")
        .then(function(result) {
          return result.json();
        })
        .then( (json) => {
          this.setState({
            publishableKey : json.publishableKey,
            corePriceId : json.corePrice,
            proPriceId : json.proPrice,
            corePriceYearId : json.corePriceYear,
            proPriceYearId : json.proPriceYear
          })}
        )
    } else {
      //set logged in state to false
      this.setState({logged_in: false})
    }
  }

  //handle pricing redirect
  async handleClick(term, redirect, year) {

    const openExternalCheckoutPage = (url) => {
      const { ipcRenderer } = window.require('electron')
      //send message to electron to start loopback server
      ipcRenderer.send('stripe-checkout-session-opened', url)
    }

    if (this.props.CurrentUser.user_id != '') {
      if (redirect == false) {
        const createCheckoutSession = (priceId, level) => {
          return fetch((process.env.REACT_APP_SERVER)+'/api/v1/payment' + "/create-checkout-session", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              priceId: priceId,
              type: level, 
              email: this.props.CurrentUser.email,
              user_id: this.props.CurrentUser.user_id,
              electronMode : this.props.electronMode
            })
          }).then(function(result) {
            return result.json();
          });
        };

        let priceID

        if (year) {
          if (term == 'core') {
            priceID = this.state.corePriceYearId
            term = 'core_year'
          } else {
            priceID = this.state.proPriceYearId
            term = 'pro_year'
          }
        } else {
          (term == 'core' ? priceID = this.state.corePriceId : priceID = this.state.proPriceId)
        }
  
        const { sessionId } = await createCheckoutSession(priceID, term);
        const stripe = await stripePromise;

        if (this.props.electronMode) {
          const url = `${process.env.REACT_APP_HOSTNAME}/checkout-redirect?session_id=${sessionId}`
          openExternalCheckoutPage(url)
        } else {
          const { error } = await stripe.redirectToCheckout({sessionId});
        }
      } else {
        this.props.history.push('/profile')
      }
    } else {
      this.props.history.push('/login')
    }  
  };

  //days between now and then helper function
  days_left(date) {
    var then = moment(date);
    var now = moment();
    return now.to(then);
  }

  
  handlePortal (text) {

    const openExternalPortalPage = (url) => {
      const { ipcRenderer } = window.require('electron')
      //send message to electron to start loopback server
      ipcRenderer.send('stripe-client-portal-opened', url)
    }

    const portal_request = (mode) => {
      return fetch((process.env.REACT_APP_SERVER)+'/api/v1/payment/' + this.props.CurrentUser.user_id + '/customer-portal', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        }, 
        body: 
          JSON.stringify({
            electronMode: this.props.electronMode
          }) 
      }).then(async (res)=> {
        const response = await res.json()
        if (mode == 'web') {
          window.location.replace(response.portal_url)
        } else {
          openExternalPortalPage(response.portal_url)
        }
      });
    }
    
    var level = this.props.CurrentUser.account_level
    var complimentary_access = this.props.CurrentUser.complimentary_access

    //if you don't have complimentary access return portal button
    if (!complimentary_access) {
      if (level == 'core' || level == 'cancelled_period_not_over') {
        if (!this.props.electronMode) {
          return (
              <a href="#" className="text-success" onClick={() => {portal_request('web');  return false;}}>{text}</a> 
            )
        }
        else {
          return (
              <a href="#" className="text-success" onClick={() => {portal_request('electron');  return false;}}>{text}</a> 
            )
        }
      } else {
        return (<div></div> )
      }
    } else {
      return (<div></div>)
    }
  }

  ChangeTitleBasedOnExpiry(){
    var account_level = this.props.CurrentUser.account_level
    var previous_account_level = this.props.CurrentUser.previous_account_level
    if (this.state.logged_in) {
      //Trial Expired - Free member!
      if ((account_level == 'free') && (previous_account_level != 'cancelled')) {
        return (
          <div>
            <h4 className="text-center pricing-first-time-heading">Your ad-free trial has expired. Upgrade for ad-free, or keep training with our ad-supported plan.</h4>
          </div>
        )
      //Payment Method Expired
      } else if ((account_level == 'payment_failed')) {
        return (
          <div>
            <h4 className="text-center pricing-first-time-heading">Your most recent payment failed.</h4>
            <h4 className="text-center section-subhead">Please {this.handlePortal('update')} your payment method.</h4>
          </div>
        )
      //Current Core member
      } else if (account_level == 'core'){
        return (
          <div>
            <h4 className="text-center pricing-first-time-heading">Your Core plan is active.</h4>
            <h4 className="text-center section-subhead">Keep training!</h4>
            {/*<h4 className="text-center section-subhead">{this.handlePortal('Upgrade Now.')}</h4>*/}
          </div>
        ) 
      //Current Pro Member
      } else if ((account_level == 'pro')) {
        return (
          <div>
            <h4 className="text-center pricing-first-time-heading">Your Pro plan is active.</h4>
            <h4 className="text-center section-subhead">Keep training!</h4>
          </div>
        )
      //Current Trial member
      } else if ((account_level == 'trial') ) {
        return (
          <div>
            <h4 className="text-center pricing-first-time-heading">Your trial of our ad-free plan will expire {(this.days_left(this.props.CurrentUser.current_period_end_date))}.</h4>
            {/*RREACTIVATE WHEN WE FIX*/}
            {/*<h4 className="text-center section-subhead">Want to stay ad-free? Upgrade now.</h4>*/}
          </div>
        )
      //canceled and period is over
      } else if ((account_level == 'free') && (previous_account_level == 'cancelled')) {
        return (
          <div>
            <h4 className="text-center pricing-first-time-heading">You cancelled your ad-free plan.</h4>
            <h4 className="text-center section-subhead">You can keep training with our ad-supported plan.</h4>
          </div>
        )
      //cancelled but period is not over
      } else if ((account_level == 'cancelled_period_not_over')) {
        return (
          <div>
            <h4 className="text-center pricing-first-time-heading">You cancelled your {this.props.CurrentUser.previous_account_level.toProperCase()} plan.</h4>
            <h4 className="text-center section-subhead">Your plan features will expire {this.days_left(this.props.CurrentUser.current_period_end_date)}. {this.handlePortal('Restore')} your plan, or keep training with our ad-supported plan.</h4>.
          </div>
        )
      } else {
        return <div>Something Went Wrong.</div>
      }
      //Not a user
    } else {
      return (
        <div>
          <div className="container-fluid">
            <div className="container-fluid">
              <div className="row mt-2 mb-2 px-0 mx-0 pb-2 pt-2 justify-content-center">
                <h4 className="text-center pricing-first-time-heading">Pick your plan.</h4>
              </div>
              <div className="row mt-0 mb-2 mx-1 pb-0 pt-0 justify-content-center">
                <h1 className="text-center section-head"><a href="/login" className="btn btn-lg btn-outline-dark">Sign me up</a></h1>
              </div>
              <div className="row mt-0 mb-0 mx-1 pb-5 pt-0 justify-content-center">
                <h6 className="text-center small">
                <br/>Join free. No credit card needed. </h6>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  toggleCheckbox(term) {
    if (term == 'core'){
      this.setState((prevState) => {
         return {
            ...prevState,
            CoreMonthlyCheckBox: !this.state.CoreMonthlyCheckBox
         }
      })
    } else if (term == 'pro') {
      this.setState((prevState) => {
         return {
            ...prevState,
            ProMonthlyCheckBox: !this.state.ProMonthlyCheckBox
         }
      })
    }
  }

  CardRendering() {
    var level = this.props.CurrentUser.account_level
    var previous_level = this.props.CurrentUser.previous_account_level
    var complimentary_access = this.props.CurrentUser.complimentary_access
    var redirect = false

    if (complimentary_access == false) {
      if (level == 'core' || level == 'pro' || level == 'cancelled_period_not_over' || level == 'payment_failed') {
        redirect = true
      }
    }

    const coreCard = () => {
      const buttons = () => {
        var level = this.props.CurrentUser.account_level
          if (this.props.CurrentUser.user_id == '') {
            return (<div><br/></div>)
          } else if (level == 'trial' || level == 'free' || level == 'cancelled') {
            return (
              <div>
              <br/>
              <div>
                <br/> 
                <button className="btn btn-lg btn-block btn-success" onClick={() => this.handleClick('core', redirect, this.state.CoreMonthlyCheckBox)}>Buy Core</button>
                <br/>
              </div>
              </div>
            )
            } else {
              return (<div><br/></div>)
            }
        }

        let price

        if (this.state.CoreMonthlyCheckBox) {
          price = '$5'
        } else {
          price = '$6'
        }

        return (
          <div className="col-md add-bottom-margin">
            <div className="card h-100 mb-0 border-muted shadow-lg d-inline-block pricing-card">
              <div className="card-header bg-light">
                <h6 className="my-0 font-weight-bold text-uppercase">Core</h6>
              </div>
              <div className="card-body">
                {this.state.CoreMonthlyCheckBox ? 
                  (<div>
                    <p className="card-title pricing-card-title text-dark display-4">
                      $5
                    </p>
                    <h6 className="small text-muted">
                      USD / month prepaid annually (≈ 17% off)
                      <br /> 
                      or $6 per month billed monthly
                      <br />
                    </h6>
                  </div>) : 
                  (<div>
                    <p className="card-title pricing-card-title text-dark display-4">
                      $6
                    </p>
                    <h6 className="small text-muted">
                      USD / month billed monthly
                      <br /> 
                      or prepay annually (≈ 17% off)
                      <br />
                    </h6>
                  </div>)}
                <br />
                <h4 className="text-dark">Core Workouts (Ad-Free)</h4>
                <span>Workout interactively to videos uploaded by the community and more. Ad-free.</span>
                <br/>
                {buttons()}
                <div className="custom-control custom-switch">  
                  <input type="checkbox" className="custom-control-input" id="customSwitch1" onChange={() => this.toggleCheckbox('core')}/>  
                  <label className="custom-control-label" for="customSwitch1"><small className="text-muted">Pay monthly</small></label>
                </div>
              </div>
            </div>
          </div>
        )
      }

    const proCard = () => {
      const buttons = () => {
        var level = this.props.CurrentUser.account_level
        if (this.props.CurrentUser.user_id == '') {
          return (<div></div>)
        } else if (level == 'trial' || level == 'free' || level == 'cancelled') {
          return (
            <div>
              {/*
              <div>
                <br/> 
                <button className="btn btn-lg btn-block btn-success" onClick={() => this.handleClick('pro', redirect, this.state.ProMonthlyCheckBox)}>Buy Pro</button>
                <br/><br/>  
              </div>*/}
              {/*<a className="text-muted btn btn-lg btn-block btn-outline-secondary bg-white">Coming Soon</a>*/}
              <br/>
            </div>
          )
        } else {
          return (<div></div>)
        }
      }

      return (
        <div className="col-md add-bottom-margin">
          <div className="card h-100 mb-0 border-muted shadow-lg d-inline-block pricing-card">
            <div className="card-header bg-light">
              <h6 className="my-0 font-weight-bold text-uppercase">Pro</h6>
            </div>
            <div className="card-body">
              {this.state.ProMonthlyCheckBox ? 
                (<div>
                  <p className="card-title pricing-card-title text-muted display-4">
                    $10
                  </p>
                  <h6 className="small text-muted">
                    USD / month prepaid annually (≈ 17% off)
                    <br /> 
                    or $12 per month billed monthly
                    <br />
                  </h6>
                </div>) : 
                (<div>
                  <p className="card-title pricing-card-title text-muted display-4">
                    $12
                  </p>
                  <h6 className="small text-muted">
                    USD / month billed monthly
                    <br /> 
                    or prepay annually (≈ 17% off)
                    <br />
                  </h6>
                </div>)}
              <br />
              <h4 className="text-muted">Core <b>+</b> Pro Collection</h4>
              <span className="text-muted">Enjoy unlimited community videos plus exclusive training videos. (Coming Soon)</span>
              <br/><br/>
              {buttons()}
              <div className="custom-control custom-switch">  
                <input type="checkbox" className="custom-control-input" id="customSwitch2" onChange={() => this.toggleCheckbox('pro')}/>  
                <label className="custom-control-label" for="customSwitch2"><small className="text-muted">Pay monthly</small></label>
              </div>
            </div>
          </div>
        </div>
      )
    }

    const freeCard = () => {
      return (
        <div className="col-md add-bottom-margin">
          <div className="card h-100 mb-0 border-muted shadow-lg d-inline-block pricing-card">
            <div className="card-header bg-light">
              <h6 className="my-0 font-weight-bold text-uppercase">Free</h6>
            </div>
            <div className="card-body">
              <p className="card-title pricing-card-title text-dark display-4">
                $0
              </p>
              <h6 className="small text-muted">
                Ad-Supported
                <br />
                &nbsp;
                <br/>
              </h6>
              <br/>
              <h4 className="text-dark">Free Workouts</h4>
              Workout interactively to videos uploaded by the community. Ad-supported.
            </div>
          </div>
        </div>
      )
    }


    const table = () => {
        return (
          <div className="col add-bottom-margin">
            <div className="card h-100 mb-0 border-muted shadow-lg d-inline-block pricing-card">
              <div className="card-body">
                <table className="card-table table w-100">
                  <thead>
                    <tr>
                      <th className="text-left p-1 border-top-0" scope="col">Features</th>
                      <th scope="col" className="p-1 border-top-0">Free</th>
                      <th scope="col" className="p-1 border-top-0">Core</th>
                      <th scope="col" className="p-1 border-top-0">Pro</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                      <td className="text-left p-1"><small>Work Out Live</small></td>
                      <td className="p-1"><span className="text-success">&nbsp;&#10003;</span></td>
                      <td className="p-1"><span className="text-success">&nbsp;&#10003;</span></td>
                       <td className="p-1"><span className="text-success">&nbsp;&#10003;</span></td>
                    </tr>
                    <tr>
                      <td className="text-left p-1"><small>Join Challenges</small></td>
                      <td className="p-1"><span className="text-success">&nbsp;&#10003;</span></td>
                      <td className="p-1"><span className="text-success">&nbsp;&#10003;</span></td>
                       <td className="p-1"><span className="text-success">&nbsp;&#10003;</span></td>
                    </tr>
                    <tr>
                      <td className="text-left p-1"><small>Track Fitness</small></td>
                      <td className="text-success p-1">&#10003;</td>
                      <td className="text-success p-1">&#10003;</td>
                      <td className="text-success p-1">&#10003;</td>
                    </tr>
                    <tr>
                      <td className="text-left p-1"><small>Export To Strava</small></td>
                      <td className="text-success p-1">&#10003;</td>
                      <td className="text-success p-1">&#10003;</td>
                      <td className="text-success p-1">&#10003;</td>
                    </tr>
                    <tr>
                      <td className="text-left p-1"><small>Upload Videos</small></td>
                      <td className="p-1"><span className="text-success">&#10003;</span></td>
                      <td className="p-1"><span className="text-success">&#10003;</span></td>
                      <td className="p-1"><span className="text-success">&#10003;</span></td>
                    </tr>
                    <tr>
                      <td className="text-left p-1"><small>Create a Channel</small></td>
                      <td className="text-success p-1">&#10003;</td>
                      <td className="text-success p-1">&#10003;</td>
                      <td className="text-success p-1">&#10003;</td>
                    </tr>
                    <tr>
                      <td className="text-left p-1"><small>Work Out Ad-Free</small></td>
                      <td className="text-danger p-1"><small>x</small></td>
                      <td className="p-1"><span className="text-success">&nbsp;&#10003;</span></td>
                       <td className="p-1"><span className="text-success">&nbsp;&#10003;</span></td>
                    </tr>
                    <tr>
                      <td className="text-left p-1"><small>Get Exclusive Content</small></td>
                      <td className="text-danger p-1"><small>x</small></td>
                      <td className="text-danger p-1"><small>x</small></td>
                      <td className="text-success p-1">&#10003;</td>
                    </tr>
                    {/* <tr>
                      <td colspan="4" className="text-left p-1 pb-0">
                      <small><i className="text-muted">SD=Standard Definition, HD=High Definition</i></small></td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      )
    }

    return (
      <div className="container pb-5">
        <div className="d-flex justify-content-center">
          <div className="row text-center">
            {freeCard()}
            {coreCard()}
            {proCard()}
            {table()}
          </div>
        </div>
      </div>
    )
  }

  render() {


    if(this.props.demoMode && this.props.electronMode){
      return <Redirect to='demo' />
    }

    return (
      <React.Fragment>

          <ScrollToTopOnMount />

          <Helmet>
            <meta name="Description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
            <meta property="og:url" content="https://vidfitness.com/pricing"/>
            <meta property="og:title" content="VidFitness - Pricing"/>
            <meta property="og:image" content="https://vidfitness.com/vidfitness-screen.jpg"/>
            <meta property="og:description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
            <meta property="og:type" content="website" />
            <meta property="fb:app_id" content="432091207556688"/>
          </Helmet>

          <div>
            <Navbar />
            <br />

            <div>
              <div className="container-fluid">
                <div className="row mt-0 mb-0 px-3 mx-1 pb-0 pt-4 justify-content-center">
                  {this.ChangeTitleBasedOnExpiry()}
                </div>
              </div>
              <br/>
              <div>
                {this.CardRendering()}
              </div>
              
            </div>

          </div>

        { !this.props.electronMode &&
          <Footer />
        }

      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const user = state.user

  return {
    CurrentUser: {
      user_id: user.userId,
      email: user.email,
      account_level: user.account_level,
      current_period_end_date: user.current_period_end_date,
      complimentary_access: user.complimentary_access,
      previous_account_level: user.previous_account_level
    },
    electronMode: state.general.electronMode || false,
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);

