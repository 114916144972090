import React, { Component } from 'react';
import { Redirect, withRouter } from "react-router-dom";
import VFImage from  './image.component.js'
import { removeLastSegment, getInfoFromVideoUrl } from '../utils/utils.js'
import  '../styles/gallery.css'
import '../styles/video.css'
import placeholder from '../images/placeholder2.png'
import checkmark from '../images/checkmark.png'
import gradient from '../images/gradient.png'
import { v4 as uuidv4 } from 'uuid';

const VideosGallery = ({ order = null, videos, history, user, type = null, watched = null }) => {
	if (type == 'CHALLENGE') {
		var sorted = videos.sort(function(a,b) {
    	return order.indexOf( a.url ) - order.indexOf( b.url );
		});
	} else {
		sorted = videos
	}

	const goToVideo = (filename, content_type, level) => {
		history.push('/video/' + filename);
	}

	function imgError(ev) {
    ev.target.src = placeholder
    return true
  }

	const renderGallery = () => {
		return sorted.map((video, i) => {
			var seen = false

			if (type == 'CHALLENGE' && watched != null) {
				if (watched.includes(video.url)) {
					seen = true
				}
			}

		  let [filename, thumb_url] = getInfoFromVideoUrl(video.url)

		  //grab content type
		  let content_type = video.content_type

      if(filename.endsWith('.mp4') ){
        filename = filename.slice(0,-4)
      }

      let minutes = 0;
      let seconds = 0;

      if(video.duration % 60 < 10){
        seconds = video.duration % 60
        seconds = '0' + seconds
      } else {
        seconds = video.duration % 60
      }

      minutes = Math.floor(video.duration / 60)

      let colclasses

      if (type == 'CHALLENGE') {
      	colclasses = "gallery-ride col-lg-4 col-md-4 col-sm-6 col-xs-12 px-2"
      } else {
      	colclasses = "gallery-ride col-lg-3 col-md-4 col-sm-6 col-xs-12 px-2"
      }

		  return (

		    <div onClick={ () => { goToVideo(filename, content_type) } } key={uuidv4()} className={colclasses}>

		      	<div className="rounded" style={{position: 'relative'}}>

					<div>
						<div className="videobox aspect16x9 bg-white rounded" style={{position:'relative'}}>
							{ type == 'CHALLENGE' && seen &&
							<img className="overlay-image-watched" src={checkmark} />
							}
							<img className="w-100 img-zoom" style={{top: '0', borderRadius:'.75rem'}} src={thumb_url} onError={imgError}/>
							{ type != 'CHALLENGE' &&
							<div className="line-clamp-5 p-3">
								<p className="line-clamp-5 small text-left text-dark">{video.description}</p>
							</div>
									}
						</div>
					</div>

					<div>
						<div className="time-indicator">
						{minutes}:{seconds}
						</div>
					{ (video.slope_source_id == 1 || video.slope_source_id == 2) &&
						<div className="gradient-indicator">
							<img className="gradient-image" src='https://img.icons8.com/ios-glyphs/2x/FFFFFF/bar-chart.png' />
						</div>
					}
						<div className="gallery-video-title text-left text-truncate pt-1 pl-2 pr-2"><b>{video.title}</b>&nbsp;
						</div>
					</div>

				</div>
			</div>

		  )
		})
	}

	if(sorted.length > 0){
		return (
			<div className="row p-0 p-sm-1 m-0 m-sm-1">
				{ renderGallery() }
			</div>
		)
	}

	else{
		return (
			<div className="row">
				{/*<div className="col-lg-12">
				  <br />
				  <h4> Loading Videos ... </h4>
				  <br />
				</div>*/}
			</div>
		)
	}

}

export default withRouter(VideosGallery);
