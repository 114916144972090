export default {
  user:{
    id: '',
    userId: '',
    login_count: 0,
    display_name: '',
    first_name: '',
    last_name: '',
    photoUrl: '',
    email: '',
    isAuthenticated: false,
    authorized: false,
    token: '',
    weight: '',
    height: '',
    gender: '',
    strava_authorization_code: '',
    strava_refresh_token: '',
    strava_access_token: '',
    strava_access_token_expires_at: '',
    channel_title: '',
    channel_description: '',
    accepted_terms_and_conditions: false,
    enter_demo_mode_at: null,
    members_riding_your_videos_notification: false,
    strava_auto_save: false,
    user_challenges: []
  },
  general:{
    electronMode: false,
    showTerms: false,
    confirmedAgreement: false,
    hostName: '',
    serverName: '',
    startSignal: false,
    demoMode: false,
    showMainOverlay: false
  },
  tracks:[],
  currentTrack:{},
  leaderboard:{
    racers:[]
  },
  tracker:{
    tracker_connected: false,
    tracker_data_updated_at: null,
    tracker_data: {}
  },
  ui:{
    isProfileFormEditable: false,
    isVideoFormEditable: false,
    isLoading: false,
    showAlert: true,
  },
  video:{
    isPlaying: false,
    isRecording: false,
    isPaused: false,
    mode: null,
    theater_mode: false,
    overlay: {
      visible: true,
      message: 'initial-buttons'
    }, 
    previewComplete: false,
    race: {},
    AudioOverlayMode: false,
    globalMuteStatus: false,
    countdown_overlay_visible: false,
    override: {
      status: false,
      newduration: 0
    }
  },
  overlay:{
    audioRecording: false,
    audioLayers: [],
    inRange: false,
    playingOverlayCurrently: false,
  },
};
