import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Navbar from './navbar.component'
import Footer from './footer.component'
import axios from 'axios';
import Track from './track.component'
import { isUserStravaAcessTokenFresh, refreshStravaAccessToken, process_tracks } from '../utils/utils'
import {Helmet} from "react-helmet";
import Skeleton from 'react-loading-skeleton';
import '../styles/challenges.css'
import ChallengeList from './challenge-list.component'
import SplashStatus from './splash-status.component'
import ProfileStatusWrapper from './profile-status-wrapper.component'

const querystring = require('querystring');
var moment = require('moment')

class ChallengeSplash extends Component {

  constructor(props) {
    super(props);
    this.state = {
      challenge: {},
      userChallengeData: {},
      registeredUser: false,
      loaded: false
    };

    this.refreshComponent =  this.refreshComponent.bind(this)

  }

  refreshComponent() {
    var challenge_id = this.props.match.params.id

    var user_id = this.props.user.id

    if (challenge_id == '') {
      this.props.history.push('/challenges')
    } else {

      var payload = {
        challenge_id: challenge_id
      }

      axios.post((process.env.REACT_APP_SERVER) + '/api/v1/challenge/values/', payload).then((response) => {

        if (response.status == 400) {
          this.props.history.push('/challenges')
          
        } else {
          
          this.setState({
            challenge: response.data.challenge,
            loaded: true
          })

        }

      })

    }
  }

  componentDidMount() {
    this.refreshComponent()
  }

  componentDidUpdate(prevProps) {
    if(this.props.match.params.id !== prevProps.match.params.id ) {
      this.setState({ loaded: false }, () => this.refreshComponent())
    };
  };

  render() {

    if(this.props.demoMode && this.props.electronMode){
      this.props.history.push('/demo')
    }

    return (



    <React.Fragment>
      <div>
        <Navbar />
        <br />
        { this.state.loaded ?
          <ProfileStatusWrapper
            onActivities={false}
          >
            {refreshUser => (
              <SplashStatus refreshUser={this.refreshUser} challenge={this.state.challenge} />
            )}
          </ProfileStatusWrapper> : 
        <div className="parent">
          <div className="loading-spinner">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        }
        { !this.props.electronMode &&
          <Footer />
        }
      </div>
    </React.Fragment>
    )
  }
}


const mapStateToProps = (state) => {
  console.log(state)
  if (state.user.id == '') {
    var signed = false
  } else {
    var signed = true
  }
  return {
    tracks:       state.tracks,
    user:         state.user,
    electronMode: state.general.electronMode,
    demoMode:     state.general.demoMode,
    signed_in: signed,
    userChallenges: state.user.user_challenges
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChallengeSplash));
