import React, { Component } from 'react';


class Countdown extends Component {

	constructor(props){
		super(props);

		this.startCountdown = this.startCountdown.bind(this)

		this.state = {
			number: 10
		}
	}

	componentDidMount(){
		this.startCountdown()
	}

	startCountdown(){
		setInterval( () => {
			if(this.state.number >= 0)
				this.setState({number: this.state.number - 1})
		}, 1000)
	}

	render(){
		return (
			<div className='countdown-timer'>
			  { this.state.number >= 1 &&
			  	<React.Fragment>
			    	{this.state.number}
			    </React.Fragment>
			  }
			  { this.state.number == 0 &&
			  	<React.Fragment>
			    	<div>Go!</div>
			    </React.Fragment>
			  	
			  }
			</div>
		)
	}

}

export default Countdown;
