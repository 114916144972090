import * as types from './actionTypes';
import { toggleStartSignal } from '../actions/generalActions'


export const turnOnPlayMode = () => {
	return { type: types.TURN_ON_PLAY_MODE }
}

export const turnOnRaceMode = () => {
	return { type: types.TURN_ON_RACE_MODE }
}

export const resetMode = () => {
  return { type: types.RESET_MODE }
}

export const toggleTheaterMode = () => {
	return { type: types.TOGGLE_THEATER_MODE }
}

export const turnOnTheaterMode = () => {
	return { type: types.TURN_ON_THEATER_MODE }
}

export const turnOffTheaterMode = () => {
	return { type: types.TURN_OFF_THEATER_MODE }
}

export const showOverlay = (message = null) => {
	return { type: types.SHOW_OVERLAY, message: message }
}

export const hideOverlay = (message = null) => {
	return { type: types.HIDE_OVERLAY }
}

export const showCountdownOverlay = () => {
  return { type: types.SHOW_COUNTDOWN_OVERLAY }
}

export const hideCountdownOverlay = (message = null) => {
  return { type: types.HIDE_COUNTDOWN_OVERLAY }
}

export const setPlayingState = (value) => {
	return { type: types.SET_PLAYING_STATE, payload: value }
}

export const setRecordingState = (value) => {
	return { type: types.SET_RECORDING_STATE, payload: value }
}

export const setPausedState = (value) => {
	return { type: types.SET_PAUSED_STATE, payload: value }
}

export const setPreviewComplete = () => {
	return {type: types.SET_PREVIEW_COMPLETE}
}

export const saveRaceToView = (value) => {
	return {type: types.SAVE_RACE_TO_VIEW, payload: value}
}

export const clearRaceFromView = () => {
	return {type: types.CLEAR_RACE_FROM_VIEW}
}

export const enterRaceMode = (start_signal=true) => {

	return ( dispatch, getState ) => {
		dispatch(turnOnTheaterMode())
    	dispatch(turnOnRaceMode())
    	dispatch(hideOverlay())

	    if(start_signal == true){
	       // wait half a second and dispatch the start signal
	      setTimeout(() => {
	        dispatch(toggleStartSignal())
	      }, 500);
	    }

	}

}

export const enterPlayMode = () => {

	return ( dispatch, getState ) => {
	    dispatch(turnOnPlayMode())
	    dispatch(setPlayingState(true))
	    dispatch(hideOverlay())
	}

}

export const updateAudioOverlayMode = (value) => {
	return { type: types.UPDATE_AUDIO_OVERLAY_MODE, payload: value }
}

export const setGlobalMuteStatus = (value) => {
	return { type: types.UPDATE_GLOBAL_MUTE_STATUS, payload: value }
}

export const overrideDuration = (value) => {
	return { type: types.OVERRIDE_DURATION, payload: value }
}


