import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link, withRouter, Redirect } from "react-router-dom";
import { isMobile } from 'react-device-detect'

import Navbar from './navbar.component'
import Footer from './footer.component'
import axios from 'axios';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactPaginate from 'react-paginate';
import '../styles/video.css'
// Custom Components
import VideosGallery from './videos-gallery.component'

import {Helmet} from "react-helmet";

class VideosList extends Component {

    constructor(props) {
        super(props);

        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.handleDurationChange = this.handleDurationChange.bind(this)

        this.state = {
          videos: [],
          searchTerm: '',
          currentPage: 0,
          pageSize: 12,
          pageCount: 0,
          duration: 'all',
          loading: true
        };

    }

    componentDidMount() {
      this.loadVideosFromServer()
      
      //added by Vee
      window.scrollTo(0, 0);

      if (isMobile) {
        this.setState({
          marginPages: 1,
          pageRange: 2,
          previousLabel: "<",
          nextLabel: ">"
        })
      } else {
        this.setState({
          marginPages: 2,
          pageRange: 5,
          previousLabel: "Previous",
          nextLabel: "Next"
        })
      }
    }

    loadVideosFromServer(term=null) {
      let currentPage = this.state.currentPage;
      let pageSize  = this.state.pageSize;
      let duration  = this.state.duration

      axios.get((process.env.REACT_APP_SERVER)+'/api/v1/races' + '?currentPage=' + currentPage + '&pageSize=' + pageSize + '&term=' + term + '&duration=' + duration)
        .then(response => {
          let data = response.data
          let videos = data.races.videos.filter( (item) => item.url !== 'demo-video')

          this.setState({
           videos: videos,
           pageCount: Math.ceil( (data.races.total_count) / data.races.page_size),
           loading: false
          });
        })
        .catch(function (error){
          console.log(error);
        })

     }

    handlePageClick = data => {
      let selected = data.selected;
      let offset = Math.ceil(selected * this.state.pageSize);

      this.setState({ currentPage: selected }, () => {
        this.loadVideosFromServer();
      });
    };

    handleSearchChange(e){

      let term = e.target.value

      this.setState({ searchTerm: term, loading: true}, () => {
        this.loadVideosFromServer(term)
      })

    }

    handleDurationChange(e) {
      const duration = e.target.dataset.duration

      console.log(duration)

      this.setState({
        duration
      }, () => {
        this.loadVideosFromServer(this.state.searchTerm)
      })

    }

    getDurationLabel(duration) {
      switch(duration) {
        case 'all':
          return 'All Durations'
          break;
        case '-15':
          return 'Less than 15 mins.'
          break;
        case '15-30':
          return 'Between 15 and 30 mins.'
          break;
        case '30-45':
          return 'Between 30 and 45 mins.'
          break;
        case '45-60':
          return 'Between 45 and 60 mins.'
          break;
        case '+60':
          return 'More than 60 mins.'
          break;
      }
    }

    render() {

      if(this.props.demoMode){
        return <Redirect to='demo' />
      }

      return (

      <React.Fragment>
        <Helmet>
            <meta name="Description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
            <meta property="og:url" content="https://vidfitness.com/videos"/>
            <meta property="og:title" content="VidFitness - Videos to Ride"/>
            <meta property="og:image" content="https://vidfitness.com/vidfitness-screen.jpg"/>
            <meta property="og:description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
            <meta property="og:type" content="website" />
            <meta property="fb:app_id" content="432091207556688"/>
        </Helmet>
        <div>
          <Navbar />
          <br />
          <div className="container-fluid" style={{minHeight: '100vh'}}>
              <div className="row justify-content-end">
                  <div className="col-12 col-sm-4 pl-4 pl-sm-5 mt-1 pb-3 pb-sm-2">
                    <h3 className="page-title">Workouts</h3>
                  </div>
                  <div className="col-6 col-sm-4">
                    <input value={this.state.searchTerm } onChange={this.handleSearchChange } type="text" className="form-control search-input" placeholder="Search" aria-describedby="inputGroupPrepend" required />
                  </div>
                  <div className="col-6 col-sm-4">
                    <div className="dropdown">
                      <button className="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        { this.getDurationLabel(this.state.duration)}
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a onClick={ this.handleDurationChange } data-duration='all' className="dropdown-item" href="#"> All </a>
                        <a onClick={ this.handleDurationChange } data-duration='-15' className="dropdown-item" href="#">   { '< 15 min' } </a>
                        <a onClick={ this.handleDurationChange } data-duration='15-30' className="dropdown-item" href="#"> { '15 min - 30 min' } </a>
                        <a onClick={ this.handleDurationChange } data-duration='30-45' className="dropdown-item" href="#"> { '30 min - 45 min' } </a>
                        <a onClick={ this.handleDurationChange } data-duration='45-60' className="dropdown-item" href="#"> { '45 min - 60 min' } </a>
                        <a onClick={ this.handleDurationChange } data-duration='+60' className="dropdown-item" href="#">   { '> 60 min' } </a>
                      </div>
                    </div>
                  </div>
              </div>
              { !this.state.loading &&
                <React.Fragment>
                  <VideosGallery videos={this.state.videos} user={this.props.user} />
                  { this.state.pageCount > 1 &&
                    <nav aria-label="Page navigation example">
                      <ReactPaginate
                        previousLabel={this.state.previousLabel}
                        nextLabel={this.state.nextLabel}
                        breakLabel={'...'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={this.state.marginPages}
                        pageRangeDisplayed={this.state.pageRange}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        pageClassName={'page-item'}
                        previousClassName={'page-item'}
                        nextClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousLinkClassName={'page-link'}
                        nextLinkClassName={'page-link'}
                      />
                    </nav>
                  }
                </React.Fragment>
              }

              { this.state.loading &&
                <div class="d-flex justify-content-center">
                  <div class="spinner-grow" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              }

              { !this.state.loading && this.state.videos.length == 0 &&
                <div className="mt-5 text-center">
                  No videos match your search.
                </div>
              }


              
          </div>

          { !this.props.electronMode &&
            <Footer />
          }

        </div>
      </React.Fragment>
      )
    }
}

const mapStateToProps = (state) => {
  return {
    user:         state.user,
    electronMode: state.general.electronMode,
    demoMode:     state.general.demoMode
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VideosList));
