import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {isMobile, isTablet, BrowserView, MobileView, isMobileOnly} from 'react-device-detect';
import {
  resetMode,
  setPlayingState,
  setPausedState,
  enterPlayMode,
  updateAudioOverlayMode,
  showOverlay
} from '../actions/videoActions'

//components
import UploadFile from './upload-file.component'
import VideoForm from './video-form.component'
import AudioOverlay from './audio-overlay.component'

//other dependencies
import { FontAwesomeIcon }   from '@fortawesome/react-fontawesome'

class VideoBottom extends Component {

	constructor(props) {

		super(props)

		const video_url = this.props.match.params.id

		this.state = {
			currentTab: 'description',
			onEditMode: false,
			video_url: video_url,
			edit_race_route:'/api/v1/race/edit',
			delete_race_route:'/api/v1/race/delete',
		}

		this.turnOnEditMode = this.turnOnEditMode.bind(this)
		this.turnOffEditMode = this.turnOffEditMode.bind(this)
		this.pickTab = this.pickTab.bind(this)
		this.deleteVideo = this.deleteVideo.bind(this)
		this.submitEditionForm = this.submitEditionForm.bind(this)
	}

	componentDidMount() {



	}

	//Video Form
	submitEditionForm(values) {

		let data = {
			url: this.state.video_url,
			title: values.title,
			description: values.description,
			channel_visible: values.channel_visible,
			search_visible: values.search_visible
		}

		const options = {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
			  'Content-Type': 'application/json',
			},
			mode: 'cors',
			cache: 'default'
		};

		let race = {...this.props.race}

		race.title = values.title
		race.description = values.description
		race.channel_visible = values.channel_visible
		race.search_visible = values.search_visible

		this.props.updateRace(race)

		// For some reason we need to wait a little
		setTimeout(() => {
			this.turnOffEditMode()
		},500)

		return fetch((process.env.REACT_APP_SERVER) + this.state.edit_race_route, options)
		.then(response => response.json() )
		.then(response => {

			this.turnOffEditMode()
		
		})
	}

	turnOnEditMode(){
		this.setState({ onEditMode: true })
	}

	turnOffEditMode(){
		this.setState({ onEditMode: false })
	}

	deleteVideo(){

		if (window.confirm('Are you sure?')){

			let data = {
				id: this.state.video_url
			}

			const options = {
				method: 'POST',
				body: JSON.stringify(data),
				headers: {
				'Content-Type': 'application/json',
				},
				mode: 'cors',
				cache: 'default'
			};

			return fetch((process.env.REACT_APP_SERVER) + this.state.delete_race_route, options)
				.then(response => response.json() )
				.then(response => {
				  this.props.history.push('/videos')
				})

		}

	}

	pickTab(tab) {

	    var current = this.state.currentTab

	    const DisableAudioOverlayMode = () => {
	      if (this.props.video.AudioOverlayMode) {
	        this.props.dispatchUpdateAudioOverlayMode(false)
	        if (this.props.electronMode || this.state.currentTime == 0) {
	          this.props.dispatchShowOverlay()
	          this.props.dispatchResetMode()
	        }
	      }
	    }

	    if (tab == 'voiceover') {
	      if (!this.props.video.AudioOverlayMode) {
	        this.setState({
	          currentTab: 'voiceover'
	        }, () => {
	          this.props.dispatchUpdateAudioOverlayMode(true)
	          if (this.props.video.mode != 'play') {
	            this.props.dispatchEnterPlayMode()
	            this.props.dispatchSetPausedState(true)
	          }
	        })
	      }
	    } else if (tab == 'description') {
	      this.setState({
	        currentTab: 'description'
	      }, () => {
	        DisableAudioOverlayMode()
	      })
	    } else if (tab == 'gradients') {
	      this.setState({
	        currentTab: 'gradients'
	      }, () => {
	        DisableAudioOverlayMode()
	      })
	    }

	}

	render() {
		return(
			<div className="bottom-container" style={{ display: (!this.props.video.theater_mode ? "block" : "none")}} >
            <div class="tabber video-page-tabs">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class={'nav-link ' + (this.state.currentTab == 'description' ? 'active' : '')} id="description-tab" data-toggle="tab" href="#description" role="tab" aria-controls="description" onClick={() => this.pickTab('description')}>Description</a>
                </li>
                { !isMobileOnly &&
                <li class="nav-item">
                  <a class={'nav-link ' + (this.state.currentTab == 'voiceover' ? 'active' : '')} id="voiceover-tab" data-toggle="tab" href="#voiceover" role="tab" aria-controls="voiceover" onClick={() => this.pickTab('voiceover')}>Voiceover</a>
                </li>
                }
                {this.props.electronMode && (this.state.owner || this.props.user.admin) &&
                <li class="nav-item">
                  <a class={'nav-link ' + (this.state.currentTab == 'gradients' ? 'active' : '')} id="gradient-tab" data-toggle="tab" href="#gradients" role="tab" aria-controls="gradients" onClick={() => this.pickTab('gradients')}>Gradients</a>
                </li>
                }
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class={'tab-pane fade show '  + (this.state.currentTab == 'description'? 'active' : '')} id="description" role="tabpanel" aria-labelledby="description-tab">
                  
                      <React.Fragment>
                        <div className="container">
                          <div className="row">
                            <div className="col">
                              <div className='video-bottom'>

                                {!this.state.onEditMode &&
                                  <React.Fragment>
                                    <div className="row">
                                    <div className="col">
                                    
                                      { this.props.race.user_id == this.props.user.id &&
                                        <FontAwesomeIcon onClick={ this.deleteVideo } className='delete-button' icon={['fa', 'trash']}/>
                                      }

                                      { this.props.race.user_id == this.props.user.id &&
                                        <FontAwesomeIcon onClick={this.turnOnEditMode} className='edit-button' icon={['fa', 'edit']}/>
                                      }
                                    
                                    </div></div>
                                    <div className="row">
                                    <div className="col">
                                    <h1 className="text-truncate">                                   { this.props.race.title }
                                    </h1>                     
                                    </div></div>
                                    
                                    <div className="mb-2">From <a href="#" className="text-dark" onClick={(() => this.props.history.push(`/channel/${this.props.race.user_id}`))}>{this.props.race.user.channel_title}<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="currentColor" style={{ width:'1rem' }} class="bi bi-chevron-right" viewBox="-2 -1 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" stroke="black" stroke-width="1"/></svg></a></div>

                                    <span> {this.props.race.description} </span>
                                    
                                  </React.Fragment>
                                }


                                { this.state.onEditMode &&
                                  <VideoForm
                                    submit={this.submitEditionForm}
                                    id={this.state.video_url}
                                    title={this.props.race.title}
                                    description={this.props.race.description}
                                    channel_visible={this.props.race.channel_visible}
                                    search_visible={this.props.race.search_visible}
                                    permalink={this.props.race.permalink}
                                    cancel={this.turnOffEditMode}
                                  />
                                }

                              </div>
                            </div>
                          </div>
                        </div>
                    </React.Fragment>

                </div>
                  <div class={'tab-pane fade show' + (this.state.currentTab == 'voiceover' ? 'active' : '')} id="voiceover" role="tabpanel" aria-labelledby="voiceover-tab">
                      <AudioOverlay /> 
                  </div>
                    {this.props.electronMode && (this.state.owner || this.props.user.admin) && 
                    
                  <div class={'tab-pane fade show' + (this.state.currentTab == 'gradients' ? 'active' : '')} id="gradients" role="tabpanel" aria-labelledby="gradient-tab">
                    <div className="container text-left mt-4"> 
                      <h1 className="mt-3 text-left">Upload a GPX file</h1>
                        <UploadFile 
                          uploaded={this.props.race.gpx_upload_filename != null} 
                          filename={this.props.race.gpx_upload_filename}
                          refreshRace={this.props.refreshRace}
                        />
                    </div>
                  </div>
                    }
              </div>
            </div>
          </div>
		)
	}
}


const mapStateToProps = (state) => {

  const { user } = state

  let guestMode = false

  if( user.userId == '' ){
    guestMode = true
  }

  return {
    user: user,
    track: state.currentTrack,
    electronMode: state.general.electronMode,
    countdown_overlay_visible: state.video.countdown_overlay_visible,
    guestMode: guestMode,
    video: state.video,
    appDemoMode: state.general.demoMode,
    overlay: state.overlay
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
  		dispatchResetMode:         ()         => { dispatch(resetMode())},
  		dispatchShowOverlay:     (message)    => { dispatch(showOverlay(message)) },
  		dispatchUpdateAudioOverlayMode:         (value)   => { dispatch(updateAudioOverlayMode(value))},
  		dispatchEnterPlayMode:     ()         => { dispatch(enterPlayMode()) },
  		dispatchSetPausedState:    (value)    => { dispatch(setPausedState(value))},
  	}
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VideoBottom))