import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Navbar from './navbar.component';
import HomePage from './homepage.component'
import Footer from './footer.component'
import axios from 'axios';
import queryString from 'query-string'
import { Redirect }from "react-router-dom";
const querystring = require('querystring');

String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

class VideoUpgrade extends Component {

  constructor(props) {
    super(props);
      
    this.state = { 
      level: '',
      needed: ''
    }
    }

  componentDidMount() {
    //helper function to see if there is a query string in a url
    this.props.history.push('/pricing')
    // const doesQueryStringExist = () => {
    //   var field = 'type';
    //   var url = window.location.href;
    //   if(url.indexOf('?' + field + '=') != -1)
    //       return true;
    //   else if(url.indexOf('&' + field + '=') != -1)
    //       return true;
    //   return false
    // }
    // //get current user level, undefined if no user
    // var user_level = this.props.current_user.level
    // //if no user or account levels that shouldnt see this page redirect to pricing
    // if (user_level == 'cancelled_period_not_over' || user_level == 'trial' || user_level == 'payment_failed' || user_level == undefined) {
    //   this.props.history.push('/pricing')
    // } else {
    //   //grab query string
    //   const url = querystring.parse(window.location.href, '?')
    //   const type = url.type
    //   //initalize variables
    //   let needed
    //   //if no querystring then redirect else set text for page render
    //   if (!doesQueryStringExist()) {
    //     this.props.history.push('/pricing')
    //   } else if (type == 'premium') {
    //     needed = 'Pro'
    //   } else if (type == 'community') {
    //     needed = 'Core or Pro'
    //   }
    //   //set state via query string and user account level
    //   this.setState({
    //     level: user_level,
    //     needed: needed
    //   })
    // }
    // //handle electron
    // if(this.props.electronMode){
    //   const { ipcRenderer } = window.require('electron')

    //   ipcRenderer.on('stripe-client-portal-closed', (event, arg) => {
    //     this.props.history.push('/portal-redirect')
    //   })
    // }
  }

  //handle billing portal
  handlePortal (text) {

    const openExternalPortalPage = (url) => {
      const { ipcRenderer } = window.require('electron')
      //send message to electron to start loopback server
      ipcRenderer.send('stripe-client-portal-opened', url)
    }

    const portal_request = (mode) => {
      return fetch((process.env.REACT_APP_SERVER)+'/api/v1/payment/' + this.props.current_user.user_id + '/customer-portal', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        }, 
        body: 
          JSON.stringify({
            electronMode: this.props.electronMode
          }) 
      }).then(async (res)=> {
        const response = await res.json()
        if (mode == 'web') {
          window.location.replace(response.portal_url)
        } else {
          openExternalPortalPage(response.portal_url)
        }
      });
    }
    
    var level = this.props.current_user.level
    var complimentary_access = this.props.current_user.complimentary_access

    //if you don't have complimentary access return portal button
    if (!complimentary_access) {
      if (level == 'core' || level == 'cancelled_period_not_over') {
        if (!this.props.electronMode) {
          return (
              <a href="#" className="text-success" onClick={() => {portal_request('web');  return false;}}>{text}</a> 
            )
        }
        else {
          return (
              <a href="#" className="text-success" onClick={() => {portal_request('electron');  return false;}}>{text}</a> 
            )
        }
      } else {
        return (<a href="#" className="text-success" onClick={() => {this.props.history.push('/pricing');  return false;}}>{text}</a>)
      }
    } else {
      return (<a href="#" className="text-success" onClick={() => {this.props.history.push('/pricing');  return false;}}>{text}</a> )
    }
  }

  render() {

    return (
      

      <div>
        <Navbar />
          <br />
          <div className="container-fluid">
            <div className="container-fluid">
              <div className="row mt-2 mb-2 px-2 mx-0 pb-2 pt-2 justify-content-center">
                <h1 className="text-center section-head">This video requires an {this.handlePortal('upgrade.')}</h1>
                <br />
              </div>
              <div className="row mt-0 mb-0 mx-1 pb-5 pt-0 justify-content-center">
                <h6 className="text-center medium">
                  You currently have a <b>{this.state.level.toProperCase()}</b> Account.
                  <br />
                  To watch this video you'll need a <b>{this.state.needed}</b> Account.
                  <br />
                  <br />
                  <a href='#' onClick={() => {this.props.history.push('/videos'); return false;}}>Back to Videos</a>
                </h6>
                <br />
              </div>
              <div className="container-fluid">
                <div className="container pb-5">
                  <div className="d-flex justify-content-center">
                    <div className="row text-center">
                      <div className="col add-bottom-margin">
                        <div className="card h-100 mb-0 border-muted shadow-lg d-inline-block pricing-card">
                          <div className="card-body">
                            <table className="card-table table w-100">
                              <thead>
                                <tr>
                                  <th className="text-left p-1 border-top-0" scope="col">Features</th>
                                  <th scope="col" className="p-1 border-top-0">Free</th>
                                  <th scope="col" className="p-1 border-top-0">Core</th>
                                  <th scope="col" className="p-1 border-top-0">Pro</th>
                                </tr>
                              </thead>
                              <tbody>
                                 <tr>
                                  <td className="text-left p-1"><small>Track Fitness</small></td>
                                  <td className="text-success p-1">&#10003;</td>
                                  <td className="text-success p-1">&#10003;</td>
                                  <td className="text-success p-1">&#10003;</td>
                                </tr>
                                <tr>
                                  <td className="text-left p-1"><small>Export To Strava</small></td>
                                  <td className="text-success p-1">&#10003;</td>
                                  <td className="text-success p-1">&#10003;</td>
                                  <td className="text-success p-1">&#10003;</td>
                                </tr>
                                <tr>
                                  <td className="text-left p-1"><small>Upload Videos</small></td>
                                  <td className="p-1"><span className="text-success">&#10003;</span></td>
                                  <td className="p-1"><span className="text-success">&#10003;</span></td>
                                  <td className="p-1"><span className="text-success">&#10003;</span></td>
                                </tr>
                                <tr>
                                  <td className="text-left p-1"><small>Create a Channel</small></td>
                                  <td className="text-success p-1">&#10003;</td>
                                  <td className="text-success p-1">&#10003;</td>
                                  <td className="text-success p-1">&#10003;</td>
                                </tr>
                                <tr>
                                  <td className="text-left p-1"><small>Monthly Samplers</small></td>
                                  <td className="p-1"><span className="text-success">&nbsp;&#10003;</span></td>
                                  <td className="p-1"><span className="text-success">&nbsp;&#10003;</span></td>
                                   <td className="p-1"><span className="text-success">&nbsp;&#10003;</span></td>
                                </tr>
                                <tr>
                                  <td className="text-left p-1"><small>Community Videos</small></td>
                                  <td className="text-danger p-1"><small>x</small></td>
                                  <td className="p-1"><small className="text-success">SD</small></td>
                                  <td className="p-1"><small className="text-success">HD</small></td>
                                </tr>
                                <tr>
                                  <td className="text-left p-1"><small>Exclusive Videos</small></td>
                                  <td className="text-danger p-1"><small>x</small></td>
                                  <td className="text-danger p-1"><small>x</small></td>
                                  <td className="text-success p-1">&#10003;</td>
                                </tr>
                                <tr>
                                  <td colspan="4" className="text-left p-1 pb-0">
                                  <small><i className="text-muted">SD=Standard Definition, HD=High Definition</i></small></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <Footer />
      </div>


    )
  }
}

const mapStateToProps = (state) => {
  const user = state.user

  return {
    current_user: {
      display_name: user.display_name,
      user_id : user.id,
      stripe_sub_id : user.stripe_sub_id,
      level: user.account_level,
      complimentary_access: user.complimentary_access
    },
    electronMode: state.general.electronMode || false,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(VideoUpgrade));