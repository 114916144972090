import React, { Component } from 'react';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { saveCurrentTrack } from '../actions/currentTrackActions'
import { convertToDoubleDigits, arraysEqual } from '../utils/utils'
import { toggleStartSignal } from '../actions/generalActions'
import { PlayerIcon } from 'react-player-controls'
import { showOverlay,
         hideOverlay,
         showCountdownOverlay,
         hideCountdownOverlay,
         turnOnTheaterMode,
         turnOffTheaterMode,
         setPlayingState,
         setRecordingState,
         setPausedState,
         setPreviewComplete,
         turnOnPlayMode,
         overrideDuration,
         enterPlayMode,
         enterRaceMode,
         setGlobalMuteStatus } from '../actions/videoActions'
import videojs from 'video.js';
import volumeUp from '../images/speaker_icon.png'
import '../styles/misc.css'
import { isMobileOnly, isBrowser } from  'react-device-detect'
import VideoControls from './video-controls.component'
import Gauges from './gauges.component'
import RaceLeaderboard from './race-leaderboard.component'
import VideoOverlay from './video-overlay.component';
import Indicator from './indicator.component'
import Countdown from './countdown.component';
import {
  updateUser
} from '../actions/userActions'
import WaitingRoom from './waiting-room.component'
import { 
  whichChallengesToRecalculate, recalculateRankings
} from '../utils/utils'

import { withRouter } from 'react-router-dom'

import axios from 'axios'

import io from 'socket.io-client';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const throttle = require('lodash.throttle');

require('videojs-contrib-ads')

var moment = require('moment')


class Tracker extends Component {

  constructor(props){

    super(props);

    this.CONTROL_REQUEST_BUFFER = 5;
    this.CONTROL_REQUEST_INTERVAL = 10;
    this.GROUP_RIDE_SYNC_MARGIN = 1
    this.VIDEO_DURATION_MARGIN = 0.01
    this.currentTime = 0
    
    this.registerElectronSocketEventHandlers = this.registerElectronSocketEventHandlers.bind(this)
    this.registerBackendSingleSocketEventHandlers = this.registerBackendSingleSocketEventHandlers.bind(this)
    this.registerBackendGroupSocketEventHandlers = this.registerBackendGroupSocketEventHandlers.bind(this)
    
    this.stopTrack = this.stopTrack.bind(this)
    this.stopVideo = this.stopVideo.bind(this)
    this.setTime = this.setTime.bind(this)
    this.setMute = this.setMute.bind(this)
    this.toggleFullscreenMode = this.toggleFullscreenMode.bind(this)
    this.startRecording = this.startRecording.bind(this)
    this.playVideo      = this.playVideo.bind(this)
    this.pauseVideo     = this.pauseVideo.bind(this)
    this.restartVideo   = this.restartVideo.bind(this)
    this.fullscreenPlayMode = this.fullscreenPlayMode.bind(this)
    this.fullscreenRaceMode = this.fullscreenRaceMode.bind(this)
    this.openTrackerPanel = this.openTrackerPanel.bind(this)
    this.toggleGroupMode = this.toggleGroupMode.bind(this)
    this.setControlFeedbackToZero = this.setControlFeedbackToZero.bind(this)
    this.unmountGroupSocket = this.unmountGroupSocket.bind(this)
    this.getStartTimeFromServer = this.getStartTimeFromServer.bind(this)

    this.state = {
      control_store: {},
      muteFeedback: false,
      leaderboard_store: {},
      sync_leaderboard_store: [],
      sync_leaderboard_buffer: {},
      tracker_store: [],
      tracker_data: {},
      last_tracker_saved_at_videostamp: 0,
      last_tracker_saved_at_timestamp: null,
      backendSingleInitialized: false,
      groupMode: false,
      waitingMode: false,
      electronSocket: null,
      isTrackerConnected: false,
      backendGroupSocket: null,
      backendSingleSocket: null,
      groupModeInitialized: false,
      groupRaceStarted: false,
      userList: {},
      emitGroupIdentity: null,
      roomId: null,
      accumulated_power:0,
      lastSavedPointTime:0,
      lastElapsedTime:null,
      track: {},
      add_point_route:'/api/v1/points/add-reworked',
      time: 0,
      isOn: false,
      start: 0,
      video: null,
      ref: '',
      race: null,
      load: false,
      override: false,
      groupWaitingOnTracker: false,
      presetRoomIdUsed: false,
      usersJoined: 0,
      without_countdown: false,
      timeFromSyncStart: null,
      sync_time: -1,
      joinGroupRace: false
    }

    this.child = React.createRef();

  }

  componentDidMount(){

    var player = videojs('race-video');

    if (this.props.electronMode) {
      const { ipcRenderer } = window.require('electron')

      this.setState({
        ipcRenderer
      })
    }

    var electronSocket = io('http://localhost:3100',{
      'reconnection': true,
      'reconnectionDelay': 1000,
      'reconnectionDelayMax' : 5000,
      'reconnectionAttempts': 5
    })

    var backendSingleSocket = io(process.env.REACT_APP_SERVER + '/single-ride')

    this.setState({
      electronSocket: electronSocket,
      backendSingleSocket: backendSingleSocket,
      video: player,
      load: true
    }, async () => {

      this.startIntervals()

      if (this.props.location.state && this.props.location.state.groupRide) {

        this.toggleGroupMode(true)

      }

    });

  }

    //need to handle the joining midway through race case
  async toggleGroupMode(value) {

    this.setState({
      groupMode: value,
      inWaitingRoom: value   
    })

    if (value) {

      this.props.dispatchHideOverlay()

      if (this.state.backendGroupSocket == null) {

        var backendGroupSocket = io(process.env.REACT_APP_SERVER + '/group-ride')

        this.setState({
          backendGroupSocket: backendGroupSocket,
        }, async () => {

          if (!this.state.presetRoomIdUsed) {

            if (this.props.location.state && this.props.location.state.groupRide) {

              var roomId = this.props.location.state.eventId

              var { data } = await axios.get(process.env.REACT_APP_SERVER + `/api/v1/event/get-status/${roomId}`)

              this.setState({
                roomId: roomId,
                presetRoomIdUsed: true,
              })

              if (data.status == 'in-progress') {

                this.setState(prevState =>({
                  groupRaceStarted: true,
                }))

              }

            }

          }

        })

      }

    } else {

      this.unmountGroupSocket()

      this.props.dispatchShowOverlay()

    }

  }

  async registerBackendGroupSocketEventHandlers() {

    var user_info = {
      id: this.props.user.id,
      display_name: this.props.user.display_name,
      photo_url: this.props.user.photo_url
    }

    this.setState({
      user_info: user_info
    }, async () => {

      if (this.state.roomId == null && this.state.isGroupHost) {

        var { data: {eventId: roomId } } = await axios.post(process.env.REACT_APP_SERVER + '/api/v1/event/create', {
          host_user: this.props.user.id,
          race_url: this.props.video_url,
          host_user_display_name: this.props.user.display_name
        })

        this.setState({
          roomId: roomId
        })

      }

    })

    let refreshGroupLeaderboard = setInterval( () => {

      if (this.state.groupMode) {
        this.setState((prevState, props) => {

          var new_store = Object.values(prevState.sync_leaderboard_buffer)

          new_store.push(prevState.tracker_data)

          new_store.sort(function(a, b) {
            return a.accumulated_power > b.accumulated_power ? -1 : 1;
          })

          return {
            sync_leaderboard_store: new_store
          }
        })
      }

      }, 2000)

    this.setState({
      refreshGroupLeaderboard
    })

    this.state.backendGroupSocket.on('group-identity-pulse', (message) => {

      var { id } = message.user_info

      if (!(id in this.state.userList)) {

        var users = this.state.userList

        users[id] = message.user_info

        this.setState({
          userList: users
        })

      }

    })

    this.state.backendGroupSocket.on('group-leave-room', (message) => {

      var { user_info } = message

      var users = this.state.userList

      delete users[user_info.id]

      var buffer = this.state.sync_leaderboard_buffer

      delete buffer[user_info.id]

      this.setState({
        userList: users,
        sync_leaderboard_buffer: buffer
      })

    })

    // this.state.backendGroupSocket.on('group-check-room', (message) => {

    //   var { timeStart } = message

    //   if (timeStart != null) {

    //     var now = new Date()

    //     var timeValue = Math.floor((moment.duration(moment(now).diff(timeStart)).as('seconds') / 1000)) - 10

    //     if (timeValue < 0) {
    //       timeValue = 0
    //     } 

    //     this.setState({
    //       sync_time: timeValue
    //     })

    //   }

    // })

    this.state.backendGroupSocket.on('group-point-pulse', (message) => {

      var { user_info, point } = message

      var buffer = this.state.sync_leaderboard_buffer

      buffer[user_info.id] = point

      this.setState({
        sync_leaderboard_buffer: buffer,
      })

      console.log('CURRENT SYNC TIME IS', this.state.sync_time)

      if (point.elapsed_time > this.state.sync_time) {

        console.log('RESETING SYNC TIME VIA NEW POINT AT', point.elapsed_time)

        this.setState({
          sync_time: point.elapsed_time,
        })

      }

      if (!this.state.groupRaceStarted) {

        this.setState({
          groupRaceStarted: true
        })

      }

    })

    this.state.backendGroupSocket.on('group-start-race', () => {

      this.setState({
        groupRaceStarted: true
      })

      if (this.state.isTrackerConnected) {

        this.setState({
          inWaitingRoom: false,
        }, () => {
          this.fullscreenRaceMode()
        })

      } else {
        
        this.setState({
          groupWaitingOnTracker: true
        })

      }

    })

    this.state.backendGroupSocket.on('group-error', (message) => {
      console.log('ERROR IN BACKEND', message)
    })

    this.state.backendGroupSocket.on('group-cancel', (message) => {
        
      if (this.state.groupMode) {

        const MySwal = withReactContent(Swal)

        MySwal.fire({
          icon: 'error',
          title: 'This workout was canceled.',
        })

        this.state.video.pause()

        this.toggleGroupMode(false)

      } 

    })

  }

  registerBackendSingleSocketEventHandlers() {

    this.state.backendSingleSocket.emit('initialize-race', {
      url: this.props.video_url, 
      userId: this.props.user.id,
      slope_source_id: this.props.metadata.slope_source_id
    })
    
    this.state.backendSingleSocket.on('race-intialized', () => {
      this.setState({
        backendSingleInitialized: true
      })

      if (this.props.metadata.slope_source_id != 0) {

        this.state.backendSingleSocket.emit('control-request', {
          startOfNeeded: 0,
          endOfNeeded: this.CONTROL_REQUEST_INTERVAL,
          slopeSourceId: this.props.metadata.slope_source_id,
          raceUrl: this.props.video_url
        })

      }

    })

    this.state.backendSingleSocket.on('track-added', (message) => {

      this.props.dispatchSaveCurrentTrack(message)

      this.setState({
        track: message
      })

      if (!this.props.groupMode) {
        this.state.backendSingleSocket.emit('history-leaderboard', {
          startOfNeeded: 0,
          endOfNeeded: this.CONTROL_REQUEST_INTERVAL,
          raceUrl: this.props.video_url
        })
      }
      
    })

    this.state.backendSingleSocket.on('control-response', (message) => {

      var { start, controls } = message

      //create a hashtable of data
      var timeTable = controls.reduce((map, obj) => {
        map[obj.elapsed_time] = obj;
        return map;
      }, {});

      for (var p = start - this.CONTROL_REQUEST_BUFFER; p <= start; p++ ) {

        if ( p in this.state.control_store) {
          timeTable[p] = this.state.control_store[p]
        }
        
      }

      this.setState({

        control_store: timeTable

      })

    })

    this.state.backendSingleSocket.on('history-leaderboard-response', (message) => {

      var { start, leaderboard } = message

      var timeTable = {}
      var intermediate;

      for ( var i of leaderboard ) {

        intermediate = i
        intermediate['active'] = false

        if (intermediate.elapsed_time in timeTable) {
          timeTable[intermediate.elapsed_time] = timeTable[intermediate.elapsed_time].concat([intermediate])
        } else {
          timeTable[intermediate.elapsed_time] = [intermediate]
        }

      }

      for (var p = start - this.CONTROL_REQUEST_BUFFER - 1; p <= start; p++ ) {

        if ( p in this.state.leaderboard_store) {

          timeTable[p] = this.state.leaderboard_store[p]

        }
        
      }

      for (var y in timeTable) {

        timeTable[y] = timeTable[y].sort(function(a, b) {

          return a.accumulated_power > b.accumulated_power ? -1 : 1;

        })

      }

      this.setState({

        leaderboard_store: timeTable

      })

    })

    this.state.backendSingleSocket.on('final-points-pushed', async (discard_track) => {

      this.state.backendSingleSocket.emit('stop-track', {
        discard_track: discard_track,
        accumulated_power: this.state.accumulated_power,
        raceUrl: this.props.video_url,
        trackId: this.props.track.id,
        userId: this.props.user.id,
        strava_access_token: this.props.user.strava_access_token,
        strava_auto_save: this.props.user.strava_auto_save
      })

      this.setState({
        accumulated_power: 0,
        tracker_store: [],
        leaderboard_store: {},
        control_store: {},
        track: null,
        last_tracker_saved_at_videostamp: 0
      })

      this.props.dispatchOverrideDuration({
        status: false,
        newduration: 0
      })

      this.props.dispatchTurnOffTheaterMode()

      this.props.dispatchTurnOnPlayMode() 

    })

    this.state.backendSingleSocket.on('track-saved', async (message) => {

      var challenge_ids = whichChallengesToRecalculate(this.props.user, this.props.video_url)
      var { data: newUser } = await recalculateRankings(challenge_ids, this.props.user.id)
      this.props.dispatchUpdateUser(newUser)
      this.props.history.push('/activities')

    })

  }

  async getStartTimeFromServer() {

    return new Promise(async (resolve, reject) => {

      var { data } = await axios.get(process.env.REACT_APP_SERVER + `/api/v1/event/get-start-time/${this.state.roomId}`)

      var { timeStart } = data

      if (timeStart != null) {

        var now = new Date()

        var timeValue = Math.floor(moment.duration(moment(now).diff(moment(timeStart))).seconds()) - 10

        if (timeValue < 0) {
          timeValue = 0
        } 

        resolve(timeValue)

      } else {

        resolve(null)

      }

    })

  }

  async componentDidUpdate(prevProps, prevState){
    // window.VIDEOJS_NO_DYNAMIC_STYLE = true

    // var video = document.getElementById("race-video")
    

    // Check if isPlaying changed and start/stop the video accordingly
    if(prevProps.video.isPlaying == false && this.props.video.isPlaying == true){

      ///////////////////////////
      /* We use to just call this.state.video.play()
         But this caused an error due to a race condition in some cases
         'DOMException: play() failed because the user didn't interact with the document first.'
         (apparently the video was not ready yet)
         so we replaced that code with this one which works better
         More info here: https://goo.gl/xX8pDD
      */

      var playPromise = this.state.video.play();

      if (playPromise !== undefined) {
        playPromise.then(_ => {
          // Automatic playback started!
          // Show playing UI.
        })
        .catch(error => {
          // Auto-play was prevented
          // Show paused UI.
        });
      }
    }

    if(prevState.electronSocket == null && this.state.electronSocket != null){
      this.registerElectronSocketEventHandlers()
    }

    if (prevState.backendSingleSocket == null && this.state.backendSingleSocket != null) {
      this.registerBackendSingleSocketEventHandlers()
    }

    if (prevState.backendGroupSocket == null && this.state.backendGroupSocket != null) {
      this.registerBackendGroupSocketEventHandlers()
    }

    if (prevState.roomId == null && this.state.roomId != null) {

      this.state.backendGroupSocket.emit('group-check-room', {
        roomId: this.state.roomId
      })

      this.state.backendGroupSocket.emit('group-join-room', {
        roomId: this.state.roomId,
        user_info: this.state.user_info
      })

      let emitGroupIdentity = setInterval( () => {

        if (this.state.backendGroupSocket != null && this.state.groupMode) {
          this.state.backendGroupSocket.emit('group-identity-pulse', {
            user_info: this.state.user_info,
            roomId: this.state.roomId
          })
        }

      }, 3000)

      this.setState({
        emitGroupIdentity,
      })

    }

    // if (prevState.syncTime == null && this.state.syncTime != null) {
    //   this.setState({
    //     inWaitingRoom: false
    //   }, () => {

    //     this.fullscreenRaceMode(true)

    //     this.toggleFullscreenMode(true)
    //   })
    // }

    //if the user connects the tracker
    if (prevState.isTrackerConnected == false && this.state.isTrackerConnected && this.state.groupWaitingOnTracker) {
       
      this.setState({

        inWaitingRoom: false,
        groupWaitingOnTracker: false

      }, async () => {

        if (this.state.sync_time == -1) {

          var timeStart = await this.getStartTimeFromServer()

          if (timeStart != null) {

            this.setState({
              sync_time: timeStart
            }, () => {
              this.setTime(this.state.sync_time)
              this.fullscreenRaceMode(true) 
            })

          } else {

            console.log('TIME START WAS NULL')

            this.setState({
              sync_time: 0
            }, () => {
              this.setTime(this.state.sync_time)
              this.fullscreenRaceMode(true) 
            })

          }

        } else {

          console.log('THIS IS THE SYNC TIME HERE', this.state.sync_time)

          this.setTime(this.state.sync_time)
          this.fullscreenRaceMode(true) 

        }

               
        
      })

    }

    if (prevState.joinGroupRace == false && this.state.joinGroupRace == true) {

      if (this.state.isTrackerConnected) {

        if (this.state.sync_time == -1) {

          var timeStart = await this.getStartTimeFromServer()

          if (timeStart != null) {

            this.setState({
              sync_time: timeStart,
              inWaitingRoom: false
            }, () => {
              this.setTime(this.state.sync_time)
              this.fullscreenRaceMode(true) 
            })

          } else {

            console.log('TIME START WAS NULL')

            this.setState({
              sync_time: 0,
              inWaitingRoom: false
            }, () => {
              this.setTime(this.state.sync_time)
              this.fullscreenRaceMode(true) 
            })

          }          

        } else {

          console.log('THIS IS THE SYNC TIME THERE', this.state.sync_time)

          this.setState({
            inWaitingRoom: false,
          }, () => {

            this.setTime(this.state.sync_time)
            this.fullscreenRaceMode(true) 

          })

        }


      } else {

        this.setState({
          groupWaitingOnTracker: true
        })

      }

    }

  }

  //clean up
  componentWillUnmount(){

    if(this.state.electronSocket != null){
      this.state.electronSocket.off('pulse')
      this.state.electronSocket.off('connect_error')
      this.state.electronSocket.off('tracker_data')
      this.state.electronSocket.close()
    }

    if(this.state.backendSingleSocket != null) {
      this.state.backendSingleSocket.off('error')
      this.state.backendSingleSocket.off('race-initialized')
      this.state.backendSingleSocket.off('control-response')
      this.state.backendSingleSocket.off('track-added')
      this.state.backendSingleSocket.off('history-leaderboard-response')
      this.state.backendSingleSocket.off('final-points-pushed')
      this.state.backendSingleSocket.off('track-saved')
      this.state.backendSingleSocket.close()
    }

    if (this.state.backendGroupSocket != null) {
      this.unmountGroupSocket()
    }

    if(!!document.getElementById("race-video")){
      var player = videojs('race-video');
      player.off('timeupdate')
    }

    clearInterval(this.state.removeTrackerDataIntervalId)

    this.setState({
      removeTrackerDataIntervalId: null
    })

  }

  unmountGroupSocket() {

    this.state.backendGroupSocket.emit('group-leave-room', {
      user_info: this.state.user_info,
      roomId: this.state.roomId
    })

    if (this.state.isGroupHost) {

      if (Object.keys(this.state.userList).length == 0) {

        console.log('user list empty')

        this.state.backendGroupSocket.emit('group-cancel', {
          roomId: this.state.roomId,
        })

      } else {

        //for later, right now just cancel race
        // this.state.backendGroupSocket.emit('group-new-host', {
        //   roomId: this.state.roomId
        // })

        if (!this.state.groupRaceStarted) {

          console.log('race hasnt started')

          this.state.backendGroupSocket.emit('group-cancel', {
            roomId: this.state.roomId,
          })

        }

      }

    }
    this.state.backendGroupSocket.off('group-identity-pulse')
    this.state.backendGroupSocket.off('group-point-pulse')
    this.state.backendGroupSocket.off('group-leave-room')
    if (this.state.emitGroupIdentity != null) {
      clearInterval(this.state.emitGroupIdentity)
      this.setState({
        emitGroupIdentity: null
      })
    }
    if (this.state.refreshGroupLeaderboard != null) {
      clearInterval(this.state.refreshGroupLeaderboard)
      this.setState({
        refreshGroupLeaderboard: null
      })
    }
    this.setState({
      roomId: null,
      userList: {},
      isGroupHost: false,
      backendGroupSocket: null,
      groupRaceStarted: false,
      joinGroupRace: false, 
      sync_time: -1,
      groupWaitingOnTracker: false,
      without_countdown: false
    })

  }

  fullscreenPlayMode() {

    this.props.dispatchEnterPlayMode()

    if (!isMobileOnly) {
        this.props.dispatchTurnOnTheaterMode();
    }

    if (this.props.electronMode) {

      let electron = window.require('electron');
      let win = electron.remote.getCurrentWindow();
      win.setFullScreen(true);

    };

  }

  fullscreenRaceMode(dont_restart = false) {

    if (this.state.groupMode && this.state.isGroupHost && this.state.inWaitingRoom) {

      this.state.backendGroupSocket.emit('group-start-race', {
        roomId: this.state.roomId,
        duration: this.props.metadata.duration,
      })

      this.setState({
        inWaitingRoom: false,
        groupRaceStarted: true
      })

    }

    if (dont_restart == true) {

      this.setState({
        without_countdown: true
      })

    } else {

      this.restartVideo()

    }
    
    this.props.dispatchEnterRaceMode()

    this.toggleFullscreenMode(true)

  }



  openTrackerPanel() {

    if (this.props.electronMode) {

      const { ipcRenderer } = window.require('electron')

      ipcRenderer.send('open-tracker', {
        user_id: this.props.user.id,
        tracker_url: ''
      })

    };

  }

    // Sets intervals for the different tasks the page performs
  startIntervals(){

    var player = videojs('race-video');

    let removeTrackerDataIntervalId = setInterval( () => {
      let time_difference = Date.now() - this.state.last_tracker_saved_at_timestamp;
      if(time_difference > 1000){

        var null_tracker_data = {
          username:     this.props.user.display_name,
          user_id:      this.props.user.id,
          url:          this.props.video_url,
          track_id:     this.props.track.id,
          elapsed_time: this.currentTime,
          accumulated_power: 0,
          power:        0,
          heart_rate:   0,
          cadence:      0,
          distance:     0,
          raw_data:     '',
          active: true,
        }

        var leaderboard_store = this.state.leaderboard_store

        var inter;

        if (this.currentTime in leaderboard_store) {

          inter = leaderboard_store[this.currentTime].concat([null_tracker_data])

          leaderboard_store[this.currentTime] = inter.sort((a, b) => {
            return a.accumulated_power > b.accumulated_power ? -1 : 1;
          })

        } else {

          leaderboard_store[this.currentTime] = [null_tracker_data]

        }

        this.setState({
          tracker_data: null_tracker_data
        })
      }
    }, 1000)

    this.setState({
      removeTrackerDataIntervalId
    })

    if (this.props.metadata.mute_video_audio) {
      player.muted(true)
    }

    // On Time Update
    player.on('timeupdate', () => {

      if(this.state.video != null && !this.state.video.paused()){

        //set current time
        this.currentTime = Math.floor(this.state.video.currentTime())

        if(this.currentTime > this.state.lastSavedPointTime || this.currentTime == 0) {

          //set last saved value (to make sure functions only being called once per second)
          this.setState({ 
            lastSavedPointTime: this.currentTime,
          })

          //set controls for smart trainer
          if (this.currentTime in this.state.control_store) {

            if (!this.state.muteFeedback) {

              this.state.electronSocket.emit('set_control', this.state.control_store[this.currentTime]);

            }

          }

          // is it time to request the next set of controls
          if ((this.currentTime + this.CONTROL_REQUEST_BUFFER) % this.CONTROL_REQUEST_INTERVAL == 0) {

            var payloadForSocket = {
              startOfNeeded: this.currentTime + this.CONTROL_REQUEST_BUFFER,
              endOfNeeded: this.currentTime + this.CONTROL_REQUEST_BUFFER + this.CONTROL_REQUEST_INTERVAL,
              raceUrl: this.props.video_url
            }

            if (this.props.metadata.slope_source_id != 0) {

              payloadForSocket['slopeSourceId'] = this.props.metadata.slope_source_id

              this.state.backendSingleSocket.emit('control-request', payloadForSocket)

            }

            if(this.props.video.isRecording && !this.state.groupMode) {

              this.state.backendSingleSocket.emit('history-leaderboard', payloadForSocket)

            }
            
          }

          if (this.currentTime % this.CONTROL_REQUEST_INTERVAL == 0 && this.currentTime != 0) {

            if(this.props.video.isRecording) {

              this.state.backendSingleSocket.emit('push-points', {
                points: this.state.tracker_store,
                status: ''
              })

              this.setState({
                tracker_store: []
              })

            }

          }
          
        }

      }

      if (this.props.video.race.mute_video_audio || this.props.video.globalMuteStatus || this.props.overlay.audioRecording) {
        if (!player.muted()) {
          player.muted(true)
        }
      } else {
        if (player.muted()) {
          player.muted(false)
        }
      }

    });

    player.on('pause', () => { 
      if (this.props.video.mode == 'race') {
        this.setControlFeedbackToZero()
      }
    })

    player.on('ended', () => {    

      this.stopVideo()

      var est_duration = this.props.video.race.duration

      //if not within percentage of duration
      if (!( this.currentTime >= est_duration * (1 - this.VIDEO_DURATION_MARGIN) && this.currentTime <= est_duration * (1 + this.VIDEO_DURATION_MARGIN))) {
        
        this.props.dispatchOverrideDuration({
          status: true,
          newduration: this.currentTime
        })

      }

    })

    player.on('fullscreenchange', () => {
      if(!player.isFullscreen() && isMobileOnly) {

        //exit theatre mode
        this.props.dispatchTurnOffTheaterMode()

        //pause video
        player.pause()

        //set playing state to not playing
        this.props.dispatchSetPlayingState(false)

        //show overlay
        this.props.dispatchShowOverlay()
      }
    })

    

  }

  stopVideo() {

    this.state.video.pause()

    this.props.dispatchSetPlayingState(false)

    if(!this.props.electronMode){
      this.toggleFullscreenMode(false)
    } else {
      this.props.dispatchShowOverlay()
    }

    if (this.props.video.mode == 'race') {

      //set control feedback to 0
      this.setControlFeedbackToZero()

      if (this.state.groupMode) {
        this.toggleGroupMode(false)
      }
    }

  }

  async stopTrack(discard_track) {

    var override = this.props.video.override

    if (override != undefined && override != null) {
      if (override.status) {
        var payload = {
          race_id: this.props.metadata.id,
          new_duration: override.newduration
        }

        await axios.post((process.env.REACT_APP_SERVER) + '/api/v1/races/change-duration/', payload)
      }
    }

    //push residual points
    this.state.backendSingleSocket.emit('push-points', {
      points: this.state.tracker_store,
      status: 'final',
      discard_track: discard_track
    })

  }

  setControlFeedbackToZero() {
    setTimeout(() => {

      this.state.electronSocket.emit('set_control', {
        slope: 0,
        road_feel_type: 0,
        road_feel_intensity: 0,
        timestamp: null
      });

    }, 1500);
  }

  registerElectronSocketEventHandlers(){

    let electronSocket = this.state.electronSocket

    electronSocket.on('pulse', function(tracker_data){
      this.setState({ isTrackerConnected: true })
    });

    electronSocket.on('connect_error', (err) => {
      console.log('Error connecting to server');
      this.setState({ isTrackerConnected: false })
    });

    electronSocket.on('tracker_data',(data) => {
      if(this.state.isTrackerConnected == false) {
        this.setState({ isTrackerConnected: true })
      }

      // only saves data if it contains something different than 0
      if(!isNaN(data.power) && data.power != 0){

        var accumulated_power = (isNaN(this.state.accumulated_power) ? 0 : this.state.accumulated_power)
        var power = (isNaN(data.power) ? 0 : data.power)

        accumulated_power = accumulated_power + power

        var new_tracker_data = {
          username:     this.props.user.display_name,
          user_id:      this.props.user.id,
          url:          this.props.video_url,
          track_id:     this.props.track.id,
          elapsed_time: this.currentTime,
          accumulated_power: accumulated_power,
          power:        power,
          heart_rate:   data.heart_rate,
          cadence:      data.cadence,
          distance:     data.distance,
          raw_data:     data.raw_data,
          active: true,
        }

        this.setState({
          tracker_data: new_tracker_data,
          last_tracker_saved_at_timestamp: Date.now()
        })

        if (this.props.video.isRecording){

          if (this.currentTime > this.state.last_tracker_saved_at_videostamp) {

            var leaderboard_store = this.state.leaderboard_store

            var inter;

            if (this.currentTime in leaderboard_store) {

              inter = leaderboard_store[this.currentTime].concat([new_tracker_data])

              leaderboard_store[this.currentTime] = inter.sort((a, b) => {
                return a.accumulated_power > b.accumulated_power ? -1 : 1;
              })

            } else {

              leaderboard_store[this.currentTime] = [new_tracker_data]

            }

            if (this.state.groupMode) {

              this.state.backendGroupSocket.emit('group-point-pulse', {
                user_info: this.state.user_info,
                roomId: this.state.roomId,
                point: new_tracker_data
              })

            }

            this.setState({
              leaderboard_store: leaderboard_store,
              tracker_store: this.state.tracker_store.concat([new_tracker_data]),
              last_tracker_saved_at_videostamp: this.currentTime,
              accumulated_power: accumulated_power
            })

          }

        }

      } 
      // else {

      //   var null_tracker_data = {
      //     username:     this.props.user.display_name,
      //     user_id:      this.props.user.id,
      //     url:          this.props.video_url,
      //     track_id:     this.props.track.id,
      //     elapsed_time: this.currentTime,
      //     accumulated_power: (isNaN(this.state.accumulated_power) ? 0 : this.state.accumulated_power),
      //     power:        0,
      //     heart_rate:   data.heart_rate,
      //     cadence:      data.cadence,
      //     distance:     data.distance,
      //     raw_data:     data.raw_data,
      //     active: true,
      //   }

      //   this.setState({
      //     tracker_data: null_tracker_data,
      //   })

      // }
    });
  }



  setTime(time) {

    this.state.video.currentTime(time)
    this.currentTime = time

  }

  startRecording(){

    if (this.state.backendSingleInitialized) {

      if (!this.state.joinGroupRace) {
        this.setTime(0)
      }

      if(this.props.video.isPaused == false){

        this.state.backendSingleSocket.emit('add-track', {
          title:this.props.metadata.title,
          race_id: this.props.metadata.id,
          username: this.props.user.display_name,
          raceUrl: this.props.metadata.url,
          userId: this.props.user.id
        })
        // //videojs('race-video').play()
        this.state.video.play()

        this.props.dispatchSetRecordingState(true)

      }

    }

  }

  playVideo(){

    if (this.state.video.paused()) {

      this.state.video.play()

      this.props.dispatchSetPlayingState(true)

    }

  }

  pauseVideo(){

    if (!this.state.video.paused()) {

      this.state.video.pause()

      this.props.dispatchSetPausedState(true)

      if (this.props.electronMode) {
        this.props.dispatchShowOverlay('initial-buttons')
      }

      setTimeout( () => {
        this.toggleFullscreenMode(false)
      }, 500)

    }
  }

  restartVideo(){

    // pause video
    this.state.video.pause()

    //sets time to 0
    this.setTime(0)

    //set playing state to false
    this.props.dispatchSetPlayingState(false)

    this.setState({
      lastSavedPointTime:0
    })

  }

  toggleFullscreenMode(direct) {

    if (direct) {
      this.props.dispatchTurnOnTheaterMode();
    } else {
      this.props.dispatchTurnOffTheaterMode();
    }

    if (this.props.electronMode) {
      let electron = window.require('electron');
      let win = electron.remote.getCurrentWindow();
      win.setFullScreen(direct);
    };

  }

  setMute(direct) {
    this.props.dispatchSetGlobalMuteStatus(direct)
    this.state.video.muted(direct)
  }

  render(){

    if(this.props.start_signal == true){
      if (this.state.without_countdown) {
        this.props.dispatchToggleStartSignal()
        this.startRecording()
        this.props.dispatchHideCountdownOverlay()
      } else {
        setTimeout(() => {
          this.startRecording()
          this.props.dispatchHideCountdownOverlay()
        }, 10000)
        this.props.dispatchToggleStartSignal()
        this.props.dispatchShowCountdownOverlay()
      }
    }

    return (
      <React.Fragment>

        { this.state.load && 

          <React.Fragment>

            {/* &&  |||||| */}
            { this.state.inWaitingRoom && this.state.groupMode &&

              <WaitingRoom 
                isGroupHost={this.state.isGroupHost}
                toggleGroupMode={() => {
                  this.toggleGroupMode(false)
                }}
                fullscreenRaceMode={() => {
                  this.fullscreenRaceMode(false)
                }}
                userList={this.state.userList}
                waitingOnTracker={this.state.groupWaitingOnTracker}
                openTrackerPanel={this.openTrackerPanel}
                groupRaceStarted={this.state.groupRaceStarted}
                joinSync={() => {
                  this.setState({
                    joinGroupRace: true
                  })
                }}
              /> 

            }

            <Indicator race={this.props.metadata}/>

            { this.state.isTrackerConnected && this.props.electronMode &&
              <Gauges tracker_data={this.state.tracker_data}/>
            }

            { this.props.electronMode && this.props.video.mode == 'race' &&
              <RaceLeaderboard
                currentTime={this.currentTime}
                leaderboard={this.state.leaderboard_store}
                sync_leaderboard_store={this.state.sync_leaderboard_store}
                groupMode={this.state.groupMode}
              />
            }

            { this.props.video.mode == 'race' && this.props.countdown_overlay_visible &&
              <Countdown />
            }

            <VideoOverlay
              url = { this.state.video_url }
              processingVideo = {this.props.disableTracker}
              metadata = { this.props.metadata }
              stopTrack = { this.stopTrack }
              toggleFull={this.toggleFullscreenMode}
              setTime={this.setTime}
              isTrackerConnected={this.state.isTrackerConnected}
              accumulated_power={this.state.accumulated_power}
              fullscreenRaceMode={this.fullscreenRaceMode}
              fullscreenPlayMode={this.fullscreenPlayMode}
              openTrackerPanel={this.openTrackerPanel}
              currentTime={this.currentTime}
              toggleGroupMode={() => {
                this.setState({
                  isGroupHost: true
                }, () => {
                  this.toggleGroupMode(true)
                })
              }}
            />
            
            <VideoControls
              isTrackerConnected={this.state.isTrackerConnected}
              disableTracker={this.props.disableTracker}
              videoRef={this.state.video}
              currentTime={this.currentTime}
              control_store={this.state.control_store}
              stop={this.stopVideo}
              play={this.playVideo}
              pause={this.pauseVideo}
              restart={this.restartVideo}
              setMute={this.setMute}
              toggleFull={this.toggleFullscreenMode}
              startRecording={this.startRecording}
              feedback={this.state.muteFeedback}
              setTime={this.setTime}
              muteFeedback={(value) => {
                this.setState({
                  muteFeedback: value
                }, () => {
                  if (value) {
                    this.setControlFeedbackToZero()
                  }
                })
              }}
            />

          </React.Fragment>

        }

        { !this.state.load &&

          <div></div> 

        }

      </React.Fragment>

    )

  }

}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    track: state.currentTrack,
    start_signal: state.general.startSignal,
    video: state.video,
    electronMode: state.general.electronMode || false,
    currentRace: state.video.race,
    overlay: state.overlay,
    countdown_overlay_visible: state.video.countdown_overlay_visible,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatchSaveCurrentTrack:   (data)  => { dispatch(saveCurrentTrack(data))},
    dispatchToggleStartSignal:      ()  => { dispatch(toggleStartSignal())},
    dispatchShowOverlay:     (message)  => {
      if(ownProps.demo_mode == true){
        dispatch(showCountdownOverlay())
      }
      else{
        dispatch(showOverlay(message))
      }
    },
    //dispatchPreviewComplete:        ()  => { dispatch(setPreviewComplete())},
    dispatchSetPlayingState:   (value)  => { dispatch(setPlayingState(value))},
    dispatchSetRecordingState: (value)  => { dispatch(setRecordingState(value))},
    dispatchSetPausedState:    (value)  => { dispatch(setPausedState(value))},
    dispatchTurnOnTheaterMode: (value)  => { dispatch(turnOnTheaterMode())},
    dispatchTurnOffTheaterMode: (value) => { dispatch(turnOffTheaterMode())},
    dispatchSetGlobalMuteStatus: (value) => { dispatch(setGlobalMuteStatus(value))},
    dispatchHideCountdownOverlay:  ()     => { dispatch(hideCountdownOverlay()) },
    dispatchShowCountdownOverlay: () => { dispatch(showCountdownOverlay())},
    dispatchOverrideDuration: (value) => { dispatch(overrideDuration(value))},
    dispatchHideOverlay: (value) => { dispatch(hideOverlay())},
    dispatchEnterRaceMode: () => { dispatch(enterRaceMode())},
    dispatchEnterPlayMode: () => { dispatch(enterPlayMode())},
    dispatchTurnOnPlayMode: () => { dispatch(turnOnPlayMode())},
    dispatchUpdateUser: (value) => { dispatch(updateUser(value))}
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tracker));