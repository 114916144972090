import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect }from "react-router-dom";
import Navbar from './navbar.component';
import HomePage from './homepage.component'

import {Helmet} from "react-helmet";

const Home = ({electronMode, demoMode}) => {

//there's another redirect in login component (this is only relevant if login redirects to "/" and not "/activities"):
	if(electronMode){
		return <Redirect to='/featured' />
	}

	if(demoMode){
	  return <Redirect to='demo' />
	}

	return (

      <React.Fragment>
		<Helmet>
		    <meta name="Description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
		    <meta property="og:url" content="https://vidfitness.com"/>
		    <meta property="og:title" content="VidFitness - Fun Indoor Cycling Workout App"/>
		    <meta property="og:image" content="https://vidfitness.com/fb-og-image-trainer.jpg"/>
		    <meta property="og:description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
		    <meta property="og:type" content="website" />
		    <meta property="fb:app_id" content="432091207556688"/>
		</Helmet>
		<div>
			<Navbar />
			<HomePage />
		</div>
      </React.Fragment>
	)
}

const mapStateToProps = (state) => {
  return {
  	electronMode: state.general.electronMode,
  	demoMode:     state.general.demoMode
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);

