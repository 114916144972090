import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isBrowser, BrowserView, isMacOs } from "react-device-detect";
import "../styles/homepage-override.css";

const Footer = () => {

  return (

  <React.Fragment>

    {/*<div className="footer footer-color text-center mt-5 pt-2">
        <div className="container text-center pt-1 pb-2">
          <div className="row">
            <div className="col mx-auto my-auto text-center">
              <Link to="/about">About</Link>&nbsp;|&nbsp;
              <Link to="/setup">Help</Link>&nbsp;|&nbsp;
              <Link to="/pricing">Pricing</Link>&nbsp;|&nbsp;
              <a href="mailto:contact@vidfitness.com">Contact</a>
            </div>
          </div>
        </div>
      </div>*/}

      <div className="container-fluid text-center py-1 py-sm-2 mt-5 footer mb-5">
        <div className="row">
          <div className="mx-auto small">
            &#169; 2020 VidFitness.&nbsp;
            <a className="footer-link" href="/terms">Terms</a> | <a className="footer-link" href="/privacy-policy">Privacy</a>
          </div>
        </div>
        <div className="row">
          <div>
          &nbsp;
          </div>
        </div>        
      </div>

  </React.Fragment>
  )
}


const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
