import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect }from "react-router-dom";
import Navbar from './navbar.component';
import HomePage from './homepage.component'
import { updateUser } from '../actions/userActions'
import { Link } from "react-router-dom";

class PaymentSuccess extends Component {

	constructor(props){
		super(props)
	}

	componentDidMount() {
		const reloadUser = () => {

    if(this.props.user.id != ''){

      const options = {
          method: 'GET',
          mode: 'cors',
          cache: 'default'
      };

      fetch((process.env.REACT_APP_SERVER)+'/api/v1/users/' + this.props.user.id, options)
        .then(response => {

          response.json()
            .then(user => {
              console.log('FORCED USER RELOAD POST PAYMENT')
              this.props.dispatchUpdateUser(user)
            });
        })
    	}
  	}

  	reloadUser()
  }

	delayRedirect () {
    const { history: { push } } = this.props;
    setTimeout(()=>push('/profile'), 8000);
  }

	render() {

	return (
		<div className="login-panel">
			<div className="container-centered">
			  <div>
		      <div className="center-align">
		        <Link to="/"><img src="./vidfitness-red.png" className="logo-main"/></Link>
		      </div>
			      <div className="center-align">
	            <span className="successfully-logged-in"> You have sucessfully purchased a membership. Please wait while we redirect you.</span>
	            <p style={{marginTop: '10px'}}>If you are not automatically redirected, please click <a href="/profile">here.</a></p>
	          </div>
		    	</div>
		    	{this.delayRedirect()}
			</div>
		</div>
	)
}

}

const mapStateToProps = (state) => {
	const { general, user } = state
  return {
  	user: user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  	dispatchUpdateUser: (data) => { dispatch(updateUser(data)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);
