import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Navbar from './navbar.component'
import Footer from './footer.component'
import axios from 'axios';
import { saveTracks, appendTracks } from '../actions/trackActions'
import { updateUser } from '../actions/userActions'
import Track from './track.component'
import { isUserStravaAcessTokenFresh, refreshStravaAccessToken, process_tracks } from '../utils/utils'
import ProfileStatusWrapper from './profile-status-wrapper.component'
import {Helmet} from "react-helmet";
import '../styles/activities.css'
import ActivityList from './activity-list.component'
import ActivitiesPlaceholder from './activities-placeholder.component'
import Skeleton from 'react-loading-skeleton';
var moment = require('moment')

class TracksList extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };

  }

  componentWillMount() {

  }

  componentDidMount() {
  }


  render() {

    if(this.props.demoMode && this.props.electronMode){
      return <Redirect to='demo' />
    }

    // console.log('tracks count: '+ this.props.tracks.length)

    return (



    <React.Fragment>
      <Helmet>
          <meta name="Description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
          <meta property="og:url" content="https://vidfitness.com/activities"/>
          <meta property="og:title" content="VidFitness - Activities of Members"/>
          <meta property="og:image" content="https://vidfitness.com/vidfitness-screen.jpg"/>
          <meta property="og:description" content="Bringing the fun of outdoor sports into indoor training so you can reach your fitness goals faster. Upload videos, share, and cycle interactively on your home trainer."/>
          <meta property="og:type" content="website" />
          <meta property="fb:app_id" content="432091207556688"/>
      </Helmet>
      <div>
        <Navbar />
        <br />

        <div>
          { this.props.signed_in &&
            <ProfileStatusWrapper
              onActivities={true}
            >
              {refreshUser => (
                <ActivityList
                  refreshUser={refreshUser}/>
              )}
            </ProfileStatusWrapper>
          }
          { !this.props.signed_in &&
            <div className="mt-3">
              <ActivitiesPlaceholder />
            </div>
          }

        </div>

        { !this.props.electronMode &&
          <Footer />
        }

      </div>
    </React.Fragment>
    )
  }
}


const mapStateToProps = (state) => {
  let signed_in

  if (state.user.id == '') {
    signed_in = false
  } else {
    signed_in = true
  }


  return {
    tracks:       state.tracks,
    user:         state.user,
    electronMode: state.general.electronMode,
    demoMode:     state.general.demoMode,
    signed_in: signed_in
  }
}

              // <div className="col-12 mb-3">
              //   <h1 className="text-left" style={{fontSize:'4.75rem', paddingLeft:'3%', paddingBottom:3}}>
              //     <b>My Activities</b>
              //   </h1>
              // </div>


 //                 <ul className="list-group list-group-flush">
   //                 <li className="list-group-item">Cras justo odio</li>
     //               <li className="list-group-item">Dapibus ac facilisis in</li>
       //             <li className="list-group-item">Vestibulum at eros</li>
         //         </ul>

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchAppendTracks: (tracks) => { dispatch(appendTracks(tracks))},
    dispatchSaveTracks: (tracks) => { dispatch(saveTracks(tracks)) },
    dispatchUpdateUser: (data) => { dispatch(updateUser(data)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TracksList);
