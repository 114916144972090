import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect }from "react-router-dom";
import Navbar from './navbar.component';
import HomePage from './homepage.component'
import { Link } from "react-router-dom";

const LoginSuccess = ({electronMode}) => {
	return (
		<div className="login-panel">
		  <div className="container-fluid container-centered">
			<div>
		      <div className="center-align">
		        <Link to="/"><img src="./vidfitness-red.png" className="logo-main"/></Link>
		      </div>
		      <div className="center-align">
				<span>You are successfully logged in. You can close this tab now.</span>
			  </div>
		    </div>
    	  <div>  
        </div>
		
	</div>
</div>

	)
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginSuccess);

