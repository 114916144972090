// Utility methods
import axios from 'axios'
import placeholder from '../images/placeholder.png'
import upload from '../images/upload-challenge.jpg'
var moment = require('moment')

export const determineChallengeThumbnail = (challenge) => {
  if (challenge.image_url == '') {
    if (challenge.challenge_type == 'RACE_BASED') {
      const goal_payload = JSON.parse(challenge.goal_payload)
      let [ _ , thumb_url ] = getInfoFromVideoUrl(goal_payload.video_subset[0])
      return thumb_url
    } else if (challenge.challenge_type == 'UPLOAD_BASED') {
      return upload
    } else {
      return placeholder
    }
  } else {
    return challenge.image_url
  } 
}

export const isVIP = (user) => {
  // var VIPs = ["vee@vidfitness.com", "stephendrobinson@gmail.com", "pranjalsingh@college.harvard.edu", "pranjalsursingh@gmail.com"]

  // if (user.id == '') {
  //   return false
  // } else {
  //   return VIPs.includes(user.email)
  // }
  return true
}

// Only returns true if the user has a Strava Access token and it's not expired
// or close to expiration date
export const isUserStravaAcessTokenFresh = (user) => {

  // Checks access token exist
  if( user.strava_access_token != '' && user.strava_access_token != null ){
    // Checks expiration date is number (seconds since epoch)
    if(typeof user.strava_access_token_expires_at == 'number'){

      // If is less than 3600 seconds away from expiration returns false
      // Example: if 10.000 is expiration date
      // anything bigger than 7400 should return expired

      var d = new Date();
      var seconds_since_epoch = d.getTime() / 1000;
      if(seconds_since_epoch >= user.strava_access_token_expires_at - 3600){
        // it's expired so it returns false
        return false
      }
      else{
      	// it's a fresh token
        return true
      }

    } else {
      // expiration date doesn't exist
      return false
    }

  } else {
    // there's no access token saved
    return false
  }

}

// Refreshes token on backend (saves new token on DB) and returns the user
export const refreshStravaAccessToken = (user) => {
	let data = {
	  user_id: user.userId
	}

	const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    cache: 'default'
	};

	return fetch((process.env.REACT_APP_SERVER)+'/api/v1/auth/strava-refresh-token', options)
	  .then(user => {
	    return user.json()
	  })
}

export const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const convertToDoubleDigits = (number) => {
  switch(number) {
    case 0:
      return '00'
      break;
    case 1:
      return '01'
      break;
    case 2:
      return '02'
      break;
    case 3:
      return '03'
      break;
    case 4:
      return '04'
      break;
    case 5:
      return '05'
      break;
    case 6:
      return '06'
      break;
    case 7:
      return '07'
      break;
    case 8:
      return '08'
      break;
    case 9:
      return '09'
      break;
    default:
      return number
  }
}


// Compares two objects
const objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length
        && Object.keys(o1).every(p => o1[p] === o2[p]);

// Comprares two arrays of objects
export const arraysEqual = (a1, a2) => {
  return a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));
}

export const removeLastSegment = (url) => {
  var to = url.lastIndexOf('/');
  to = to == -1 ? url.length : to + 1;
  return url.substring(0, to);
}



const getVideosBucket = (param) => {
  if(param == 'web'){
    return process.env.REACT_APP_WEBSITE_VIDEOS_BUCKET
  }
  else{
    return process.env.REACT_APP_ELECTRON_VIDEOS_BUCKET
  }
}

export const getInfoFromVideoUrl = (url, image_number = 1, bucket) => {

  let videos_bucket = getVideosBucket('electron');

  if(typeof(url) == 'undefined'){
    return ['','']
  }
  let thumb_url = videos_bucket + '/' + url + '/thumbs-'+ url + '-0000'+ image_number +'.jpg'
  return [url, thumb_url]
}

export const getS3VideoUrlElectronVersion = (url) => {

  if(typeof(url) == 'undefined'){
    return ['']
  }

  let videos_bucket = getVideosBucket('electron');

  let s3_video_url = videos_bucket + '/' + url + '/index.mpd'

  return s3_video_url
}

export const getS3VideoUrlHlsElectronVersion = (url) => {

  if(typeof(url) == 'undefined') {
    return ['']
  }

  let videos_bucket = getVideosBucket('electron');

  let s3_video_url = videos_bucket + '/' + url + '/hls/index.m3u8'

  return s3_video_url
}


// param URL -> string of format '1576130048998-vr'
export const getS3VideoUrlHlsWebVersion = (url) => {

  if(typeof(url) == 'undefined'){
    return ['']
  }

  let videos_bucket = getVideosBucket('web');
  return videos_bucket + '/' + url + '/hls/index.m3u8'

}


// param URL -> string of format '1576130048998-vr'
export const getS3VideoUrlDashWebVersion = (url) => {

  if(typeof(url) == 'undefined') { 
    return ['']
  }

  let videos_bucket = getVideosBucket('web');

  return videos_bucket + '/' +  url + '/dash/index.mpd'

}

export const extractPeaks = (layer) => {
  const WaveformData = require('waveform-data');

  const createJSON = (data) => {

    function roundTo(n, digits) {
      var negative = false;
      if (digits === undefined) {
          digits = 0;
      }
          if( n < 0) {
          negative = true;
        n = n * -1;
      }
      var multiplicator = Math.pow(10, digits);
      n = parseFloat((n * multiplicator).toFixed(11));
      n = (Math.round(n) / multiplicator).toFixed(2);
      if( negative ) {    
          n = (n * -1).toFixed(2);
      }
      return n;
    }

    const channel = data.channel(0);
    var maxes = channel.max_array()
    var mins = channel.min_array()
    var arrayCombined = []

    mins.forEach((value,index) => {
      arrayCombined.push(value)
      arrayCombined.push(maxes[index])
    })

    var finalPeaks = []

    var max_value = Math.max(...maxes)

    arrayCombined.forEach((el) => {
      finalPeaks.push(roundTo((el / max_value), 2))
    })
    
    const payload = {
      version: 2,
      channels: data.channels,
      bits: data.bits,
      length: data.length,
      sample_rate: data.sample_rate,
      samples_per_pixel: data.scale,
      data: finalPeaks
    }

    return payload
  }

  return new Promise((resolve, reject) => {

    const url = layer.url
    const audioContext = new AudioContext();

    fetch(url)
      .then(response => response.arrayBuffer())
      .then(buffer => {
        const options = {
          audio_context: audioContext,
          array_buffer: buffer,
          scale: 128
        };
     
        return new Promise((resolve, reject) => {
          WaveformData.createFromAudio(options, (err, waveform) => {
            if (err) {
              reject(err);
            }
            else {
              resolve(waveform);
            }
          });
        });
      })
      .then(waveform => {
        resolve(createJSON(waveform))
      });
  })
}

export const whichChallengesToRecalculate = (user, race_url) => {
  const { user_challenges } = user

  var ids = []

  user_challenges.map((UC) => {

    switch (UC.challenge_type) {
      case 'POWER_BASED':
        ids.push(UC.challenge_id)
        break;

      case 'TIME_BASED': 
        ids.push(UC.challenge_id)
        break;

      case 'DISTANCE_BASED':
        ids.push(UC.challenge_id)
        break;

      case 'RACE_BASED':
        var goals = JSON.parse(UC.goal_payload)

        if (goals.video_subset.includes(race_url)) {
          ids.push(UC.challenge_id)
        }
        break;
      case 'UPLOAD_BASED':
        ids.push(UC.challenge_id)
        break;
      default:
        break;
    }
  })

  console.log(ids)

  //remove duplicates
  return [...new Set(ids)];
}

export const recalculateRankings = (challenge_ids, user_id) => {
  return new Promise((resolve, reject) => {
    var payload = {
      challenge_ids: challenge_ids,
      user_id: user_id
    }

    axios.post((process.env.REACT_APP_SERVER) + '/api/v1/challenge/recalculate-rankings', payload).then((new_user) => {
      console.log(new_user)
      resolve(new_user)
    })
        
  })
}

export const getMiles = (i) => {
  return i*0.000621371192;
}

export const getMeters = (i) => {
  return i*1609.344;
}

export const formatPower = (i) => {
  return (Math.round(i / 36.0)/100.0)
}

export const formatTime = (i) => {
  return (i  / 60)
}

export const formatDistance = (i) => {
  return i*0.000621371192
}

export const imgError = (ev) => {
  ev.target.src = placeholder
  return true
}




