import initialState from './initialState';
import * as types from '../actions/actionTypes';

const uiReducer = (state = initialState.ui, action) => {
  switch (action.type) {
    case types.TOGGLE_PROFILE_FORM_EDITABILITY:
      return { ...state, isProfileFormEditable: !state.isProfileFormEditable }
    case types.TOGGLE_PROFILE_FORM_EDITABILITY:
      return { ...state, isVideoFormEditable: !state.isVideoFormEditable }
    case types.TOGGLE_LOADING_STATE:
      return { ...state, isLoading: !state.isLoading }
    case types.TOGGLE_SHOW_ALERT:
      return { ...state, showAlert: !state.showAlert}
    default:
      return state;
  }
}

export default uiReducer;