import React, { Component } from 'react';
import { connect } from 'react-redux'
import store from '../index.js';
import config from '../config.json';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogo from '../images/google.png';
import GoogleLogin from 'react-google-login';
import { authenticate } from '../actions/userActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect }from "react-router-dom";
import { Link } from "react-router-dom";
import queryString from 'query-string';
import PrivacyText from './privacy-text.component'
import TermsText from './terms-text.component'
import '../styles/misc.css'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

// This mock component is needed to avoid getting an error
// on the web version
const VrGoogleLogin = () => {
  return null
}

const sendGoogleTokensToBackend = (response) => {

  const tokenBlob = new Blob([JSON.stringify({access_token: response.access_token}, null, 2)], {type : 'application/json'});

  const options = {
      method: 'POST',
      body: tokenBlob,
      mode: 'cors',
      cache: 'default'
  };

  fetch((process.env.REACT_APP_SERVER)+'/api/v1/auth/google', options).then(r => {

      const token = r.headers.get('x-auth-token');
      r.json().then(user => {
          if (token) {

            store.dispatch(authenticate({
              photoUrl: user.photo_url,
              email:    user.email,
              authorized: user.authorized,
              ...user,
              token })
            )

            localStorage.setItem('token', token);
            localStorage.setItem('user_id', user.id);
            localStorage.setItem('vr_env', process.env.NODE_ENV);
          }
      });
  })
}



class Login extends Component {

  constructor(props) {
    super(props)

    this.modalTOS = this.modalTOS.bind(this)
    this.modalPP = this.modalPP.bind(this)
  }


  componentDidMount(){

    if(this.props.electronMode){

      const { ipcRenderer } = window.require('electron')

      ipcRenderer.on('google-tokens-received', (event, arg) => {
        sendGoogleTokensToBackend(arg)
      })

      // const VrGoogleLogin = () => (
      //   <a onClick={ () => { ipcRenderer.send('external-browser-google-auth')  }} className="sign-in-button">
      //     <img src={GoogleLogo} alt="Logo" className="google_logo"/>
      //     Sign up with Google
      //   </a>
      // )

    }

  }

  goToSignUpPage(){

  }

  modalTOS() {
    const MySwal = withReactContent(Swal)

    MySwal.fire({ width: '90%', html: <TermsText />})
  }

  modalPP() {
    const MySwal = withReactContent(Swal)

    MySwal.fire({ width: '90%', html: <PrivacyText />})
  }

  renderGoogleLogin(){
    const { ipcRenderer } = window.require('electron')

    return (
      <a onClick={ () => { ipcRenderer.send('external-browser-google-auth')  }} className="sign-in-button">
        <img src={GoogleLogo} alt="Logo" className="google_logo"/>
        Sign Up / Log in with Google
      </a>
    )
  }

  facebookResponse = (response) => {
      const tokenBlob = new Blob([JSON.stringify({access_token: response.accessToken}, null, 2)], {type : 'application/json'});
      const options = {
          method: 'POST',
          body: tokenBlob,
          mode: 'cors',
          cache: 'default'
      };
      fetch((process.env.REACT_APP_SERVER)+'/api/v1/auth/facebook', options).then(r => {
          const token = r.headers.get('x-auth-token');
          r.json().then(user => {
              if (token) {
                this.props.dispatchAuthenticate({ ...user, token })
                localStorage.setItem('token', token);
                localStorage.setItem('user_id', user.id);
                localStorage.setItem('vr_env', process.env.NODE_ENV);
              }
          });
      })
  };

  googleResponse = (response) => {
      const tokenBlob = new Blob([JSON.stringify({access_token: response.accessToken}, null, 2)], {type : 'application/json'});

      const options = {
          method: 'POST',
          body: tokenBlob,
          mode: 'cors',
          cache: 'default'
      };
      fetch((process.env.REACT_APP_SERVER)+'/api/v1/auth/google', options).then(r => {

          const token = r.headers.get('x-auth-token');
          r.json().then(user => {
              if (token) {
                this.props.dispatchAuthenticate(
                  {
                    ...user,
                    photoUrl: response.profileObj.imageUrl,
                    email: response.profileObj.email,
                    authorized: user.authorized,
                    token }
                  )
                localStorage.setItem('token', token);
                localStorage.setItem('user_id', user.id);
                localStorage.setItem('vr_env', process.env.NODE_ENV);
              }
          });
      })
  };

  render(){


    if(this.props.user.id != ''){

      let queryParams = queryString.parse(this.props.location.search)

      if(queryParams['redirect'] == 'profile'){
        this.props.history.push('/profile')
      }
      else{
      // default page loaded:
       return <Redirect to='/featured' />
      }
    }

    return (
      <div>
      <div className="login-panel">

          <div className="container-fluid container-centered pt-3">
              <div className="no-gutters">

                  <div className="center-align">
                    <Link to="/"><img src="./vidfitness-red.png" className="logo-main"/></Link>
                </div>

                <div className="pt-2 center-align col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">

                  <div className="row mb-0">
                    <div className="col mt-3 mr-1 ml-1 text-left">
                        <h5><b>Returning user?</b> Log in:</h5>
                      </div>
                  </div>

                    {/* Disabled for the moment */}
                    { false && <FacebookLogin
                        appId={config.FACEBOOK_APP_ID}
                        autoLoad={false}
                        fields="name,email,picture"
                        callback={this.facebookResponse}
                        render={renderProps => (
                          <a onClick={renderProps.onClick} className="facebook-sign-in-button sign-in-button">
                            <FontAwesomeIcon className='facebook-icon' icon={['fas', 'facebook-square']}/>
                            Sign up with Facebook
                          </a>
                        )}
                    ></FacebookLogin> }

                    <div className="pt-0 center-align">
                      <a onClick={ () => this.props.history.push('login-email') } className="sign-in-button">
                        <FontAwesomeIcon className='envelope-icon' icon={['fa', 'envelope']}/>
                        Log in with email
                      </a>
                    </div>

                    { this.props.electronMode && this.renderGoogleLogin() }

                    { !this.props.electronMode &&
                      <GoogleLogin
                          clientId={config.GOOGLE_CLIENT_ID}
                          render={renderProps => (
                            <a onClick={renderProps.onClick} className="sign-in-button">
                              <img src={GoogleLogo} alt="Logo" className="google_logo"/>
                              Log in with Google
                            </a>
                          )}
                          onSuccess={this.googleResponse}
                          onFailure={this.googleResponse}
                          cookiePolicy={'single_host_origin'}
                        ></GoogleLogin>
                    }

                  <div className="row mb-0">
                    <div className="col mt-5 mr-1 ml-1 text-left">
                      <h5><b>New user?</b> Sign up:</h5>
                    </div>
                  </div>                    

                  <div className="center-align">
                    <a onClick={ () => this.props.history.push('sign-up') } className="sign-in-button">
                      <FontAwesomeIcon className='envelope-icon' icon={['fa', 'envelope']}/>
                      Sign up with email
                    </a>
                  </div>

                  { this.props.electronMode && this.renderGoogleLogin() }

                  { !this.props.electronMode &&
                    <GoogleLogin
                        clientId={config.GOOGLE_CLIENT_ID}
                        render={renderProps => (
                          <a onClick={renderProps.onClick} className="sign-in-button">
                            <img src={GoogleLogo} alt="Logo" className="google_logo"/>
                            Sign up with Google
                          </a>
                        )}
                        onSuccess={this.googleResponse}
                        onFailure={this.googleResponse}
                        cookiePolicy={'single_host_origin'}
                      ></GoogleLogin>
                  }

                    {/* <hr /> */}

                    <div className="center-align mt-3 mb-3 terms-and-services-panel-2">
                        By using VidFitness, you agree to the <b><a href="#" style={{cursor:'pointer'}} onClick={this.modalTOS}>Terms of Service</a></b> and <b><a href="#" style={{cursor:'pointer'}} onClick={this.modalPP}>Privacy Policy.</a></b>
                    </div>

                  </div>
              </div>
          </div>
      </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    electronMode: state.general.electronMode
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchAuthenticate: (user_info) => { dispatch(authenticate(user_info)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

